import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Divider from 'components/Divider';
import Banner from 'components/Banner';
import * as authActions from 'actions/auth';
import helpers from 'helpers';
import Auth from 'schemas/auth';
import M from './Login.locale.json';
import './Login.scss';

import intlTypes from 'types/intl';

const mapState = state => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class LoginWithEmail extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		user: PropTypes.object,
	}

	state = {
		loader: false,
		form: {
			email: '',
			password: ''
		},
		messages: [],
		errors: {
			email: [],
			password: []
		},
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.login);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions } = this.props;
		const { form, loader } = this.state;
		if (loader) return;

		const errors = helpers.validator.all(form, Auth.login);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ messages: [], loader: true });

		const response = await actions.auth.login(form);

		if (response && response.errors) {
			const update = {
				errors: { ...this.state.errors, ...response.errors },
				loader: false,
			};
			if (response.messages) update.messages = response.messages;
			this.setState(update);
		}
	}

	render () {
		const { form, errors, messages, loader } = this.state;
		const { user, intl } = this.props;
		const t = intl.formatMessage;

		if (user) return <Redirect to={{ pathname: '/' }} />;

		return (
			<div>
				{messages && !!messages.length && <Banner type="error" data={messages} />}
				<Form onSubmit={this.onSubmit}>
					<Form.Group>
						<Input
							name="email"
							placeholder={t(M.inputs.email)}
							onChange={this.onChange}
							value={form.email}
							errors={errors.email}
							autoFocus
						/>
					</Form.Group>
					<Form.Group>
						<Input
							id="password-login-email"
							name="password"
							type="password"
							placeholder={t(M.inputs.password)}
							onChange={this.onChange}
							value={form.password}
							errors={errors.password}
						/>
					</Form.Group>
					<Button form loading={loader}>{t(M.buttons.login)}</Button>
				</Form>
				<Form.Note>
					{`${t(M.messages.signup)} `}
					<ButtonLink to="/auth/signup/private">{t(M.buttons.signup)}</ButtonLink>
				</Form.Note>
				<Divider />
				<Form.Note>
					{`${t(M.messages.reset)} `}
					<ButtonLink to="/auth/reset">{t(M.buttons.reset)}</ButtonLink>
				</Form.Note>
			</div>
		);
	}
}
