import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import * as cardSelector from 'selectors/card';
import * as pfsPaymentActions from 'actions/pfs';
import intlTypes from 'types/intl';
import './Load.scss';
import './PFS.scss';

const mapState = state => ({
	currentCard: cardSelector.getById(state, state.card.current),
	unverifiedCards: cardSelector.getUnverifyCards(state)
});

const mapDispatch = dispatch => ({
	actions: bindActionCreators(pfsPaymentActions, dispatch),
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Sepa extends Component {
	static propTypes = {
		intl: intlTypes.isRequired,
		actions: PropTypes.shape({
			performPayment: PropTypes.func.isRequired
		})
	};

	state = {
		amount: 0,
	};

	onChange = value => {
		this.setState({ amount: value });
	};

	onSubmit = e => {
		const { amount } = this.state;

		e.preventDefault();

		this.props.actions.performPayment({ amount });
	};

	render () {
		const { intl } = this.props;
		const t = intl.formatMessage;

		return (
			<div className="payment-section-pfs">
				<p className="payment-section-pfs__description">
					{t({ id: 'payments.pfs.section.description' })}
				</p>

				<Form onSubmit={this.onSubmit}>
					<Input
						type="number"
						className="payment-section-pfs__input"
						name="amount"
						placeholder={t({ id: 'payments.pfs.section.form.price' })}
						onChange={this.onChange}
					/>

					<Button
						className="payment-section-pfs__button"
						form
						small
					>
						{t({ id: 'payments.pfs.section.form.button' })}
					</Button>
				</Form>
			</div>
		);
	}
}
