import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from '../../Loader';
import Tooltip from '../../Tooltip';
import Icon from '../../Icon';

import './Preview.scss';

class Preview extends React.PureComponent {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			preview: PropTypes.string,
			type: PropTypes.string,
			loading: PropTypes.bool,
			errors: PropTypes.array
		}),
		onRemove: PropTypes.func,
		onDownload: PropTypes.func,
		className: PropTypes.string,
	}

	onEnter = e => e.key === 'Enter' && this.onClick()
	onClick = () => this.props.onRemove(this.props.item.id)

	render () {
		const { className, item, onDownload } = this.props;
		const { type, preview, name, loading, errors } = item;

		const previewContent = /(jpg|jpeg|png)/.test(type)
			? <img src={preview} alt={name} className="InputFilePreview__Image" />
			: (
				<div className="InputFilePreview__Placeholder">
					<Icon className="InputFilePreview__Icon" name="file" />
					<h5>{name.split('.').pop()}</h5>
				</div>
			);

		return (
			<div className={classnames('InputFilePreview', className)}>
				{!loading && (
					<div className="InputFilePreview__Preview">
						<a
							className="InputFilePreview__Download"
							href={`data:application/octet-stream;filename=${name},${encodeURIComponent(preview)}`}
							download={name}
						>
							<Icon name="download" />
						</a>

						{onDownload && (
							<div
								className="InputFilePreview__Download"
								onClick={onDownload}
								role="button"
								tabIndex={0}
							>
								<Icon name="download" />
							</div>
						)}

						{errors && !!errors.length && (
							<div className="InputFilePreview__Errors">
								<div>{errors[0]}</div>
							</div>
						)}
						{previewContent}
					</div>
				)}

				<Tooltip className="InputFilePreview__Tooltip" title={name} />
				{loading && <Loader className="InputFilePreview__Loader" />}
				<div
					onClick={this.onClick}
					className="InputFilePreview__Remove"
					onKeyUp={this.onEnter}
					tabIndex={0}
					role="button"
				>
					<Icon name="close-small" />
				</div>
			</div>
		);
	}
}

export default Preview;
