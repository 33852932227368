import DocumentType from 'constants/document';

const map = {
	certificateOfRegistration: DocumentType.types.CERTIFICATE_OF_REGISTRATION,
	memorandumOfAssociation: DocumentType.types.ARTICLE_OF_ASSOCIATION,
	annualReturn: DocumentType.types.ANNUAL_RETURN,
	shareholderDetails: DocumentType.types.CONFIRMATION_OF_SHAREHOLDING_DETAILS,
	companyDirectors: DocumentType.types.CONFIRMATION_OF_COMPANY_DIRECTORS,
	evidenceOfBankAccount: DocumentType.types.BANK_ACCOUNT_EVIDENCE,
};

export default function document (type) {
	if (!type) return type;
	return map[type] || type;
}
