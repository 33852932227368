import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import enhanceWithClickOutside from 'react-click-outside';
import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import { getCurrentCard, fetch } from 'selectors/card';
import * as cardActions from 'actions/card';

import './CardSelect.scss';
import currentCardTypes from 'types/currentCard';

const mapState = state => ({
	cards: fetch(state),
	currentCard: getCurrentCard(state)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
@enhanceWithClickOutside
export default class CardSelect extends React.PureComponent {
	static propTypes = {
		dropdown: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
				]),
				title: PropTypes.string,
				subtitle: PropTypes.string,
			})
		),
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}),
		className: PropTypes.string,
		currentCard: currentCardTypes,
		actions: PropTypes.shape({
			card: PropTypes.shape({
				current: PropTypes.func.isRequired,
			})
		}),
		cards: PropTypes.array,
		showCardDataFulfillmentFlag: PropTypes.bool,
	}

	state = {
		open: false,
	}

	onSelect = current => {
		const { dropdown, actions, cards } = this.props;

		const target = dropdown.find(item => item.value === current.value);
		const targetCard = cards.find(item => item.id === target.value);

		if (!targetCard) return;

		actions.card.current(targetCard.id);
		this.setState({ open: false });
	}

	onDropdown = () => {
		const { open } = this.state;
		this.setState({ open: !open });
	}

	handleClickOutside = () => this.setState({ open: false })

	isCardDataFulfilled = () => this.props.currentCard.udfs

	render () {
		const { open } = this.state;
		const { cards, currentCard, dropdown, showCardDataFulfillmentFlag, className, location } = this.props;
		const controlIsHidden = location.pathname.includes('create') && location.pathname.includes('private');

		if (!cards || !(currentCard && dropdown.length > 0)) return null;

		return !controlIsHidden ? (
			<div className={classnames('CardSelect', className)}>
				<div
					onClick={this.onDropdown}
					role="button"
					tabIndex={0}
					className="CardSelect__Wrap"
				>
					<div className="CardSelect__Name">{currentCard.cardName}</div>
					<div className="CardSelect__Status">{currentCard.cardNumber}</div>
					<div className="CardSelect__Toggle" role="button" tabIndex={0}>
						<Icon className="CardSelect__Icon" name="caveat-down" />
					</div>
					{showCardDataFulfillmentFlag && this.isCardDataFulfilled() && (
						<Icon className="CardSelect__Icon--Verified" name="success" />
					)}
				</div>
				<Dropdown
					className="CardSelect__Dropdown"
					data={dropdown}
					onClick={this.onSelect}
					open={open}
				/>
			</div>
		) : null;
	}
}
