import React from 'react';
import {injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';

import * as modalsActions from 'actions/modals';
import { bindActionCreators } from 'redux';

import './MaintenanceModal.scss';

const mapDispatch = dispatch => ({
	actions: {
		modals: bindActionCreators(modalsActions, dispatch),
	},
});

@injectIntl
@connect(null, mapDispatch)
class MaintenanceModal extends React.Component {
	onClose = () => {
		this.props.actions.modals.toggleMaintenanceModal();
	};

	render() {
		const t = this.props.intl.formatMessage;

		return (
			<Modal className="DashboardSection__VerifyCardModal maintenance-modal">
				<Section>
					<Container medium>
						<Wrap>
						<Title
						    className="maintenance-modal__title"
							title={t({ id: 'modals.maintenance.title' })}
							small
						/>
						<p
						  className="DashboardSection__VerifyCardModal__Info maintenance-modal__subtitle"
						>
							{t({ id: 'modals.maintenance.text' })}
						</p>
						<Button
							className="DashboardSection__VerifyCardModal__Button maintenance-modal__button"
							onClick={this.onClose}
							secondary
							small
						>
						  OK
						</Button>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}

export default MaintenanceModal;
