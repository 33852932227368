import { CLIENT_GET_ADDRESS_SUCCESS } from 'constants';

const initial = null;

const reducer = (state = initial, action) => {
	if (action.type === CLIENT_GET_ADDRESS_SUCCESS)	return { ...action.payload };
	return state;
};

export default reducer;
