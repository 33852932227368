import React, { useMemo, useState, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';

import { NotificationIcon } from '../icons';
import Icon from '../Icon';
import './NotificationsPopup.scss';

const count = 10;

const NotificationsPopup = () => {
	const [isOpened, setIsOpened] = useState();

	const counterView = useMemo(() => count >= 10 ? '9+' : count, [count]);

	const toggleOpen = useCallback(() => setIsOpened(state => !state), []);

	const handleClose = useCallback(e => {
		e.stopPropagation();
		e.preventDefault();
		setIsOpened(false);
	}, []);

	return (
		<div className="notifications-icon-wrap" onClick={toggleOpen}>
			<NotificationIcon className="notifications-icon"  />
			{counterView && <span className="notifications-icon-count">{counterView}</span>}

			{isOpened && (
				<OutsideClickHandler onOutsideClick={handleClose}>
					<div className="notifications-popup-desktop">
						asdasd
					</div>
				</OutsideClickHandler>
			)}

			<div className={cn('notifications-popup-small', { 'notifications-popup-small--open': isOpened })}>
				<Icon className="notifications-popup-small__close" name="close-small" onClick={handleClose} />
			</div>
		</div>
	);
};

export default NotificationsPopup;
