import fetcher from 'helpers/fetcher';

export default {
	fetch (cid, params, bid) {
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/payment/${cid}/card-to-card`, { params });
	},
	get (id, cid, bid) {
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/payment/${cid}/card-to-card/${id}`);
	},
	create (cid, form, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/card-to-card/create`, form);
	},
	amount (id, cid, params, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/card-to-card/${id}/confirm`, {}, { params });
	},
	phone (id, cid, params, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/card-to-card/${id}/sign`, {}, { params });
	},
	code (id, cid, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/card-to-card/${id}/resend-token`);
	},
};
