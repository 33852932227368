import React from 'react';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import PaymentSection from 'sections/Payment';
import Layout from 'components/Layout';
import InternationalPaymentCreate from './components';

export default function InternationalPayment () {
	return (
		<Layout className="InternationalPayment Page" sidebar>
			<Nav />
			<Header />
			<PaymentSection />
		</Layout>
	);
}

InternationalPayment.InternationalPaymentCreate = InternationalPaymentCreate;
