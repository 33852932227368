import { combineReducers } from 'redux';
import {
	PLACE_AUTOCOMPLETE_SUCCESS,
	PLACE_GEOCODE_SUCCESS,
} from 'constants';

const initial = [];

const autocomplete = (state = initial, action) => {
	if (action.type === PLACE_AUTOCOMPLETE_SUCCESS) return [...action.payload];
	return state;
};

const geocode = (state = initial, action) => {
	if (action.type === PLACE_GEOCODE_SUCCESS) return [...action.payload];
	return state;
};

export default combineReducers({
	autocomplete,
	geocode,
});
