import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import M from './Status.locale.json';
import './Status.scss';

const mapStatus = status => {
	switch (status) {
		case 'ISSUED':
			return (
				<Badge className="CardStatus__Status" info>
					<FM id={M.issued.id} />
				</Badge>
			);
		case 'DEPOSIT_ONLY':
			return (
				<Badge className="CardStatus__Status" info>
					<FM id={M.deposit.id} />
				</Badge>
			);
		case 'OPEN':
			return (
				<Badge className="CardStatus__Status" success>
					<FM id={M.open.id} />
				</Badge>
			);
		case 'LOST':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.lost.id} />
				</Badge>
			);
		case 'STOLEN':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.stolen.id} />
				</Badge>
			);
		case 'BLOCKED':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.blocked.id} />
				</Badge>
			);
		case 'PIN_CHANGE_REQUIRED':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.pin.id} />
				</Badge>
			);
		case 'PHONE_NUMBER_VERIFICATION':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.phone.id} />
				</Badge>
			);
		case 'CLOSED':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.closed.id} />
				</Badge>
			);
		case 'DONE':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.done.id} />
				</Badge>
			);
		case 'VERIFIED':
			return (
				<div>
					<Icon name="card-verified" />
					<Badge className="CardStatus__Status" info>
						<FM id={M.verified.id} />
					</Badge>
				</div>
			);
		case 'UNVERIFIED':
			return (
				<div>
					<Icon name="card-unverified" />
					<Badge className="CardStatus__Status" info>
						<FM id={M.unverified.id} />
					</Badge>
				</div>
			);
		case 'PC':
			return (
				<div>
					<Badge className="CardStatus__Status" info>
						<FM id="card.status.plastic" />
					</Badge>
				</div>
			);
		case 'VC':
			return (
				<div>
					<Badge className="CardStatus__Status" info>
						<FM id="card.status.virtual" />
					</Badge>
				</div>
			);
		case null:
			return (
				<div>
					<Badge className="CardStatus__Status" info>
						<FM id="card.status.plastic" />
					</Badge>
				</div>
			);
		default:
			return null;
	}
};

const mapStatusTrunk = status => {
	switch (status) {
		case 'ISSUED':
			return (
				<Badge className="CardStatus__Status" info>
					<FM id={M.issued.id} />
				</Badge>
			);
		case 'DEPOSIT_ONLY':
			return (
				<Badge className="CardStatus__Status" info>
					<FM id={M.deposit.id} />
				</Badge>
			);
		case 'OPEN':
			return (
				<Badge className="CardStatus__Status" success>
					<FM id={M.open.id} />
				</Badge>
			);
		case 'LOST':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.lost.id} />
				</Badge>
			);
		case 'STOLEN':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.stolen.id} />
				</Badge>
			);
		case 'BLOCKED':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.blocked.id} />
				</Badge>
			);
		case 'PIN_CHANGE_REQUIRED':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.pin.id} />
				</Badge>
			);
		case 'PHONE_NUMBER_VERIFICATION':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.phone.id} />
				</Badge>
			);
		case 'CLOSED':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.closed.id} />
				</Badge>
			);
		case 'DONE':
			return (
				<Badge className="CardStatus__Status" error>
					<FM id={M.done.id} />
				</Badge>
			);
		case 'VERIFIED':
			return (
				<div>
					<Icon name="card-verified" />
					<Badge className="CardStatus__Status" info>
						<FM id={M.verified.id} />
					</Badge>
				</div>
			);
		case 'UNVERIFIED':
			return (
				<div>
					<Icon name="card-unverified" />
					<Badge className="CardStatus__Status" info>
						<FM id={M.unverified.id} />
					</Badge>
				</div>
			);
		case 'PC':
			return (
				<div>
					<Badge className="CardStatus__Status" info>
						<FM id="card.status.plastic" />
					</Badge>
				</div>
			);
		case 'VC':
			return (
				<div>
					<Badge className="CardStatus__Status" info>
						<FM id="card.status.virtual" />
					</Badge>
				</div>
			);
		case null:
			return (
				<div>
					<Badge className="CardStatus__Status" info>
						<FM id="card.status.plastic" />
					</Badge>
				</div>
			);
		default:
			return null;
	}
};

export default function CardStatus (props) {
	const { className, trunk } = props;

	const status = trunk
		? mapStatusTrunk(props.status)
		: mapStatus(props.status);

	return (
		<div className={classnames('CardStatus', className)}>
			{status}
		</div>
	);
}

CardStatus.propTypes = {
	status: PropTypes.string,
	trunk: PropTypes.bool,
	className: PropTypes.string,
};
