import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputError from 'components/InputError';
import './Banner.scss';

function Banner (props) {
	const { type, data, className } = props;
	const classes = {
		success: 'Banner--Success',
		warning: 'Banner--Warning',
		error: 'Banner--Error',
		info: 'Banner--Info',
	};

	const content = data.map(item => (
		<div key={item.id} className="Banner__Message">
			{data && data.length > 0 && (
				<InputError
					placeholder="placeholder thing"
					label="something"
					errors={data}
				/>
			)}
		</div>
	));

	return (
		<div className={classnames('Banner', className, classes[type])}>
			{content}
		</div>
	);
}

Banner.propTypes = {
	type: PropTypes.string,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]).isRequired,
			type: PropTypes.string,
		}),
	),
	className: PropTypes.string,
};

export default Banner;
