import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Banner from 'components/Banner';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import InputCheckbox from 'components/InputCheckbox';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import PasswordPolicy from 'components/PasswordPolicy';
import * as authActions from 'actions/auth';
import * as countryActions from 'actions/country';
import * as countrySelector from 'selectors/country';
import helpers from 'helpers';
import reduxer from 'helpers/reduxer';
import Auth from 'schemas/auth';
import M from './Business.locale.json';
import './Business.scss';

import intlTypes from 'types/intl';

const mapState = (state, props) => ({
	language: state.language,
	user: state.user,
	country: countrySelector.getEnrollCountries(state, props, M.countries),
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props, M.countries),
});

const mapDispatch = dispatch => ({
	actions: {
		auth: reduxer.bindNestedActionCreators(authActions, dispatch),
		country: bindActionCreators(countryActions, dispatch)
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Signup extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.shape({
			auth: PropTypes.func.isRequired,
			country: PropTypes.func.isRequired,
		}).isRequired,
		intl: intlTypes.isRequired,
		language: PropTypes.shape({
			locale: PropTypes.string.isRequired,
		}).isRequired,
		user: PropTypes.object.isRequired,
		country: PropTypes.string.isRequired,
		phoneCountry: PropTypes.string.isRequired,
	}

	state = {
		loader: false,
		form: {
			businessName: '',
			registrationNumber: '',
			businessCountry: '',
			name: '',
			surname: '',
			email: '',
			phone: '',
			phoneCountry: '',
			password: '',
			passwordConfirm: '',
			agree: false,
			legalRepresentative: false,
		},
		messages: [],
		errors: {
			businessName: [],
			registrationNumber: [],
			businessCountry: [],
			name: [],
			surname: [],
			email: [],
			phone: [],
			phoneCountry: [],
			password: [],
			passwordConfirm: [],
			agree: [],
			legalRepresentative: [],
		},
	}

	componentDidMount () {
		const { actions } = this.props;
		actions.country.fetch({ entroll: true });
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.signup.business);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions, language } = this.props;
		const { form, loader } = this.state;
		if (loader) return;

		const errors = helpers.validator.all(form, Auth.signup.business);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ messages: [], loader: true });

		form.language = language.locale.toUpperCase();

		const response = await actions.auth.signup.business(form);

		if (response && response.errors) {
			const update = {
				errors: { ...this.state.errors, ...response.errors },
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);
		}
	}

	render () {
		const { user, country, phoneCountry, intl } = this.props;
		const { form, loader, errors, messages } = this.state;
		if (user) return <Redirect to={{ pathname: '/clients/create/start' }} />;

		const t = intl.formatMessage;

		return (
			<Layout className="AuthSignupScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Title title={t(M.title)} legend={t(M.legend)} />
						{messages && !!messages.length && <Banner type="error" data={messages} />}
						<Form onSubmit={this.onSubmit}>
							<Form.Group>
								<Input
									name="businessName"
									placeholder={t(M.inputs.businessName)}
									onChange={this.onChange}
									value={form.businessName}
									errors={errors.businessName}
									autoFocus
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="registrationNumber"
									placeholder={t(M.inputs.registrationNumber)}
									onChange={this.onChange}
									value={form.registrationNumber}
									errors={errors.registrationNumber}
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="businessCountry"
									onChange={this.onChange}
									placeholder={t(M.inputs.businessCountry)}
									value={form.businessCountry}
									errors={errors.businessCountry}
									data={country}
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="name"
									placeholder={t(M.inputs.name)}
									onChange={this.onChange}
									value={form.name}
									errors={errors.name}
								/>
								<Input
									name="surname"
									placeholder={t(M.inputs.surname)}
									onChange={this.onChange}
									value={form.surname}
									errors={errors.surname}
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="email"
									placeholder={t(M.inputs.email)}
									onChange={this.onChange}
									value={form.email}
									errors={errors.email}
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="phoneCountry"
									onChange={this.onChange}
									placeholder={t(M.inputs.phoneCountry)}
									value={form.phoneCountry}
									errors={errors.phoneCountry}
									data={phoneCountry}
								/>
								<Input
									name="phone"
									placeholder={t(M.inputs.phone)}
									onChange={this.onChange}
									value={form.phone}
									errors={errors.phone}
								/>
							</Form.Group>
							<Form.Group row>
								<Input
									name="password"
									type="password"
									placeholder={t(M.inputs.password)}
									onChange={this.onChange}
									value={form.password}
									errors={errors.password}
								/>
								<PasswordPolicy value={form.password} />
							</Form.Group>
							<Form.Group>
								<Input
									name="passwordConfirm"
									type="password"
									placeholder={t(M.inputs.passwordConfirm)}
									onChange={this.onChange}
									value={form.passwordConfirm}
									errors={errors.passwordConfirm}
								/>
							</Form.Group>
							<Form.Group row>
								<InputCheckbox
									name="agree"
									type="agree"
									onChange={this.onChange}
									value="on"
									checked={form.agree}
									errors={errors.agree}
								>
									<FormattedMessage
										id={M.inputs.agree.id}
										values={{
											terms: (
												<ButtonLink
													to={{
														pathname: '/terms-and-services',
														state: { modal: true }
													}}
												>
													{t(M.buttons.terms)}
												</ButtonLink>
											),
											privacy: (
												<ButtonLink
													to={{
														pathname: '/privacy-policy',
														state: { modal: true }
													}}
												>
													{t(M.buttons.privacy)}
												</ButtonLink>
											)
										}}
									/>
								</InputCheckbox>
								<InputCheckbox
									name="legalRepresentative"
									type="legalRepresentative"
									onChange={this.onChange}
									value="on"
									checked={form.legalRepresentative}
									errors={errors.legalRepresentative}
								>
									{t(M.inputs.legalRepresentative)}
								</InputCheckbox>
							</Form.Group>
							<Button form loading={loader}>{t(M.buttons.signup)}</Button>
						</Form>
						<Form.Note>
							{`${t(M.messages.login)} `} <ButtonLink to="/auth/login">{t(M.buttons.login)}</ButtonLink>
						</Form.Note>
					</Container>
				</Section>
			</Layout>
		);
	}
}
