import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sidebar from 'sections/Sidebar';
import './Layout.scss';

export default function Layout (props) {
	const { className, sidebar, children } = props;
	return (
		<div className={classnames('Layout', className, sidebar && 'Layout--Sidebar')}>
			{sidebar && (
				<div>
					<Sidebar />
				</div>
			)}

			<div className="Layout__Content">
				{children}
			</div>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.any,
	className: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string,
	]),
	sidebar: PropTypes.bool,
};
