import { bindActionCreators } from 'redux';

const bindNestedActionCreators = (actions, dispatch) => {
	if (typeof actions !== 'function' && (typeof actions !== 'object' || actions === null)) return false;

	let dispatchedActions;

	if (typeof actions === 'object') {
		dispatchedActions = {};

		Object.keys(actions).forEach(a => {
			const newActions = bindNestedActionCreators(actions[a], dispatch);
			if (newActions) dispatchedActions[a] = newActions;
		});
	}

	if (typeof actions === 'function') {
		dispatchedActions = bindActionCreators(actions, dispatch);
	}

	return dispatchedActions;
};

export default {
	bindNestedActionCreators,
};
