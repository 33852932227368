import keys from './keys.json';
import en from './en.json';
import lv from './lv.json';
import ru from './ru.json';
import de from './de.json';
import ee from './ee.json';
import lt from './lt.json';
import pl from './pl.json';
import it from './it.json';
import nl from './nl.json';
import es from './es.json';

const FALLBACK = {
	...keys,
	...en,
};

export default {
	en: {
		...en,
	},
	lv: {
		...FALLBACK,
		...lv,
	},
	ru: {
		...FALLBACK,
		...ru,
	},
	de: {
		...FALLBACK,
		...de,
	},
	ee: {
		...FALLBACK,
		...ee,
	},
	lt: {
		...FALLBACK,
		...lt,
	},
	pl: {
		...FALLBACK,
		...pl,
	},
	it: {
		...FALLBACK,
		...it,
	},
	nl: {
		...FALLBACK,
		...nl,
	},
	es: {
		...FALLBACK,
		...es,
	}
};
