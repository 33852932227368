import fetcher from 'helpers/fetcher';

export default {
	fetch (card, params, bid) {
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/view-statement/${card}`, { params });
	},
	pdf (card, params, bid) {
		const config = { responseType: 'blob', params };
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/view-statement/pdf/${card}`, config);
	},
	singlepdf (card, params, bid, lang) {
		const config = { responseType: 'blob', params: { language: lang } };
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/view-statement/pdf/${card}/${params}`, config);
	},
	csv (card, params, bid) {
		const config = { responseType: 'blob', params };
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/view-statement/csv/${card}`, config);
	},
};
