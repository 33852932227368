import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../Button';
import Icon from '../Icon';
import Avatar from '../Avatar';
import Preview from './Preview';
import M from './InputAvatar.locale.json';
import './InputAvatar.scss';
import intlTypes from 'types/intl';

@injectIntl
export default class InputAvatar extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		user: PropTypes.shape({
			avatar: PropTypes.string,
		}).isRequired,
		loading: PropTypes.bool,
		checked: PropTypes.bool,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,
		errors: PropTypes.arrayOf(PropTypes.string),
		onFocus: PropTypes.func,
		onUpload: PropTypes.func,
		onBlur: PropTypes.func,
		onRemove: PropTypes.func,
		className: PropTypes.string,
		intl: intlTypes.isRequired,
	};

	state = {
		hover: false,
		focus: false,
	};

	onChange = e => {
		const { name, disabled, onUpload } = this.props;

		if (disabled) return;

		onUpload(e.target.files[0], name, e)
		// fix for Chrome on broadcasting onChange
		document.getElementById(name).value = '';
	};

	onDragEnter = () => this.setState({ hover: true });

	onDragLeave = () => this.setState({ hover: false });

	onFocus = e => {
		const { onFocus } = this.props;
		this.setState({ focus: true });
		if (onFocus) onFocus(e);
	};

	onBlur = e => {
		const { onBlur } = this.props;
		this.setState({ focus: false });
		if (onBlur) onBlur(e);
	};

	render () {
		const { user, disabled, loading, className, intl, name,
			onRemove, errors, checked, autoFocus, placeholder } = this.props;
		const { hover, focus } = this.state;
		const t = intl.formatMessage;

		return (
			<div className={classnames(
				'InputAvatar', className,
				hover && 'InputAvatar--Dragover',
				focus && 'InputAvatar--Focus',
				loading && 'InputAvatar--Loading',
				disabled && 'InputAvatar--Disabled',
				errors && 'InputAvatar--Invalid')}
			>
				<div>
					<div className="InputAvatar__Image">
						<input
							id={name}
							type="file"
							className="InputAvatar__Input"
							onChange={this.onChange}
							name={name}
							checked={checked}
							autoFocus={autoFocus}
							placeholder={placeholder}
							onDragEnter={this.onDragEnter}
							onDragLeave={this.onDragLeave}
							onDragExit={this.onDragLeave}
							onDrop={this.onDragLeave}
							onFocus={this.onFocus}
							onBlur={this.onBlur}
						/>
						<Avatar user={user} large loading={loading} />
						<div className="InputAvatar__Hover">
							<Icon name="camera" />
						</div>
					</div>

					<div className="InputAvatar__Details">
						<p>{t(M.messages.note, { size: '120x120' })}</p>
						<label htmlFor={name}>
							<Button
								className="InputAvatar__Button"
								type="div"
								disabled={loading}
								plain
								small
							>
								{t(M.buttons.upload)}
							</Button>
						</label>

						{user.avatar && (
							<Button
								className="InputAvatar__Button"
								onClick={onRemove}
								disabled={loading}
								plain
								small
							>
								{t(M.buttons.remove)}
							</Button>
						)}
					</div>
				</div>

				{errors && !!errors.length && (
					<div className="InputAvatar__Errors">
						<div>{errors[0]}</div>
					</div>
				)}
			</div>
		);
	}
}

InputAvatar.Preview = Preview;
