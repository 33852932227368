import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Day (props) {
	const { className, today, range, disabled, onClick, date } = props;
	return (
		<div
			className={classnames(
				'Calendar__Day',
				today && 'Calendar__Day--Today',
				range && 'Calendar__Day--Range',
				disabled && 'Calendar__Day--Disabled',
				className
			)}
			onClick={disabled ? null : onClick}
			role="button"
			tabIndex={0}
		>
			{date && <div>{date.getDate()}</div>}
		</div>
	);
}

Day.propTypes = {
	date: PropTypes.instanceOf(Date),
	today: PropTypes.bool,
	range: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func,
};
