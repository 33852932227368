import M from './Dashboard.locale.json';

export const columns = [
	{
		id: 'date',
		title: M.table.date,
	},
	{
		id: 'type',
		title: M.table.transactionType,
	},
	{
		id: 'translated',
		title: M.table.transactionSubType,
	},
	{
		id: 'beneficiary',
		title: M.table.beneficiary,
	},
	{
		id: 'details',
		title: M.table.details,
	},
	{
		id: 'amount',
		title: M.table.amount,
	},
	{
		id: 'status',
		title: M.table.status,
	}
];
