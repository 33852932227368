export const keys = {
	dev: {
		site: 'dev.weststeincard.com',
		sitekey: '6Ld6NugUAAAAAIqzywnlolxn8UkKm03iTp6UKmfU',
	},
	demo: {
		site: 'demo.weststeincard.com',
		sitekey: '6LeWNugUAAAAAJOmv95Y2VxRw4apd678po1o00D4',
	},
	prod: {
		site: 'my.weststeincard.com',
		sitekey: '6LelNugUAAAAAAsPaA9AO1_Zz9cSK9PrS5_T94Gz',
	},
	localhost: {
		site: 'localhost:8080',
		sitekey: '6LdQRegUAAAAAC3Wz4uO8gyMCFFWAd2r10ciNVmE',
	},
	'127.0.0.1:8080': {
		site: '127.0.0.1:8080',
		sitekey: '6LdQRegUAAAAAC3Wz4uO8gyMCFFWAd2r10ciNVmE',
	},
};
