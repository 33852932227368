import {
	CLIENT_GET_BUSINESS_SUCCESS,
	CLIENT_CREATE_BUSINESS_SUCCESS,
	CLIENT_EDIT_BUSINESS_SUCCESS,
	CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS,
	CLIENT_BUSINESS_NAV_SUCCESS,
	CLIENT_BUSINESS_FORM,
} from 'constants';
import API from 'services/api';
import * as clientSelector from 'selectors/client';
import renamer from 'helpers/renamer';

export const get = step => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const { data: { response } } = await API.client.business.get(clientId, step);
		const form = renamer.from.client(response, step);
		dispatch({ type: CLIENT_GET_BUSINESS_SUCCESS, payload: { step, form } });
	} catch (error) { }
};

export const create = (form, step) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const { data: { response } } = await API.client.business.create(form, clientId, step);
		dispatch({
			type: CLIENT_CREATE_BUSINESS_SUCCESS,
			payload: { step, id: clientId, form: response },
		});
		return response;
	} catch (error) { return error; }
};

export const edit = (form, step) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const { data: { response } } = await API.client.business.edit(form, clientId, step);
		dispatch({
			type: CLIENT_EDIT_BUSINESS_SUCCESS,
			payload: { step, id: clientId, form: response },
		});
		return response;
	} catch (error) { return error; }
};

export const createContact = (form, step) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const { data: { response } } = await API.client.business.create(form, clientId, step);
		dispatch({
			type: CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS,
			payload: { step, id: clientId, form: response },
		});
		return response;
	} catch (error) { return error; }
};

export const nav = () => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const { data: { response } } = await API.client.business.nav(clientId);
		dispatch({ type: CLIENT_BUSINESS_NAV_SUCCESS, payload: response });
		return response;
	} catch (error) { return error; }
};

export const form = (f, step) => ({ type: CLIENT_BUSINESS_FORM, payload: { form: f, step } });
