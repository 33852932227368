import React, { useState, useCallback } from "react";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';

import { getById } from "../../../../selectors/card";
import * as cardActions from "../../../../actions/card";
import Button from "../../../../components/Button";

const PIN_AVAILABILITY_TIMER = 60;

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch)
	}
});

const GetPIN = ({ card, actions }) => {
	const [isRequestAvailable, setIsRequestAvailable] = useState(true);
	const [timer, setTimer] = useState(0);

	const onSendRequest = useCallback(() => {
		setIsRequestAvailable(false);
		setTimer(PIN_AVAILABILITY_TIMER);

		actions.card.getPIN(card.id);

		const intervalHandle = setInterval(() => {
			setTimer(state => state - 1);
		}, 1000);

		setTimeout(() => {
			clearInterval(intervalHandle);
			setIsRequestAvailable(true);
		}, PIN_AVAILABILITY_TIMER * 1000);
	}, [card, actions]);

	return (
	  <div>
		  <React.Fragment>
			  <FormattedMessage tagName="p" id="card.get.pin.text" />
		  </React.Fragment>
		  <Button
			onClick={onSendRequest}
			disabled={!isRequestAvailable}
		  >
			  {isRequestAvailable ? <FormattedMessage id="card.get.button.title" /> : <span>{timer}</span>}
		  </Button>
	  </div>
	);
};

export default compose(withRouter, connect(mapState, mapDispatch))(GetPIN);
