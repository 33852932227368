import { BANK_GET_SUCCESS } from 'constants';
import API from 'services/api';
import renamer from 'helpers/renamer';

export const get = () => async dispatch => {
	try {
		const result = await API.bank.get();
		const response = renamer.from.bank.get(result.data.response);
		dispatch({ type: BANK_GET_SUCCESS, payload: result.data.response });
		return response;
	} catch (error) {
		return error;
	}
};
