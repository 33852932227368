import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';
import Banner from 'components/Banner/Banner';
import * as paymentActions from 'actions/c2cpayment';
import * as paymentSelector from 'selectors/payment';
import validator from 'helpers/validator';
import PaymentSchema from 'schemas/c2cpayment';
import M from './Amount.locale.json';

import intlTypes from 'types/intl';

const mapState = (state, props) => ({
	payment: paymentSelector.get(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		payment: PropTypes.shape({
			fromIban: PropTypes.string.isRequired,
			fromCardNumber: PropTypes.string.isRequired,
			signerName: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
			signerLastName: PropTypes.string.isRequired,
			amount: PropTypes.string.isRequired,
		}),
	}

	state = {
		loader: false,
		form: {
			amount: '',
		},
		messages: [],
		errors: {
			amount: [],
		},
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;
		form.original = this.props.payment.amount;

		let messages = [];

		if (errors[name].length) {
			messages = validator.single({
				...form,
				[name]: value,
			}, name, PaymentSchema.amount);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { form, loader } = this.state;
		const { actions, payment } = this.props;

		form.original = payment.amount;

		if (loader) return;

		const errors = validator.all(form, PaymentSchema.amount);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ loader: true });

		const response = await actions.payment.amount(payment.id, form);

		const update = {
			errors: { ...this.state.errors, ...response.errors },
			loader: false,
		};

		if ((response && response.errors) && response.messages) {
			update.messages = response.messages;
		}

		this.setState(update);
	}

	render () {
		const { payment, intl } = this.props;
		const { loader, form, messages, errors } = this.state;
		if (!payment) return null;
		const t = intl.formatMessage;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>
				{messages && !!messages.length && <Banner type="error" data={messages} />}
				<Form.Group>
					<Input
						name="to"
						placeholder={t(M.inputs.to)}
						value={payment.to}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="amount-amount"
						placeholder={t(M.inputs.amount)}
						value={payment.amount}
						unit="EUR"
						disabled
					/>
				</Form.Group>
				<br />
				<Form.Group>
					<Input
						name="predictedFee"
						placeholder={t(M.inputs.fee)}
						unit="EUR"
						value={payment.predictedFee}
						disabled
					/>
				</Form.Group>
				<br />
				<Form onSubmit={this.onSubmit}>
					<Form.Group>
						<Input
							name="amount"
							placeholder={t(M.inputs.amount)}
							onChange={this.onChange}
							value={form.amount}
							errors={errors.amount}
						/>
					</Form.Group>
					<Button
						className="PaymentCreateScreen__Submit"
						loading={loader}
						small
						form
					>
						{t(M.buttons.next)}
					</Button>
				</Form>
			</div>
		);
	}
}
