import React from 'react';
import PropTypes from 'prop-types';

class Row extends React.PureComponent {
	onClick = () => {
		const { onClick, row } = this.props;
		onClick(row);
	}

	render () {
		const { children } = this.props;
		return (
			<tr className="Table__Row" onClick={this.onClick}>
				{children}
			</tr>
		);
	}
}

Row.propTypes = {
	children: PropTypes.any,
	onClick: PropTypes.func,
	row: PropTypes.object,
};

export default Row;
