import { createSelector } from 'reselect';
import { getUnverifyCards } from './card';

const getCountryId = state => state.user.phoneCountry;

const getCountryList = state => state.country;

export const getUser = state => state.user;

export const getUserPhone = createSelector(
	getCountryId,
	getCountryList,
	(id, countryList) => {
		const country = countryList.find(item => item.id === id);
		if (country) return `+${country.phoneCode}`;
		return '';
	}
);

export const getCountryName = createSelector(
	getCountryId,
	getCountryList,
	(id, countryList) => {
		const country = countryList.find(item => item.id === id);
		if (country) return country.name;
		return '';
	}
);

export const getIsUserVerified = createSelector(
	getUnverifyCards,
	unverifiedCards => !(unverifiedCards && unverifiedCards.length)
);