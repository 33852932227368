// wrap to fake mock requests
const wrap = data => ({
	data: {
		response: data,
	}
});

export default {
	wrap,
};
