import PropTypes from 'prop-types';

export default PropTypes.shape({
	goBack: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired,
	location: PropTypes.shape({
		hash: PropTypes.string,
		pathname: PropTypes.string,
		search: PropTypes.string,
		state: PropTypes.object,
	}).isRequired,
});
