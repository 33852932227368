const file = (name, type, data) => {
	const element = document.createElement('a');

	document.body.appendChild(element);

	window.URL = window.URL || window.webkitURL;

	if (data != null && navigator.msSaveBlob) return navigator.msSaveBlob(new Blob([data]), name);

	const href = window.URL.createObjectURL(new Blob([data]));

	element.href = href;
	element.download = name;
	element.style = 'display: none';
	element.click();

	setTimeout(() => {
		document.body.removeChild(element);
		window.URL.revokeObjectURL(href);
	}, 100);
};

export default {
	file,
};
