import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import PasswordPolicy from 'components/PasswordPolicy';
import * as passwordActions from 'actions/password';
import helpers from 'helpers';
import SettingSchema from 'schemas/auth';
import { migratedStatus } from 'constants/common';
import M from './ResetMigrated.locale.json';
import '../Login/Login.scss';

import intlTypes from 'types/intl';

const mapState = state => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		password: bindActionCreators(passwordActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class ResetMigrated extends React.PureComponent {
	static propTypes = {
		user: PropTypes.shape({
			migration: PropTypes.string.isRequired,
		}).isRequired,
		intl: intlTypes.isRequired,
	}

	state = {
		loader: false,
		form: {
			password: '',
			passwordConfirm: '',
		},
		errors: {
			password: [],
			passwordConfirm: [],
		}
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, SettingSchema.password);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	};

	onSubmit = async e => {
		e.preventDefault();
		const { actions } = this.props;
		const { form, loader } = this.state;

		if (loader) return;
		const errors = helpers.validator.all(form, SettingSchema.password);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ loader: true });

		const response = await actions.password.migration(form);

		if (response && response.errors) {
			this.setState({ loader: false });
			return;
		}

		this.setState({
			loader: false,
			form: { password: '', passwordConfirm: '' },
		});
	}

	render () {
		const { errors, form, loader } = this.state;
		const { user, intl } = this.props;
		const t = intl.formatMessage;

		if (!user) return <Redirect to="/" />;
		if (user.migration === migratedStatus.COMPLETED) return <Redirect to="/" />;

		return (
			<Layout className="AuthLoginScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Title title={t(M.title)} legend={t(M.legend)} />
						<Form onSubmit={this.onSubmit}>
							<Form.Group row>
								<Input
									name="password"
									placeholder={t(M.inputs.password.new)}
									onChange={this.onChange}
									value={form.password}
									type="password"
									errors={errors.password}
								/>
								<PasswordPolicy value={form.password} />
							</Form.Group>
							<Form.Group>
								<Input
									name="passwordConfirm"
									placeholder={t(M.inputs.password.repeat)}
									onChange={this.onChange}
									value={form.passwordConfirm}
									type="password"
									errors={errors.passwordConfirm}
								/>
							</Form.Group>
							<Button
								className="SettingScreen__Submit"
								loading={loader}
								small
								form
							>
								{t(M.buttons.change)}
							</Button>
						</Form>
					</Container>
				</Section>
			</Layout>
		);
	}
}
