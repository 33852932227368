import { CLIENT_CURRENT } from 'constants';

const initial = 0; // index in list

const reducer = (state = initial, action) => {
	if (action.type === CLIENT_CURRENT) return action.payload;
	return state;
};

export default reducer;
