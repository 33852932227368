import fetcher from 'helpers/fetcher';

export default {
	get () {
		return fetcher.get('user/info');
	},
	address () {
		return fetcher.get('user/address');
	},
	create (data) {
		return fetcher.post(`private/card-request`, data);
	},
};
