import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as countryActions from 'actions/country';
import * as shareholderActions from 'actions/shareholder';

import * as shareholderSelector from 'selectors/shareholder';
import * as countrySelector from 'selectors/country';

import placeService from '../../services/place';

import helpers from 'helpers';

import Shareholder from 'schemas/shareholder';

import Modal from 'components/Modal';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Title from 'components/Title';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import InputAutocomplete from 'components/InputAutocomplete';
import InputDate from 'components/InputDate';
import Section from 'components/Section';

import M from './Create.locale.json';




const ROLES = [{
	title: M.roles.shareholder,
	value: 'SHAREHOLDER',
}, {
	title: M.roles.director,
	value: 'DIRECTOR',
}, {
	title: M.roles.both,
	value: 'BOTH',
}];

const mapState = (state, props) => ({
	roles: shareholderSelector.roles(props, ROLES),
	country: countrySelector.getCountryListWithTranslations(state, props),
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props),
});

const mapDispatch = dispatch => ({
	actions: {
		shareholder: bindActionCreators(shareholderActions, dispatch),
		country: bindActionCreators(countryActions, dispatch),
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class ShareholderCreate extends Component {
	state = {
		loader: false,

		addressLoader: false,

		form: {
			name: '',
			surname: '',
			role: '',
			percentageOwned: '',
			dateOfBirth: '',
			email: '',
			phone: '',
			phoneCountry: '',
			address: '',
			country: '',
			postalCode: '',
		},

		errors: {
			name: [],
			surname: [],
			role: [],
			percentageOwned: [],
			dateOfBirth: [],
			email: [],
			phone: [],
			phoneCountry: [],
			address: [],
			country: [],
			postalCode: [],
		},

		autocomplete: []
	}

	componentDidMount () {
		const { actions } = this.props;

		actions.country.fetch();
	}

	onAddressSelect = async (value, name) => {
		const { form, onChange } = this.props;

		const loader = 'addressLoader';
		const country = 'country';
		const postalCode = 'postalCode';

		this.setState({
			[loader]: true,
		});

		try {
			const result = await placeService.geocode(value);
			let address = result.route;

			// there might not be a street
			if (result.street) address = `${result.street} ${address}`;

			this.onChange(address, name);

			if (result.country) {
				// backend takes id not country code itself
				const item = this.props.country.find(item => item.code === result.countryCode);

				this.onChange(item.value, country);
			}

			if (result.post) this.onChange(result.post, postalCode);
		} catch (error) {

		}

		this.setState({
			[loader]: false,
		});
	}

	onLookup = (value, name) => {
		this.onChange(value, name);
		this.getAutocomplete(value, name);
	}

	getAutocomplete = async (value, name) => {
		if (!value) return;

		try {
			const result = await placeService.autocomplete(value);
			const data = helpers.geocoder.autocomplete(result);

			this.setState({
				autocomplete: data,
			});
		} catch (error) {
			// if (error !== google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
			// 	return [];
			// }
			this.setState({
				autocomplete: []
			});
		}
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Shareholder.create);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, history } = this.props;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, Shareholder.create);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.shareholder.create(form);

		if (response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		history.goBack();
	}

	pop = () => {
		this.props.history.goBack();
	}

	render () {
		const { form, errors, autocomplete, addressLoader, loader } = this.state;
		const { roles, country, phoneCountry } = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<Modal
				onClick={this.pop}
				className="ShareholderCreateScreen Screen"
			>
				<Section>
					<Container medium>
						<Wrap>
							<Modal.Close
								onClick={this.pop}
							/>

							<Title
								title={t(M.title)}
								small
							/>

							<Form
								onSubmit={this.onSubmit}
							>
								<Form.Group>
									<Input
										name="name"
										placeholder={t(M.inputs.name)}
										onChange={this.onChange}
										value={form.name}
										errors={errors.name}
										autoFocus
									/>
									<Input
										name="surname"
										placeholder={t(M.inputs.surname)}
										onChange={this.onChange}
										value={form.surname}
										errors={errors.surname}
									/>
								</Form.Group>

								<Form.Group>
									<InputSelect
										name="role"
										data={roles}
										placeholder={t(M.inputs.role)}
										onChange={this.onChange}
										value={form.role}
										errors={errors.role}
									/>
									<Input
										name="percentageOwned"
										placeholder={t(M.inputs.percentageOwned)}
										onChange={this.onChange}
										value={form.percentageOwned}
										errors={errors.percentageOwned}
										disabled={form.role === 'DIRECTOR'}
									/>
								</Form.Group>

								<Form.Group>
									<InputDate
										name="dateOfBirth"
										label={t(M.inputs.dateOfBirth)}
										placeholder={t(M.inputs.date)}
										onChange={this.onChange}
										value={form.dateOfBirth}
										errors={errors.dateOfBirth}
										disabledDate={new Date()}
									/>
								</Form.Group>

								<Form.Group>
									<Input
										name="email"
										placeholder={t(M.inputs.email)}
										onChange={this.onChange}
										value={form.email}
										errors={errors.email}
									/>
								</Form.Group>

								<Form.Group>
									<InputSelect
										data={phoneCountry}
										name="phoneCountry"
										placeholder={t(M.inputs.phoneCountry)}
										onChange={this.onChange}
										value={form.phoneCountry}
										errors={errors.phoneCountry}
									/>

									<Input
										name="phone"
										placeholder={t(M.inputs.phone)}
										onChange={this.onChange}
										value={form.phone}
										errors={errors.phone}
									/>
								</Form.Group>

								<Form.Group>
									<InputAutocomplete
										name="address"
										placeholder={t(M.inputs.address)}
										onChange={this.onLookup}
										onSelect={this.onAddressSelect}
										value={form.address}
										errors={errors.address}
										data={autocomplete}
									/>
								</Form.Group>

								<Form.Group>
									<InputSelect
										name="country"
										onChange={this.onChange}
										placeholder={t(M.inputs.country)}
										value={form.country}
										errors={errors.country}
										data={country}
										disabled={addressLoader}
									/>

									<Input
										name="postalCode"
										placeholder={t(M.inputs.postalCode)}
										onChange={this.onChange}
										value={form.postalCode}
										errors={errors.postalCode}
										disabled={addressLoader}
									/>
								</Form.Group>

								<Button form loading={loader}>{t(M.buttons.add)}</Button>
							</Form>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
