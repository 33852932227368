import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import reduxer from 'helpers/reduxer';

import * as clientSelector from 'selectors/client';

import * as clientActions from 'actions/client';

import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import NavVertical from 'components/NavVertical';
import Tab from 'components/Tab';
import Status from 'components/Status';

import Nav from 'sections/Nav';

import Basic from './Basic';
import Location from './Location';
import Contact from './Contact';
import Shareholder from './Shareholder';
import Document from './Document';

import TipWrap from './Tip';

import M from './Business.locale.json';

import './Business.scss';



const NAV = {
	basic: M.steps.basic,
	location: M.steps.location,
	contact: M.steps.contact,
	shareholders: M.steps.shareholders,
	documents: M.steps.documents,
};

const getCurrent = props => {
	if (typeof props.match.params.section === 'undefined') return 0;

	const idx = Object.keys(NAV).indexOf(props.match.params.section);

	return (idx !== -1 ? idx : 0);
};

const mapState = (state, props) => ({
	current: getCurrent(props),
	nav: clientSelector.nav(state, props, NAV),
});

const mapDispatch = dispatch => ({
	actions: {
		client: reduxer.bindNestedActionCreators(clientActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Create extends PureComponent {
	componentDidMount () {
		this.onMount();
	}

	onMount = async () => {
		const { actions } = this.props;

		actions.client.business.nav();
	}

	onNav = section => {
		const { history, match } = this.props;
		const { id } = match.params;

		history.push(`/clients/create/business/${id}/${section}`);
	};

	renderNav = items => items.map(item => ({
		...item,
		title: (
			<div>
				{item.title}

				{
					item.status &&
					<Status type={item.status} />
				}
			</div>
		)
	}))

	render () {
		const { user, match, nav, current } = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<Layout className="ClientCreateScreen Screen">
				<Nav
					user={user}
					logo
				/>

				<Section className="ClientCreateBusinessScreen">
					<Container>
						<Title
							title={t(M.title)}
							className="ClientCreateBusinessScreen__Title"
						/>

						<NavVertical
							title={t(M.nav.title)}
							items={this.renderNav(nav)}
							active={current}
							onClick={this.onNav}
							className="ClientCreateBusinessScreen__Nav"
						/>

						<TipWrap
							current={current}
							unsaved={nav[current].status}
						/>

						<Tab
							current={current}
							className="ClientCreateBusinessScreen__Tab"
						>
							<Tab.Panel>
								<Basic />
							</Tab.Panel>

							<Tab.Panel>
								<Location />
							</Tab.Panel>

							<Tab.Panel>
								<Contact />
							</Tab.Panel>

							<Tab.Panel>
								<Shareholder />
							</Tab.Panel>

							<Tab.Panel>
								<Document />
							</Tab.Panel>
						</Tab>
					</Container>
				</Section>
			</Layout>
		);
	}
}
