import fetcher from 'helpers/fetcher';

export default {
	get () {
		return fetcher.get('user/phone/validate');
	},
	create (form) {
		return fetcher.post('user/phone/confirm-validation', {}, { params: form });
	},
	change (form) {
		return fetcher.post('user/change/request/phone', {}, { params: form });
	},
	confirm (formId, token) {
		return fetcher.put(`user/change/confirm/phone/${formId}/${token}`);
	},
	resend (id) {
		return fetcher.put(`user/change/request/phone/${id}`);
	},
};
