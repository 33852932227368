import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'components/Container';
import Title from 'components/Title';
import Button from 'components/Button';
import M from './Blocking.locale.json';
import './Blocking.scss';

import intlTypes from 'types/intl';

@withRouter
@injectIntl
export default class Blocking extends React.PureComponent {
	static propTypes = {
		history: PropTypes.shape({
			goBack: PropTypes.func.isRequired,
		}).isRequired,
		intl: intlTypes.isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
		}).isRequired,
	};

	state = {
		loader: false,
	};

	goBack = () => this.props.history.goBack();

	render () {
		const { intl, location } = this.props;
		const t = intl.formatMessage;

		if (location.pathname === '/settings/document') {
			return (
				<div className="Blocking">
					<Container>
						<Title title={t(M.titlecustomer)} legend={t(M.legendcustomer)} />
						<Button onClick={this.goBack} medium>
							{t(M.buttons.back)}
						</Button>
					</Container>
				</div>
			);
		}
		return (
			<div className="Blocking">
				<Container>
					<Title title={t(M.title)} legend={t(M.legend)} />
					<Button onClick={this.goBack} medium>
						{t(M.buttons.back)}
					</Button>
				</Container>
			</div>
		);
	}
}
