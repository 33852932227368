import React, { Component } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';

import Nav from 'sections/Nav';

import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Icon from 'components/Icon';

import M from './Submitted.locale.json';

import './Submitted.scss';


@injectIntl
export default class Submitted extends Component {
	render () {
		const t = this.props.intl.formatMessage;

		return (
			<Layout className="ClientSubmittedScreen Screen">
				<Nav
					hide
				/>

				<Section>
					<Container>
						<div
							className="ClientSubmittedScreen__Icon"
						>
							<Icon
								name="success"
							/>
						</div>

						<Title
							title={t(M.title)}
						/>

						<p className="ClientSubmittedScreen__Note">
							<FM
								id={M.note.id}
								values={{
									contact: (<strong>{t(M.values.contact)}</strong>)
								}}
							/>
						</p>
						<p className="ClientSubmittedScreen__Instruction">
							<FM
								id={M.instruction.id}
								values={{
									contact: (<strong>{t(M.values.duration)}</strong>)
								}}
							/>
						</p>
					</Container>
				</Section>
			</Layout>
		);
	}
}
