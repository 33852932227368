import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';
import storage from 'services/storage';

export default {
	fetch (business) {
		return fetcher.get(`business/${business}/application/required-documents`);
	},
	get (business, id) {
		const token = storage.get('session').split(' ')[1];
		const config = { responseType: 'blob' };
		return fetcher.get(`open/business/${business}/application/required-documents/${id}?token=${token}`, config);
	},
	create (file, type, business) {
		const data = new FormData();
		data.append('file', file);
		return fetcher.post(`business/${business}/application/required-documents`, data, {
			params: { type: renamer.to.document(type) },
			headers: { 'Content-Type': null }
		});
	},
	remove (id, business) {
		return fetcher.delete(`business/${business}/application/required-documents/${id}`);
	},
};
