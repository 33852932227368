import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import helpers from 'helpers';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';
import * as cardActions from 'actions/card';
import * as voucherActions from 'actions/voucher';
import * as toastActions from 'actions/toast';
import * as cardSelector from 'selectors/card';

import voucherSchema from 'schemas/voucher';

import Section from 'components/Section';
import Container from 'components/Container';
import Input from 'components/Input';
import Button from 'components/Button';
import BlockingScreen from '../../screens/Blocking';

import M from './Load.locale.json';
import './Load.scss';
import Wrap from 'components/Wrap';

const mapState = state => ({
	card: state.card,
	currentCard: cardSelector.getById(state, state.card.current)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		voucher: bindActionCreators(voucherActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	},
});


@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Voucher extends Component {
	state = {
		loader: false,
		cardid: 0,
		form: {
			voucher: '',
		},
		errors: {
			voucher: [],
		}
	};

	onChange = value => {
		this.setState({
			form: {
				voucher: value,
			},
		});
	};

	onSend = async () => {
		const { actions, card } = this.props;
		const { form, loader } = this.state;

		if (loader) return;

		const errors = helpers.validator.all(form, voucherSchema.voucher);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}
		this.setState({
			loader: true
		});

		const id = card.current;

		const response = await actions.voucher.post(form, id);

		if (response && response.errors) {
			this.setState({
				loader: false,
				form: {
					voucher: '',
				}
			});

			actions.toast.create({
				title: M.voucher.error,
				type: 'error',
			});
			return false;
		}
		this.setState({
			loader: false,
			form: {
				voucher: '',
			}
		});

		actions.card.fetch();

		actions.toast.create({
			title: M.voucher.success,
			type: 'success',
		});
		triggerEvent(GTMEvent.voucher, '/loads');
	};

	render () {
		const t = this.props.intl.formatMessage;
		const { errors, loader } = this.state;
		const { currentCard } = this.props;

		if (['CARD_04', 'CARD_05', 'CARD_06', 'CARD_07', 'CARD_08'].includes(currentCard.product) && currentCard.cardStatus === 'OPEN') {
			return (
				<div>
					<h3>{t(M.voucher.title)}</h3>

					<Input
						name="voucherCode"
						placeholder={t(M.voucher.placeholder)}
						value={this.state.form.voucher}
						errors={errors.voucher}
						onChange={this.onChange}
					/>
					<Button
						onClick={this.onSend}
						loading={loader}
						form
						small
					>
						{t(M.voucher.submit)}
					</Button>
				</div>
			);
		}
		return (
			<Section className="LoadSection">
				<Container>
					<Wrap>
						<BlockingScreen />
					</Wrap>
				</Container>
			</Section>
		);
	}
}
