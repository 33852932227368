import moment from 'moment';

const get = item => {
	const convert = {
		...item,
	};

	// convert.id = uuid().substring(0, 8).toUpperCase();
	convert.id = convert.transactionId;
	convert.idShort = convert.id.split('-')[2];

	// dates are returned from this endpoint in a DD-MM-YYYY HH:mm:ss format,
	// we want the epoch seconds in the
	// state because that makes sorting way faster and simpler
	convert.date = moment(convert.date, 'DD-MM-YYYY HH:mm:ss').valueOf();
	return convert;
};

const fetch = transactions => {
	if (!transactions) return transactions;
	const convert = { ...transactions };
	convert.transactions = convert.transactions.map(get);
	return convert;
};

export default {
	fetch,
};
