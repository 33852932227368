import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import store from './stores';
import Router from './Router';
import Language from './Language';

export default class Root extends React.PureComponent {
	render () {
		return (
			<Provider store={store}>
				<Language>
					<BrowserRouter>
						<Route component={Router} />
					</BrowserRouter>
				</Language>
			</Provider>
		);
	}
}
