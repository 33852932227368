import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Section from 'components/Section';
import Tab from 'components/Tab';
import Button from 'components/Button/Button';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import { fetchToAddSecondary } from 'selectors/card';
import CardCreateMe from '../CardCreateMe';
import CardCreateOther from '../CardCreateOther';
import M from '../CardCreate.locale.json';
import '../Create.scss';

import intlTypes from 'types/intl';
import historyTypes from 'types/history';
import currentCardTypes from 'types/currentCard';

const cardsUrl = '/cards';

const mapState = state => ({
	cards: fetchToAddSecondary(state)
});

@withRouter
@injectIntl
@connect(mapState)
class CardCreateTab extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		cards: PropTypes.arrayOf(currentCardTypes).isRequired,
		history: historyTypes.isRequired,
	}

	constructor (props) {
		super(props);
		const { cards, history } = this.props;
		if (!cards.length) history.push(cardsUrl);
	}

	state = {
		current: 0,
	}

	activateCreateMe = () => this.setState({ current: 0 })

	activateCreateOther = () => this.setState({ current: 1 })

	render () {
		const { intl } = this.props;
		const { current } = this.state;
		const t = intl.formatMessage;

		return (
			<Layout className="PaymentCreateScreen Screen" sidebar>
				<Nav />
				<Header />
				<Section>
					<Container>
						<Wrap>
							<div>
								<Button className="Header__Button Create" onClick={this.activateCreateMe} small>
									{t(M.buttons.createMe)}
								</Button>
								<Button className="Header__Button Create" onClick={this.activateCreateOther} small>
									{t(M.buttons.createOther)}
								</Button>
							</div>
							<Tab current={current}>
								<Tab.Panel>
									<CardCreateMe />
								</Tab.Panel>

								<Tab.Panel>
									<CardCreateOther />
								</Tab.Panel>
							</Tab>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}

export default CardCreateTab;
