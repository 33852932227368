import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './InputSearch.scss';

class InputSearch extends React.PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		type: PropTypes.string,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
		value: PropTypes.string,
	}

	onChange = e => {
		const { onChange, name } = this.props;
		onChange(e.target.value, name, e);
	}

	render () {
		const { className, name, onFocus, onBlur, type,
			value, autoFocus, placeholder, disabled } = this.props;
		return (
			<div className={classnames('InputSearch', className)}>
				<input
					id={name}
					className="InputSearch__Input"
					onChange={this.onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					name={name}
					type={type}
					value={value}
					autoComplete="off"
					autoFocus={autoFocus}
					placeholder={placeholder}
					disabled={disabled}
				/>
			</div>
		);
	}
}

export default InputSearch;
