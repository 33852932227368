import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import currentCardType from 'types/currentCard';
import clientTypes from 'types/client';

import helpers from 'helpers';

import * as clientSelector from 'selectors/client';
import * as transactionSelector from 'selectors/transaction';
import * as cardSelector from 'selectors/card';

import * as cardActions from 'actions/card';
import * as transactionActions from 'actions/transaction';

import Section from 'components/Section';
import Container from 'components/Container';
import Table from 'components/Table';
import Title from 'components/Title';
import Button from 'components/Button';
import Pagination from 'components/Pagination';
import InputSearch from 'components/InputSearch';
import InputFilter from 'components/InputFilter';

import HistoryDate from './HistoryDate';

import M from './History.locale.json';

import './History.scss';
import Banner from 'components/Banner/Banner';

const PAGE_SIZE = 20;

const COLUMNS = [{
	id: 'date',
	title: M.table.date,
}, {
	id: 'type',
	title: M.table.transactionType,
}, {
	id: 'translated',
	title: M.table.transactionSubType,
},	{
	id: 'beneficiary',
	title: M.table.beneficiary,
}, {
	id: 'details',
	title: M.table.details,
}, {
	id: 'amount',
	title: M.table.amount,
}, {
	id: 'status',
	title: M.table.status,
}];

const STATUS = [{
	id: 'SUCCESSFUL',
	title: M.status.successful,
}, {
	id: 'DECLINED',
	title: M.status.declined,
}];

const TYPES = [{
	id: 'IN',
	title: M.types.incoming,
}, {
	id: 'OUT',
	title: M.types.outgoing,
}];

const mapState = (state, props) => ({
	client: { id: clientSelector.getEntityClientId(state) },
	transactions: transactionSelector.fetch(state, props),

	columns: transactionSelector.columns(state),
	columnsNav: transactionSelector.columnsNav(props, COLUMNS),

	status: transactionSelector.status(state),
	statusNav: transactionSelector.statusNav(props, STATUS),

	types: transactionSelector.types(state),
	typesNav: transactionSelector.typesNav(props, TYPES),

	search: transactionSelector.search(state),
	sort: transactionSelector.sort(state),

	currentCard: cardSelector.getById(state, state.card.current)
});

const mapDispatch = dispatch => ({
	actions: {
		transaction: bindActionCreators(transactionActions, dispatch),
		card: bindActionCreators(cardActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class HistorySection extends PureComponent {
	static propTypes = {
		types: PropTypes.array,
		status: PropTypes.array,
		actions: PropTypes.shape({
			card: PropTypes.object,
			transaction: PropTypes.object,
		}),
		client: clientTypes,
		currentCard: currentCardType,
	}

	state = {
		loaderTable: true,
		page: 0,
		total: 0,
		start: moment(new Date()).add(-1, 'month').format('DD-MM-YYYY'),
		end: moment(new Date()).format('DD-MM-YYYY'),
		messages: [],
	}

	componentDidMount () {
		this.mounted = true;
		this.onFetch();
	}

	componentDidUpdate (prevProps, prevState) {
		if (!prevProps || !this.props) return;
		const { client, types, status, currentCard } = this.props;
		const { page, start, end } = this.state;

		const sameClient = prevProps.client.id === client.id;
		const samePage = prevState.page === page;
		const sameCard =
			currentCard && prevProps.currentCard &&
			currentCard.id && prevProps.currentCard.id &&
			currentCard.id === prevProps.currentCard.id;

		if (
			samePage &&
			sameCard &&
			sameClient &&
			prevProps.types === types &&
			prevProps.types === types &&
			prevProps.status === status &&
			prevState.end === end &&
			prevState.start === start) {
			return;
		}

		this.onFetch(!sameClient, !samePage);
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onFetch = async (shouldUpdate, changePage) => {
		const { actions, types, status } = this.props;
		const { page, start, end } = this.state;

		this.mounted && this.setState({
			loaderTable: true,
		});

		try {
			await actions.card.fetch(shouldUpdate);

			const { response, messages } = await actions.transaction.fetch({
				size: PAGE_SIZE,
				page: changePage && page ? page : 0,
				start,
				end,
				status: status.length === 2 ? null : status[0],
				type: types.length === 2 ? 'ALL' : types[0],
			});

			this.mounted && this.setState({
				loaderTable: false,
				total: Math.ceil(response.totalTransactions / PAGE_SIZE),
				page: response.page,
				messages,
			});
		} catch (e) {}
	}

	onDate = range => {
		this.setState({
			start: range.start,
			end: range.end,
		});
	}

	// not used
	onSort = column => {
		const { actions, sort } = this.props;

		actions.transaction.sort({
			id: column.id,
			asc: (sort.id === column.id ? !sort.asc : true)
		});
	}

	onPage = page => {
		this.setState({
			page,
		});
	}

	onStatus = options => {
		const { actions } = this.props;

		if (options.length === 0) return;

		actions.transaction.status(options);
	}

	onType = options => {
		const { actions } = this.props;

		if (options.length === 0) return;

		actions.transaction.types(options);
	}

	onColumn = options => {
		const { actions } = this.props;

		if (options.length === 0) return;

		actions.transaction.columns(options);
	}

	onSearch = keyword => {
		const { actions } = this.props;

		actions.transaction.search(keyword);
	}

	onExport = () => {
		const { history } = this.props;
		const { start, end } = this.state;

		history.push({
			pathname: `/history/export`,
			state: { modal: true },
			start,
			end
		});
	}

	onSingle = item => {
		const { history } = this.props;

		history.push(`/history/${item.id}`, { modal: true });
	}

	mounted = false

	renderHeader = () => {
		const { columns, columnsNav } = this.props;

		return columnsNav.filter(item => (columns.indexOf(item.id) > -1));
	}

	renderRow = row => {
		const { columns, columnsNav } = this.props;

		return (
			<Table.Row
				key={row.id}
				onClick={() => this.onSingle(row)}
			>
				{
					columnsNav
						.filter(item => (columns.indexOf(item.id) > -1))
						.map(item => (
							<Table.Cell
								key={item.id}
							>
								{helpers.formatter(item.id, row[item.id])}
							</Table.Cell>
						))
				}
			</Table.Row>
		);
	}

	renderDate () {
		const { start, end } = this.state;

		return (
			<HistoryDate
				start={start}
				end={end}
				onDate={this.onDate}
				name="transaction"
			/>
		);
	}

	render () {
		const { loaderTable, total, page, messages } = this.state;
		const {
			transactions,
			columns,
			columnsNav,
			types,
			typesNav,
			status,
			statusNav,
			search,
			sort
		} = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<Section className="HistorySection">
				<Container>
					<Title
						title={t(M.title)}
						legend={this.renderDate()}
					/>

					<div
						className="HistorySection__Actions"
					>
						<div>
							<InputFilter
								name="columns"
								value={columns}
								data={columnsNav}
								onChange={this.onColumn}
							>
								{t(M.buttons.columns)}
							</InputFilter>

							<InputFilter
								className="HistorySection__Actions--status"
								name="status"
								value={status}
								data={statusNav}
								onChange={this.onStatus}
							>
								{t(M.buttons.status)}
							</InputFilter>

							<InputFilter
								name="type"
								value={types}
								data={typesNav}
								onChange={this.onType}
							>
								{t(M.buttons.types)}
							</InputFilter>
						</div>

						<InputSearch
							className="InputSearch--desktop"
							name="search"
							value={search}
							placeholder={t(M.inputs.filter)}
							onChange={this.onSearch}
						/>

						<Button
							onClick={this.onExport}
							plain
						>
							{t(M.buttons.export)}
						</Button>
					</div>

					<InputSearch
						className="InputSearch--mobile"
						name="search"
						value={search}
						placeholder={t(M.inputs.filter)}
						onChange={this.onSearch}
					/>

					{
						(messages && messages.length > 0) &&
						<Banner type="error" data={messages} />
					}

					<Table
						items={transactions}
						sort={sort}
						renderHeader={this.renderHeader}
						renderRow={this.renderRow}
						className="History__Table"
						loading={loaderTable}
					/>

					{
						(transactions.length > 0 && !loaderTable) &&
						<Pagination
							total={total}
							current={page}
							onChange={this.onPage}
						/>
					}
				</Container>
			</Section>
		);
	}
}
