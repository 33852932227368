import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';

export default {
	fetch () {
		return fetcher.get('user/attached-roles');
	},
	get (id, step) {
		let endpoint;
		switch (step) {
			case 'basic':
				endpoint = `business/${id}`;
				break;
			case 'location':
				endpoint = `business/${id}/application/company-address`;
				break;
			case 'contact':
				endpoint = `business/${id}/application/contact-info`;
				break;
			default:
				endpoint = '';
				break;
		}
		return fetcher.get(endpoint);
	},
	create (form, id, step) {
		const convert = renamer.to.client(form, step);
		let endpoint;
		switch (step) {
			case 'basic':
				endpoint = `business/${id}`;
				break;
			case 'location':
				endpoint = `business/${id}/application/company-address`;
				break;
			case 'contact':
				endpoint = `business/${id}/application/contact-info`;
				break;
			case 'documents':
				endpoint = `business/${id}/application/confirm-declaration`;
				break;
			default:
				endpoint = '';
				break;
		}
		return fetcher.post(endpoint, convert);
	},
	edit (form, id, step) {
		const convert = renamer.to.client(form, step);
		let endpoint;
		switch (step) {
			case 'basic':
				endpoint = `business/${id}`;
				break;
			case 'location':
				endpoint = `business/${id}/application/company-address`;
				break;
			case 'contact':
				endpoint = `business/${id}/application/contact-info`;
				break;
			case 'documents':
				endpoint = `business/${id}/application/confirm-declaration`;
				break;
			default:
				endpoint = '';
				break;
		}
		return fetcher.put(endpoint, convert);
	},
	nav (id) {
		return fetcher.get(`business/${id}/application/completed-steps`);
	},
};
