import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DropdownItem from './DropdownItem';
import './Dropdown.scss';

export default class Dropdown extends PureComponent {
	static propTypes = {
		open: PropTypes.bool,
		className: PropTypes.string,
		focusedIdx: PropTypes.number,
		selectedIdx: PropTypes.number,
		data: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				subtitle: PropTypes.string,
				check: PropTypes.bool,
			})
		),
		onClick: PropTypes.func,
		onMouseDown: PropTypes.func,
		onMouseOver: PropTypes.func,
		onMouseOverItem: PropTypes.func,
		onMouseOut: PropTypes.func,
	}

	onMouseOverItem = (item, idx) => {
		const { onMouseOverItem } = this.props;
		if (onMouseOverItem) onMouseOverItem(item, idx);
	}

	onRef = (ref, focused, selected) => {
		if (focused) this.focused = ref;
		if (selected) this.selected = ref;
	}

	focused = null

	render () {
		const {
			data, open, className, onMouseOver, onMouseOut,
			focusedIdx, selectedIdx, onClick, onMouseDown,
		} = this.props;

		return (
			<div
				className={classnames('Dropdown', className, open && 'Dropdown--Open')}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			>
				<ul className="Dropdown__List">
					{data.map((item, idx) => (
						<DropdownItem
							key={item.title + item.subtitle}
							idx={idx}
							focused={idx === focusedIdx}
							selected={idx === selectedIdx}
							item={item}
							onClick={onClick}
							onMouseDown={onMouseDown}
							onRef={this.onRef}
							onMouseOverItem={this.onMouseOverItem}
						/>
					))}
				</ul>
			</div>
		);
	}
}
