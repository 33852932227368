import React from 'react';
import PropTypes from 'prop-types';

export default function Close (props) {
	const { onClick } = props;
	return (
		<div onClick={onClick} className="Modal__Close" role="button" />
	);
}

Close.propTypes = {
	onClick: PropTypes.func.isRequired,
};
