import axios from 'axios';
import storage from 'services/storage';
import renamer from 'helpers/renamer';
import handler from 'helpers/handler';
import { increase, decrease } from 'helpers/fetchCounter';
import CONFIG from '../../../env';

const client = axios.create({
	baseURL: CONFIG.api,
});

client.interceptors.response.use(response => {
	if (response.headers && response.headers.authorization) {
		storage.set('session', response.headers.authorization);
	}
	const convert = { ...response };
	if (convert.data.response && Object.prototype.toString.call(convert.data.response) === '[object Object]') {
		convert.data.response = renamer.from.clean.wrap(convert.data.response);
	}
	decrease();
	return convert;
}, error => {
	const handle = error.response || error.request;
	if (handle && (handle.headers && handle.headers.authorization)) {
		storage.set('session', handle.headers.authorization);
	}
	decrease();
	return Promise.reject(handler.error(handle));
});

client.interceptors.request.use(config => {
	if (storage.get('session')) config.headers.authorization = storage.get('session');
	increase();
	return config;
}, error => Promise.reject(handler.error(error)));

export default {
	get (endpoint, config) {
		return client.get(endpoint, config);
	},
	post (endpoint, payload, config) {
		return client.post(endpoint, payload, config);
	},
	put (endpoint, payload, config) {
		return client.put(endpoint, payload, config);
	},
	patch (endpoint, payload, config) {
		return client.patch(endpoint, payload, config);
	},
	delete (endpoint, config) {
		return client.delete(endpoint, config);
	}
};
