import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';


import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as passwordActions from 'actions/password';

import SettingSchema from 'schemas/setting';

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import NavVertical from 'components/NavVertical';
import Wrap from 'components/Wrap';
import Button from 'components/Button';
import PasswordPolicy from 'components/PasswordPolicy';

import './Password.scss';



const mapState = () => ({});

const mapDispatch = dispatch => ({
	actions: {
		password: bindActionCreators(passwordActions, dispatch),
	},
});

@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
    state = {
    	loader: false,

    	form: {
    		current: '',
    		new: '',
    		confirm: '',
    	},

    	errors: {
    		current: [],
    		new: [],
    		confirm: [],
    	},

    	redirect: false,
    }

    nav = [{
    	id: 'account',
    	title: 'Account',
    	icon: 'account',
    }, {
    	id: 'phone',
    	title: 'Phone',
    	icon: 'phone',
    }, {
    	id: 'password',
    	title: 'Password',
    	icon: 'password',
    }]

    onChange = (value, name) => {
    	const { form, errors } = this.state;

    	let messages = [];

    	if (errors[name].length) {
    		messages = helpers.validator.single({
    			...form,
    			[name]: value,
    		}, name, SettingSchema.password);
    	}

    	this.setState({
    		form: {
    			...form,
    			[name]: value,
    		},
    		errors: {
    			...errors,
    			[name]: messages,
    		},
    	});
    };

    onSubmit = async e => {
    	e.preventDefault();

    	const { actions } = this.props;
    	const { form, loader, redirect } = this.state;

    	if (loader) return;

    	const errors = helpers.validator.all(form, SettingSchema.password);

    	// set errors and return
    	if (errors) {
    		this.setState({
    			errors: {
    				...this.state.errors,
    				...errors,
    			},
    		});

    		return;
    	}

    	this.setState({
    		loader: true,
    	});

    	const response = await actions.password.edit(form);

    	if (response && response.errors) {
    		this.setState({
    			loader: false,
    		});

    		return false;
    	}

    	this.setState({
    		loader: false,
    		form: {
    			current: '',
    			new: '',
    			confirm: '',
    		},
    		redirect: true,
    	});
    };

    onNav = idx => {
    	const { history } = this.props;

    	const path = this.nav[idx].id;

    	history.push(`/settings/${path}`);
    }

    render () {
    	const { errors, form, loader, redirect } = this.state;

    	if (redirect) {
    		return (
	<Redirect
    				to={{
    					pathname: `/`,
    					state: {
    						redirect: true,
    					},
    				}}
    			/>
    		);
    	}
    	return (
	<Section className="SettingPasswordSection">
	<Container>

	<Wrap>
			<Title
	title="Change your password"
	className="SettingScreen__Title"
    					/>

			<Form.Note>In order to keep your login safe, please change your password frequently.</Form.Note>

    					<Form
    						onSubmit={this.onSubmit}
		>
    						<Form.Group>
		<Input
		name="current"
		placeholder="Current password"
		onChange={this.onChange}
		value={form.current}
    								errors={errors.current}
    								type="password"
		autoFocus
	/>
	</Form.Group>

    						<Form.Group
    							row
	>
		<Input
	name="new"
    								placeholder="New password"
    								onChange={this.onChange}
	value={form.new}
	type="password"
    								errors={errors.new}
    							/>

    							<PasswordPolicy
		value={form.new}
	/>
	</Form.Group>

    						<Form.Group>
    							<Input
    								name="confirm"
    								placeholder="Repeat new password"
    								onChange={this.onChange}
		value={form.confirm}
		type="password"
		errors={errors.confirm}
	/>
	</Form.Group>

			<Button
	className="SettingScreen__Submit"
	loading={loader}
	small
	form
    						>
                                Change
    						</Button>
		</Form>
    				</Wrap>
    			</Container>
    		</Section>
    	);
    }
}
