import React from 'react';

import Icon from '../../Error/Icon';
import PaymentResultPage from '../../../PaymentResultPage';

const LINK_URL = 'weststein://home';

const Error = () => {
	return (
		<PaymentResultPage titleId="pages.payments.pfs.error.title" linkId="buttons.back" linkUrl={LINK_URL} mobileLink Icon={Icon} hideLayout />
	);
};

export default Error;
