import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import InputError from "../InputError";
import Calendar from "../Calendar";
import "./InputDate.scss";

export default class InputDate extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		type: PropTypes.string,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,
		disabledDate: PropTypes.instanceOf(Date),
		errors: PropTypes.array,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
		label: PropTypes.string,
		hotjar: PropTypes.bool
	};

	state = {
		range: null,
		month: null,
		year: null,
		isOpened: false
	};

	onMonth = date => {
		const { month, year } = date;

		this.setState({ month, year });
	};

	onSelect = range => {
		this.closeCalendar();
		const { name, onChange } = this.props;
		const date = range.getDate();
		const month = range.getMonth() + 1;
		const year = range.getFullYear();
		this.setState({ range });
		onChange(`${date}/${month}/${year}`, name);
	};

	onChange = e => {
		const { onChange, name } = this.props;

		onChange(e.target.value, name, e);
	};

	openCalendar = () => this.setState({ isOpened: true });

	closeCalendar = () => this.setState({ isOpened: false });

	render () {
		const { range, year, month, isOpened } = this.state;
		const {
			className,
			errors,
			label,
			placeholder,
			value,
			name,
			onFocus,
			onBlur,
			type,
			autoFocus,
			disabled,
			disabledDate,
			hotjar
		} = this.props;

		return (
			<div
				onClick={this.openCalendar}
				className={cn(
					"InputDate",
					className,
					errors && "InputDate--Invalid"
				)}
			>
				{(label || (placeholder && value.length)) && (
					<label htmlFor={name} className="InputDate__Label">
						{label || placeholder}
					</label>
				)}

				<input
					id={name}
					className="InputDate__Input"
					onChange={this.onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					name={name}
					value={value}
					type={type}
					autoComplete="off"
					autoFocus={autoFocus}
					placeholder={placeholder}
					disabled={disabled}
					ref={ref => {
						this.input = ref;
					}}
					data-hj-allow={hotjar}
				/>

				<div className={cn('InputDate__Calendar', { 'InputDate__Calendar--visible': isOpened })}>
					<Calendar
						range={range}
						year={year || this.props.year}
						month={month}
						onChange={this.onMonth}
						onSelect={this.onSelect}
						onClose={this.closeCalendar}
						disabled={disabledDate}
					/>
				</div>

				<InputError
					errors={errors}
					label={label}
					placeholder={placeholder}
					name={name}
				/>
			</div>
		);
	}
}
