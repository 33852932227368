export default {
	create: {
		to: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				is: 16,
				message: '^schemas.length.is',
			},
		},
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			},
		},
	},
	amount: {
		amount: {
			presence: { message: '^schemas.presence' },
			numericality: { message: '^schemas.numerical' },
			equality: {
				attribute: 'original',
				message: '^schemas.match',
				comparator: (v1, v2) => parseFloat(v1) === parseFloat(v2)
			},
		},
	},
	phone: {
		code: {
			presence: { message: '^schemas.presence' },
		},
	},
};
