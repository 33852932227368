export const paths = {
	payments: '/payments/create',
	c2cPayments: '/c2cpayments/create',
	internationalPayment: '/international-payment/create',
};

export const payment = 0;
export const c2cPayment = 1;
export const internationalPayment = 2;
export const mapIds = {
	[paths.payments]: payment,
	[paths.c2cPayments]: c2cPayment,
	[paths.internationalPayment]: internationalPayment,
};

export const headers = [
	{ id: payment, title: 'sepaSend' },
	{ id: c2cPayment, title: 'c2cSend' },
	{ id: internationalPayment, title: 'internationalPayment' },
];
