import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authActions from 'actions/auth';

import Title from 'components/Title';
import Wrap from 'components/Wrap';
import Icon from 'components/Icon';
import Button from 'components/Button';

import M from './Failed.locale.json';



const mapState = state => ({});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Failed extends PureComponent {
	onLogout = () => {
		const { auth } = this.props.actions;

		auth.logout();
	};

	render () {
		const t = this.props.intl.formatMessage;

		return (
			<Wrap>
				<div
					className="ClientCreatePrivateScreen__Icon ClientCreatePrivateScreen__Icon--Error"
				>
					<Icon
						name="error"
					/>
				</div>

				<Title
					title={t(M.title)}
				/>

				<p>{t(M.messages.note)}</p>

				<Button
					form
					small
					onClick={this.onLogout}
				>
					{t(M.buttons.logout)}
				</Button>
			</Wrap>
		);
	}
}
