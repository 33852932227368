export default function user (form) {
	if (!form) return form;

	const convert = {
		...form,
	};

	if (convert.credentials) {
		convert.code = form.credentials;
		delete convert.credentials;
	}

	return convert;
}
