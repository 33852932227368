import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import numeral from 'numeral';
import moment from 'moment';
import Badge from 'components/Badge';
import Button from 'components/Button';
import M from './formatter.locale.json';

const formatId = id => <Button plain>#{id}</Button>;

const formatAmount = amount => <span className={'amount-col'}>{`${numeral(amount).format('- 0.00')} €`}</span>;

const formatBadge = status => {
	switch (status) {
		case 'SUCCESSFUL':
		case 'COMPLETED':
			return <Badge success><FM id={M.status.accepted.id} /></Badge>;
		case 'DECLINED':
			return <Badge error><FM id={M.status.declined.id} /></Badge>;
		case 'PROCESSING':
			return <Badge><FM id={M.status.processing.id} /></Badge>;
		case 'DRAFT':
			return <Badge><FM id={M.status.draft.id} /></Badge>;
		case 'SIGNING':
			return <Badge info><FM id={M.status.signing.id} /></Badge>;
		case 'DELETED':
			return <Badge><FM id={M.status.deleted.id} /></Badge>;
		case 'IN':
			return <Badge orange><FM id={M.status.in.id} /></Badge>;
		case 'OUT':
			return <Badge info><FM id={M.status.out.id} /></Badge>;
		case 'PHONE':
			return <FM id={M.applications.phone.id} />;
		case 'GRANTED':
			return <Badge success><FM id={M.status.granted.id} /></Badge>;
		case 'REQUESTED':
			return <Badge info><FM id={M.status.requested.id} /></Badge>;
		default:
			return <Badge><FM id={M.status.processing.id} /></Badge>;
	}
};

const formatDate = input => {
	const date = moment(new Date(input));

	if (!date.isValid()) return '';

	return `${moment(date).format('L')} ${moment(date).add(7, 'h').format('HH:mm:ss')}`;
};

export default function formater (name, field) {
	switch (name) {
		case 'id':
		case 'idShort':
			return formatId(field);
		case 'status':
		case 'badge':
			return formatBadge(field);
		case 'amount':
		case 'money':
		case 'fee':
		case 'predictedFee':
		case 'price':
		case 'balanceAfter':
			return formatAmount(field);
		case 'payment' : return numeral(field).format('- 0.00');
		case 'date':
		case 'created':
		case 'paymentDate':
			return formatDate(field);
		case 'type':
			return formatBadge(field);
		default:
			return field;
	}
}
