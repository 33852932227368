import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import { cardStatus } from 'constants/common';
import * as cardActions from 'actions/card';
import { getById } from 'selectors/card';
import helpers from 'helpers';
import CardSchema from 'schemas/card';
import { cardsUrl } from '../constants';
import M from './Activate.locale.json';

import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import { cardActionsTypes } from 'types/actions';

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class Activate extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
		}).isRequired,
		history: historyTypes.isRequired,
	}

	state = {
		loader: false,
		form: {
			cardNumber: '',
		},
		errors: {
			cardNumber: [],
		},
	}

	componentDidMount () {
		this.mounted = true;
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;
		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single(
				{ ...form, [name]: value },
				name,
				CardSchema.activate
			);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, history, match, card } = this.props;
		const { id } = match.params;

		if (loader) return;

		const errors = helpers.validator.all(form, CardSchema.activate);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ loader: true });

		const response = await actions.card.activate(form.cardNumber, id);

		if (response && response.errors) {
			this.mounted && this.setState({
				errors: { ...this.state.errors, ...response.errors },
				loader: false,
			});
			return;
		}

		// TODO it should be change!!!
		card.cardStatus = cardStatus.OPEN;

		history.push(cardsUrl);
	}

	mounted = false

	render () {
		const { card, intl } = this.props;
		const { loader, form, errors } = this.state;
		const t = intl.formatMessage;

		return (
			<div>
				<Form onSubmit={this.onSubmit}>
					<p><FM id={M.messages.note.id} /></p>
					<p>
						<FM
							id={M.messages.guide.id}
							values={{
								phone: (<strong>{card.phoneCardNumber}</strong>)
							}}
						/>
					</p>
					<Form.Group>
						<Input
							name="cardNumber"
							placeholder={t(M.input.cardnumber)}
							onChange={this.onChange}
							value={form.cardNumber}
							errors={errors.cardNumber}
							autoFocus
						/>
					</Form.Group>
					<Button
						className="CardSingleScreen__Submit"
						loading={loader}
						form
						small
					>
						{t(M.buttons.activate)}
					</Button>
				</Form>
			</div>
		);
	}
}

export default Activate;
