import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from '../Loader';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import './Preview.scss';

class Preview extends React.PureComponent {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			preview: PropTypes.string,
			type: PropTypes.string,
			loading: PropTypes.bool,
		}),
		onRemove: PropTypes.func,
		className: PropTypes.string,
	}

	onEnter = e => e.key === 'Enter' && this.onClick()
	onClick = () => this.props.onRemove(this.props.item.id)

	render () {
		const { className, item } = this.props;
		const { type, preview, name, loading } = item;

		const previewContent = (/(jpg|jpeg|png)/.test(type))
			? <img src={preview} alt={name} className="InputFilePreview__Image" />
			: (
				<div className="InputFilePreview__Placeholder">
					<Icon className="InputFilePreview__Icon" name="file" />
					<h5>{name.split('.').pop()}</h5>
				</div>
			);

		return (
			<div className={classnames('InputFilePreview', className)}>
				{!loading && <div className="InputFilePreview__Preview">{previewContent}</div>}
				<Tooltip className="InputFilePreview__Tooltip" title={name} />
				{loading && <Loader className="InputFilePreview__Loader" />}
				<div
					onClick={this.onClick}
					className="InputFilePreview__Remove"
					tabIndex={0}
					onKeyUp={this.onEnter}
					role="button"
				>
					<Icon name="close-small" />
				</div>
			</div>
		);
	}
}

export default Preview;
