import auth from './auth';
import user from './user';
import avatar from './avatar';
import password from './password';
import phone from './phone';
import email from './email';
import language from './language';
import client from './client';
import card from './card';
import member from './member';
import payment from './payment';
import c2cpayment from './c2cpayment';
import internationalPayment from './internationalPayment';
import transaction from './transaction';
import shareholder from './shareholder';
import country from './country';
import currency from './currency';
import document from './document';
import bank from './bank';
import voucher from './voucher';
import notification from './notification';
import application from './application';

export default {
	auth,
	user,
	avatar,
	password,
	phone,
	email,
	language,
	client,
	card,
	member,
	payment,
	c2cpayment,
	internationalPayment,
	transaction,
	shareholder,
	country,
	currency,
	document,
	bank,
	voucher,
	notification,
	application,
};
