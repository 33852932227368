import {
	MEMBER_FETCH_SUCCESS,
	MEMBER_GET_SUCCESS,
	MEMBER_CREATE_SUCCESS,
	MEMBER_EDIT_SUCCESS,
	MEMBER_SET_STATUS,
	MEMBER_SET_ACCESS,
	MEMBER_SET_SEARCH,
} from 'constants';
import { memberStatus, memberAccess } from 'constants/common';

const initial = {
	search: '',
	status: [memberStatus.ACTIVE, memberStatus.REQUESTED],
	access: [memberAccess.OWNER, memberAccess.BOOKKEEPER],
	items: [],
};

const reducer = (state = initial, action) => {
	switch (action.type) {
		case MEMBER_FETCH_SUCCESS:
			return {
				...state,
				items: [...action.payload],
			};
		case MEMBER_GET_SUCCESS: {
			const items = [...state.items];
			const idx = items.findIndex(item => item.userId === action.payload.userId);
			if (idx >= 0) items.splice(idx, 1, { ...action.payload });
			return { ...state, items };
		}
		case MEMBER_CREATE_SUCCESS:
			return {
				...state,
				items: [...state.items, action.payload],
			};
		case MEMBER_EDIT_SUCCESS: {
			const items = state.items.map(item => {
				if (item.userId !== action.payload.userId) return item;
				return {
					...item,
					position: action.payload.position,
				};
			});
			return { ...state, items };
		}
		case MEMBER_SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case MEMBER_SET_ACCESS:
			return {
				...state,
				access: action.payload,
			};
		case MEMBER_SET_SEARCH:
			return {
				...state,
				search: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
