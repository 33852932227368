const trackInitObj = {
	startTime: 0,
	keyboard: 0,
	mouse: 0,
	errors: 0,
};

const init = () => {
	localStorage.setItem('track', JSON.stringify({ ...trackInitObj, startTime: Date.now() }));
};

const destroy = () => {
	localStorage.removeItem('track');
};

const onMouseMove = () => {
	const savedTrack = localStorage.getItem('track');

	if (savedTrack) {
		const track = JSON.parse(savedTrack);
		track.mouse += 1;
		localStorage.setItem('track', JSON.stringify(track));
	}
};

const onKeyboardEvent = event => {
	const savedTrack = localStorage.getItem('track');

	if (savedTrack && (event.key === 'Backspace' || event.key === 'Delete')) {
		const track = JSON.parse(savedTrack);
		track.keyboard += 1;
		localStorage.setItem('track', JSON.stringify(track));
	}
};

const onError = () => {
	const savedTrack = localStorage.getItem('track');

	if (savedTrack) {
		const track = JSON.parse(savedTrack);
		track.errors += 1;
		localStorage.setItem('track', JSON.stringify(track));
	}
};


const analyse = (endTracking = false) => {
	const savedTrack = localStorage.getItem('track');
	let mark = 0;

	if (savedTrack) {
		const track = JSON.parse(savedTrack);
		const time = ((Date.now() - track.startTime) / 60000);
		if (endTracking && (time > 4 && time < 5)) {
			mark += 1;
		}
		if (track.mouse < 300) {
			mark += 1;
		}
		if (track.keyboard === 0 && track.errors === 0) {
			mark += 1;
		}
	}

	return { mark: `${mark}out3`, count: mark };
};

export default {
	init,
	destroy,
	onMouseMove,
	onKeyboardEvent,
	onError,
	analyse,
};
