// TODO: cases when multiple rows have the same value sort should behave consistently based
// on a fallback column. e.g. all IN / OUT should be sorted first by IN / OUT, then by some other column
// otherwise the table rows seems to "jump around" when sorting

const direction = (current, asc) => (asc ? current : -current);

export default sort => (aObj, bObj) => {
	const a = aObj[sort.id];
	const b = bObj[sort.id];

	if (typeof a === 'number' && typeof b === 'number') {
		return direction(b - a, sort.asc);
	}

	if (typeof a === 'string' && typeof b === 'string') {
		return direction(b.localeCompare(a), sort.asc);
	}

	// sort implemented only for strings and numbers
	return 0;
};
