export default {
	occupationProfession: [
		{
			value: 1,
			title: 'sections.regulatorydatacollectionform.occupationProfession.director'
		},
		{
			value: 2,
			title: 'sections.regulatorydatacollectionform.occupationProfession.executive'
		},
		{
			value: 3,
			title: 'sections.regulatorydatacollectionform.occupationProfession.manager'
		},
		{
			value: 4,
			title: 'sections.regulatorydatacollectionform.occupationProfession.employee'
		},
		{
			value: 5,
			title: 'sections.regulatorydatacollectionform.occupationProfession.selfEmployed'
		},
		{
			value: 6,
			title: 'sections.regulatorydatacollectionform.occupationProfession.student'
		},
		{
			value: 7,
			title: 'sections.regulatorydatacollectionform.occupationProfession.retiree'
		},
		{
			value: 8,
			title: 'sections.regulatorydatacollectionform.occupationProfession.unemployed'
		}
	],
	occupation: [
		{
			value: 9,
			title: 'sections.regulatorydatacollectionform.occupation.publicServantPoliceMilitary'
		},
		{
			value: 10,
			title: 'sections.regulatorydatacollectionform.occupation.agriculture'
		},
		{
			value: 11,
			title: 'sections.regulatorydatacollectionform.occupation.craftworkTrade'
		},
		{
			value: 12,
			title: 'sections.regulatorydatacollectionform.occupation.artsCultureSport'
		},
		{
			value: 13,
			title: 'sections.regulatorydatacollectionform.occupation.bankingInsuranceFinanceAuditing'
		},
		{
			value: 14,
			title: 'sections.regulatorydatacollectionform.occupation.construction'
		},
		{
			value: 15,
			title: 'sections.regulatorydatacollectionform.occupation.education'
		},
		{
			value: 16,
			title: 'sections.regulatorydatacollectionform.occupation.manufacturingMaintenance'
		},
		{
			value: 17,
			title: 'sections.regulatorydatacollectionform.occupation.medicalParamedical'
		},
		{
			value: 18,
			title: 'sections.regulatorydatacollectionform.occupation.foodIndustry'
		},
		{
			value: 19,
			title: 'sections.regulatorydatacollectionform.occupation.servicesIt'
		},
		{
			value: 20,
			title: 'sections.regulatorydatacollectionform.occupation.socialSecurityNgo'
		},
		{
			value: 21,
			title: 'sections.regulatorydatacollectionform.occupation.politician'
		}
	],
	cardPurpose: [
		{
			value: 22,
			title: 'sections.regulatorydatacollectionform.cardPurpose.socialWelfarePayments'
		},
		{
			value: 23,
			title: 'sections.regulatorydatacollectionform.cardPurpose.salary'
		},
		{
			value: 24,
			title: 'sections.regulatorydatacollectionform.cardPurpose.bills'
		},
		{
			value: 25,
			title: 'sections.regulatorydatacollectionform.cardPurpose.personalExpenses'
		},
		{
			value: 26,
			title: 'sections.regulatorydatacollectionform.cardPurpose.income'
		},
		{
			value: 27,
			title: 'sections.regulatorydatacollectionform.cardPurpose.moneyTransfer'
		},
		{
			value: 28,
			title: 'sections.regulatorydatacollectionform.cardPurpose.travelExpenses'
		},
		{
			value: 29,
			title: 'sections.regulatorydatacollectionform.cardPurpose.gamble'
		},
		{
			value: 30,
			title: 'sections.regulatorydatacollectionform.cardPurpose.payInStore'
		},
		{
			value: 31,
			title: 'sections.regulatorydatacollectionform.cardPurpose.saveMoney'
		}
	],
	monthlyIncome: [
		{
			value: 32,
			title: 'sections.regulatorydatacollectionform.monthlyIncome.lessThan500EUR'
		},
		{
			value: 33,
			title: 'sections.regulatorydatacollectionform.monthlyIncome.500To1000EUR'
		},
		{
			value: 34,
			title: 'sections.regulatorydatacollectionform.monthlyIncome.1001To1500EUR'
		},
		{
			value: 35,
			title: 'sections.regulatorydatacollectionform.monthlyIncome.1501To2000EUR'
		},
		{
			value: 36,
			title: 'sections.regulatorydatacollectionform.monthlyIncome.2001To3000EUR'
		},
		{
			value: 37,
			title: 'sections.regulatorydatacollectionform.monthlyIncome.moreThan3000EUR'
		}
	],
	estate: [
		{
			value: 38,
			title: 'sections.regulatorydatacollectionform.estate.lessThan20000EUR'
		},
		{
			value: 39,
			title: 'sections.regulatorydatacollectionform.estate.20000To50000EUR'
		},
		{
			value: 40,
			title: 'sections.regulatorydatacollectionform.estate.50001To75000EUR'
		},
		{
			value: 41,
			title: 'sections.regulatorydatacollectionform.estate.75001To100000EUR'
		},
		{
			value: 42,
			title: 'sections.regulatorydatacollectionform.estate.100001To250000EUR'
		},
		{
			value: 43,
			title: 'sections.regulatorydatacollectionform.estate.250001To500000EUR'
		},
		{
			value: 44,
			title: 'sections.regulatorydatacollectionform.estate.500001To1mEUR'
		},
		{
			value: 45,
			title: 'sections.regulatorydatacollectionform.estate.moreThan1mEUR'
		}
	]
};
