import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Loader.scss';

export default function Loader (props) {
	const { className, errors } = props;
	return (
		<div className={classnames('Loader', className, errors && 'Loader--Invalid')} />
	);
}

Loader.propTypes = {
	className: PropTypes.string,
	errors: PropTypes.array,
};
