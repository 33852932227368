import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as avatarActions from 'actions/avatar';

import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import InputAvatar from 'components/InputAvatar';

import M from './Account.locale.json';
import * as privateActions from 'actions/client/private';




const mapState = (state, props) => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		avatar: bindActionCreators(avatarActions, dispatch),
		private: bindActionCreators(privateActions, dispatch)
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loaderAvatar: false,

		errors: {
			avatar: [],
		}
	}

	componentDidMount () {
		this.onMount();
	}

	onMount = async () => {
		const { actions } = this.props;

		await actions.private.getAddress();

		this.setState({
			loader: false,
		});
	};

	onUpload = async file => {
		const { actions } = this.props;
		const { loaderAvatar } = this.state;

		if (loaderAvatar) return;

		this.setState({
			loaderAvatar: true,
		});

		const response = await actions.avatar.create(file);

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loaderAvatar: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState({
			loaderAvatar: false,
		});

		return response;
	};

	onRemove = async () => {
		const { actions } = this.props;
		const { loaderAvatar } = this.state;

		if (loaderAvatar) return;

		this.setState({
			loaderAvatar: true,
		});

		await actions.avatar.remove();

		this.setState({
			loaderAvatar: false,
		});
	}

	render () {
		const { user } = this.props;
		const { loaderAvatar, errors } = this.state;

		const t = this.props.intl.formatMessage;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="SettingScreen__Title"
				/>

				<InputAvatar
					user={user}
					onUpload={this.onUpload}
					onRemove={this.onRemove}
					name="avatar"
					loading={loaderAvatar}
					errors={errors.avatar}
				/>

				<Form.Group>
					<Input
						name="name"
						placeholder={t(M.inputs.name)}
						value={user.name}
						disabled
					/>

					<Input
						name="surname"
						placeholder={t(M.inputs.surname)}
						value={user.surname}
						disabled
					/>
				</Form.Group>

				<Form.Group>
					<Input
						name="email"
						placeholder={t(M.inputs.email)}
						value={user.email}
						disabled
					/>

				</Form.Group>
				{ user.address &&
				<Form.Group>
					<Input
						name="address"
						placeholder={t(M.inputs.address)}
						value={`${user.address.line1}, ${user.address.city}, ${user.address.postalCode}`}
						disabled
					/>
				</Form.Group>
				}
			</div>
		);
	}
}
