import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import document from 'constants/document';
import storage from '../../../services/storage';
import helpers from 'helpers';

import * as documentActions from 'actions/document/private';
import * as toastActions from 'actions/toast';
import * as cardSelector from 'selectors/card';

import Schema from 'schemas/setting/document';

import Title from 'components/Title';
import Label from 'components/Label';
import InputFile from 'components/InputFile';
import Form from 'components/Form';
import Button from 'components/Button';
import Tip from 'components/Tip';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';

import M from './Document.locale.json';
import env from '../../../../../env';
import BlockingScreen from '../../Blocking';

const mapState = state => ({
	currentCard: cardSelector.getById(state, state.card.current),
	cards: state.card.items
});

const mapDispatch = dispatch => ({
	actions: {
		document: bindActionCreators(documentActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends PureComponent {
	constructor () {
		super();
		this.state = {
			loader: false,
			loaders: {},
			files: [],
			errors: {
				[document.types.PASSPORT]: [],
				[document.types.PHOTO_ID_FRONT]: [],
				[document.types.PHOTO_ID_BACK]: [],
				[document.types.DRIVERS_LICENCE_FRONT]: [],
				[document.types.DRIVERS_LICENCE_BACK]: [],
				[document.types.PROOF_OF_ADDRESS]: [],
				[document.types.UTILITY_BILL]: [],
				[document.types.BANK_ACCOUNT_EVIDENCE]: []
			}
		};
	}

	componentDidMount () {
		const { actions } = this.props;
		const token = storage.get('session').split(' ')[1];
		actions.document.list().then(response => {
			const documents = response.map(doc => {
				doc.fieldName = doc.type;
				doc.type = doc.fileType;
				doc.preview = `${env.api}open/user/required-documents/${doc.id}?token=${token}`;
				return doc;
			});

			this.setState({
				files: documents
			});
		});
	}

	onChange = (file, name) => {
		const { files, errors } = this.state;

		const error = helpers.validator.custom(file, Schema.all);

		if (error) {
			this.setState({
				errors: {
					...errors,
					[name]: error
				}
			});
			return;
		}

		this.setState({
			files: [
				{
					name: file.name,
					fieldName: name,
					id: null,
					loading: false,
					type: file.type,
					preview: URL.createObjectURL(file),
					file,
					errors: []
				},
				...files
			],
			errors: {
				...errors,
				[name]: []
			}
		});
	};

	onRemove = async idx => {
		const { files, loaders } = this.state;
		const { actions } = this.props;

		const file = files[idx];

		if (file.id) {
			actions.document.remove(file.id);
		}

		this.setState({
			files: files.filter((val, i) => i !== idx)
		});
	};

	onDownload = async idx => {
		const { files } = this.state;
		const { actions } = this.props;

		const item = files[idx];
		const data = await actions.document.get(item.id);
		helpers.downloader.file(item.name, item.type, data);
	};

	onSubmit = async e => {
		e.preventDefault();

		const { loader, files, errors } = this.state;
		const { actions, intl } = this.props;
		const t = intl.formatMessage;

		if (loader) return;

		if (!this.isProofOfIdentity()) {
			actions.toast.create({ type: 'error', title: M.validation.proofOfIdentity });
			return;
		}

		if (!this.isProofOfAddress()) {
			actions.toast.create({ type: 'error', title: M.validation.proofOfAddress });
			return;
		}

		this.setState({
			loader: true,
		});

		files.filter(f => f.id === null).map(f => {
			f.loading = true;
		});

		this.setState({
			files
		});

		// upload only new files
		Promise.all(files.filter(f => f.id === null).map(async f => {
			const res = await actions.document.create(f.file, f.fieldName);

			f.loading = false;
			if (res.errors) {
				f.errors = [t(M.errors.upload)];
			} else {
				f.id = res.id;
			}

			this.setState({
				files: [...files]
			});
		})).then(res => {
			triggerEvent(GTMEvent.documentsUploaded, '/settings/document');
			this.setState({
				loader: false,
			});
		});
	};

	isProofOfIdentity = () => {
		const { files } = this.state;

		const passport = files.find(f => f.fieldName === document.types.PASSPORT);
		const driversLicence = files.filter(f => f.fieldName === document.types.DRIVERS_LICENCE_FRONT
			|| f.fieldName === document.types.DRIVERS_LICENCE_BACK).length;
		const photoId = files.filter(f => f.fieldName === document.types.PHOTO_ID_FRONT
			|| f.fieldName === document.types.PHOTO_ID_BACK).length;

		return passport || driversLicence >= 2 || photoId >= 2;
	};

	isProofOfAddress = () => {
		const { files } = this.state;
		return files.find(f => f.fieldName === document.types.PROOF_OF_ADDRESS
			|| f.fieldName === document.types.BANK_ACCOUNT_EVIDENCE || f.fieldName === document.types.UTILITY_BILL);
	};


	render () {
		const { files, loader, loaders, errors } = this.state;
		const t = this.props.intl.formatMessage;

		const preview = files.reduce((r, item, idx) => {
			r[item.fieldName] = r[item.fieldName] || [];
			r[item.fieldName].push(
				<InputFile.Preview
					key={idx}
					item={item}
					onRemove={() => this.onRemove(idx)}
					onDownload={item.id !== null ? () => this.onDownload(idx) : null} // download only uploaded
				/>
			);
			return r;
		}, {});

		if (this.props.cards.some(cards => ['CARD_04', 'CARD_05', 'CARD_06'].includes(cards.product))) {
			return (
				<div className="SettingScreen__Document">
					<Title
						title={t(M.title)}
						legend={t(M.legend)}
						className="SettingScreen__Title"
					/>

					<div className="Separator" />

					<div className="Rules">
						<Tip
							type="info"
							// icon="warning"
							title={t(M.rules.title)}
						>
							<ul>
								<li>{t(M.rules.one)}</li>
								<li>{t(M.rules.two)}</li>
								<li>{t(M.rules.three)}</li>
								<li>{t(M.rules.four)}</li>
							</ul>
						</Tip>

					</div>

					<div className="InfoBlock Identity">
						<h3>{t(M.identity.title)}</h3>
						<ul>
							<li>{t(M.identity.bullets.one)}</li>
							<li>{t(M.identity.bullets.two)}</li>
							<li>{t(M.identity.bullets.three)}</li>
						</ul>
						<h5>{t(M.identity.note)}</h5>
						<ul>
							<li>{t(M.identity.bullets.four)}</li>
							<li>{t(M.identity.bullets.five)}</li>
						</ul>
					</div>

					<Form.Group>
						<Label>{t(M.inputs.passport)}</Label>
						<div>
							{preview[document.types.PASSPORT]}
							<InputFile
								name={document.types.PASSPORT}
								label={t(M.inputs.label)}
								onChange={this.onChange}
								errors={errors[document.types.PASSPORT]}
							/>
						</div>
					</Form.Group>

					<div className="Separator" />

					<Form.Group>
						<div className="SubGroup">
							<Label>{t(M.inputs.idFront)}</Label>
							<div>
								{preview[document.types.PHOTO_ID_FRONT]}
								<InputFile
									name={document.types.PHOTO_ID_FRONT}
									label={t(M.inputs.label)}
									onChange={this.onChange}
									errors={errors[document.types.PHOTO_ID_FRONT]}
								/>
							</div>
						</div>
						<div className="SubGroup">
							<Label>{t(M.inputs.idBack)}</Label>
							<div>
								{preview[document.types.PHOTO_ID_BACK]}
								<InputFile
									name={document.types.PHOTO_ID_BACK}
									label={t(M.inputs.label)}
									onChange={this.onChange}
									errors={errors[document.types.PHOTO_ID_BACK]}
								/>
							</div>
						</div>
					</Form.Group>

					<div className="Separator" />

					<Form.Group>
						<div className="SubGroup">
							<Label>{t(M.inputs.driversLicenceFront)}</Label>
							<div>
								{preview[document.types.DRIVERS_LICENCE_FRONT]}
								<InputFile
									name={document.types.DRIVERS_LICENCE_FRONT}
									label={t(M.inputs.label)}
									onChange={this.onChange}
									errors={errors[document.types.DRIVERS_LICENCE_FRONT]}
								/>
							</div>
						</div>
						<div className="SubGroup">
							<Label>{t(M.inputs.driversLicenceBack)}</Label>
							<div>
								{preview[document.types.DRIVERS_LICENCE_BACK]}
								<InputFile
									name={document.types.DRIVERS_LICENCE_BACK}
									label={t(M.inputs.label)}
									onChange={this.onChange}
									errors={errors[document.types.DRIVERS_LICENCE_BACK]}
								/>
							</div>
						</div>
					</Form.Group>

					<div className="Separator" />

					<div className="InfoBlock Proof">
						<h3>{t(M.proof.title)}</h3>
						<ul>
							<li>{t(M.proof.bullets.one)}</li>
							<li>{t(M.proof.bullets.two)}</li>
							<li>{t(M.proof.bullets.three)}</li>
						</ul>
						<h5>{t(M.proof.note)}</h5>
						<ul>
							<li>{t(M.proof.bullets.four)}</li>
							<li>{t(M.proof.bullets.five)}</li>
							<li>{t(M.proof.bullets.six)}</li>
						</ul>
					</div>

					<Form.Group>

						<Label>{t(M.inputs.utilityBill)}</Label>
						<div>
							{preview[document.types.UTILITY_BILL]}
							<InputFile
								name={document.types.UTILITY_BILL}
								label={t(M.inputs.label)}
								onChange={this.onChange}
								errors={errors[document.types.UTILITY_BILL]}
							/>
						</div>
					</Form.Group>

					<div className="Separator" />

					<Form.Group>

						<Label>{t(M.inputs.proofOfAddress)}</Label>
						<div>
							{preview[document.types.PROOF_OF_ADDRESS]}
							<InputFile
								name={document.types.PROOF_OF_ADDRESS}
								label={t(M.inputs.label)}
								onChange={this.onChange}
								errors={errors[document.types.PROOF_OF_ADDRESS]}
							/>
						</div>
					</Form.Group>

					<div className="Separator" />

					<Form.Group>

						<Label>{t(M.inputs.bankStatement)}</Label>
						<div>
							{preview[document.types.BANK_ACCOUNT_EVIDENCE]}
							<InputFile
								name={document.types.BANK_ACCOUNT_EVIDENCE}
								label={t(M.inputs.label)}
								onChange={this.onChange}
								errors={errors[document.types.BANK_ACCOUNT_EVIDENCE]}
							/>
						</div>
					</Form.Group>

					<Button
						className="SettingScreen__Upload"
						loading={loader}
						onClick={this.onSubmit}
						form
						small
					>
						{t(M.buttons.upload)}
					</Button>

				</div>
			);
		}
		return (
			<div className="SettingScreen__Document">
				{
					!loader &&
						<BlockingScreen />
				}
			</div>
		);
	}
}
