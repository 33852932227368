import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import validator from 'helpers/validator';
import reduxer from 'helpers/reduxer';

import ClientSchema from 'schemas/client';

import * as clientSelectors from 'selectors/client';

import * as clientActions from 'actions/client';

import industry from '../../../../services/industry';

import Form from 'components/Form';
import Input from 'components/Input';
import Label from 'components/Label';
import Button from 'components/Button';
import InputRadio from 'components/InputRadio';
import InputCheckbox from 'components/InputCheckbox';
import InputDate from 'components/InputDate';
import InputSelect from 'components/InputSelect';
import Tip from 'components/Tip';
import Tooltip from 'components/Tooltip';
import Tab from 'components/Tab';

import M from './Basic.locale.json';



const INDUSTRY = industry.list();

const mapState = (state, props) => ({
	form: state.client.business.form.basic,
	industry: clientSelectors.industry(props, INDUSTRY),
});

const mapDispatch = dispatch => ({
	actions: {
		client: reduxer.bindNestedActionCreators(clientActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Basic extends PureComponent {
	state = {
		loader: false,
		errors: {
			name: [],
			tradingName: [],
			businessType: [],
			businessTypeOther: [],
			industry: [],
			registrationNumber: [],
			dateOfIncorporation: [],
			taxNumber: [],
			vatNumber: [],
			authority: [],
			authorityName: [],
			authorityLicence: []
		}
	};

	componentDidMount = () => {
		const { actions } = this.props;

		actions.client.business.get('basic');
	}

	onChange = (value, name) => {
		const { errors } = this.state;
		const { form, actions } = this.props;

		let messages = [];

		if (errors[name].length) {
			messages = validator.single({
				...form,
				[name]: value,
			}, name, ClientSchema.create.basic);
		}

		if (name === 'taxNumber' || name === 'vatNumber') value = value.toUpperCase();

		this.setState({
			errors: {
				...errors,
				[name]: messages,
			},
		});

		actions.client.business.form({
			[name]: value,
		}, 'basic');
	};

	onSubmit = async e => {
		e.preventDefault();

		const { loader } = this.state;
		const { client, form, actions, history, match } = this.props;

		const { id } = match.params;

		if (loader) return;

		const errors = validator.all(form, ClientSchema.create.basic);

		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
				loader: false,
			});

			return;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.client.business.edit(form, 'basic');

		if (response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState({
			loader: false,
		});

		history.push(`/clients/create/business/${id}/location`);
	};

	render () {
		const { loader, errors } = this.state;
		const { form, current, step, industry } = this.props;

		const t = this.props.intl.formatMessage;

		const labelNameTrading = (
			<div>
				{t(M.inputs.tradingName)} {t(M.inputs.ifAny)}
				<Tooltip.Mark>
					<Tooltip
						title={t(M.tooltips.tradingName.title)}
						body={t(M.tooltips.tradingName.body)}
						large
					/>
				</Tooltip.Mark>
			</div>
		);

		const labelIndustry = (
			<div>
				{t(M.inputs.industry)}
				<Tooltip.Mark>
					<Tooltip
						title={t(M.tooltips.industry.title)}
						body={t(M.tooltips.industry.body)}
						large
					/>
				</Tooltip.Mark>
			</div>
		);

		const labelAuthority = (
			<Tooltip.Mark>
				<Tooltip
					title={t(M.tooltips.authority.title)}
					body={t(M.tooltips.authority.body)}
					bottom
				/>
			</Tooltip.Mark>
		);

		return (
			<div
				className="ClientCreateBusinessScreen__Step"
			>
				<Form
					onSubmit={this.onSubmit}
				>
					<Form.Group>
						<Input
							name="name"
							placeholder={t(M.inputs.name)}
							onChange={this.onChange}
							value={form.name}
							errors={errors.name}
							disabled
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="tradingName"
							label={labelNameTrading}
							placeholder={t(M.inputs.tradingName)}
							onChange={this.onChange}
							value={form.tradingName}
							errors={errors.tradingName}
							autoFocus
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="registrationNumber"
							placeholder={t(M.inputs.registrationNumber)}
							onChange={this.onChange}
							value={form.registrationNumber}
							errors={errors.registrationNumber}
							disabled
						/>
					</Form.Group>

					<Form.Group>
						<InputDate
							name="dateOfIncorporation"
							label={t(M.inputs.dateOfIncorporation)}
							placeholder={t(M.inputs.date)}
							onChange={this.onChange}
							value={form.dateOfIncorporation}
							errors={errors.dateOfIncorporation}
							disabledDate={new Date()}
						/>
					</Form.Group>

					<Form.Group
						row
					>
						<Label>{t(M.inputs.businessType)}</Label>
						<InputRadio
							id="type1"
							name="businessType"
							value="PRIVATE"
							checked={form.businessType === 'PRIVATE'}
							onChange={this.onChange}
							errors={errors.businessType}
						>
							{t(M.inputs.businessTypes.private)}
						</InputRadio>

						<InputRadio
							id="type2"
							name="businessType"
							value="PUBLIC"
							checked={form.businessType === 'PUBLIC'}
							onChange={this.onChange}
							errors={errors.businessType}

						>
							{t(M.inputs.businessTypes.public)}
						</InputRadio>

						<InputRadio
							id="type3"
							name="businessType"
							value="OTHER"
							checked={form.businessType === 'OTHER'}
							onChange={this.onChange}
							errors={errors.businessType}
						>
							{t(M.inputs.businessTypes.other)}
						</InputRadio>

						<InputRadio.Error
							errors={errors.businessType}
						/>
					</Form.Group>

					{
						form.businessType === 'OTHER' &&
						<Form.Group>
							<Input
								name="businessTypeOther"
								placeholder={t(M.inputs.businessTypeOther)}
								onChange={this.onChange}
								value={form.businessTypeOther}
								errors={errors.businessTypeOther}
							/>
						</Form.Group>
					}

					<Form.Group>
						<InputSelect
							name="industry"
							onChange={this.onChange}
							label={labelIndustry}
							placeholder={t(M.inputs.industry)}
							value={form.industry}
							errors={errors.industry}
							data={industry}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="taxNumber"
							placeholder={t(M.inputs.taxNumber)}
							onChange={this.onChange}
							value={form.taxNumber}
							errors={errors.taxNumber}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="vatNumber"
							placeholder={t(M.inputs.vatNumber)}
							onChange={this.onChange}
							value={form.vatNumber}
							errors={errors.vatNumber}
						/>
					</Form.Group>

					<Form.Group>
						<InputCheckbox
							name="authority"
							onChange={this.onChange}
							errors={errors.authority}
							checked={form.authority}
							value="notRegulated"
						>
							{t(M.inputs.authority)} {labelAuthority}
						</InputCheckbox>
					</Form.Group>

					{
						form.authority &&
						<Form.Group>
							<Input
								name="authorityName"
								placeholder={t(M.inputs.authorityName)}
								onChange={this.onChange}
								value={form.authorityName}
								errors={errors.authorityName}
							/>
						</Form.Group>
					}

					{
						form.authority &&
						<Form.Group>
							<Input
								name="authorityLicence"
								placeholder={t(M.inputs.authorityLicence)}
								onChange={this.onChange}
								value={form.authorityLicence}
								errors={errors.authorityLicence}
							/>
						</Form.Group>
					}

					<Button
						className="Stepper__Button"
						loading={loader}
						form
						small
					>
						<span>2/5</span> {t(M.buttons.next)}
					</Button>
				</Form>
			</div>
		);
	}
}
