export default {
	voucher: {
		voucher: {
			presence: { message: '^schemas.presence' },
			length: {
				is: 10,
				message: '^schemas.length.is',
			},
		},
	},
};
