import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';

import Tip from 'components/Tip';

import M from './Tip.locale.json';


@injectIntl
export default class Basic extends PureComponent {
	render () {
		const { current, unsaved } = this.props;

		const t = this.props.intl.formatMessage;

		const button = (
			<strong>{t(M.buttons.next)}</strong>
		);

		return (
			<div
				className="ClientCreateBusinessScreen__Tip"
			>
				<div
					className="ClientCreateBusinessScreen__TipWrap"
				>
					{
						(unsaved === 'warning') &&
						<Tip
							type="warning"
							icon="circle-warning"
							title={t(M.unsaved.title)}
						>
							<FM
								id={M.unsaved.body.id}
								values={{ button }}
							/>
						</Tip>
					}

					{
						current === 0 &&
						<Tip
							type="info"
							icon="hatchling"
							title={t(M.basic.title)}
						>
							{t(M.basic.body)}
						</Tip>
					}

					{
						current === 1 &&
						<Tip
							type="info"
							icon="map"
							title={t(M.location.title)}
						>
							{t(M.location.body)}
						</Tip>
					}

					{
						current === 2 &&
						<Tip
							type="info"
							icon="contact"
							title={t(M.contact.title)}
						>
							{t(M.contact.body)}
						</Tip>
					}

					{
						current === 3 &&
						<Tip
							type="info"
							icon="shareholders"
							title={t(M.shareholders.title)}
						>
							{t(M.shareholders.body)}
						</Tip>
					}

					{
						current === 4 &&
						<Tip
							type="info"
							icon="documents"
						>
							{t(M.documents.documents)}
						</Tip>
					}

					{
						current === 4 &&
						<Tip
							type="info"
							icon="size"
						>
							{t(M.documents.size)}
						</Tip>
					}
				</div>
			</div>
		);
	}
}
