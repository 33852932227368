import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button';
import Icon from '../Icon';
import './Toast.scss';

const classes = {
	success: 'Toast--Success',
	warning: 'Toast--Warning',
	error: 'Toast--Error',
	info: 'Toast--Info',
};

export default class Toast extends React.Component {
	static propTypes = {
		toast: PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			body: PropTypes.string,
			type: PropTypes.string,
			hide: PropTypes.bool,
		}),
		onRemove: PropTypes.func,
		className: PropTypes.string,
	}

	shouldComponentUpdate (nextProps) {
		return (nextProps.toast.title !== this.props.toast.title) ||
			(nextProps.toast.body !== this.props.toast.body) ||
			(nextProps.toast.hide !== this.props.toast.hide);
	}

	onRemove = () => {
		const { onRemove, toast: { id } } = this.props;
		onRemove(id);
	}

	render () {
		const { toast, className } = this.props;
		const { type, hide, title, body } = toast;

		return (
			<div className={classnames('Toast', className, hide && 'Toast--Hide', classes[type])}>
				<div className="Toast__Body">
					<div className="Toast__Icon">
						<Icon name={type || 'success'} />
					</div>
					<h5>{title}</h5>
					{body && <p>{toast.body}</p>}
				</div>

				<Button
					className="Toast__Close"
					onClick={this.onRemove}
					type="div"
					ghost
					small
				>
					<Icon name="close-small" />
				</Button>
			</div>
		);
	}
}
