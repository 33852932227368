import storage from 'services/storage';
import API from 'services/api';
import { success, error } from '../toast';
import M from './document.private.locale.json';

export const create = (file, type) => async dispatch => {
	try {
		const { data: { response } } = await API.user.documents.create(file, type);
		success(dispatch)({ title: M.success.create.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.create.title });
		return err;
	}
};

export const list = () => async () => {
	try {
		const { data: { response } } = await API.user.documents.list();
		return response;
	} catch (err) { return null; }
};

export const get = id => async () => {
	try {
		const { data } = await API.user.documents.get(id, storage.get('session').split(' ')[1]);
		return data;
	} catch (err) { return null; }
};

export const remove = id => async dispatch => {
	try {
		const result = await API.user.documents.remove(id);
		success(dispatch)({ title: M.success.remove.title });
		return result;
	} catch (err) {
		error(dispatch)({ title: M.error.remove.title });
		return null;
	}
};
