import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import Timer from 'components/Timer';
import * as countryActions from 'actions/country';
import * as phoneActions from 'actions/phone';
import helpers from 'helpers';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';
import AuthSchema from 'schemas/auth';
import * as userSelector from 'selectors/user';
import M from './Phone.locale.json';
import './Phone.scss';
import tracker from 'helpers/tracker';
import { UTMParams } from 'constants/common';

import intlTypes from 'types/intl';

const disableTime = 15000;

const mapState = state => ({
	user: state.user,
	userPhone: userSelector.getUserPhone(state),
});

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		phone: bindActionCreators(phoneActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Phone extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		user: PropTypes.shape({
			phoneVerified: PropTypes.bool,
		}),
		userPhone: PropTypes.string.isRequired,
		location: PropTypes.shape({
			state: PropTypes.shape({
				from: PropTypes.object.isRequired,
			}),
		}).isRequired,
		actions: PropTypes.shape({
			country: PropTypes.shape({
				fetch: PropTypes.func.isRequired,
			}).isRequired,
			phone: PropTypes.shape({
				get: PropTypes.func.isRequired,
				create: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
	}

	state = {
		loader: false,
		loaderResend: false,
		disabledResend: false,
		form: { code: '' },
		errors: { code: [] },
	}

	componentDidMount () {
		this.onMount();
		triggerEvent(GTMEvent.phoneVerification, '/auth/phone');

		document.addEventListener('mousemove', tracker.onMouseMove);
	}

	componentWillUnmount () {
		clearTimeout(this.timeout);

		document.removeEventListener('mousemove', tracker.onMouseMove);
	}

	onMount = () => {
		const { actions, user } = this.props;
		actions.country.fetch();
		if (!user.phoneSent) actions.phone.get();
	}

	onResend = async () => {
		const { actions } = this.props;
		const { loaderResend, disabledResend } = this.state;

		if (loaderResend || disabledResend) return;

		this.setState({ loaderResend: true });
		await actions.phone.get();
		this.onDisable();
	}

	onDisable = () => {
		this.setState({ loaderResend: false, disabledResend: true });
		this.timeout = setTimeout(
			() => this.setState({ disabledResend: false }), disableTime);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, AuthSchema.phone);
		}

		this.setState({
			loader: false,
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions, user } = this.props;
		const { form, loader, loaderResend } = this.state;
		if (loader || loaderResend) return;

		const errors = helpers.validator.all(form, AuthSchema.phone);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });

			tracker.onError();
			return;
		}

		form.language = user.language;
		this.setState({ loader: true });

		this.fixateTrackResult();

		const response = await actions.phone.create(form);

		if (response && response.errors) {
			const update = {
				errors: { ...this.state.errors, ...response.errors },
				loader: false,
			};

			if (response.messages) update.messages = response.messages;
			this.setState(update);
		}
	}

	fixateTrackResult = () => {
		const result = tracker.analyse(true);
		if (result.count > 0) {
			const params = {
				utm_source: 'PROBABLY_UNSAFE',
				utm_medium: 'PROBABLY_UNSAFE',
				utm_campaign: result.mark,
			};
			localStorage.setItem(UTMParams, JSON.stringify(params));
		}
		tracker.destroy();
	}

	render () {
		const { form, errors, loader, loaderResend, disabledResend } = this.state;
		const { user, userPhone, intl } = this.props;

		if (!user) return <Redirect to="/" />;
		if (user.phoneVerified) return <Redirect to={{ pathname: '/auth/regulatory-data-collection' }} />;

		const t = intl.formatMessage;

		return (
			<Layout className="AuthPhoneScreen Screen">
				<Nav logo />
				<Section>
					<Container small>
						<Title title={t(M.title)} legend={t(M.legend)} />
						<Form onSubmit={this.onSubmit} onKeyDown={tracker.onKeyboardEvent}>
							<Form.Group>
								<Input
									name="phoneCountry"
									placeholder={t(M.inputs.phoneCountry)}
									value={userPhone}
									disabled
								/>
								<Input
									name="phone"
									placeholder={t(M.inputs.phone)}
									value={user.phone}
									disabled
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="code"
									placeholder={t(M.inputs.code)}
									onChange={this.onChange}
									value={form.code}
									errors={errors.code}
								/>
							</Form.Group>
							<Button
								loading={loader}
								disabled={loaderResend}
								form
								small
							>
								{t(M.buttons.verify)}
							</Button>
							<ButtonLink
								onClick={this.onResend}
								className={cn('AuthPhoneScreenResend', { 'AuthPhoneScreenResendDisabled': loader || disabledResend })}
							>
								{disabledResend && <Timer time={15} />}
								{!disabledResend && t(M.buttons.resend)}
							</ButtonLink>
						</Form>
					</Container>
				</Section>
			</Layout>
		);
	}
}
