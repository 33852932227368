import {
	CARD_FETCH_REQUEST,
	CARD_FETCH_SUCCESS,
	CARD_GET_SUCCESS,
	CARD_CREATE_SUCCESS,
	CARD_SET_STATUS,
	CARD_SET_SEARCH,
	CARD_CURRENT,
	CARD_SET_REGULATORY_DATA_COLLECTION,
	CARD_REMOVE_REGULATORY_DATA_COLLECTION,
	CARD_SET_NEW_DATA_REQUEST,
	CARD_SET_NEW_DATA_SUCCESS,
	CARD_SET_NEW_DATA_ERROR,
} from 'constants';
import { cardStatuses } from 'constants/common';
import updateHandler from 'helpers/updateHandler';

const initial = {
	search: '',
	status: cardStatuses,
	items: [],
	current: '',
	initialRegulatoryDataCollection: null,
};

const reducer = (state = initial, action) => {
	switch (action.type) {
		case CARD_FETCH_REQUEST: {
			if (!action.clear) return state;
			return { ...state, items: [] };
		}
		case CARD_FETCH_SUCCESS: {
			const current = !state.current ? action.payload[0].id : state.current;
			return {
				...state,
				items: [...action.payload],
				current,
			};
		}
		case CARD_GET_SUCCESS: {
			const items = state.items.filter(item => item.id !== action.payload.id);
			return {
				...state,
				items: [...items, action.payload],
			};
		}
		case CARD_CREATE_SUCCESS:
			return {
				...state,
				items: [
					...state.items,
					{ ...action.payload },
				]
			};
		case CARD_SET_STATUS:
			return {
				...state,
				status: action.payload
			};
		case CARD_SET_SEARCH:
			return {
				...state,
				search: action.payload
			};
		case CARD_CURRENT:
			return {
				...state,
				current: action.payload
			};
		case CARD_SET_REGULATORY_DATA_COLLECTION:
			return {
				...state,
				initialRegulatoryDataCollection: action.payload,
			};
		case CARD_REMOVE_REGULATORY_DATA_COLLECTION:
			return {
				...state,
				initialRegulatoryDataCollection: null,
			};
		case CARD_SET_NEW_DATA_REQUEST: {
			const { cardId } = action.payload;
			const items = updateHandler(state.items, cardId, {}, true);
			return { ...state, items };
		}
		case CARD_SET_NEW_DATA_SUCCESS: {
			const { cardId, data } = action.payload;
			const items = updateHandler(state.items, cardId, data, false);
			return { ...state, items };
		}
		case CARD_SET_NEW_DATA_ERROR: {
			const { payload } = action;
			const items = updateHandler(state.items, payload.id, payload, false, true);
			return { ...state, items };
		}
		default:
			return state;
	}
};

export default reducer;
