import React from 'react';

function Icon (props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 468.293 468.293"
			version="1.1"
			viewBox="0 0 468.293 468.293"
			xmlSpace="preserve"
			{...props}
		>
			<circle
				cx="234.146"
				cy="234.146"
				r="234.146"
				fill="#44C4A1"
			/>
			<path
				fill="#EBF0F3"
				d="M357.52 110.145L191.995 275.67 110.773 194.451 69.534 235.684 191.995 358.148 398.759 151.378z"
			/>
		</svg>
	);
}

export default Icon;
