import React from 'react';

import Icon from './Icon';
import PaymentResultPage from '../../PaymentResultPage';

const Success = () => {
	return (
		<PaymentResultPage titleId="pages.payments.pfs.success.title" Icon={Icon} />
	);
};

export default Success;
