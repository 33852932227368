const signup = form => {
	if (!form) return null;

	const convert = {
		...form,
	};

	if (form.businessName) {
		convert.name = form.businessName;
		delete convert.businessName;
	}
	if (form.businessCountry) {
		convert.country = form.businessCountry;
		delete convert.businessCountry;
	}
	return convert;
};

export default {
	signup,
};
