import fetcher from 'helpers/fetcher';

export default {
	get () {
		return fetcher.get('user');
	},
	documents: {
		create (file, type) {
			const data = new FormData();
			data.append('file', file);
			return fetcher.post(`user/required-documents`, data, {
				params: { type },
				headers: { 'Content-Type': null }
			});
		},
		list () {
			return fetcher.get(`user/required-documents`);
		},
		get (id, token) {
			return fetcher.get(`open/user/required-documents/${id}?token=${token}`, {
				responseType: 'blob',
			});
		},
		remove (id) {
			return fetcher.delete(`/user/required-documents/${id}`);
		}
	}
};
