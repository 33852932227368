import {
	PAYMENT_FETCH_SUCCESS,
	PAYMENT_GET_SUCCESS,
	PAYMENT_CREATE_SUCCESS,
	PAYMENT_AMOUNT_SUCCESS,
	PAYMENT_AMOUNT_ERROR,
	PAYMENT_PHONE_SUCCESS,
	PAYMENT_PHONE_ERROR,
	PAYMENT_SET_SEARCH,
	PAYMENT_SET_COLUMNS,
	PAYMENT_SET_STATUS,
	PAYMENT_SET_SORT,
} from 'constants';
import { paymentStatuses } from 'constants/common';

const initial = {
	search: '',
	sort: {
		id: 'created',
		asc: true,
	},
	columns: ['id', 'bic', 'beneficiary', 'details', 'iban', 'status', 'created', 'amount', 'predictedFee'],
	status: paymentStatuses,
	items: [],
	current: null,
};

const reducer = (state = initial, action) => {
	switch (action.type) {
		case PAYMENT_FETCH_SUCCESS:
			return {
				...state,
				items: [...action.payload],
			};
		case PAYMENT_GET_SUCCESS: {
			const items = [...state.items];
			const idx = items.findIndex(item => item.id === action.payload.id);
			if (idx >= 0) items.splice(idx, 1, { ...action.payload });
			return { ...state, items };
		}
		case PAYMENT_CREATE_SUCCESS: {
			const items = [...state.items, action.payload];
			return { ...state, items };
		}
		case PAYMENT_AMOUNT_SUCCESS:
		case PAYMENT_AMOUNT_ERROR: {
			const items = state.items.map(item => {
				if (item.id !== action.id) return item;
				return {
					...item,
					status: action.payload.status,
				};
			});
			return { ...state, items };
		}
		case PAYMENT_PHONE_SUCCESS:
		case PAYMENT_PHONE_ERROR: {
			const items = state.items.map(item => {
				if (item.id !== action.id) return item;
				return { ...item, ...action.payload };
			});
			return { ...state, items };
		}
		case PAYMENT_SET_SEARCH:
			return {
				...state,
				search: action.payload,
			};
		case PAYMENT_SET_COLUMNS:
			return {
				...state,
				columns: action.payload,
			};
		case PAYMENT_SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case PAYMENT_SET_SORT:
			return {
				...state,
				sort: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
