import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HeaderItem from './HeaderItem';
import './Header.scss';

class TabHeader extends React.PureComponent {
	static propTypes = {
		current: PropTypes.number,
		header: PropTypes.array,
		onSelect: PropTypes.func,
		className: PropTypes.string,
	}

	render () {
		const { className, header, current, onSelect } = this.props;
		return (
			<div className={classnames('TabHeader', className)}>
				{header.map((item, idx) => (
					<HeaderItem
						key={item.id}
						idx={idx}
						item={item}
						onSelect={onSelect}
						active={current === idx}
					/>
				))}
			</div>
		);
	}
}

export default TabHeader;
