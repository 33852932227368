import {
	DOCUMENT_FETCH_SUCCESS,
	DOCUMENT_CREATE_SUCCESS,
	DOCUMENT_REMOVE_REQUEST,
	DOCUMENT_REMOVE_SUCCESS,
} from 'constants';
import { success, error } from 'actions/toast';
import renamer from 'helpers/renamer';
import API from 'services/api';
import * as clientSelector from 'selectors/client';
import M from './document.locale.json';

const errorMessage = 'File too large';

export const fetch = () => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.document.fetch(clientId);
		const document = renamer.from.document.get(clientId, result.data.response);
		dispatch({ type: DOCUMENT_FETCH_SUCCESS, payload: document });
	} catch (err) { }
};

export const get = id => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const { data } = await API.document.get(clientId, id);
		return data;
	} catch (err) { return err; }
};

export const create = (file, type) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.document.create(file, type, clientId);
		const document = renamer.from.document.get(clientId, [result.data.response]);
		dispatch({ type: DOCUMENT_CREATE_SUCCESS, payload: { type, document } });
		success(dispatch)({ title: M.success.create.title });
		return document;
	} catch (err) {
		if (!err.status) {
			err.status = 413;
			err.messages.push({ message: errorMessage });
		}
		error(dispatch)({ title: M.error.create.title });
		return err;
	}
};

export const remove = (id, type, total) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	dispatch({ type: DOCUMENT_REMOVE_REQUEST, payload: { type, id } });
	try {
		const result = await API.document.remove(id, clientId);
		dispatch({ type: DOCUMENT_REMOVE_SUCCESS, payload: { type, id, total } });
		success(dispatch)({ title: M.success.remove.title });
		return result.data.response;
	} catch (err) {
		error(dispatch)({ title: M.error.remove.title });
		return err;
	}
};
