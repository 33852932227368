export const roles = (props, items) => {
	const t = props.intl.formatMessage;

	return Object.keys(items).map(key => ({
		...items[key],
		title: t(items[key].title),
	}));
};

export const rolesTable = (state, props, items) => {
	const t = props.intl.formatMessage;

	return state.map(item => {
		const role = item.role.toLowerCase();

		return {
			...item,
			role: t(items[role]),
		};
	});
};
