import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import CardSelect from 'components/CardSelect/CardSelect';
import RegulatoryDataCollectionForm from 'sections/RegulatoryDataCollectionForm';
import intlTypes from 'types/intl';

import M from './RegulatoryDataModal.locale.json';

@injectIntl
class RegulatoryDataModal extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		onSave: PropTypes.func.isRequired,
		disabled: PropTypes.bool.isRequired,
		loading: PropTypes.bool.isRequired,
		values: PropTypes.shape({
			occupationProfession: PropTypes.number,
			occupation: PropTypes.number,
			cardPurpose: PropTypes.number,
			monthlyIncome: PropTypes.number,
			estate: PropTypes.number,
		}),
		onNextCard: PropTypes.func.isRequired,
		isNextButton: PropTypes.bool.isRequired,
		dropdown: PropTypes.array,
	}

	render () {
		const {
			intl, onSave, onNextCard,
			disabled, loading, values,
			isNextButton, dropdown,
		} = this.props;
		const t = intl.formatMessage;
		return (
			<Modal className="DashboardSection__RegulatoryDataModal">
				<Section>
					<Container medium>
						<Wrap>
							<Title title={t(M.title)} legend={t(M.info)} small />
							<CardSelect
								className="CardSelect--Long CardSelect--Modal"
								showCardDataFulfillmentFlag
								dropdown={dropdown}
							/>
							<RegulatoryDataCollectionForm
								disabled={disabled || loading}
								values={values}
								onSubmit={onSave}
								button={(
									<div className="DashboardSection__RegulatoryDataModal-Actions">
										{isNextButton && (
											<Button small secondary onClick={onNextCard}>
												{t(M.buttons.next)}
											</Button>
										)}
										<Button form small loading={loading} disabled={disabled}>
											{t(M.buttons.save)}
										</Button>
									</div>
								)}
							/>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}

export default RegulatoryDataModal;
