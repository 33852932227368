import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class HeaderItem extends React.PureComponent {
	static propTypes = {
		idx: PropTypes.number.isRequired,
		item: PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]).isRequired,
			title: PropTypes.string.isRequired,
		}).isRequired,
		onSelect: PropTypes.func.isRequired,
		active: PropTypes.bool.isRequired,
	}

	onSelect = () => {
		const { onSelect, idx, item } = this.props;
		onSelect(item, idx);
	}

	render () {
		const { item: { title }, active } = this.props;
		return (
			<div
				className={classnames('TabHeader__Button', active && 'TabHeader__Button--Active')}
				onClick={this.onSelect}
				role="button"
				tabIndex="0"
			>
				{title}
			</div>
		);
	}
}

export default HeaderItem;
