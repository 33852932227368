import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import * as paymentSelector from 'selectors/payment';
import * as cardActions from 'actions/card';
import { paymentStatus } from 'constants/common';
import Completed from '../Completed';
import Declined from '../Declined';

import paymentTypes from 'types/payment';
import { cardActionsTypes } from 'types/actions';

function Summary (props) {
	useEffect(() => {
		props.actions.card.fetch();
	});
	const { payment } = props;

	if (!payment) return null;

	return (
		<div>
			{payment.status === paymentStatus.COMPLETED && <Completed />}
			{payment.status === paymentStatus.DECLINED && <Declined />}
		</div>
	);
}

Summary.propTypes = {
	payment: paymentTypes,
	actions: PropTypes.shape({
		card: cardActionsTypes.isRequired,
	})
};

const mapState = (state, props) => {
	const { match: { params: { id } } } = props;
	return {
		payment: paymentSelector.get(state, id),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

export default compose(
	withRouter,
	connect(mapState, mapDispatch)
)(Summary);
