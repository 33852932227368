import Account from './Account';
import Interface from './Interface';
import Phone from './Phone';
import Password from './Password';
import Passwordchange from './Passwordchange';

export default {
	Interface,
	Account,
	Phone,
	Password,
	Passwordchange
};
