export default {
	email: {
		presence: { message: '^schemas.presence' },
		email: { message: '^schemas.email' },
	},
	password: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 8,
			message: '^schemas.length.min',
		},
	},
};
