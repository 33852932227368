import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Button.scss';

export default function Button (props) {
	const {
		className,
		form,
		disabled,
		ghost,
		secondary,
		small,
		plain,
		loading,
		children,
		type,
		url,
		onRef,
		ariaLabel,
		large,
		medium,
		...passedProps
	} = props;

	const classNames = classnames(
		'Button', className,
		form && 'Button--Form',
		(disabled || loading) && 'Button--Disabled',
		ghost && 'Button--Ghost',
		secondary && 'Button--Secondary',
		small && 'Button--Small',
		plain && 'Button--Plain',
		loading && 'Button--Loading'
	);

	const commonProps = {
		'aria-label': ariaLabel,
		ref: onRef,
		className: classNames,
	};
	const commonButtonProps = {
		disabled: disabled || loading,
		'aria-busy': loading,
		role: 'button',
	};

	const buttonType = form ? 'submit' : 'button';
	const content = (
		<React.Fragment>
			<span className="Button__Content">
				{children}
			</span>
			{loading && <span className="Button__Loader" />}
		</React.Fragment>
	);

	if (type === 'a') {
		return (
			<a href={url} {...passedProps} {...commonProps}>
				{content}
			</a>
		);
	}

	if (type === 'div') {
		return (
			<div tabIndex="-1" {...passedProps} {...commonProps} {...commonButtonProps}>
				{content}
			</div>
		);
	}

	return (
		<button type={buttonType} {...passedProps} {...commonProps} {...commonButtonProps}>
			{content}
		</button>
	);
}

Button.propTypes = {
	children: PropTypes.any,
	type: PropTypes.string,
	ariaLabel: PropTypes.string,
	form: PropTypes.bool,
	small: PropTypes.bool,
	plain: PropTypes.bool,
	ghost: PropTypes.bool,
	secondary: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	url: PropTypes.string,
	onClick: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onMouseUp: PropTypes.func,
	className: PropTypes.string,
	onRef: PropTypes.func,
	large: PropTypes.bool,
	medium: PropTypes.bool,
};
