import fetcher from 'helpers/fetcher';
import CONFIG from '../../../../../env';

export default {
	get () {
		return fetcher.get('user/private/resend-token');
	},
	validate (email) {
		if (!email || email.length === 0) {
			throw new Error('Provided email is not a valid string');
		}

		return fetcher.get(`api/json/email/ec96KkWOqPRqVvLgcOTTyXZOnBUOwBjq/${email}`, {
			baseURL: CONFIG.scoringApi,

		});
	}
};
