import { createSelector } from 'reselect';



export const list = state => state.member.items;

// filters
export const access = state => state.member.access;
export const accessNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

export const search = state => state.member.search;
export const status = state => state.member.status;
export const statusNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

const filterAccess = items => item => items.indexOf(item.role) > -1;

const filterStatus = items => item => items.indexOf(item.status) > -1;


const filterSearch = raw => {
	const keyword = raw.toLowerCase();

	return item => {
		// check in name/surname
		if (item.name.toLowerCase().includes(keyword) ||
			item.surname.toLowerCase().includes(keyword)) {
			return true;
		}

		// check in position
		if (item.position && item.position.toLowerCase().includes(keyword)) return true;

		return false;
	};
};

export const fetch = createSelector(
	list,
	access,
	status,
	search,
	(list, access, status, search) => {
		let filtered = access.length > 0
			? list.filter(filterAccess(access))
			: [];

		filtered = status.length > 0
			? list.filter(filterStatus(status))
			: [];

		return search
			? filtered.filter(filterSearch(search))
			: filtered;
	},
);

export const get = (state, id) => {
	if (id) {
		// we get a string from the route, but have a number in the redux state
		const numberId = Number(id);

		if (isNaN(numberId)) return undefined;

		return state.member.items.find(item => item.userId === numberId);
	}

	return undefined; // I'm assuming that there will be no cards with id 0
};
