import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputError from '../InputError';
import './Error.scss';

function InputRadioError (props) {
	const { className, placeholder, errors, name } = props;
	return (
		<InputError
			className={classnames('InputRadioError', className)}
			placeholder={placeholder}
			errors={errors}
			name={name}
		/>
	);
}

InputRadioError.propTypes = {
	placeholder: PropTypes.string,
	name: PropTypes.string,
	errors: PropTypes.array,
	className: PropTypes.string,
};

export default injectIntl(InputRadioError);
