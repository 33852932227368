import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './InputError.scss';

import intlTypes from 'types/intl';

function InputError (props) {
	const { className, errors, intl, label, placeholder } = props;
	if (!errors || !errors.length) return null;

	const t = intl.formatMessage;
	return (
		<div className={classnames('InputError', className)}>
			<div>
				{t({
					id: errors[0].id }, {
					name: label || placeholder,
					...errors[0].variables,
					defaultMessage: errors[0].defaultMessage,
				})}
			</div>
		</div>
	);
}

InputError.propTypes = {
	placeholder: PropTypes.string,
	errors: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		variables: PropTypes.object,
	})),
	className: PropTypes.string,
	intl: intlTypes.isRequired,
	label: PropTypes.string,
};

export default injectIntl(InputError);
