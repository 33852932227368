export default {
	current: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 6,
			maximum: 20,
			message: '^schemas.length.between',
		}
	},
	new: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 6,
			maximum: 20,
			message: '^schemas.length.between',
		}
	},
	confirm: {
		presence: { message: '^schemas.presence' },
		equality: {
			attribute: 'new',
			message: '^schemas.password.match',
			comparator: (v1, v2) => v1 === v2
		},
	},
};
