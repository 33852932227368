import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Banner from 'components/Banner';
import Input from 'components/Input';
import InputError from 'components/InputError';
import InputDate from 'components/InputDate';
import InputSelect from 'components/InputSelect';
import InputCheckbox from 'components/InputCheckbox';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import PasswordPolicy from 'components/PasswordPolicy';
import * as authActions from 'actions/auth';
import * as countryActions from 'actions/country';
import * as countrySelector from 'selectors/country';
import helpers from 'helpers';
import reduxer from 'helpers/reduxer';
import Auth from 'schemas/auth';
import { triggerEvent } from 'helpers/analytics';
import { UTMParams } from 'constants/common';
import { GTMEvent } from 'constants/gtm';
import { isDevelopment } from 'constants/build';
import { getKeys } from 'helpers/recaptcha';
import tracker from 'helpers/tracker';
import formFocusErrors from '../../../../helpers/form-focus-errors';
import formatPhoneNumberToBEFormat from '../../../../helpers/formatPhoneNumberToBEFormat';
import intlTypes from 'types/intl';
import { validatePhone } from '../../../../helpers/validator';

import M from './Private.locale.json';
import './Private.scss';

const SIGN_UP_FORM_ID = 'SIGN_UP_FORM_ID';

const mapState = (state, props) => ({
	language: state.language,
	user: state.user,
	country: countrySelector.getEnrollCountries(state, props, M.countries),
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props, M.countries),
});

const mapDispatch = dispatch => ({
	actions: {
		auth: reduxer.bindNestedActionCreators(authActions, dispatch),
		country: bindActionCreators(countryActions, dispatch)
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Private extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		language: PropTypes.shape({
			locale: PropTypes.string.isRequired,
		}).isRequired,
		user: PropTypes.object,
		country: PropTypes.array.isRequired,
		phoneCountry: PropTypes.array.isRequired,
		actions: PropTypes.shape({
			auth: PropTypes.object.isRequired,
			country: PropTypes.object.isRequired,
		}).isRequired,
	}

	state = {
		loader: false,
		form: {
			name: '',
			surname: '',
			dateOfBirth: '',
			email: '',
			phone: '',
			phoneCountry: '',
			address: '',
			address2: '',
			country: '',
			city: '',
			postalCode: '',
			password: '',
			passwordConfirm: '',
			agree: false,
			agreeMonitored: false,
			'g-recaptcha-response': null
		},
		messages: [],
		errors: {
			name: [],
			surname: [],
			dateOfBirth: [],
			email: [],
			country: [],
			address: [],
			address2: [],
			city: [],
			postalCode: [],
			phoneCountry: [],
			phone: [],
			password: [],
			passwordConfirm: [],
			agree: [],
			agreeMonitored: [],
		},
		autocomplete: [],
	}

	componentDidMount () {
		const { actions } = this.props;
		actions.country.fetch({ entroll: true });
		const urlParams = new URLSearchParams(location.search);
		const params = {
			utm_source: urlParams.get('utm_source'),
			utm_medium: urlParams.get('utm_medium'),
			utm_campaign: urlParams.get('utm_campaign'),
		};
		localStorage.setItem(UTMParams, JSON.stringify(params));
		triggerEvent(GTMEvent.signup, '/auth/signup/private');

		tracker.init();
		document.addEventListener('mousemove', tracker.onMouseMove);
	}

	componentWillUnmount () {
		document.removeEventListener('mousemove', tracker.onMouseMove);
	}

	onChange = (value, name) => {
		const t = this.props.intl.formatMessage;
		const { form, errors } = this.state;
		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.signup.private);
		}

		if (name === 'phone') {
			const validation = validatePhone(value);

			if (validation) {
				messages.unshift(validation);
			}
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onChangeName = (value, name) => {
		const { form, errors } = this.state;

		this.setState({ form: { ...form, [name]: value } }, () => {
			const nameValue = this.nameRef.current.props.value;
			const surnameValue = this.surnameRef.current.props.value;
			const messages = helpers.validator.single({
				...form,
				name: nameValue,
			}, 'name', Auth.signup.private);
			const messagesLname = helpers.validator.single({
				...form,
				surname: surnameValue,
			}, 'surname', Auth.signup.private);
			this.setState({ errors: { ...errors, name: messages, surname: messagesLname } });
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions, language } = this.props;
		const { form, loader } = this.state;

		if (loader) return;

		const errors = helpers.validator.all(form, Auth.signup.private);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			this.focusAfterError({ ...this.state.errors, ...errors });

			tracker.onError();
			return;
		}

		if (!isDevelopment && !form['g-recaptcha-response']) {
			const recaptcha = document.querySelector('#recaptcha-input');

			if (recaptcha) {
				window.scrollTo(0, recaptcha.offsetTop);
				return;
			}
		}

		this.setState({ messages: [], loader: true });

		form.language = language.locale.toUpperCase();

		this.fixateTrackResult();

		form.phone = formatPhoneNumberToBEFormat(form.phone);

		const response = await actions.auth.signup.private(form);

		if (response && response.errors) {
			const update = {
				errors: { ...this.state.errors, ...response.errors },
				loader: false,
			};
			this.focusAfterError({ ...response.errors });

			if (response.messages) update.messages = response.messages;

			this.setState(update);
		}
	};

	onChangeReCaptcha = value => this.setState(prevState => ({
		form: { ...prevState.form, 'g-recaptcha-response': value }
	}))

	nameRef = React.createRef();
	surnameRef = React.createRef();
	recaptchaKeys = getKeys();

	fixateTrackResult = () => {
		const result = tracker.analyse();
		if (result.count > 0) {
			const params = {
				utm_source: 'PROBABLY_UNSAFE',
				utm_medium: 'PROBABLY_UNSAFE',
				utm_campaign: result.mark,
			};
			localStorage.setItem(UTMParams, JSON.stringify(params));
		}
	};

	focusAfterError = errors => {
		const errorKeys = Object.keys(errors);

		formFocusErrors(SIGN_UP_FORM_ID, errors, errorKeys, name => {
			if (name === 'country' || name === 'phoneCountry') {
				return `${name}Filter`;
			}

			return name;
		});
	};

	render () {
		const { user, country, phoneCountry, intl, language: { locale } } = this.props;
		const { form, loader, errors, messages } = this.state;

		if (user) return <Redirect to={{ pathname: '/clients/create/start' }} />;

		const dateOfBirth = new Date();
		dateOfBirth.setFullYear(dateOfBirth.getFullYear() - 18);
		const year = dateOfBirth.getFullYear();

		const t = intl.formatMessage;

		return (
			<Layout className="AuthSignupScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Title title={t(M.title)} legend={t(M.legend)} />
						{messages && !!messages.length && <Banner type="error" data={messages} />}
						<Form id={SIGN_UP_FORM_ID} onSubmit={this.onSubmit} onKeyDown={tracker.onKeyboardEvent}>
							<Form.Group>
								<Input
									id="name-input"
									name="name"
									ref={this.nameRef}
									placeholder={t(M.inputs.name)}
									onChange={this.onChangeName}
									value={form.name}
									errors={errors.name}
									hotjar
								/>
								<Input
									id="surname-input"
									name="surname"
									ref={this.surnameRef}
									placeholder={t(M.inputs.surname)}
									onChange={this.onChangeName}
									value={form.surname}
									errors={errors.surname}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputDate
									name="dateOfBirth"
									label={t(M.inputs.dateOfBirth)}
									placeholder={t(M.inputs.date)}
									onChange={this.onChange}
									value={form.dateOfBirth}
									errors={errors.dateOfBirth}
									disabledDate={dateOfBirth}
									year={year}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="email"
									placeholder={t(M.inputs.email)}
									onChange={this.onChange}
									value={form.email}
									errors={errors.email}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="country"
									onChange={this.onChange}
									placeholder={t(M.inputs.country)}
									value={form.country}
									errors={errors.country}
									data={country}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="address"
									placeholder={t(M.inputs.address)}
									onChange={this.onChange}
									value={form.address}
									errors={errors.address}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="address2"
									placeholder={t(M.inputs.address2)}
									onChange={this.onChange}
									value={form.address2}
									errors={errors.address2}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="city"
									placeholder={t(M.inputs.city)}
									onChange={this.onChange}
									value={form.city}
									errors={errors.city}
									hotjar
								/>
								<Input
									name="postalCode"
									placeholder={t(M.inputs.postalCode)}
									onChange={this.onChange}
									value={form.postalCode}
									errors={errors.postalCode}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="phoneCountry"
									onChange={this.onChange}
									placeholder={t(M.inputs.phoneCountry)}
									value={form.phoneCountry}
									errors={errors.phoneCountry}
									data={phoneCountry}
									hotjar
								/>
								<Input
									name="phone"
									type="number"
									placeholder={t(M.inputs.phone)}
									onChange={this.onChange}
									value={form.phone}
									errors={errors.phone}
									hotjar
								/>
							</Form.Group>
							<Form.Group row>
								<Input
									name="password"
									type="password"
									placeholder={t(M.inputs.password)}
									onChange={this.onChange}
									value={form.password}
									errors={errors.password}
									hotjar
								/>

								<PasswordPolicy value={form.password} />
							</Form.Group>
							<Form.Group>
								<Input
									name="passwordConfirm"
									type="password"
									placeholder={t(M.inputs.passwordConfirm)}
									onChange={this.onChange}
									value={form.passwordConfirm}
									errors={errors.passwordConfirm}
									hotjar
								/>
							</Form.Group>
							<Form.Group row>
								<InputCheckbox
									name="agree"
									type="agree"
									onChange={this.onChange}
									value="on"
									checked={form.agree}
									errors={errors.agree}
								>
									<FormattedMessage
										id={M.inputs.agree.id}
										values={{
											terms: (
												<ButtonLink
													to={{
														pathname: '/terms-and-services',
														state: { modal: true }
													}}
												>
													{t(M.buttons.terms)}
												</ButtonLink>
											),
											privacy: (
												<ButtonLink
													to={{
														pathname: '/privacy-policy',
														state: { modal: true }
													}}
												>
													{t(M.buttons.privacy)}
												</ButtonLink>
											)
										}}
									/>
								</InputCheckbox>
								<InputCheckbox
									name="agreeMonitored"
									type="agree"
									onChange={this.onChange}
									value="on"
									checked={form.agreeMonitored}
									errors={errors.agreeMonitored}
								>
									<FormattedMessage
										id={M.inputs.agreeMonitored.id}
										values={{
											terms: (
												<ButtonLink
													to={{
														pathname: '/terms-and-services',
														state: { modal: true }
													}}
												/>
											)
										}}
									/>
								</InputCheckbox>
							</Form.Group>
							<div id="recaptcha-input" className="AuthSignupScreen__ReCaptcha" key={locale}>
								<ReCAPTCHA
									hl={locale}
									sitekey={this.recaptchaKeys.sitekey}
									onChange={this.onChangeReCaptcha}
								/>
							</div>
							<InputError label="reCAPTCHA" errors={errors['g-recaptcha-response']} />
							<Button form loading={loader}>{t(M.buttons.signup)}</Button>
						</Form>
						<Form.Note>
							{`${t(M.messages.login)} `} <ButtonLink to="/auth/login">{t(M.buttons.login)}</ButtonLink>
						</Form.Note>
					</Container>
				</Section>
			</Layout>
		);
	}
}
