import {
	SHAREHOLDER_FETCH_SUCCESS,
	SHAREHOLDER_CREATE_SUCCESS,
	SHAREHOLDER_EDIT_SUCCESS,
	SHAREHOLDER_REMOVE_REQUEST,
	SHAREHOLDER_REMOVE_SUCCESS,
} from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import renamer from 'helpers/renamer';
import * as clientSelector from 'selectors/client';
import M from './shareholder.locale.json';

export const fetch = () => async (dispatch, getState) => {
	try {
		const clientId = clientSelector.getEntityClientId(getState());
		const { data: { response } } = await API.shareholder.fetch(clientId);
		dispatch({ type: SHAREHOLDER_FETCH_SUCCESS, payload: response });
		return response;
	} catch (err) { return err; }
};

export const get = id => async (dispatch, getState) => {
	try {
		const clientId = clientSelector.getEntityClientId(getState());
		const { data: { response } } = await API.shareholder.get(id, clientId);
		return renamer.from.shareholder.get(response);
	} catch (err) { return err; }
};

export const create = form => async (dispatch, getState) => {
	try {
		const clientId = clientSelector.getEntityClientId(getState());
		const { data: { response } } = await API.shareholder.create(form, clientId);
		dispatch({ type: SHAREHOLDER_CREATE_SUCCESS, payload: response });
		success(dispatch)({ title: M.success.create.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.create.title });
		return err;
	}
};

export const edit = (form, id) => async (dispatch, getState) => {
	try {
		const clientId = clientSelector.getEntityClientId(getState());
		const { data: { response } } = await API.shareholder.edit(form, id, clientId);
		dispatch({ type: SHAREHOLDER_EDIT_SUCCESS, payload: response });
		success(dispatch)({ title: M.success.edit.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.edit.title });
		return err;
	}
};

export const remove = (id, total) => async (dispatch, getState) => {
	try {
		const clientId = clientSelector.getEntityClientId(getState());
		dispatch({ type: SHAREHOLDER_REMOVE_REQUEST, payload: { id } });
		const { data: { response } } = await API.shareholder.remove(id, clientId);
		dispatch({ type: SHAREHOLDER_REMOVE_SUCCESS, payload: { ...response, id, total } });
		success(dispatch)({ title: M.success.remove.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.remove.title });
		return err;
	}
};
