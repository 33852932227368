import { success, error } from 'actions/toast';
import API from 'services/api';
import M from './email.locale.json';

export const get = () => async dispatch => {
	try {
		const result = await API.email.get();
		success(dispatch)({ title: M.success.get.title });
		return result.data.response;
	} catch (err) {
		error(dispatch)({ title: M.error.get.title });
		return err;
	}
};
