import storage from 'services/storage';
import CONFIG from '../../../../../env';

const get = (business, data) => {
	if (!data) return data;

	const convert = {
		CertificateOfRegistration: [],
		ArticleOfAssociation: [],
		AnnualReturn: [],
		ConfirmationOfShareholdingDetails: [],
		ConfirmationOfCompanyDirectors: [],
		BankAccountEvidence: [],
	};

	const token = storage.get('session').split(' ')[1];

	for (let i = 0; i < data.length; i++) {
		const item = {
			id: data[i].id,
			name: data[i].name,
			type: data[i].fileType,
		};

		item.preview = `${CONFIG.api}open/business/${business}/application/required-documents/${data[i].id}?token=${token}`;

		convert[data[i].type].push(item);
	}

	convert.certificateOfRegistration = convert.CertificateOfRegistration;
	delete convert.CertificateOfRegistration;

	convert.memorandumOfAssociation = convert.ArticleOfAssociation;
	delete convert.ArticleOfAssociation;

	convert.annualReturn = convert.AnnualReturn;
	delete convert.AnnualReturn;

	convert.shareholderDetails = convert.ConfirmationOfShareholdingDetails;
	delete convert.ConfirmationOfShareholdingDetails;

	convert.companyDirectors = convert.ConfirmationOfCompanyDirectors;
	delete convert.ConfirmationOfCompanyDirectors;

	convert.evidenceOfBankAccount = convert.BankAccountEvidence;
	delete convert.BankAccountEvidence;

	return convert;
};

export default {
	get,
};
