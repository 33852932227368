export default {
	password: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 8,
			maximum: 20,
			message: '^schemas.length.between',
		}
	},
	passwordConfirm: {
		presence: { message: '^schemas.presence' },
		equality: {
			attribute: 'password',
			message: '^schemas.password.match',
			comparator: (v1, v2) => v1 === v2
		},
	}
};
