import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class DropdownItem extends React.PureComponent {
	static propTypes = {
		idx: PropTypes.number.isRequired,
		focused: PropTypes.bool.isRequired,
		selected: PropTypes.bool.isRequired,
		item: PropTypes.shape({
			title: PropTypes.string,
			subtitle: PropTypes.string,
			check: PropTypes.bool,
		}).isRequired,
		onClick: PropTypes.func.isRequired,
		onMouseDown: PropTypes.func,
		onRef: PropTypes.func.isRequired,
		onMouseOverItem: PropTypes.func.isRequired,
	}

	onClick = () => {
		const { item, onClick } = this.props;
		onClick(item);
	}

	onRef = ref => {
		const { focused, selected, onRef } = this.props;
		onRef(ref, focused, selected);
	}

	onMouseOverItem = () => {
		const { idx, item, onMouseOverItem } = this.props;
		onMouseOverItem(item, idx);
	}

	render () {
		const { focused, selected, item, onMouseDown } = this.props;
		return (
			<li
				ref={this.onRef}
				className={classnames(
					'Dropdown__Item',
					focused && 'Dropdown__Item--Focus',
					selected && 'Dropdown__Item--Selected',
					item.check && 'Dropdown__Item--Checked'
				)}
				onClick={this.onClick}
				role="menuitem"
				onMouseDown={onMouseDown}
				onMouseOver={this.onMouseOverItem}
			>
				<div className="Dropdown__Title">
					{item.check && <div className="Dropdown__Checkbox" />}
					{item.title}
				</div>
				{item.subtitle && <div className="Dropdown__Subtitle">{item.subtitle}</div>}
			</li>
		);
	}
}

export default DropdownItem;
