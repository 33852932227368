import { combineReducers } from 'redux';

import business from './business';
import current from './current';
import list from './list';

export default combineReducers({
	current,
	list,
	business,
});
