// PLACE
export const PLACE_AUTOCOMPLETE_SUCCESS = 'PLACE_AUTOCOMPLETE_SUCCESS';
export const PLACE_GEOCODE_SUCCESS = 'PLACE_GEOCODE_SUCCESS';


// AUTH
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_EMAIL_SUCCESS = 'AUTH_EMAIL_SUCCESS';


// PASSWORD
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS';


// PHONE
export const PHONE_GET_SUCCESS = 'PHONE_GET_SUCCESS';
export const PHONE_CREATE_SUCCESS = 'PHONE_CREATE_SUCCESS';


// DASHBOARD
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS';
export const DASHBOARD_GET_SUCCESS = 'DASHBOARD_GET_SUCCESS';
export const DASHBOARD_SET_SORT = 'DASHBOARD_SET_SORT';


// TRANSACTIONS
export const TRANSACTION_FETCH_REQUEST = 'TRANSACTION_FETCH_REQUEST';
export const TRANSACTION_FETCH_SUCCESS = 'TRANSACTION_FETCH_SUCCESS';
export const TRANSACTION_GET_SUCCESS = 'TRANSACTION_GET_SUCCESS';
export const TRANSACTION_SET_SEARCH = 'TRANSACTION_SET_SEARCH';
export const TRANSACTION_SET_TYPES = 'TRANSACTION_SET_TYPES';
export const TRANSACTION_SET_COLUMNS = 'TRANSACTION_SET_COLUMNS';
export const TRANSACTION_SET_STATUS = 'TRANSACTION_SET_STATUS';
export const TRANSACTION_SET_SORT = 'TRANSACTION_SET_SORT';


// PAYMENTS
export const PAYMENT_FETCH_SUCCESS = 'PAYMENT_FETCH_SUCCESS';
export const PAYMENT_GET_SUCCESS = 'PAYMENT_GET_SUCCESS';
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS';
export const PAYMENT_AMOUNT_SUCCESS = 'PAYMENT_AMOUNT_SUCCESS';
export const PAYMENT_AMOUNT_ERROR = 'PAYMENT_AMOUNT_ERROR';
export const PAYMENT_PHONE_SUCCESS = 'PAYMENT_PHONE_SUCCESS';
export const PAYMENT_PHONE_ERROR = 'PAYMENT_PHONE_ERROR';
export const PAYMENT_SET_SEARCH = 'PAYMENT_SET_SEARCH';
export const PAYMENT_SET_COLUMNS = 'PAYMENT_SET_COLUMNS';
export const PAYMENT_SET_STATUS = 'PAYMENT_SET_STATUS';
export const PAYMENT_SET_SORT = 'PAYMENT_SET_SORT';


// MEMBER
export const MEMBER_FETCH_SUCCESS = 'MEMBER_FETCH_SUCCESS';
export const MEMBER_GET_SUCCESS = 'MEMBER_GET_SUCCESS';
export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS';
export const MEMBER_EDIT_SUCCESS = 'MEMBER_EDIT_SUCCESS';
export const MEMBER_SET_ACCESS = 'MEMBER_SET_ACCESS';
export const MEMBER_SET_SEARCH = 'MEMBER_SET_SEARCH';
export const MEMBER_SET_STATUS = 'MEMBER_SET_STATUS';


// CARD
export const CARD_FETCH_REQUEST = 'CARD_FETCH_REQUEST';
export const CARD_FETCH_SUCCESS = 'CARD_FETCH_SUCCESS';
export const CARD_GET_SUCCESS = 'CARD_GET_SUCCESS';
export const CARD_CREATE_SUCCESS = 'CARD_CREATE_SUCCESS';
export const CARD_SET_STATUS = 'CARD_SET_STATUS';
export const CARD_SET_SEARCH = 'CARD_SET_SEARCH';
export const CARD_CURRENT = 'CARD_CURRENT';
export const CARD_SET_REGULATORY_DATA_COLLECTION = 'CARD_SET_REGULATORY_DATA_COLLECTION';
export const CARD_REMOVE_REGULATORY_DATA_COLLECTION = 'CARD_REMOVE_REGULATORY_DATA_COLLECTION';
export const CARD_SET_NEW_DATA_REQUEST = 'CARD_SET_NEW_DATA_REQUEST';
export const CARD_SET_NEW_DATA_SUCCESS = 'CARD_SET_NEW_DATA_SUCCESS';
export const CARD_SET_NEW_DATA_ERROR = 'CARD_SET_NEW_DATA_ERROR';
export const CARD_GET_CVV_REQUEST = 'CARD_GET_CVV_REQUEST';
export const CARD_GET_CVV_SUCCESS = 'CARD_GET_CVV_SUCCESS';
export const CARD_GET_PIN_REQUEST = 'CARD_GET_PIN_REQUEST';
export const CARD_GET_PIN_SUCCESS = 'CARD_GET_PIN_SUCCESS';
export const CARD_UPDATE_TO_PLASTIC_REQUEST = 'CARD_UPDATE_TO_PLASTIC_REQUEST';
export const CARD_UPDATE_TO_PLASTIC_SUCCESS = 'CARD_UPDATE_TO_PLASTIC_SUCCESS';


// CLIENT BUSINESS
export const CLIENT_FETCH_BUSINESS_SUCCESS = 'CLIENT_FETCH_BUSINESS_SUCCESS';
export const CLIENT_CREATE_BUSINESS_SUCCESS = 'CLIENT_CREATE_BUSINESS_SUCCESS';
export const CLIENT_BUSINESS_NAV_SUCCESS = 'CLIENT_BUSINESS_NAV_SUCCESS';
export const CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS = 'CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS';
export const CLIENT_EDIT_BUSINESS_SUCCESS = 'CLIENT_EDIT_BUSINESS_SUCCESS';
export const CLIENT_GET_BUSINESS_SUCCESS = 'CLIENT_GET_BUSINESS_SUCCESS';
export const CLIENT_BUSINESS_FORM = 'CLIENT_BUSINESS_FORM';


// CLIENT PRIVATE
export const CLIENT_CREATE_PRIVATE_SUCCESS = 'CLIENT_CREATE_PRIVATE_SUCCESS';
export const CLIENT_CREATE_PRIVATE_ERROR = 'CLIENT_CREATE_PRIVATE_ERROR';
export const CLIENT_GET_ADDRESS_SUCCESS = 'CLIENT_GET_ADDRESS_SUCCESS';


// CLIENT
export const CLIENT_CURRENT = 'CLIENT_CURRENT';


// SHAREHOLDER
export const SHAREHOLDER_FETCH_SUCCESS = 'SHAREHOLDER_FETCH_SUCCESS';
export const SHAREHOLDER_CREATE_SUCCESS = 'SHAREHOLDER_CREATE_SUCCESS';
export const SHAREHOLDER_EDIT_SUCCESS = 'SHAREHOLDER_EDIT_SUCCESS';
export const SHAREHOLDER_REMOVE_REQUEST = 'SHAREHOLDER_REMOVE_REQUEST';
export const SHAREHOLDER_REMOVE_SUCCESS = 'SHAREHOLDER_REMOVE_SUCCESS';


// DOCUMENT
export const DOCUMENT_FETCH_SUCCESS = 'DOCUMENT_FETCH_SUCCESS';
export const DOCUMENT_CREATE_SUCCESS = 'DOCUMENT_CREATE_SUCCESS';
export const DOCUMENT_REMOVE_REQUEST = 'DOCUMENT_REMOVE_REQUEST';
export const DOCUMENT_REMOVE_SUCCESS = 'DOCUMENT_REMOVE_SUCCESS';


// AVATAR
export const AVATAR_CREATE_SUCCESS = 'AVATAR_CREATE_SUCCESS';
export const AVATAR_REMOVE_SUCCESS = 'AVATAR_REMOVE_SUCCESS';


// LANGUAGE
export const LANGUAGE_GET_SUCCESS = 'LANGUAGE_GET_SUCCESS';
export const LANGUAGE_EDIT_SUCCESS = 'LANGUAGE_EDIT_SUCCESS';


// COUNTRY
export const COUNTRY_FETCH_SUCCESS = 'COUNTRY_FETCH_SUCCESS';

// CURRENCY
export const CURRENCY_FETCH_SUCCESS = 'CURRENCY_FETCH_SUCCESS';


// TOAST
export const TOAST_CREATE = 'TOAST_CREATE';
export const TOAST_REMOVE = 'TOAST_REMOVE';
export const TOAST_HIDE = 'TOAST_HIDE';


// SESSION
export const SESSION_MODAL = 'SESSION_MODAL';
export const SESSION_EXTEND_SUCCESS = 'SESSION_EXTEND_SUCCESS';


// BANKINFO
export const BANK_GET_SUCCESS = 'BANK_GET_SUCCESS';


// MAINTENANCE MODAL
export const TOGGLE_MAINTENANCE_MODAL = 'TOGGLE_MAINTENANCE_MODAL';

// PFS PAYMENTS
export const PERFORM_PAYMENT = 'PERFORM_PAYMENT';

export * from './build';
