import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Wrap.scss';

export default function Wrap (props) {
	return (
		<div className={cn('Wrap', props.className)}>
			{props.children}
		</div>
	);
}

Wrap.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
};
