import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CardSelect from 'components/CardSelect/CardSelect';
import Logo from 'components/Logo';
import User from 'components/User';
import Client from 'components/Client';
import Language from 'components/Language';
import Icon from 'components/Icon';
import NotificationsPopup from '../../components/NotificationsPopup';
import * as authActions from 'actions/auth';
import { getBalance, getDropdownValues } from 'selectors/card';
import M from './Nav.locale.json';
import './Nav.scss';

import intlTypes from 'types/intl';
import userTypes from 'types/user';
import { authActionsTypes } from 'types/actions';

const mapState = state => ({
	user: state.user,
	balance: getBalance(state),
	dropdown: getDropdownValues(state),
});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Nav extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.shape({
			auth: authActionsTypes.isRequired,
		}).isRequired,
		intl: intlTypes.isRequired,
		logo: PropTypes.bool,
		user: userTypes,
		balance: PropTypes.number,
		dropdown: PropTypes.array,
	};

	onLogout = () => this.props.actions.auth.logout();

	render () {
		const { user, logo, balance, intl, dropdown } = this.props;
		const t = intl.formatMessage;

		return (
			<nav className="Nav">
				{!logo && <Client className="Nav__Client" />}
				{logo && <Logo className="Nav__Logo" />}
				{user && !isNaN(String(balance)) && <CardSelect className="Nav__CardSelect" dropdown={dropdown} />}
				{(user && user.verified && user.phoneVerified) && (
					<div className="nav-user-wrap">
						<NotificationsPopup />
						<User className="Nav__User" user={user} />
					</div>
				)}
				{(user && !(user.verified && user.phoneVerified)) && (
					<NavLink className="Logout" activeClassName="" onClick={this.onLogout} to="" exact>
						<Icon name="logout" />
						<span>{t(M.nav.logout)}</span>
					</NavLink>
				)}
				{!user && <Language className="Nav__Language" />}
			</nav>
		);
	}
}
