import React from 'react';
import PropTypes from 'prop-types';
import './Wrap.scss';

export default function Wrap (props) {
	return (
		<div className="TooltipWrap">
			{props.children}
		</div>
	);
}

Wrap.propTypes = {
	children: PropTypes.any,
};
