import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from '../Image';
import Loader from '../Loader';
import './Avatar.scss';

export default function Avatar (props) {
	const { user, tip, className, small, large, loading } = props;
	const { role, avatar, name, surname } = user;
	const classNames = classnames(
		'Avatar', className,
		small && 'Avatar--Small',
		large && 'Avatar--Large'
	);

	if (loading) {
		return (
			<div className={classNames}>
				<div className="Avatar__Placeholder">
					<Loader className="Avatar__Loader" />
				</div>
			</div>
		);
	}

	return (
		<div className={classNames}>
			{avatar && (
				<Image
					className="Avatar__Image"
					src={avatar}
					alt={`${name} ${surname}`}
				/>
			)}

			{tip && (
				<div className={classnames('Avatar__Access',
					role && role[0] === 'B' && 'Avatar__Access--Viewer')}
				>
					{tip.short}
				</div>
			)}

			{!avatar && (
				<div className="Avatar__Placeholder">
					{name && <span>{name[0] + surname[0]}</span>}
				</div>
			)}
		</div>
	);
}

Avatar.propTypes = {
	user: PropTypes.shape({
		image: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
	}).isRequired,
	tip: PropTypes.shape({
		short: PropTypes.string,
		title: PropTypes.string,
	}),
	loading: PropTypes.bool,
	small: PropTypes.bool,
	large: PropTypes.bool,
	className: PropTypes.string,
};
