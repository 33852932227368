import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as memberActions from 'actions/member';

import * as clientSelector from 'selectors/client';
import * as memberSelector from 'selectors/member';

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Member from 'components/Member';
import Button from 'components/Button';
import InputSearch from 'components/InputSearch';
import InputFilter from 'components/InputFilter';
import Wrap from 'components/Wrap';

import M from './Member.locale.json';

import './Member.scss';



const ACCESS = [{
	value: 'OWNER',
	title: M.access.administrator,
}, {
	value: 'BOOKKEEPER',
	title: M.access.viewer,
}];

const STATUS = [{
	value: 'ACTIVE',
	title: M.status.active,
}, {
	value: 'REQUESTED',
	title: M.status.requested,
}];

const mapState = (state, props) => ({
	members: memberSelector.fetch(state),

	access: memberSelector.access(state),
	accessNav: memberSelector.accessNav(props, ACCESS),

	status: memberSelector.status(state),
	statusNav: memberSelector.statusNav(props, STATUS),

	search: memberSelector.search(state),
	client: {
		id: clientSelector.getEntityClientId(state),
	},
	user: {
		id: state.user.id,
	},
});

const mapDispatch = dispatch => ({
	actions: {
		member: bindActionCreators(memberActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	componentDidMount () {
		this.onFetch();
	}

	componentDidUpdate (prevProps) {
		const { client } = this.props;

		if (client.id === prevProps.client.id) return;

		this.onFetch();
	}

	onFetch = () => {
		const { actions } = this.props;

		actions.member.fetch();
	}

	onAccess = options => {
		const { actions } = this.props;

		actions.member.access(options);
	};

	onStatus = options => {
		const { actions } = this.props;

		actions.member.status(options);
	};

	onSearch = keyword => {
		const { actions } = this.props;

		actions.member.search(keyword);
	};

	onGrid = () => {

	};

	onEdit = item => {
		const { history } = this.props;

		history.push(`/members/edit/${item.userId}/${item.roleId}`, { modal: true });
	};

	onCreate = () => {
		const { history } = this.props;

		history.push('/members/create', { modal: true });
	};

	render () {
		const { members, search, access, accessNav, status, statusNav, user } = this.props;

		const t = this.props.intl.formatMessage;

		const content = members.map(item => {
			const actions = [{
				title: t(M.buttons.edit),
				onClick: () => this.onEdit(item),
			}];

			return (
				<Member
					key={item.userId}
					user={item}
					current={user.id}
					actions={actions}
					onClick={() => this.onEdit(item)}
				/>
			);
		});

		return (
			<Section className="MemberSection">
				<Container>
					<Title
						title={t(M.title)}
					/>

					<div
						className="MemberSection__Actions"
					>
						<div>
							<InputFilter
								name="access"
								value={access}
								data={accessNav}
								onChange={this.onAccess}
							>
								{t(M.buttons.access)}
							</InputFilter>

							<InputFilter
								name="status"
								value={status}
								data={statusNav}
								onChange={this.onStatus}
							>
								{t(M.buttons.status)}
							</InputFilter>
						</div>

						<InputSearch
							name="search"
							value={search}
							placeholder={t(M.inputs.filter)}
							onChange={this.onSearch}
						/>

						<Button
							onClick={this.onGrid}
							plain
						>
							{t(M.buttons.grid)}
						</Button>
					</div>

					<Wrap>
						<Member.Create
							onClick={this.onCreate}
						>
							{t(M.buttons.create)}
						</Member.Create>

						{content}
					</Wrap>
				</Container>
			</Section>
		);
	}
}
