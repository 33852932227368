import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as languageActions from 'actions/language';

import SettingSchema from 'schemas/setting';

// import * as userActions from "actions/user";

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Form from 'components/Form';
import InputSelect from 'components/InputSelect';
import NavVertical from 'components/NavVertical';
import Wrap from 'components/Wrap';
import Button from 'components/Button';

import M from './Interface.locale.json';

import './Interface.scss';



const mapState = (state, props) => ({
	language: state.language,
});

const mapDispatch = dispatch => ({
	actions: {
		language: bindActionCreators(languageActions, dispatch),
		// user: bindActionCreators(userActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	constructor (props) {
		super(props);

		this.state = {
			loader: false,
			form: {
				language: props.language,
			},
			errors: {
				language: [],
			}
		};
	}

	nav = [{
		id: 'account',
		title: 'Account',
		icon: 'account',
	}, {
		id: 'interface',
		title: 'Interface',
		icon: 'interface',
	}, {
		id: 'phone',
		title: 'Phone',
		icon: 'phone',
	}, {
		id: 'password',
		title: 'Password',
		icon: 'password',
	}]

	language = [{
		value: 'EN',
		title: 'English',
	}, {
		value: 'RU',
		title: 'Russian',
	}, {
		value: 'LV',
		title: 'Latvian',
	}]

	onNav = idx => {
		const { history } = this.props;

		const path = this.nav[idx].id;

		history.push(`/settings/${path}`);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, SettingSchema.interface);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, history } = this.props;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, SettingSchema.interface);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.language.edit(form);

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			this.setState(update);

			return false;
		}

		this.setState({
			loader: false,
		});
	}

	render () {
		const { loader, form, errors } = this.state;

		const t = this.props.intl.formatMessage;

		return (
			<Section className="SettingPhoneSection">
				<Container>
					<NavVertical
						className="SettingScreen__Nav"
						items={this.nav}
						onClick={this.onNav}
						active={1}
					/>

					<Wrap>
						<Title
							title={t(M.title)}
							legend={t(M.legend)}
							className="SettingScreen__Title"
						/>

						<Form
							onSubmit={this.onSubmit}
						>
							<Form.Group>
								<InputSelect
									name="language"
									data={this.language}
									placeholder={t(M.inputs.language)}
									onChange={this.onChange}
									value={form.language}
									errors={errors.language}
								/>
							</Form.Group>

							<Button
								loading={loader}
								className="SettingScreen__Submit"
								small
								form
							>
								{t(M.buttons.save)}
							</Button>
						</Form>
					</Wrap>
				</Container>
			</Section>
		);
	}
}
