import { combineReducers } from 'redux';
import {
	SESSION_MODAL,
	SESSION_EXTEND_SUCCESS,
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	AUTH_LOGOUT,
	COUNTRY_FETCH_SUCCESS,
} from 'constants';

const TIMEOUT = 5 * 60 * 1000; // 5 minutes

const timeout = (state = TIMEOUT, action) => {
	if (action.type === COUNTRY_FETCH_SUCCESS) return state;
	if (action.type.includes('_SUCCESS')) return new Date().getTime() + TIMEOUT;
	return state;
};

const modal = (state = false, action) => {
	switch (action.type) {
		case SESSION_MODAL:
			return true;
		case SESSION_EXTEND_SUCCESS:
		case AUTH_LOGIN_SUCCESS:
		case AUTH_SIGNUP_SUCCESS:
		case AUTH_LOGOUT:
			return false;
		default:
			return state;
	}
};

export default combineReducers({
	timeout,
	modal,
});
