export default {
	list () {
		return [
			{ value: 'Mr', title: 'Mr' },
			{ value: 'Mrs', title: 'Mrs' },
			{ value: 'Ms', title: 'Ms' },
			{ value: 'Miss', title: 'Miss' },
			{ value: 'Dr', title: 'Dr' },
			{ value: 'Prof', title: 'Prof' },
		];
	}
};
