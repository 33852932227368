import M from './shareholder.locale.json';

export default {
	create: {
		name: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		surname: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		percentageOwned: {
			numericality: {
				greaterThan: 25,
				lessThanOrEqualTo: 100,
				message: `^${M.create.schemas.percentageOwned.id}`,
			},
		},
		email: {
			presence: { message: '^schemas.presence' },
			email: { message: '^schemas.email' },
		},
		phoneCountry: {
			presence: { message: '^schemas.presence' },
		},
		phone: {
			presence: { message: '^schemas.presence' },
			numericality: { message: '^schemas.numerical' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		address: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		country: {
			presence: { message: '^schemas.presence' },
		},
		postalCode: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		role: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		dateOfBirth: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
				message: '^schemas.incorrect',
			},
			dateBefore: { message: '^schemas.date.future' },
		}
	},
	edit: {
		name: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		surname: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		percentageOwned: {
			numericality: {
				greaterThan: 25,
				lessThanOrEqualTo: 100,
				message: `^${M.edit.schemas.percentageOwned.id}`,
			}
		},
		email: {
			presence: { message: '^schemas.presence' },
			email: { message: '^schemas.email' },
		},
		phone: {
			presence: { message: '^schemas.presence' },
			numericality: { message: '^schemas.numerical' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		country: {
			presence: { message: '^schemas.presence' },
		},
		address: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		postalCode: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		role: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		dateOfBirth: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
				message: '^schemas.incorrect',
			},
			dateBefore: { message: '^schemas.date.future' },
		}
	},
};
