import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';
import InputError from '../InputError';
import Dropdown from '../Dropdown';
import M from './InputSelect.locale.json';
import './InputSelect.scss';

import intlTypes from 'types/intl';

@injectIntl
export default class InputSelect extends React.PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		disabled: PropTypes.bool,
		className: PropTypes.string,
		data: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number
				]),
			})
		),
		errors: PropTypes.array,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		intl: intlTypes.isRequired,
		label: PropTypes.string,
		autoFocus: PropTypes.func,
		hotjar: PropTypes.bool,
	};

	state = {
		focused: false,
		holder: false,
		focusedIdx: 0,
		filter: '',
	};

	onKeyDown = e => {
		if (this.props.disabled) {
			return;
		}

		switch (e.keyCode) {
			case 13: // enter
				e.preventDefault();
				e.stopPropagation();
				this.onSelectFocus();
				break;
			case 27: // escape
				e.preventDefault();
				e.stopPropagation();
				this.holder.focus();
				break;
			case 38: // up
				e.preventDefault();
				this.onFocusNext();
				break;
			case 40: // down
				e.preventDefault();
				this.onFocusPrev();
				break;
			case 33: // page up
				e.preventDefault();
				this.onFocusNext();
				break;
			case 34: // page down
				e.preventDefault();
				this.onFocusPrev();
				break;
			default: break;
		}
	};

	onKeyDownHolder = e => {
		switch (e.keyCode) {
			case 13: // enter
				e.preventDefault();
				e.stopPropagation();
				this.filter.focus();
				break;
			case 27: // escape
				e.preventDefault();
				e.stopPropagation();
				this.holder.blur();
				break;
			default:
				this.filter.focus();
				break;
		}
	};

	onFocus = () => {
		const { onFocus } = this.props;

		this.setState({ focused: true, filter: '' },
			() => onFocus === 'function' && onFocus());
	};

	onFocusNext = () => {
		const { data } = this.props;
		const { focusedIdx } = this.state;

		if (data.length <= 0) {
			return;
		}

		let nextIdx = focusedIdx - 1;

		if (nextIdx < 0) {
			nextIdx = data.length - 1;
		}

		this.setState({ focusedIdx: nextIdx });
	};

	onFocusPrev = () => {
		const { data } = this.props;
		const { focusedIdx } = this.state;

		if (data.length <= 0) {
			return;
		}

		let nextIdx = focusedIdx + 1;

		if (nextIdx > data.length - 1) {
			nextIdx = 0;
		}

		this.setState({ focusedIdx: nextIdx });
	};

	onBlur = () => {
		const { onBlur, name } = this.props;

		this.setState({ focused: false, filter: '' },
			() => onBlur && onBlur(null, name));
	};

	onBlurHolder = () => this.setState({ holder: false });

	onToggle = () => {
		if (this.filter === document.activeElement) {
			this.holder.focus();
		} else {
			this.filter.focus();
		}
	};

	onFilter = value => this.setState({ focusedIdx: 0, filter: value });

	onSelect = item => {
		const { name, onChange } = this.props;

		if (onChange) {
			onChange(item.value, name);
		}

		this.setState({ holder: true },
			() => this.holder.focus());
	};

	onSelectFocus = () => {
		const { data, name, onChange } = this.props;
		const { filter, focusedIdx } = this.state;

		if (focusedIdx < 0) {
			return;
		}

		const term = filter.toLowerCase();
		const filtered = data.filter(item => item.title.toLowerCase().includes(term));

		if (filtered.length <= 0) {
			return;
		}

		const current = filtered[focusedIdx];

		if (onChange) {
			onChange(current.value, name);
		}

		this.setState({ holder: true },
			() => this.holder.focus());
	};

	onMouseOverItem = (item, idx) => this.setState({ focusedIdx: idx });

	onPrevent = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	onChange = e => this.onFilter(e.target.value);

	renderOptions = () => {
		const { data } = this.props;
		const { filter } = this.state;

		if (!data || !filter.length) {
			return data;
		}

		const term = filter.toLowerCase();

		return data.filter(item => {
			if (item.title) {
				const isTitleIncludes = item.title.toLowerCase().includes(term);
				if (isTitleIncludes) return isTitleIncludes;
			}
			if (item.subtitle) {
				const isSubtitleIncludes = item.subtitle.toLowerCase().includes(term);
				if (isSubtitleIncludes) return isSubtitleIncludes;
			}
			return null;
		});
	};

	render () {
		const {
			name,
			placeholder,
			label,
			value,
			data,
			errors,
			disabled,
			className,
			intl,
			autoFocus,
			hotjar
		} = this.props;
		const { focused, focusedIdx, filter, holder } = this.state;
		const selectedIdx = data.findIndex(item => item.value === value);
		const selected = selectedIdx > -1 ? data[selectedIdx] : null;
		const title = selected ? selected.title : '';
		const options = this.renderOptions();
		const t = intl.formatMessage;

		return (
			<div className={classnames(
				'InputSelect', className,
				errors && 'InputSelect--Invalid',
				disabled && 'InputSelect--Disabled')}
			>
				<input
					type="hidden"
					className="InputSelect__Input"
					name={name}
					value={value || ''}
				/>

				{placeholder && (focused || !!title.length || label) && (
					<label htmlFor={`${name}Filter`} className="Input__Label">
						{label || placeholder}
					</label>
				)}

				<div
					className="InputSelect__Toggle"
					onMouseDown={this.onPrevent}
					onClick={this.onToggle}
				>
					<Icon className="InputSelect__Icon" name="caveat-down" />
				</div>

				<div
					ref={ref => { this.holder = ref; }}
					className="InputSelect__Holder"
					tabIndex={-1}
					onBlur={this.onBlurHolder}
					onKeyDown={this.onKeyDownHolder}
				/>

				<input
					id={`${name}Filter`}
					ref={ref => { this.filter = ref; }}
					className="InputSelect__Filter"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					name={`${name}Filter`}
					value={focused ? filter : title}
					autoComplete="off"
					autoFocus={autoFocus}
					placeholder={focused ? t(M.inputs.filter) : placeholder}
					disabled={disabled}
					onKeyDown={this.onKeyDown}
					tabIndex={holder ? -1 : 0}
					data-hj-allow={hotjar}
				/>

				{errors && !!errors.length && (
					<InputError
						errors={errors}
						placeholder={placeholder}
						className="InputAutocomplete__Errors"
					/>
				)}

				<Dropdown
					className="InputSelect__List"
					onMouseDown={this.onPrevent}
					onMouseOverItem={this.onMouseOverItem}
					onClick={this.onSelect}
					focusedIdx={focusedIdx}
					selectedIdx={selectedIdx}
					data={options}
				/>
			</div>
		);
	}
}
