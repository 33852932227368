import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import validator from 'helpers/validator';

import * as paymentActions from 'actions/payment';

import * as paymentSelector from 'selectors/payment';

import PaymentSchema from 'schemas/payment';

import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';

import M from './Amount.locale.json';
import Banner from 'components/Banner/Banner';



const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	return {
		payment: paymentSelector.get(state, id),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,

		form: {
			amount: '',
		},
		messages: [],
		errors: {
			amount: [],
		},
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		// special case
		form.original = this.props.payment.amount;

		let messages = [];

		if (errors[name].length) {
			messages = validator.single({
				...form,
				[name]: value,
			}, name, PaymentSchema.amount);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, payment } = this.props;

		// special case
		form.original = payment.amount;

		// check if form was already submitted
		if (loader) return;

		const errors = validator.all(form, PaymentSchema.amount);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.payment.amount(payment.id, form);

		const update = {
			errors: {
				...this.state.errors,
				...response.errors,
			},
			loader: false,
		};

		if ((response && response.errors) && response.messages) {
			update.messages = response.messages;

			this.setState(update);

			return;
		}

		this.setState(update);
	}

	render () {
		const { payment } = this.props;
		const { loader, form, messages, errors } = this.state;

		const t = this.props.intl.formatMessage;

		if (!payment) return null;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>

				{
					(messages && messages.length > 0) &&
					<Banner type="error" data={messages} />
				}

				<Form.Group>
					<Input
						name="beneficiary"
						placeholder={t(M.inputs.beneficiary)}
						value={payment.beneficiary}
						disabled
					/>
				</Form.Group>

				<Form.Group>
					<Input
						name="iban"
						placeholder={t(M.inputs.iban)}
						value={payment.iban}
						disabled
					/>
				</Form.Group>

				<Form.Group>
					<Input
						name="bic"
						placeholder={t(M.inputs.bic)}
						value={payment.bic}
						disabled
					/>
				</Form.Group>

				<Form.Group>
					<Input
						name="amount-amount"
						placeholder={t(M.inputs.amount)}
						value={payment.amount}
						unit="EUR"
						disabled
					/>
				</Form.Group>

				{
					payment.details &&
					<Form.Group>
						<Input
							name="details"
							placeholder={t(M.inputs.details)}
							value={payment.details}
							disabled
						/>
					</Form.Group>
				}

				<br />

				<Form.Group>
					<Input
						name="predictedFee"
						placeholder={t(M.inputs.fee)}
						unit="EUR"
						value={payment.predictedFee}
						disabled
					/>
				</Form.Group>

				<br />

				<Form
					onSubmit={this.onSubmit}
				>
					<Form.Group>
						<Input
							name="amount"
							placeholder={t(M.inputs.amount)}
							onChange={this.onChange}
							value={form.amount}
							errors={errors.amount}
						/>
					</Form.Group>

					<Button
						className="PaymentCreateScreen__Submit"
						loading={loader}
						small
						form
					>
						{t(M.buttons.next)}
					</Button>
				</Form>
			</div>
		);
	}
}
