import {
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	CLIENT_FETCH_BUSINESS_SUCCESS,
	CLIENT_CREATE_BUSINESS_SUCCESS,
	CLIENT_CREATE_PRIVATE_SUCCESS,
} from 'constants';
import { memberStatus, clientStep } from 'constants/common';

const initial = [];

const list = (state = initial, action) => {
	switch (action.type) {
		case AUTH_LOGIN_SUCCESS:
		case AUTH_SIGNUP_SUCCESS:
			return [...action.payload.list];
		case CLIENT_FETCH_BUSINESS_SUCCESS:
			return [...action.payload];
		case CLIENT_CREATE_BUSINESS_SUCCESS: {
			if (action.payload.step !== clientStep.DOCUMENTS) return state;
			return [...state].map(item => {
				if (item.entityId !== action.payload.id) return item;
				return { ...item, status: memberStatus.REQUESTED };
			});
		}
		case CLIENT_CREATE_PRIVATE_SUCCESS:
			return [...state].map(item => {
				if (item.entityId !== action.payload.id) return item;
				return { ...item, status: memberStatus.ACTIVE };
			});
		default:
			return state;
	}
};

export default list;
