import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import Form from 'components/Form';
import { cardStatus } from 'constants/common';
import * as cardActions from 'actions/card';
import { getById } from 'selectors/card';
import { cardsUrl } from '../constants';
import M from './Block.locale.json';

import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import { cardActionsTypes } from 'types/actions';

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class Block extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
		}).isRequired,
		history: historyTypes.isRequired,
	}

	state = {
		loader: false,
	}

	componentDidMount () {
		this.mounted = true;
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onSubmit = async e => {
		e.preventDefault();

		const { actions, match, history, card } = this.props;
		const { id } = match.params;

		this.setState({ loader: true });

		await actions.card.block(id);
		this.mounted && this.setState({ loader: false });

		// TODO it should be change!!!
		card.cardStatus = cardStatus.DEPOSIT_ONLY;

		history.push(cardsUrl);
	}

	mounted = false

	render () {
		const { intl } = this.props;
		const { loader } = this.state;
		const t = intl.formatMessage;

		return (
			<div>
				<p>{t(M.messages.note)}</p>
				<p><strong>{t(M.messages.warning)}</strong></p>
				<Form onSubmit={this.onSubmit}>
					<Button
						className="CardSingleScreen__Submit"
						loading={loader}
						form
						small
					>
						{t(M.buttons.block)}
					</Button>
				</Form>
			</div>
		);
	}
}

export default Block;
