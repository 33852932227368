import {
	DASHBOARD_FETCH_SUCCESS,
	DASHBOARD_SET_SORT,
} from 'constants';
import { clientRole } from 'constants/common';
import API from 'services/api';
import renamer from 'helpers/renamer';
import * as clientSelector from 'selectors/client';

export const fetch = params => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const clientId = isBusiness ? client.entityId : null;
	const cardId = state.card.current;
	try {
		const { data } = await API.transaction.fetch(cardId, { ...params }, clientId);
		const payload = renamer.from.transaction.fetch(data.response);
		dispatch({ type: DASHBOARD_FETCH_SUCCESS, payload });
		return data;
	} catch (err) { return err; }
};

export const sort = value => ({
	type: DASHBOARD_SET_SORT,
	payload: value
});
