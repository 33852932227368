import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import queryString from 'query-string';

import * as languageActions from 'actions/language';
import { languages } from './constants';
import Image from '../Image';
import InputSelect from '../InputSelect';
import './Language.scss';

const mapState = state => ({
	language: state.language.locale,
});

const mapDispatch = dispatch => ({
	actions: {
		language: bindActionCreators(languageActions, dispatch),
	},
});

@connect(mapState, mapDispatch)
@enhanceWithClickOutside
export default class Language extends React.PureComponent {
	onSelect = value => {
		const search = queryString.parse(location.search);
		search.locale = value;

		window.location.search = queryString.stringify(search);
		this.props.actions.language.get(value);
	};

	render () {
		const { language, className } = this.props;
		const flag = require(`image/flags/${language}.png`);

		return (
			<div className={classnames('Language', className)}>
				<Image className="Language__Flag" src={flag} />

				<InputSelect
					className="Language__Select"
					data={languages}
					onChange={this.onSelect}
					name="language"
					value={language}
				/>
			</div>
		);
	}
}

Language.propTypes = {
	className: PropTypes.string,
	actions: PropTypes.shape({
		language: PropTypes.shape({
			get: PropTypes.func.isRequired,
		}).isRequired,
	}),
	language: PropTypes.string,
};
