import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import './Tip.scss';

const classes = {
	success: 'Tip--Success',
	warning: 'Tip--Warning',
	error: 'Tip--Error',
	info: 'Tip--Info',
};

export default function Tip (props) {
	const { className, icon, type, title, children } = props;
	return (
		<div className={classnames('Tip', className, classes[type])}>
			{icon && <Icon className="Tip__Icon" name={icon} />}
			<div className="Tip__Title">{title}</div>
			<div className="Tip__Content">{children}</div>
		</div>
	);
}

Tip.propTypes = {
	children: PropTypes.any,
	icon: PropTypes.string,
	type: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
};
