import moment from 'moment';

const pdf = form => {
	const convert = {
		...form,
	};

	if (form.from) {
		convert.start = moment(form.from, 'DD/MM/YYYYY').format('DD-MM-YYYY');
		convert.startCompact = moment(form.from, 'DD/MM/YYYYY').format('DDMMYYYY');
		delete convert.from;
	}
	if (form.till) {
		convert.end = moment(form.till, 'DD/MM/YYYYY').format('DD-MM-YYYY');
		convert.endCompact = moment(form.till, 'DD/MM/YYYYY').format('DDMMYYYY');
		delete convert.till;
	}
	return convert;
};

export default {
	pdf,
};
