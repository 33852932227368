export const gtmId = 'GTM-NFZ95F3';
export const GAId = 'UA-66844477-1';

export const GTMEvent = {
	signup: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_0'
	},
	regulatoryData: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_1'
	},
	eMailVerification: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_2'
	},
	phoneVerification: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_3'
	},
	congratulationsScreen: {
		event: 'SIGNUP',
		category: 'SIGNUP',
		action: 'SIGNUP_STEP_4'
	},
	secondaryCardIsOrdered: {
		event: 'SECONDARY',
		category: 'SECONDARY',
		action: 'SECONDARY_ORDERED'
	},
	cardIsActivated: {
		event: 'ACTIVATION',
		category: 'ACTIVATION',
		action: 'CARD_ACTIVATED'
	},
	documentsUploaded: {
		event: 'VERIFICATION',
		category: 'VERIFICATION',
		action: 'DOCUMENTS_UPLOADED'
	},
	SPEAPayment: {
		event: 'PAYMENT',
		category: 'PAYMENT',
		action: 'SEPA_COMPLETED'
	},
	C2CPayment: {
		event: 'PAYMENT',
		category: 'PAYMENT',
		action: 'C2C_COMPLETED'
	},
	internationalPayment: {
		event: 'PAYMENT',
		category: 'PAYMENT',
		action: 'IP_COMPLETED'
	},
	voucher: {
		event: 'VOUCHER',
		category: 'VOUCHER',
		action: 'VOUCHER_LOADED'
	},
};
