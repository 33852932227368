import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import Wrap from 'components/Wrap';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Form from 'components/Form';
import Input from 'components/Input';
import * as paymentActions from 'actions/payment';
import * as paymentSelector from 'selectors/payment';
import helpers from 'helpers';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';
import M from './Completed.locale.json';

import intlTypes from 'types/intl';

const mapState = (state, props) => ({
	payment: paymentSelector.get(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Completed extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		payment: PropTypes.shape({
			fromIban: PropTypes.string.isRequired,
			fromCardNumber: PropTypes.string.isRequired,
			signerName: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
			signerLastName: PropTypes.string.isRequired,
		}),
	}

	componentDidMount () {
		triggerEvent(GTMEvent.C2CPayment, '/c2cpayments/create');
	}

	onCreate = () => this.props.history.push('/c2cpayments/create')
	onDashboard = () => this.props.history.push('/payments')

	render () {
		const { payment, intl } = this.props;
		const t = intl.formatMessage;

		return (
			<Wrap>
				<div className="PaymentCreateScreen__Icon PaymentCreateScreen__Icon--Success">
					<Icon name="success" />
				</div>
				<Title className="PaymentCreateScreen__Subtitle" title={t(M.title)} />
				<p>{t(M.messages.success)}</p>
				<Form.Group>
					<Input
						name="fromIban"
						placeholder={t(M.inputs.fromIban)}
						value={payment.fromIban}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="cardNumber"
						placeholder={t(M.inputs.cardNumber)}
						value={payment.fromCardNumber}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="iban"
						placeholder={t(M.inputs.to)}
						value={payment.to}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="amount-amount"
						placeholder={t(M.inputs.amount)}
						value={helpers.formatter('payment', payment.amount)}
						unit="EUR"
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="signerName"
						placeholder={t(M.inputs.signerName)}
						value={payment.signerName}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="signerLastName"
						placeholder={t(M.inputs.signerLastName)}
						value={payment.signerLastName}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="paymentDate"
						placeholder={t(M.inputs.paymentDate)}
						value={helpers.formatter('paymentDate', payment.paymentDate)}
						disabled
					/>
				</Form.Group>
				<Button
					form
					ghost
					small
					className="PaymentCreateScreen__Back"
					onClick={this.onDashboard}
				>
					{t(M.buttons.payments)}
				</Button>
				<Button
					form
					small
					className="PaymentCreateScreen__Submit"
					onClick={this.onCreate}
				>
					{t(M.buttons.create)}
				</Button>
			</Wrap>
		);
	}
}
