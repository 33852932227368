export const FETCH_COUNTER_IS_ZERO_EVENT = 'fetch_counter_is_zero';

let counter = null;

export function checkFetchFinishing () {
	if (counter === 0) document.dispatchEvent(new Event(FETCH_COUNTER_IS_ZERO_EVENT));
}

export function increase () {
	counter += 1;
}

export function decrease () {
	if (!counter) return;
	counter -= 1;
	checkFetchFinishing();
}

export function clear () {
	counter = null;
}

export function getCounter () {
	return counter;
}
