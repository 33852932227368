import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button';

class PaginationButton extends React.PureComponent {
	static propTypes = {
		idx: PropTypes.number.isRequired,
		current: PropTypes.number.isRequired,
		onClick: PropTypes.func.isRequired,
	}

	onClick = () => {
		const { idx, onClick } = this.props;
		onClick(idx);
	}

	render () {
		const { current, idx } = this.props;
		return (
			<Button
				className={classnames('Pagination__Page', current && 'Pagination__Page--Current')}
				onClick={this.onClick}
				small
			>
				{idx + 1}
			</Button>
		);
	}
}

export default PaginationButton;
