import moment from 'moment';

const get = form => {
	if (!form) return form;

	const convert = {
		...form,
	};

	delete convert.id;

	convert.address = form.address.line1;
	convert.postalCode = form.address.postalCode;
	convert.country = form.address.country;

	if (convert.percentageOwned) convert.percentageOwned += '';
	if (form.firstName) {
		convert.name = form.firstName;
		delete convert.firstName;
	}
	if (form.lastName) {
		convert.surname = form.lastName;
		delete convert.lastName;
	}
	if (form.dateOfBirth) {
		convert.dateOfBirth = moment(form.dateOfBirth, 'DD-MM-YYYY').format('DD/MM/YYYY');
	}

	return convert;
};

export default {
	get,
};
