import {
	CLIENT_BUSINESS_NAV_SUCCESS,
	CLIENT_BUSINESS_FORM,
	CLIENT_CREATE_BUSINESS_SUCCESS,
	CLIENT_EDIT_BUSINESS_SUCCESS,
	SHAREHOLDER_CREATE_SUCCESS,
	SHAREHOLDER_REMOVE_SUCCESS,
	DOCUMENT_CREATE_SUCCESS,
	DOCUMENT_REMOVE_SUCCESS,
} from 'constants';
import { clientStep } from 'constants/common';

const STATE = {
	basic: clientStep.EMPTY,
	location: clientStep.EMPTY,
	contact: clientStep.EMPTY,
	shareholders: clientStep.EMPTY,
	documents: clientStep.EMPTY,
};

const nav = (state = STATE, action) => {
	switch (action.type) {
		case CLIENT_BUSINESS_NAV_SUCCESS:
			return { ...state, ...action.payload };
		case CLIENT_BUSINESS_FORM: {
			if (state[action.payload.step] === clientStep.EMPTY) return state;
			return { ...state, [action.payload.step]: clientStep.UNSAVED };
		}
		case CLIENT_CREATE_BUSINESS_SUCCESS:
		case CLIENT_EDIT_BUSINESS_SUCCESS:
			return { ...state, [action.payload.step]: clientStep.COMPLETE };
		case SHAREHOLDER_CREATE_SUCCESS:
			return { ...state, shareholders: clientStep.COMPLETE };
		case SHAREHOLDER_REMOVE_SUCCESS: {
			if (action.payload.total - 1 > 0) return state;
			return { ...state, shareholders: clientStep.EMPTY };
		}
		case DOCUMENT_CREATE_SUCCESS:
			return { ...state, documents: clientStep.COMPLETE };
		case DOCUMENT_REMOVE_SUCCESS: {
			if (action.payload.total - 1 > 0) return state;
			return { ...state, documents: 'EMPTY' };
		}
		default:
			return state;
	}
};

export default nav;
