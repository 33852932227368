import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Close from './Close';
import ModalLoader from './ModalLoader';
import './Modal.scss';

export default class Modal extends React.PureComponent {
	static propTypes = {
		onClick: PropTypes.func,
		children: PropTypes.node,
		className: PropTypes.string,
	}

	componentDidMount () {
		document.addEventListener('keydown', this.onKeyDown);
	}

	componentWillUnmount () {
		document.removeEventListener('keydown', this.onKeyDown);
	}

	onKeyDown = e => {
		// esc
		if (e.keyCode !== 27) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	render () {
		const { className, onClick, children } = this.props;
		return (
			<div className={classnames('Modal', className)}>
				<div className="Modal__Conceiler">
					<div className="Modal__Overlay" onClick={onClick} />
					<div className="Modal__Content">{children}</div>
				</div>
			</div>
		);
	}
}

Modal.Close = Close;
Modal.Loader = ModalLoader;
