import moment from 'moment';

export default function basic (data) {
	const convert = {
		...data,
	};

	if (data.dateOfIncorporation) {
		convert.dateOfIncorporation = moment(data.dateOfIncorporation, 'DD/MM/YYYY').format('DD-MM-YYYY');
	}

	if (data.authority) {
		convert.regulatedByAuthority = convert.authority;
		delete convert.authority;

		convert.regulationAuthorityName = convert.authorityName;
		delete convert.authorityName;

		convert.licenceNumberGrantedByAuthority = convert.authorityLicence;
		delete convert.authorityLicence;
	}

	if (data.businessType) {
		convert.legalStatus = data.businessType;
		delete convert.businessType;

		convert.otherLegalStatus = data.businessTypeOther;
		delete convert.businessTypeOther;
	}

	return convert;
}
