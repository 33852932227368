export default {
	create: {
		name: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		surname: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		role: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		phone: {
			presence: { message: '^schemas.presence' },
			numericality: { message: '^schemas.numerical' },
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		phoneCountry: {
			presence: { message: '^schemas.presence' },
		},
		email: {
			presence: { message: '^schemas.presence' },
			email: { message: '^schemas.email' },
		},
	},
};
