import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Image.scss';

class Image extends React.PureComponent {
	static propTypes = {
		src: PropTypes.string.isRequired,
		alt: PropTypes.string,
		className: PropTypes.string,
	}

	state = {
		loaded: false,
		complete: false,
	}

	componentDidMount () {
		// eslint-disable-next-line react/no-find-dom-node
		const image = findDOMNode(this.image);
		if (image.complete) this.onComplete();
	}

	onComplete = () => this.setState({ complete: true })

	onLoad = () => {
		const { loaded, complete } = this.state;
		if (loaded || complete) return;
		this.setState({ loaded: true });
	}

	render () {
		const { src, alt, className } = this.props;
		const { loaded, complete } = this.state;
		return (
			<div className={classnames('Image', className)}>
				<img
					ref={ref => { this.image = ref; }}
					src={src}
					alt={alt}
					className={classnames(
						'Image__Image',
						complete && 'Image__Image--Complete',
						loaded && 'Image__Image--Loaded'
					)}
					onLoad={this.onLoad}
				/>
			</div>
		);
	}
}

export default Image;
