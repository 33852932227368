import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';
import reduxer from 'helpers/reduxer';

import Client from 'schemas/client';

import * as clientActions from 'actions/client';
import * as countryActions from 'actions/country';

import * as countrySelector from 'selectors/country';

import API from '../../../../services/api';
import placeService from '../../../../services/place';

import Form from 'components/Form';
import Input from 'components/Input';
import InputCheckbox from 'components/InputCheckbox';
import Button from 'components/Button';
import InputAutocomplete from 'components/InputAutocomplete';
import InputSelect from 'components/InputSelect';
import Tip from 'components/Tip';
import Tab from 'components/Tab';

import M from './Location.locale.json';



const mapState = (state, props) => ({
	form: state.client.business.form.location,
	country: countrySelector.getCountryListWithTranslations(state, props),
});

const mapDispatch = dispatch => ({
	actions: {
		client: reduxer.bindNestedActionCreators(clientActions, dispatch),
		country: bindActionCreators(countryActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Location extends PureComponent {
	state = {
		loader: false,
		loaderRegAddress: false,
		loaderAddress: false,

		errors: {
			regAddress: [],
			regCountry: [],
			regCity: [],
			city: [],
			regPostalCode: [],
			identical: [],
			address: [],
			country: [],
			postalCode: [],
			website: [],
		},

		regAutocomplete: [],
		autocomplete: [],
	}

	componentDidMount () {
		const { actions, country } = this.props;

		actions.client.business.get('location');
		actions.country.fetch();
	}

	onAddressSelect = async (value, name) => {
		const loader = (name === 'regAddress' ? 'loaderRegAddress' : 'loaderAddress');
		const country = (name === 'regAddress' ? null : 'country');
		const postalCode = (name === 'regAddress' ? 'regPostalCode' : 'postalCode');
		const city = (name === 'regAddress' ? 'regCity' : 'city');

		this.setState({
			[loader]: true,
		});

		try {
			const result = await placeService.geocode(value);

			let address = result.route;

			// there might not be a street
			if (result.street) address = `${result.street} ${address}`;

			this.onChange(address, name);

			if (country && result.country) {
				// backend takes id not country code itself
				const item = this.props.country.find(item => item.code === result.countryCode);

				this.onChange(item.value, country);
			}
			if (result.post) this.onChange(result.post, postalCode);
			if (result.locality) this.onChange(result.locality, city);
		} catch (error) {

		}

		this.setState({
			[loader]: false,
		});
	}

	onLookup = (value, name) => {
		this.onChange(value, name);
		this.getAutocomplete(value, name);
	}

	onChange = (value, name) => {
		const { errors } = this.state;
		const { actions, form } = this.props;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Client.create.location);
		}

		this.setState({
			errors: {
				...errors,
				[name]: messages,
			},
		});

		actions.client.business.form({
			[name]: value,
		}, 'location');
	}

	onSubmit = async e => {
		e.preventDefault();

		const { loader } = this.state;
		const { form, actions, history, match } = this.props;

		const { id } = match.params;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, Client.create.location);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
				loader: false,
			});

			return;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.client.business.edit(form, 'location');

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState({
			loader: false,
		});

		history.push(`/clients/create/business/${id}/contact`);
	}


	getAutocomplete = async (value, name) => {
		const { form, country } = this.props;
		const regCountry = country.find(item => item.value === form.regCountry);

		if (!value) return;

		try {
			let result;

			if (name === 'regAddress') {
				result = await placeService.autocomplete(value, regCountry.code);
			} else {
				result = await placeService.autocomplete(value);
			}

			const data = helpers.geocoder.autocomplete(result);

			const autocomplete = (name === 'regAddress' ? 'regAutocomplete' : 'autocomplete');

			this.setState({
				[autocomplete]: data,
			});
		} catch (error) {
			// if (error !== google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
			// 	return [];
			// }

			this.setState({
				autocomplete: []
			});
		}
	}

	render () {
		const { loader, loaderRegAddress, loaderAddress, errors, regAutocomplete, autocomplete } = this.state;
		const { form, current, onPrev, step, country } = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<div
				className="ClientCreateBusinessScreen__Step"
			>
				<Form onSubmit={this.onSubmit}>
					<Form.Group>
						<InputAutocomplete
							name="regAddress"
							placeholder={t(M.inputs.regAddress)}
							onChange={this.onLookup}
							onSelect={this.onAddressSelect}
							value={form.regAddress}
							errors={errors.regAddress}
							autoFocus
							data={regAutocomplete}
						/>
					</Form.Group>

					<Form.Group>
						<InputSelect
							name="regCountry"
							onChange={this.onChange}
							placeholder={t(M.inputs.regCountry)}
							value={form.regCountry}
							errors={errors.regCountry}
							data={country}
							disabled
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="regCity"
							placeholder={t(M.inputs.regCity)}
							onChange={this.onChange}
							value={form.regCity}
							errors={errors.regCity}
							disabled={loaderRegAddress}
						/>
						<Input
							name="regPostalCode"
							placeholder={t(M.inputs.regPostalCode)}
							onChange={this.onChange}
							value={form.regPostalCode}
							errors={errors.regPostalCode}
							disabled={loaderRegAddress}
						/>
					</Form.Group>

					<Form.Group
						row
					>
						<InputCheckbox
							name="identical"
							type="identical"
							onChange={this.onChange}
							value="on"
							checked={form.identical}
							errors={errors.identical}
						>
							{t(M.inputs.identical)}
						</InputCheckbox>
					</Form.Group>

					{
						!form.identical &&
						<Form.Group>
							<InputAutocomplete
								name="address"
								placeholder={t(M.inputs.address)}
								onChange={this.onLookup}
								onSelect={this.onAddressSelect}
								value={form.address}
								errors={errors.address}
								data={autocomplete}
							/>
						</Form.Group>
					}

					{
						!form.identical &&
						<Form.Group>
							<InputSelect
								name="country"
								onChange={this.onChange}
								placeholder={t(M.inputs.country)}
								value={form.country}
								errors={errors.country}
								data={country}
								disabled={loaderAddress}
							/>
						</Form.Group>
					}

					{
						!form.identical &&
						<Form.Group>
							<Input
								name="city"
								placeholder={t(M.inputs.city)}
								onChange={this.onChange}
								value={form.city}
								errors={errors.city}
								disabled={loaderAddress}
							/>
							<Input
								name="postalCode"
								placeholder={t(M.inputs.postalCode)}
								onChange={this.onChange}
								value={form.postalCode}
								errors={errors.postalCode}
								disabled={loaderAddress}
							/>
						</Form.Group>
					}

					<Form.Group>
						<Input
							name="website"
							placeholder="http://"
							label={`${t(M.inputs.website)} ${t(M.inputs.ifAny)}`}
							onChange={this.onChange}
							value={form.website}
							errors={errors.website}
							disabled={loaderAddress}
						/>
					</Form.Group>

					<Button
						className="Stepper__Button"
						loading={loader}
						form
						small
					>
						<span>3/5</span> {t(M.buttons.next)}
					</Button>

					<Button
						className="Stepper__Button"
						disabled={loader}
						onClick={() => onPrev()}
						secondary
						small
					>
						{t(M.buttons.back)}
					</Button>
				</Form>
			</div>
		);
	}
}
