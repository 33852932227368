import React, { Component } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as clientSelector from 'selectors/client';
import * as countrySelector from 'selectors/country';

import * as memberActions from 'actions/member';
import * as countryActions from 'actions/country';

import MemberSchema from 'schemas/member';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Title from 'components/Title';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';

import M from './Create.locale.json';

import './Create.scss';



const mapState = (state, props) => ({
	current: clientSelector.getCurrentClient(state),
	phoneCountry: countrySelector.getPhoneCodesCountries(state.country, props),
	member: state.member,
});

const mapDispatch = dispatch => ({
	actions: {
		member: bindActionCreators(memberActions, dispatch),
		country: bindActionCreators(countryActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class MemberCreate extends Component {
	state = {
		loader: false,
		loaderPage: true,

		form: {
			name: '',
			surname: '',
			position: '',
			email: '',
			phoneCountry: '',
			phone: '',
			role: '',
		},

		errors: {
			name: [],
			surname: [],
			position: [],
			email: [],
			phoneCountry: [],
			phone: [],
			role: [],
		}
	}

	componentDidMount () {
		this.onMount();
	}

	onMount = async () => {
		const { actions, history } = this.props;

		const response = await actions.country.fetch();

		if (response.errors) {
			history.goBack();

			return;
		}

		this.setState({
			loaderPage: false,
		});
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, MemberSchema.create);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, history } = this.props;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, MemberSchema.create);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		const convert = helpers.renamer.to.member.create(form);

		const response = await actions.member.create(convert);

		if (response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		history.goBack();
	}

	pop = () => {
		this.props.history.goBack();
	}

	render () {
		const { current, phoneCountry } = this.props;
		const { form, errors, loader, loaderPage } = this.state;

		const t = this.props.intl.formatMessage;

		const role = [{
			title: t(M.access.viewer),
			value: 'viewer',
		}];

		if (loaderPage) {
			return (
				<Modal
					onClick={this.pop}
					className="MemberCreateScreen"
				>
					<Modal.Loader />
				</Modal>
			);
		}

		return (
			<Modal
				onClick={this.pop}
				className="MemberCreateScreen Screen"
			>
				<Section>
					<Container medium>
						<Wrap>
							<Modal.Close
								onClick={this.pop}
							/>

							<Title
								title={t(M.title)}
							/>

							<Form.Note>
								<FM
									id={M.messages.note.id}
									values={{
										client: (<strong>{current.name}</strong>)
									}}
								/>
							</Form.Note>

							<Form
								onSubmit={this.onSubmit}
							>
								<Form.Group>
									<Input
										name="name"
										placeholder={t(M.inputs.name)}
										onChange={this.onChange}
										value={form.name}
										errors={errors.name}
										autoFocus
									/>
									<Input
										name="surname"
										placeholder={t(M.inputs.surname)}
										onChange={this.onChange}
										value={form.surname}
										errors={errors.surname}
									/>
								</Form.Group>

								<Form.Group>
									<Input
										name="position"
										placeholder={t(M.inputs.position)}
										onChange={this.onChange}
										value={form.position}
										errors={errors.position}
									/>
								</Form.Group>

								<Form.Group>
									<InputSelect
										data={role}
										name="role"
										placeholder={t(M.inputs.access)}
										onChange={this.onChange}
										value={form.role}
										errors={errors.role}
									/>
								</Form.Group>

								<Form.Group>
									<InputSelect
										data={phoneCountry}
										name="phoneCountry"
										placeholder={t(M.inputs.phoneCountry)}
										onChange={this.onChange}
										value={form.phoneCountry}
										errors={errors.phoneCountry}
									/>

									<Input
										name="phone"
										placeholder={t(M.inputs.phone)}
										onChange={this.onChange}
										value={form.phone}
										errors={errors.phone}
									/>
								</Form.Group>

								<Form.Group>
									<Input
										name="email"
										placeholder={t(M.inputs.email)}
										onChange={this.onChange}
										value={form.email}
										errors={errors.email}
									/>
								</Form.Group>

								<Button
									loading={loader}
									form
								>
									{t(M.buttons.invite)}
								</Button>
							</Form>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
