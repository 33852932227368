import { clientStep } from 'constants/common';
import basic from './basic';
import location from './location';
import contact from './contact';

export default function client (form, step) {
	switch (step) {
		case clientStep.BASIC:
			return basic(form);
		case clientStep.LOCATION:
			return location(form);
		case clientStep.CONTACT:
			return contact(form);
		default:
			return form;
	}
}
