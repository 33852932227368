import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Form from 'components/Form';
import Button from 'components/Button';
import * as cardActions from 'actions/card';
import { getById } from 'selectors/card';
import M from './PIN.locale.json';

import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import { cardActionsTypes } from 'types/actions';

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class PIN extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
		}).isRequired,
	}

	state = {
		loader: false,
	}

	componentDidMount () {
		this.mounted = true;
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onSubmit = async e => {
		e.preventDefault();

		const { actions, match } = this.props;
		const { id } = match.params;

		this.setState({ loader: true });
		await actions.card.pin(id);
		this.mounted && this.setState({ loader: false });
	}

	mounted = false

	render () {
		const { card, intl } = this.props;
		const { loader } = this.state;
		const t = intl.formatMessage;
		return (
			<div>
				<Form onSubmit={this.onSubmit}>
					<p>
						<FM
							id={M.messages.guide.id}
							values={{
								phone: (<strong>{card.phoneCardNumber}</strong>),
							}}
						/>
					</p>
					<Button
						className="CardSingleScreen__Submit"
						loading={loader}
						form
						small
					>
						{t(M.buttons.send)}
					</Button>
				</Form>
			</div>
		);
	}
}

export default PIN;
