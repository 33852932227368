import {
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	AUTH_LOGOUT,
	AUTH_EMAIL_SUCCESS,
} from 'constants';
import { success } from 'actions/toast';
import renamer from 'helpers/renamer';
import API from 'services/api';
import M from './auth.locale.json';

const dispatchLoginAction = async (dispatch, response) => {
	const list = await API.client.business.fetch();
	const user = renamer.from.user.get(response);
	const locale = user.language.toLowerCase();
	const messages = await API.language.get(locale);

	dispatch({
		type: AUTH_LOGIN_SUCCESS,
		payload: {
			user,
			list: list.data.response,
			language: {
				locale,
				messages: messages.data,
			},
		},
	});
	return user;
};

const dispatchSignupAction = async (dispatch, response) => {
	const list = await API.client.business.fetch();
	const user = renamer.from.user.get(response);
	const locale = user.language.toLowerCase();
	const messages = await API.language.get(locale);

	dispatch({
		type: AUTH_SIGNUP_SUCCESS,
		payload: {
			user,
			list: list.data.response,
			language: {
				locale,
				messages: messages.data,
			},
		},
	});
	return user;
};

export const login = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.login(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) { return error; }
};

export const loginCreditCard = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.loginCreditCard(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) { return error; }
};

export const activate = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.activate(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) { return error; }
};

export const signup = {
	private: form => async dispatch => {
		try {
			const { data: { response } } = await API.auth.signup.private(form);
			return await dispatchSignupAction(dispatch, response);
		} catch (error) { return error; }
	},
	business: form => async dispatch => {
		try {
			const { data: { response } } = await API.auth.signup.business(form);
			return await dispatchSignupAction(dispatch, response);
		} catch (error) { return error; }
	},
};

export const logout = () => ({ type: AUTH_LOGOUT });

export const reset = form => async () => {
	try {
		const { data: { response } } = await API.auth.reset(form);
		return response;
	} catch (error) { return error; }
};

export const password = (form, token) => async dispatch => {
	try {
		const result = await API.auth.password(form, token);
		success(dispatch)({ title: M.success.password.title });
		return result.data.response;
	} catch (error) { return error; }
};

export const email = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.email(form);
		dispatch({ type: AUTH_EMAIL_SUCCESS, payload: response });
		return response;
	} catch (error) {
		if (error.errors) error.errors = renamer.from.email(error.errors);
		return error;
	}
};
