import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Section from 'components/Section';
import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';
import PasswordPolicy from 'components/PasswordPolicy';
import * as authActions from 'actions/auth';
import * as toastActions from 'actions/toast';
import helpers from 'helpers';
import Auth from 'schemas/auth';
import M from './Password.locale.json';

import intlTypes from 'types/intl';

const isInvalid = 'errors.access.token.invalid';
const isReset = 'errors.access.password.reset';

const mapDispatch = dispatch => ({
	actions: {
		toast: bindActionCreators(toastActions, dispatch),
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@connect(null, mapDispatch)
export default class Password extends Component {
	static propTypes = {
		intl: intlTypes.isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				token: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		actions: PropTypes.shape({
			toast: PropTypes.shape({
				create: PropTypes.func.isRequired,
			}).isRequired,
			auth: PropTypes.shape({
				password: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
	}

	state = {
		loader: false,
		form: {
			password: '',
			passwordConfirm: '',
		},
		errors: {
			password: [],
			passwordConfirm: [],
		},
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;
		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.password);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { form, loader } = this.state;
		const { actions, match, history } = this.props;
		const { params: { token } } = match;

		const email = new URLSearchParams(location.search).get('email');

		if (loader) return;

		form.token = token;
		const errors = helpers.validator.all(form, Auth.password);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ loader: true });

		const response = await actions.auth.password({ email, password: form.password }, token);

		if (response && response.errors) this.handleErrors(response);

		this.setState({ loader: false });
		history.push('/');
	}

	handleErrors = ({ errors, messages }) => {
		const { actions } = this.props;
		const update = {
			errors: { ...this.state.errors, ...errors },
			loader: false,
		};

		this.setState(update);

		const id = messages[0].id;

		if (messages && id === isInvalid) {
			return actions.toast.create({ title: M.error.token, type: 'error' });
		}

		if (messages && id === isReset) {
			return actions.toast.create({ title: M.error.reset, type: 'error' });
		}

		return actions.toast.create({ title: M.error.default, type: 'error' });
	}

	render () {
		const { form, loader, errors } = this.state;
		const { match, intl } = this.props;
		const { token } = match.params;

		const t = intl.formatMessage;

		if (!token) return <Redirect to={{ pathname: '/auth/reset' }} />;

		return (
			<Layout className="AuthPasswordScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Wrap>
							<Title title={t(M.title)} legend={t(M.legend)} />
							<Form onSubmit={this.onSubmit}>
								<Form.Group row>
									<Input
										name="password"
										type="password"
										placeholder={t(M.inputs.password)}
										onChange={this.onChange}
										value={form.password}
										errors={errors.password}
									/>
									<PasswordPolicy value={form.password} />
								</Form.Group>
								<Form.Group>
									<Input
										name="passwordConfirm"
										type="password"
										placeholder={t(M.inputs.passwordConfirm)}
										onChange={this.onChange}
										value={form.passwordConfirm}
										errors={errors.passwordConfirm}
									/>
								</Form.Group>
								<Button form loading={loader}>{t(M.buttons.change)}</Button>
							</Form>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
