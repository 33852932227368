import 'babel-polyfill';
import 'helpers/analytics.js';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Root from './Root';

Sentry.init({
	dsn: 'https://bacecfc8a4ef49e59d38e21dc0743370@o479281.ingest.sentry.io/5523689',
	integrations: [
		new Integrations.BrowserTracing(),
	],
	environment: process.env.BUILD_ENV || 'development',
	tracesSampleRate: 1.0,
});

const render = Component => {
	ReactDOM.render(
		<Component />,
		document.getElementById('App')
	);
};

render(Root);

if (module.hot) {
	module.hot.accept();
}
