import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as memberActions from 'actions/member';

import * as memberSelector from 'selectors/member';

import MemberSchema from 'schemas/member';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Member from 'components/Member';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';

import M from './Edit.locale.json';

import './Edit.scss';



const mapState = (state, props) => {
	const { match: { params: { id } } } = props;

	return {
		member: memberSelector.get(state, id),
		current: state.user.id,
	};
};

const mapDispatch = dispatch => ({
	actions: {
		member: bindActionCreators(memberActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,
		loaderPage: true,

		form: {
			position: '',
			role: '',
		},

		errors: {
			position: [],
			role: [],
		}
	}

	componentDidMount () {
		this.onMount();
	}

	onMount = async () => {
		const { actions, history, member } = this.props;
		const { userId, roleId } = member;

		const response = await actions.member.get(userId, roleId);

		if (response.errors) {
			history.goBack();

			return;
		}

		this.setState({
			loaderPage: false,

			form: {
				position: response.position,
				role: response.role,
			},
		});
	}

	onRemove = async () => {
		const { actions, history, member } = this.props;
		const { roleId } = member;

		await actions.member.remove(roleId);

		history.goBack();
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, MemberSchema.edit);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, history, member } = this.props;

		const { userId, roleId } = member;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, MemberSchema.edit);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.member.edit(userId, roleId, form);

		if (response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		history.goBack();
	}

	pop = () => {
		this.props.history.goBack();
	}

	render () {
		const { member, current } = this.props;
		const { form, errors, loader, loaderPage } = this.state;

		const t = this.props.intl.formatMessage;

		const role = [{
			title: t(M.access.administrator),
			value: 'OWNER',
		}, {
			title: t(M.access.viewer),
			value: 'BOOKKEEPER',
		}];

		if (loaderPage) {
			return (
				<Modal
					onClick={this.pop}
					className="MemberEditScreen"
				>
					<Modal.Loader />
				</Modal>
			);
		}

		const phoneCode = member.phoneCountry.phoneCode;

		return (
			<Modal
				onClick={this.pop}
				className="MemberEditScreen"
			>
				<Section>
					<Container medium>
						<Wrap>
							<Modal.Close
								onClick={this.pop}
							/>

							<div
								className="MemberEditScreen__Header"
							>
								<Member
									user={member}
									large
								/>
							</div>

							<div
								className="MemberEditScreen__Footer"
							>
								<div className="MemberEditScreen__Meta">
									<Button
										type="a"
										url={`mailto:${member.email}`}
										plain
									>
										{member.email}
									</Button>

									<Button
										type="a"
										url={`tel:${phoneCode + member.phoneNumber}`}
										plain
									>
										+{phoneCode} {member.phoneNumber}
									</Button>
								</div>

								<Form
									onSubmit={this.onSubmit}
								>
									<Form.Group>
										<Input
											name="position"
											placeholder={t(M.inputs.position)}
											onChange={this.onChange}
											value={form.position}
											errors={errors.position}
											autoFocus
											disabled={(member.requestStatus !== 'GRANTED')}
										/>
									</Form.Group>

									<Form.Group>
										<InputSelect
											data={role}
											name="role"
											placeholder={t(M.inputs.access)}
											onChange={this.onChange}
											value={form.role}
											errors={errors.role}
											disabled
										/>
									</Form.Group>

									{
										member.requestStatus === 'GRANTED' &&
										<Button
											className="MemberEditScreen__Submit"
											loading={loader}
											form
											small
										>
											{t(M.buttons.save)}
										</Button>
									}
								</Form>
							</div>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
