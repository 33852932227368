import { createSelector } from 'reselect';
import { clientStatus } from 'constants/common';

export const getClientList = state => state.client.list;

export const getCurrentClientId = state => state.client.current;

const status = type => {
	switch (type) {
		case clientStatus.COMPLETE:
			return 'success';
		case clientStatus.UNSAVED:
			return 'warning';
		default:
			return 'default';
	}
};

export const nav = (state, props, M) => {
	const items = state.client.business.nav;
	const t = props.intl.formatMessage;
	return Object.keys(items).map(key => ({
		id: key,
		status: status(items[key]),
		title: t(M[key]),
	}));
};

export const industry = (props, M) => {
	const t = props.intl.formatMessage;
	return Object.keys(M).map(key => ({
		value: M[key].value,
		title: t({ id: M[key].title }),
	}));
};

export const getCurrentClient = createSelector(
	getClientList,
	getCurrentClientId,
	(items, currentClientId) =>
		items.find(item => item.id === currentClientId) || items[0]
);

export const getEntityClientId = createSelector(
	getCurrentClient,
	client => client.entityId || null
);

export const getDropdownClientList = createSelector(
	getClientList,
	clients => clients.map(client => ({
		id: client.id,
		value: client.entityId,
		title: client.name,
		subtitle: client.status,
	}))
);
