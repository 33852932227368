import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Section from 'components/Section';
import Tab from 'components/Tab';
import { card, cardStatus, paymentStatus } from 'constants/common';
import * as paymentActions from 'actions/c2cpayment';
import * as paymentSelector from 'selectors/payment';
import * as cardSelector from 'selectors/card';
import Beneficiary from './Beneficiary';
import Amount from './Amount';
import Phone from './Phone';
import Summary from './Summary';
import BlockingScreen from '../../Blocking';
import './Create.scss';

const cardStatuses = [cardStatus.OPEN, cardStatus.ISSUED];
const cards = [card.CARD01, card.CARD02, card.CARD03, card.CARD07, card.CARD08];

const getCurrent = payment => {
	if (!payment) return 0;

	switch (payment.status) {
		case paymentStatus.DRAFT: return 1;
		case paymentStatus.SIGNING: return 2;
		case paymentStatus.DECLINED:
		case paymentStatus.COMPLETED: return 3;
		default: return 0;
	}
};

const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	const payment = paymentSelector.get(state, id);

	return {
		payment,
		current: getCurrent(payment),
		currentCard: cardSelector.getById(state, state.card.current)
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends React.PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.number,
			}).isRequired,
		}).isRequired,
		current: PropTypes.number.isRequired,
		currentCard: PropTypes.shape({
			product: PropTypes.string.isRequired,
			cardStatus: PropTypes.string.isRequired,
			activated: PropTypes.bool.isRequired,
		}).isRequired,
	}

	state = {
		loader: true,
	}

	componentDidMount () {
		this.onMount();
	}

	componentDidUpdate (prevProps) {
		const { match: { params: { id } } } = this.props;
		if (prevProps.match.params.id !== id) this.onMount();
	}

	onMount = async () => {
		const { match, actions } = this.props;
		const { params: { id } } = match;
		if (!id) {
			this.setState({ loader: false });
			return;
		}
		await actions.payment.get(id);
		this.setState({ loader: false });
	}

	render () {
		const { loader } = this.state;
		const { current, currentCard } = this.props;
		const isOpen =
			cards.includes(currentCard.product) && cardStatuses.includes(currentCard.cardStatus);
		const isDepositOnly =
			currentCard.cardStatus === cardStatus.DEPOSIT_ONLY && !currentCard.activated;

		if (currentCard.cardStatus === cardStatus.EXPIRED) return null;

		if (isOpen || isDepositOnly) {
			return (
				<Layout className="PaymentCreateScreen Screen" sidebar>
					<Nav />
					<Header />
					<Section>
						<Container>
							<Wrap>
								{!loader && (
									<Tab current={current}>
										<Tab.Panel>
											<Beneficiary />
										</Tab.Panel>
										<Tab.Panel>
											<Amount />
										</Tab.Panel>
										<Tab.Panel>
											<Phone />
										</Tab.Panel>
										<Tab.Panel>
											<Summary />
										</Tab.Panel>
									</Tab>
								)}
							</Wrap>
						</Container>
					</Section>
				</Layout>
			);
		}
		return (
			<Layout className="PaymentCreateScreen Screen" sidebar>
				<Nav />
				<Header />
				<Section className="LoadSection">
					<Container>
						<Wrap>
							{!loader && <BlockingScreen />}
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
