import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Mark from './Mark';
import Wrap from './Wrap';
import './Tooltip.scss';

export default function Tooltip (props) {
	const { className, large, bottom, title, body } = props;
	return (
		<div className={classnames(
			'Tooltip', className,
			large && 'Tooltip--Large',
			bottom && 'Tooltip--Bottom')}
		>
			<div className="Tooltip__Content">
				<div className="title">{title}</div>
				{body && <div className="body">{body}</div>}
			</div>
			<div className="Tooltip__Corner" />
		</div>
	);
}

Tooltip.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	body: PropTypes.string,
	large: PropTypes.bool,
	bottom: PropTypes.bool,
};

Tooltip.Wrap = Wrap;
Tooltip.Mark = Mark;
