import login from './login';
import signup from './signup';
import reset from './reset';
import email from './email';
import password from './password';
import phone from './phone';
import activate from './activate';
import loginCreditCard from './loginCreditCard';

export default {
	activate,
	login,
	loginCreditCard,
	signup,
	reset,
	email,
	password,
	phone,
};
