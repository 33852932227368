import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Header from './Header';
import Panel from './Panel';
import './Tab.scss';

class Tab extends React.PureComponent {
	static propTypes = {
		current: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		header: PropTypes.arrayOf(PropTypes.any),
		children: PropTypes.any,
		className: PropTypes.string,
		onChange: PropTypes.func,
	}

	onChange = (item, idx) => {
		const { onChange } = this.props;
		this.setState({ current: idx });
		if (typeof onChange === 'function') onChange(item, idx);
	}

	render () {
		const { className, header, children, current } = this.props;
		const child = Array.isArray(children) ? children : [children];
		const content = child
			.filter(item => !!item)
			.map((item, idx) => React.cloneElement(item, {
				key: idx,
				current: current === idx,
			}));

		return (
			<div className={classnames('Tab', className)}>
				{header && !!header.length && (
					<Header header={header} current={current || 0} onSelect={this.onChange} />
				)}
				<div className="Tab__Content">
					{content}
				</div>
			</div>
		);
	}
}

Tab.Panel = Panel;

export default Tab;
