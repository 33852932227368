import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Group.scss';

export default function Group (props) {
	const { row, small, float, className, children } = props;
	return (
		<div className={classnames(
			'FormGroup', className,
			row && 'FormGroup--Row',
			small && 'FormGroup--Small',
			float && 'FormGroup--Float')}
		>
			{children}
		</div>
	);
}

Group.propTypes = {
	children: PropTypes.any,
	row: PropTypes.bool,
	small: PropTypes.bool,
	float: PropTypes.bool,
	className: PropTypes.string,
};
