import {
	TOAST_CREATE,
	TOAST_HIDE,
	TOAST_REMOVE,
} from 'constants';
import { toastType } from 'constants/common';

const initial = [];

const reducer = (state = initial, action) => {
	switch (action.type) {
		case TOAST_CREATE:
			return [...state, { id: action.id, type: toastType.SUCCESS, ...action.payload, hide: false }];
		case TOAST_HIDE:
			return state.map(item => {
				if (item.id !== action.id) return item;
				return { ...item, hide: true };
			});
		case TOAST_REMOVE:
			return state.filter(item => item.id !== action.id);
		default:
			return state;
	}
};

export default reducer;
