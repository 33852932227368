import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Card from 'components/Card';
import Tab from 'components/Tab';
import {
	card as commonCard,
	cardStatus,
	clientRole,
	IS_VIRTUAL_CARD_MAINTENANCE_MODE_ACTIVE,
} from "constants/common"
import * as cardActions from 'actions/card';
import * as modalsActions from 'actions/modals';
import { getById } from 'selectors/card';
import { getCurrentClient } from 'selectors/client';
import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import { cardActionsTypes, cardModalActionsTypes } from 'types/actions';
import clientTypes from 'types/client';
import { isPhysicalCard, isVirtualCard } from 'services/card';

import PINPanel from './PIN';
import ActivatePanel from './Activate';
import BlockPanel from './Block';
import GetCVV from './GetCVV';
import GetPIN from './GetPIN';
import GetPlastic from './GetPlastic';
import SuccessGetPlasticModal from './GetPlastic/ConfirmGetPlasticModal';
import { cardsUrl } from './constants';

import M from './Single.locale.json';
import './Single.scss';

const cards = [commonCard.CARD04, commonCard.CARD05, commonCard.CARD06];

const mapState = (state, props) => ({
	client: getCurrentClient(state),
	card: getById(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		modals: bindActionCreators(modalsActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
class Single extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
			modals: cardModalActionsTypes.isRequired
		}).isRequired,
		history: historyTypes.isRequired,
		match: PropTypes.object.isRequired,
		client: clientTypes.isRequired,
	};

	state = {
		loaderPage: true,
		verified: cardStatus.VERIFIED,
		isSuccessModalVisible: false,
	};

	componentDidMount () {
		this.mounted = true;
		this.onMount();
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	// eslint-disable-next-line consistent-return
	onMount = async () => {
		const { history, match, card } = this.props;
		const { tab } = match.params;
		const isVirtual = isVirtualCard(card);

		if (isVirtual &&  IS_VIRTUAL_CARD_MAINTENANCE_MODE_ACTIVE) {
			this.props.actions.modals.toggleMaintenanceModal();
			history.goBack();

			return;
		}

		this.setState({
			tab,
			...(cards.includes(card.product) ? { verified: cardStatus.UNVERIFIED } : {})
		});

		this.mounted && this.setState({ loaderPage: false });
	};

	onTab = tab => {
		const { history, match } = this.props;
		const { id } = match.params;

		history.push(`${cardsUrl}/${id}/${tab.id}`, { modal: true, replace: true });
	};

	pop = () => this.props.history.push(cardsUrl);

	togglingSuccessGetPlasticModal = () => this.setState(state => ({
		...state,
		isSuccessModalVisible: !state.isSuccessModalVisible
	}));

	mounted = false;

	render () {
		const { card, match, client, intl } = this.props;
		const { loaderPage, verified, isSuccessModalVisible } = this.state;

		if (loaderPage) {
			return (
				<Modal onClick={this.pop} className="CardSingleScreen">
					<Modal.Loader />
				</Modal>
			);
		}

		const { tab } = match.params;
		const t = intl.formatMessage;
		const header = [];
		const isVirtual = isVirtualCard(card);
		const isPhysical = isPhysicalCard(card);
		const canPIN = card.cardStatus === cardStatus.OPEN && isPhysical;
		const canBlock = card.cardStatus === cardStatus.OPEN && isPhysical;

		const canActivate = isPhysical &&
			(card.cardStatus === cardStatus.ISSUED ||
				(card.activated === false && card.cardStatus === cardStatus.DEPOSIT_ONLY)
			);

		const notBlocked = card.cardStatus !== cardStatus.BLOCKED;
		const hasAccess = client.role === clientRole.OWNER || client.role === clientRole.PRIVATE;

		if (canPIN) {
			header.push({ id: 'pin', title: t(M.tabs.pin) });
		}

		if (canActivate) {
			header.push({ id: 'activate', title: t(M.tabs.activate) });
		}

		if (notBlocked && canBlock) {
			header.push({ id: 'block', title: t(M.tabs.block) });
		}

		if (isVirtual) {
			header.push(
				{ id: 'cvv', title: t(M.tabs.cvv) },
				{ id: 'getPin', title: t(M.tabs.getPin) },
				{ id: 'getPlastic', title: t({ id: 'card.get.plastic.title' }) }
			);
		}

		let current = null;
		if (tab && header.length) current = header.findIndex(item => item.id === tab);
		else if (header.length) current = 0;

		return (
			<Modal onClick={this.pop} className="CardSingleScreen Screen">
				<Section>
					<Container medium>
						<Wrap>
							{isSuccessModalVisible && (
								<SuccessGetPlasticModal t={t} onSkip={this.togglingSuccessGetPlasticModal} />
							)}
							<Modal.Close onClick={this.pop} />
							<div className="CardSingleScreen__Inner">
								<div className="CardSingleScreen__Header">
									<Card card={card} design shadow trunk showCardStatus={false} />
									<div className='CardSingleScreen__StatusContainer'>
										<Card.Status
											className="CardSingleScreen__Status"
											status={card.cardStatus}
											trunk={false}
										/>
										<Card.Status
											className="CardSingleScreen__Status"
											status={card.deliveryType}
											trunk={false}
										/>
									</div>
									<Card.Status
										className="CardSingleScreen__Verified"
										status={verified}
										trunk={false}
									/>
								</div>
								{hasAccess && notBlocked && (
									<Tab header={header} current={current} onChange={this.onTab}>
										{canPIN && (
											<Tab.Panel>
												<PINPanel />
											</Tab.Panel>
										)}
										{canActivate && (
											<Tab.Panel>
												<ActivatePanel />
											</Tab.Panel>
										)}
										{notBlocked && canBlock && (
											<Tab.Panel>
												<BlockPanel />
											</Tab.Panel>
										)}
										{isVirtual && (
											<Tab.Panel>
												<GetCVV />
											</Tab.Panel>
										)}
										{isVirtual && (
											<Tab.Panel>
												<GetPIN />
											</Tab.Panel>
										)}
										{isVirtual && (
											<Tab.Panel>
												<GetPlastic toggleSuccessModal={this.togglingSuccessGetPlasticModal} />
											</Tab.Panel>
										)}
									</Tab>
								)}
							</div>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}

export default Single;
