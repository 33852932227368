import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../Button';
import Calendar from '../Calendar';
import './ButtonDate.scss';

export default class ButtonDate extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
		disabledDate: PropTypes.instanceOf(Date),
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
		children: PropTypes.node.isRequired,
		range: PropTypes.object,
		month: PropTypes.number,
		year: PropTypes.number,
	};

	constructor (props) {
		super(props);

		this.state = {
			range: props.range || null,
			month: props.month || null,
			year: props.year || null,
		};
	}

	state = {
		isOpened: false
	};

	onMonth = date => {
		const { month, year } = date;
		this.setState({ month, year });
	};

	onSelect = range => {
		this.closeCalendar();
		const { name, onChange } = this.props;
		const date = range.getDate();
		const month = range.getMonth() + 1;
		const year = range.getFullYear();
		this.setState({ range });
		onChange(`${date}/${month}/${year}`, name);
	};

	onClick = () => this.button.focus();

	openCalendar = () => {
		this.setState({ isOpened: true });
	};

	closeCalendar = () => {
		this.setState({ isOpened: false });
	};

	render () {
		const { range, year, month, isOpened } = this.state;
		const { className, disabledDate, children, disabled, onBlur, onFocus } = this.props;

		return (
			<div className={cn('ButtonDate', className)}>
				<Button
					className="ButtonDate__Button"
					onFocus={onFocus}
					onBlur={onBlur}
					disabled={disabled}
					onRef={ref => { this.button = ref; }}
					plain
					onClick={this.openCalendar}
				>
					{children}
				</Button>

				<div className={cn('ButtonDate__Calendar', { 'ButtonDate__Calendar--visible': isOpened })}>
					<Calendar
						range={range}
						year={year}
						month={month}
						onChange={this.onMonth}
						onSelect={this.onSelect}
						onClose={this.closeCalendar}
						disabled={disabledDate}
					/>
				</div>
			</div>
		);
	}
}
