import axios from 'axios';
import fetcher from 'helpers/fetcher';

export default {
	get (locale) {
		return axios.get(`/locales/${locale}.json`);
	},
	edit (locale) {
		return fetcher.put('user/language', {}, {
			params: {
				language: locale,
			}
		});
	},
};
