import {
	TOAST_CREATE,
	TOAST_HIDE,
	TOAST_REMOVE,
} from 'constants';
import { toastType } from 'constants/common';

const DURATION = 5000;
const ANIMATION = 600;

export const create = payload => dispatch => {
	const id = new Date().getTime();
	dispatch({ type: TOAST_CREATE, id, payload });
	setTimeout(() => dispatch({ type: TOAST_HIDE, id }), DURATION - ANIMATION);
	setTimeout(() => dispatch({ type: TOAST_REMOVE, id }), DURATION);
};

export const success = dispatch => payload => {
	const id = new Date().getTime();
	dispatch({ type: TOAST_CREATE, id, payload });
	setTimeout(() => dispatch({ type: TOAST_HIDE, id }), DURATION - ANIMATION);
	setTimeout(() => dispatch({ type: TOAST_REMOVE, id }), DURATION);
};

export const error = dispatch => payload => {
	const id = new Date().getTime();
	dispatch({ type: TOAST_CREATE, id, payload: { ...payload, type: toastType.ERROR } });
	setTimeout(() => dispatch({ type: TOAST_HIDE, id }), DURATION - ANIMATION);
	setTimeout(() => dispatch({ type: TOAST_REMOVE, id }), DURATION);
};

export const remove = id => dispatch => {
	dispatch({ type: TOAST_HIDE, id });
	setTimeout(() => dispatch({ type: TOAST_REMOVE, id }), ANIMATION);
};
