import PropTypes from 'prop-types';
import { clientStatuses, clientRoles, clientRoleTypes } from 'constants/common';

export default PropTypes.shape({
	id: PropTypes.number,
	entityId: PropTypes.number,
	name: PropTypes.string,
	roleType: PropTypes.oneOf(clientRoleTypes),
	role: PropTypes.oneOf(clientRoles),
	status: PropTypes.oneOf(clientStatuses),
});
