import geocoder from './geocoder';
import fetcher from './fetcher';
import validator from './validator';
import formatter from './formatter';
import handler from './handler';
import downloader from './downloader';
import renamer from './renamer';
import reduxer from './reduxer';
import tracker from './tracker';
import copyToClipBoard from './copyToClipboard';
import formFocusErrors from './form-focus-errors';

export default {
	geocoder,
	fetcher,
	validator,
	formatter,
	handler,
	downloader,
	renamer,
	reduxer,
	tracker,
	copyToClipBoard,
	formFocusErrors
};
