import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as clientSelector from 'selectors/client';
import * as clientActions from 'actions/client';
import Icon from '../Icon';
import Dropdown from '../Dropdown';
import './Client.scss';

const mapState = state => ({
	client: clientSelector.getCurrentClient(state),
	clientList: clientSelector.getDropdownClientList(state),
});

const mapDispatch = dispatch => ({
	actions: {
		client: bindActionCreators(clientActions, dispatch),
	},
});

@connect(mapState, mapDispatch)
@enhanceWithClickOutside
export default class Client extends React.PureComponent {
	static propTypes = {
		client: PropTypes.shape({
			name: PropTypes.string,
		}),
		clientList: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
				]),
				title: PropTypes.string,
				subtitle: PropTypes.string,
			})
		),
		className: PropTypes.string,
		actions: PropTypes.shape({
			client: PropTypes.shape({
				current: PropTypes.func,
			})
		}),
	}

	state = {
		open: false,
	}

	onSelect = ({ id }) => {
		const { actions } = this.props;
		actions.client.current(id);
		this.setState({ open: false });
	}

	onDropdown = () => {
		const { open } = this.state;
		this.setState({ open: !open });
	}

	handleClickOutside = () => {
		this.setState({
			open: false,
		});
	}

	render () {
		const { open } = this.state;
		const { client, clientList, className } = this.props;
		if (!client) return null;

		return (
			<div className={classnames('Client', className)}>
				<div
					onClick={this.onDropdown}
					role="button"
					tabIndex={0}
					className="Client__Wrap"
				>
					<div className="Client__Name">{client.name}</div>
					<div
						className="Client__Toggle"
						onMouseDown={this.onPrevent}
						onClick={this.onToggle}
						role="button"
						tabIndex={0}
					>
						<Icon className="Client__Icon" name="caveat-down" />
					</div>
				</div>

				<Dropdown
					className="Client__Dropdown"
					data={clientList}
					onClick={this.onSelect}
					open={open}
				/>
			</div>
		);
	}
}
