import React from 'react';

import Icon from './Icon';
import PaymentResultPage from '../../PaymentResultPage';

const LINK_URL = '/international-payment/create';

const Success = () => {
	return (
		<PaymentResultPage titleId="pages.payments.pfs.error.title" linkId="buttons.back" linkUrl={LINK_URL} Icon={Icon} />
	);
};

export default Success;
