import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import './Status.scss';

const classes = {
	success: 'Status--Success',
	warning: 'Status--Warning',
	error: 'Status--Error',
	info: 'Status--Info',
};

const icons = {
	success: <Icon name="success" />,
	warning: <Icon name="warning" />,
	error: <Icon name="error" />,
	info: <Icon name="info" />,
};

export default function Status (props) {
	const { className, type } = props;
	return (
		<div className={classnames('Status', className, classes[type])}>
			{icons[type]}
		</div>
	);
}

Status.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
};
