import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';
import Banner from 'components/Banner';
import validator from 'helpers/validator';
import * as paymentActions from 'actions/internationalPayment';
import * as paymentSelector from 'selectors/payment';
import InternationalPaymentSchema from 'schemas/internationalPayment';
import M from './Amount.locale.json';

import intlTypes from 'types/intl';
import paymentTypes from 'types/payment';

function Amount (props) {
	const [loader, setLoader] = useState(false);
	const [form, setForm] = useState({ amount: '' });
	const [messages, setMessages] = useState([]);
	const [errors, setErrors] = useState({ amount: [] });

	const onChange = (value, name) => {
		const { amount } = props.payment;
		const mess = errors[name].length
			? validator.single({ ...form, [name]: value }, name, InternationalPaymentSchema.amount)
			: [];
		setForm(prevForm => ({ ...prevForm, original: amount, [name]: value }));
		setErrors(prevErrors => ({ ...prevErrors, [name]: mess }));
		setLoader(false);
	};

	const onSubmit = async e => {
		e.preventDefault();
		if (loader) return;
		const { actions, payment: { id } } = props;
		const errs = validator.all(form, InternationalPaymentSchema.amount);

		if (errs) {
			setErrors(prevErrors => ({ ...prevErrors, ...errs }));
			return;
		}

		setLoader(true);
		const response = await actions.payment.amount(id, form);
		setLoader(false);
		setErrors(prevErrors => ({ ...prevErrors, ...response.errors }));

		if (response && response.errors && response.messages) {
			setMessages(response.messages);
		}
	};

	const { intl, payment } = props;
	const t = intl.formatMessage;

	if (!payment) return null;

	return (
		<div>
			<Title title={t(M.title)} className="InternationalPaymentCreateScreen__Title" />
			{messages && messages.length > 0 && <Banner type="error" data={messages} />}
			<Form.Group>
				<Input
					name="beneficiary"
					placeholder={t(M.inputs.beneficiary)}
					value={payment.beneficiary}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="currency"
					placeholder={t(M.inputs.currency)}
					value={payment.currency}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="amount-amount"
					placeholder={t(M.inputs.amount)}
					value={payment.amount}
					unit={payment.currency}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="creditorCountryCode"
					placeholder={t(M.inputs.creditorCountryCode)}
					value={payment.creditorCountryCode}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="city"
					placeholder={t(M.inputs.city)}
					value={payment.city}
					disabled
				/>
			</Form.Group>
			{payment.state && (
				<Form.Group>
					<Input
						name="state"
						placeholder={t(M.inputs.state)}
						value={payment.state}
						disabled
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Input
					name="creditorAddress1"
					placeholder={t(M.inputs.creditorAddress1)}
					value={payment.creditorAddress1}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="creditorAddress2"
					placeholder={t(M.inputs.creditorAddress2)}
					value={payment.creditorAddress2}
					disabled
				/>
			</Form.Group>
			{payment.postCode && (
				<Form.Group>
					<Input
						name="postCode"
						placeholder={t(M.inputs.postCode)}
						value={payment.postCode}
						disabled
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Input
					name="iban"
					placeholder={t(M.inputs.iban)}
					value={payment.iban}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="bic"
					placeholder={t(M.inputs.bic)}
					value={payment.bic}
					disabled
				/>
			</Form.Group>
			{payment.details &&
				<Form.Group>
					<Input
						name="details"
						placeholder={t(M.inputs.details)}
						value={payment.details}
						disabled
					/>
				</Form.Group>
			}
			<br />
			<Form.Group>
				<Input
					name="predictedFee"
					placeholder={t(M.inputs.fee)}
					unit={payment.currency}
					value={payment.predictedFee}
					disabled
				/>
			</Form.Group>
			<br />
			<Form onSubmit={onSubmit}>
				<Form.Group>
					<Input
						name="amount"
						placeholder={t(M.inputs.amount)}
						onChange={onChange}
						value={form.amount}
						errors={errors.amount}
					/>
				</Form.Group>
				<Button
					className="PaymentCreateScreen__Submit"
					loading={loader}
					small
					form
				>
					{t(M.buttons.next)}
				</Button>
			</Form>
		</div>
	);
}

Amount.propTypes = {
	intl: intlTypes.isRequired,
	payment: paymentTypes,
};

const mapState = (state, props) => {
	const { match: { params: { id } } } = props;
	return {
		payment: paymentSelector.get(state, id),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

export default compose(
	injectIntl,
	withRouter,
	connect(mapState, mapDispatch),
)(Amount);
