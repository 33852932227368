import React, { useEffect, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import Title from 'components/Title';
import Banner from 'components/Banner';
import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';
import * as paymentActions from 'actions/internationalPayment';
import * as currencyActions from 'actions/currency';
import InternationalPaymentSchema from 'schemas/internationalPayment';
import helpers from 'helpers';
import { internationalPaymentCreate } from 'constants/paths';
import { defaultFormData, defaultErrorsData, controls, exceptedCountries } from './constants';
import { prepareCurrencies, prepareCountries } from './utils';
import M from './Beneficiary.locale.json';

import intlTypes from 'types/intl';
import countryTypes from 'types/country';
import currencyTypes from 'types/currency';
import { currencyActionsTypes } from 'types/actions';

function Beneficiary (props) {
	const [loader, setLoader] = useState(false);
	const [calculateAmountLoader, setCalculateAmountLoader] = useState(false);
	const [form, setForm] = useState(defaultFormData);
	const [errors, setErrors] = useState(defaultErrorsData);
	useEffect(() => {
		const onFetch = async () => {
			await props.actions.currency.fetch();
		};
		onFetch();
	}, []);
	useEffect(() => {
		const { payment } = props.location;
		if (typeof payment !== 'undefined') {
			setForm(prevForm => ({
				...prevForm,
				iban: payment.iban,
				bic: payment.bic,
				amount: payment.amount,
				beneficiary: payment.beneficiary,
				currency: payment.currency,
				creditorCountryCode: payment.creditorCountryCode,
				city: payment.city,
				creditorAddress1: payment.creditorAddress1,
				creditorAddress2: payment.creditorAddress2,
				postCode: payment.postCode,
				details: payment.details,
			}));
		}
	}, [props.location.payment]);
	const memoizedCurrenciesCallback = useCallback(
		() => prepareCurrencies(props.currencies),
		[props.currencies],
	);
	const memoizedCountriesCallback = useCallback(
		() => prepareCountries(props.countries),
		[props.countries],
	);

	// eslint-disable-next-line no-return-await
	const onConvertCurrency = async params => await props.actions.currency.convert(params);

	const onLostFocus = async (value, name) => {
		if ([controls.currency, controls.amount].includes(name)
			&& form.amount.length && form.currency.length
			&& !errors[controls.currency].length && !errors[controls.amount].length) {
			const params = {
				amount: form.amount,
				currencyFrom: form.currency,
				currencyTo: 'EUR'
			};
			setCalculateAmountLoader(true);
			const debitAmount = await onConvertCurrency(params);
			setCalculateAmountLoader(false);
			setForm(prevForm => ({ ...prevForm, debitAmount }));
		}
	};

	const onChange = (value, name) => {
		let messages = [];
		let convert = value;

		if (errors[name].length) {
			messages = helpers.validator.single(
				{ ...form, [name]: value }, name, InternationalPaymentSchema.create);
		}

		if (name === controls.bic || name === controls.iban) convert = value.toUpperCase();

		setForm(prevForm => ({ ...prevForm, [name]: convert }));
		setErrors(prevErrors => ({ ...prevErrors, [name]: messages }));
		setLoader(false);
	};

	const onSubmit = async e => {
		e.preventDefault();
		if (loader) return;
		const { actions, history } = props;
		const errs = helpers.validator.all(form, InternationalPaymentSchema.create);

		if (errs) {
			setErrors(prevErrors => ({ ...prevErrors, ...errs }));
			return;
		}
		setLoader(true);
		const response = await actions.payment.create(form);
		setLoader(false);
		setErrors(prevErrors => ({ ...prevErrors, ...response.errors }));

		if (response && response.errors && response.messages) return;

		setForm(defaultFormData);
		history.push(`${internationalPaymentCreate}/${response.id}`);
	};

	const countries = memoizedCountriesCallback();
	const currencies = memoizedCurrenciesCallback();
	const { intl } = props;
	const t = intl.formatMessage;
	const isExceptedState = exceptedCountries.includes(form.creditorCountryCode);

	return (
		<div>
			<Title title={t(M.title)} className="InternationalPaymentCreateScreen__Title" />
			<Form onSubmit={onSubmit}>
				<Form.Group>
					<Input
						name="beneficiary"
						placeholder={t(M.inputs.beneficiary)}
						onChange={onChange}
						value={form.beneficiary}
						errors={errors.beneficiary}
						autoFocus
					/>
					<Form.Note>{t(M.prompts.beneficiary)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<InputSelect
						name="currency"
						placeholder={t(M.inputs.currency)}
						onChange={onChange}
						onBlur={onLostFocus}
						value={form.currency}
						errors={errors.currency}
						data={currencies}
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="amount"
						placeholder={t(M.inputs.amount)}
						onChange={onChange}
						onBlur={onLostFocus}
						value={form.amount}
						unit={form.currency || 'EUR'}
						errors={errors.amount}
					/>
				</Form.Group>
				<Form.Note>{t(M.prompts.equivalent)} EUR:{' '}
					{calculateAmountLoader && (
						<span className="AmountLoaderContainer">
							<Loader className="AmountLoaderContainer__Loader" />
						</span>
					)}
					{!calculateAmountLoader && (form.debitAmount || 0)}
				</Form.Note>
				<Form.Note>{t(M.prompts.beneficiaryAddress)}</Form.Note>
				<Form.Group>
					<InputSelect
						name="creditorCountryCode"
						placeholder={t(M.inputs.creditorCountryCode)}
						onChange={onChange}
						value={form.creditorCountryCode}
						errors={errors.creditorCountryCode}
						data={countries}
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="city"
						placeholder={t(M.inputs.city)}
						onChange={onChange}
						value={form.city}
						errors={errors.city}
					/>
					<Form.Note>{t(M.prompts.base)}</Form.Note>
				</Form.Group>
				{isExceptedState && (
					<Form.Group>
						<Input
							name="state"
							placeholder={t(M.inputs.state)}
							onChange={onChange}
							value={form.state}
							errors={errors.state}
						/>
						<Form.Note>{t(M.prompts.base)}</Form.Note>
					</Form.Group>
				)}
				<Form.Group>
					<Input
						name="creditorAddress1"
						placeholder={t(M.inputs.creditorAddress1)}
						onChange={onChange}
						value={form.creditorAddress1}
						errors={errors.creditorAddress1}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.address1)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.address)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="creditorAddress2"
						placeholder={t(M.inputs.creditorAddress2)}
						onChange={onChange}
						value={form.creditorAddress2}
						errors={errors.creditorAddress2}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.address2)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.address)}</Form.Note>
				</Form.Group>
				{isExceptedState && (
					<Form.Group>
						<Input
							name="postCode"
							placeholder={t(M.inputs.postCode)}
							onChange={onChange}
							value={form.postCode}
							errors={errors.postCode}
							autoFocus
						/>
						<Form.Note>{t(M.prompts.postCode)}</Form.Note>
					</Form.Group>
				)}
				<Form.Group>
					<Input
						name="iban"
						placeholder={t(M.inputs.iban)}
						onChange={onChange}
						value={form.iban}
						errors={errors.iban}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.iban)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.iban)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="bic"
						placeholder={t(M.inputs.bic)}
						onChange={onChange}
						value={form.bic}
						errors={errors.bic}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.bic)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.bic)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="details"
						placeholder={t(M.inputs.details)}
						onChange={onChange}
						value={form.details}
						errors={errors.details}
					/>
					<Form.Note>{t(M.prompts.base)}</Form.Note>
				</Form.Group>
				<Banner type="info" data={[M.notify]} />
				<Button
					className="InternationalPaymentCreateScreen__Submit"
					loading={loader}
					small
					form
				>
					{t(M.buttons.next)}
				</Button>
			</Form>
		</div>
	);
}

Beneficiary.propTypes = {
	intl: intlTypes.isRequired,
	location: PropTypes.shape({
		payment: PropTypes.string,
	}),
	actions: PropTypes.shape({
		currency: currencyActionsTypes.isRequired,
	}).isRequired,
	currencies: PropTypes.arrayOf(currencyTypes).isRequired,
	countries: PropTypes.arrayOf(countryTypes).isRequired,
};

const mapState = state => ({
	currencies: state.currency,
	countries: state.country,
});

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
		currency: bindActionCreators(currencyActions, dispatch),
	},
});

export default compose(
	injectIntl,
	withRouter,
	connect(mapState, mapDispatch),
)(Beneficiary);
