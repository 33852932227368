import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import * as transactionActions from 'actions/transaction';

import helpers from 'helpers';

import HistorySchema from 'schemas/history';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Title from 'components/Title';
import Label from 'components/Label';
import InputSelect from 'components/InputSelect';
import InputRadio from 'components/InputRadio';
import InputDate from 'components/InputDate';
import Button from 'components/Button';

import M from './Export.locale.json';

import './Export.scss';



const mapState = (state, props) => ({
});

const mapDispatch = dispatch => ({
	actions: {
		transaction: bindActionCreators(transactionActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,

		form: {
			from: '',
			till: '',
			type: 'PDF',
		},

		errors: {
			from: [],
			till: [],
			type: [],
		}
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, HistorySchema.export);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	componentDidMount () {
		this.onMount();
	}

    onMount = () => {
    	const { start, end } = this.props.location;

    	this.setState({
    		form: {
    			from: moment(start, 'DD-MM-YYYY').format('D/M/YYYY'),
    			till: moment(end, 'DD-MM-YYYY').format('D/M/YYYY'),
    		},
    	});
    }


	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions } = this.props;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, HistorySchema.export);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		let response;

		// works for both PDF and CSV
		const convert = helpers.renamer.to.transaction.pdf({
			...form,
			type: 'ALL',
			size: 2000,
			page: 1,
		});

		if (form.type === 'PDF') response = await actions.transaction.pdf(convert);
		else response = await actions.transaction.csv(convert);

		if (response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		const name = `transactions-${convert.startCompact}-${convert.endCompact}.${form.type.toLowerCase()}`;

		helpers.downloader.file(name, 'pdf', response);

		this.setState({
			loader: false,
		});
	}

	pop = () => {
		const { history } = this.props;

		history.goBack();
	}

	render () {
		const { form, errors, loader } = this.state;

		const t = this.props.intl.formatMessage;

		return (
			<Modal
				onClick={this.pop}
				className="HistoryExportScreen Screen"
			>
				<Section>
					<Container medium>
						<Wrap>
							<Modal.Close
								onClick={this.pop}
							/>

							<Title
								title={t(M.title)}
								small
							/>

							<Form
								onSubmit={this.onSubmit}
							>
								<Form.Group>
									<InputDate
										name="from"
										placeholder={t(M.inputs.from)}
										onChange={this.onChange}
										value={form.from}
										errors={errors.from}
										disabledDate={new Date()}
									/>
								</Form.Group>

								<Form.Group>
									<InputDate
										name="till"
										placeholder={t(M.inputs.till)}
										onChange={this.onChange}
										value={form.till}
										errors={errors.till}
										disabledDate={new Date()}
									/>
								</Form.Group>

								<Form.Group
									row
								>
									<Label>{t(M.inputs.type)}</Label>

									<InputRadio
										id="type1"
										name="type"
										value="PDF"
										checked={form.type === 'PDF'}
										onChange={this.onChange}
										errors={errors.type}
									>
										PDF
									</InputRadio>

									<InputRadio
										id="type2"
										name="type"
										value="CSV"
										checked={form.type === 'CSV'}
										onChange={this.onChange}
										errors={errors.type}

									>
										CSV
									</InputRadio>

									<InputRadio.Error
										errors={errors.type}
									/>
								</Form.Group>

								<Button
									loading={loader}
									form
								>
									{t(M.buttons.download)}
								</Button>
							</Form>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
