export default function location (form) {
	const convert = {
		registeredAddress: {
			line1: form.regAddress,
			country: form.regCountry,
			postalCode: form.regPostalCode,
			city: form.regCity,
		},
	};

	if (form.identical) {
		convert.principalAddress = {
			line1: form.regAddress,
			country: form.regCountry,
			postalCode: form.regPostalCode,
			city: form.regCity,
		};
	}
	if (!form.identical) {
		convert.principalAddress = {
			line1: form.address,
			country: form.country,
			postalCode: form.postalCode,
			city: form.city,
		};
	}
	convert.website = form.website;
	convert.registeredAddress.country = 120;
	return convert;
}
