import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import M from './PasswordPolicy.locale.json';
import './PasswordPolicy.scss';

import intlTypes from 'types/intl';

const LENGTH_MIN = 8;
const LENGTH_MAX = 20;

function PasswordPolicy (props) {
	const { className, intl, value } = props;
	const t = intl.formatMessage;

	const hasNumber = /[0-9]/.test(value);
	const hasUppercase = /[A-Z]/.test(value);
	const hasLength = value.length >= LENGTH_MIN;
	const errorSymbol = /[^a-zA-Z0-9@,-.#!%*&~$^()";:_'+?<{|}\/=>\\`\[\]]/.test(value);
	const errorLength = value.length > LENGTH_MAX;

	return (
		<div className={classnames('PasswordPolicy', className)}>
			<ul>
				<li className={(hasNumber ? 'PasswordPolicy__Item--Valid' : '')}>
					<Icon className="PasswordPolicy__Icon Icon" name={(hasNumber ? 'success' : 'warning')} />
					<div className="PasswordPolicy__Title">
						{t(M.conditions.contains.numbers)}
					</div>
				</li>
				<li className={(hasUppercase ? 'PasswordPolicy__Item--Valid' : '')}>
					<Icon className="PasswordPolicy__Icon Icon" name={(hasUppercase ? 'success' : 'warning')} />
					<div className="PasswordPolicy__Title">
						{t(M.conditions.contains.letters)}
					</div>
				</li>
				<li className={(hasLength ? 'PasswordPolicy__Item--Valid' : '')}>
					<Icon className="PasswordPolicy__Icon Icon" name={(hasLength ? 'success' : 'warning')} />
					<div className="PasswordPolicy__Title">
						{t(M.conditions.length.min, { length: LENGTH_MIN })}
					</div>
				</li>

				{errorSymbol && (
					<li className="PasswordPolicy__Item--Invalid">
						<Icon className="PasswordPolicy__Icon Icon" name="error" />
						<div className="PasswordPolicy__Title">
							{t(M.conditions.excludes)}
						</div>
					</li>
				)}

				{errorLength && (
					<li className="PasswordPolicy__Item--Invalid">
						<Icon className="PasswordPolicy__Icon Icon" name="error" />
						<div className="PasswordPolicy__Title">
							{t(M.conditions.length.max, { length: LENGTH_MAX })}
						</div>
					</li>
				)}
			</ul>
		</div>
	);
}

PasswordPolicy.propTypes = {
	value: PropTypes.string,
	className: PropTypes.string,
	intl: intlTypes.isRequired,
};

export default injectIntl(PasswordPolicy);
