const create = user => {
	const convert = {
		...user,
	};

	if (convert.name) {
		convert.firstName = user.name;
		delete convert.name;
	}
	if (convert.surname) {
		convert.lastName = user.surname;
		delete convert.surname;
	}
	return convert;
};

export default {
	create,
};
