import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as paymentActions from 'actions/payment';

import * as paymentSelector from 'selectors/payment';

import PaymentSchema from 'schemas/payment';

import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';

import M from './Beneficiary.locale.json';



const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	return {
		payment: paymentSelector.get(state, id),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,

		form: {
			beneficiary: '',
			iban: '',
			bic: '',
			amount: '',
			details: '',
		},

		errors: {
			beneficiary: [],
			iban: [],
			bic: [],
			amount: [],
			details: []
		}
	}

	componentDidUpdate (prevProps) {
		this.onUpdate(prevProps);
	}

	onUpdate (prevProps) {
		const { payment } = this.props.location;

		if (typeof payment === 'undefined' ||
		prevProps.location.payment === payment) return;

		this.setState({
			form: {
				...this.state.form,
				iban: payment.iban,
				bic: payment.bic,
				amount: payment.amount,
				beneficiary: payment.beneficiary,
				details: payment.details,
			}
		});
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, PaymentSchema.create);
		}

		let convert = value;

		if (name === 'bic' || name === 'iban') convert = value.toUpperCase();

		this.setState({
			form: {
				...form,
				[name]: convert,
			},
			errors: {
				...errors,
				[name]: messages,
			},
			loader: false,
		});
	}

	onSubmit = async e => {
		e.preventDefault();

		const { form, loader } = this.state;
		const { actions, history } = this.props;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, PaymentSchema.create);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}

		this.setState({
			loader: true,
		});

		// hard coded for now
		form.currency = 'EUR';

		const response = await actions.payment.create(form);

		const update = {
			errors: {
				...this.state.errors,
				...response.errors,
			},
			loader: false,
		};

		if ((response && response.errors) && response.messages) {
			update.messages = response.messages;

			this.setState(update);

			return;
		}

		// clean
		update.form = {
			beneficiary: '',
			iban: '',
			bic: '',
			amount: '',
			details: '',
		};

		this.setState(update);

		history.push(`/payments/create/${response.id}`);
	}

	render () {
		const { payment } = this.props;
		const { form, errors, loader } = this.state;

		const t = this.props.intl.formatMessage;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>

				<Form
					onSubmit={this.onSubmit}
				>
					<Form.Group>
						<Input
							name="beneficiary"
							placeholder={t(M.inputs.beneficiary)}
							onChange={this.onChange}
							value={form.beneficiary}
							errors={errors.beneficiary}
							autoFocus
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="iban"
							placeholder={t(M.inputs.iban)}
							onChange={this.onChange}
							value={form.iban}
							errors={errors.iban}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="bic"
							placeholder={t(M.inputs.bic)}
							onChange={this.onChange}
							value={form.bic}
							errors={errors.bic}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="amount"
							placeholder={t(M.inputs.amount)}
							onChange={this.onChange}
							value={form.amount}
							unit="EUR"
							errors={errors.amount}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="details"
							placeholder={t(M.inputs.details)}
							onChange={this.onChange}
							value={form.details}
							errors={errors.details}
						/>
					</Form.Group>

					<Button
						className="PaymentCreateScreen__Submit"
						loading={loader}
						small
						form
					>
						{t(M.buttons.next)}
					</Button>
				</Form>
			</div>
		);
	}
}
