/**
 * Created by artis on 07/11/2017.
 */

export default {
	list () {
		return [
			{ value: 0, title: 'screens.client.create.business.basic.industries.agriculture-forestry-and-fiashing' },
			{ value: 0, title: 'screens.client.create.business.basic.industries.agriculture-forestry-and-fiashing' },
			{ value: 1, title: 'screens.client.create.business.basic.industries.mining-and-quarrying' },
			{ value: 2, title: 'screens.client.create.business.basic.industries.manufacturing' },
			{ value: 3, title: 'screens.client.create.business.basic.industries.electricity-gas-steam-and-air-conditioning-supply' },
			{ value: 4, title: 'screens.client.create.business.basic.industries.water-supply' },
			{ value: 5, title: 'screens.client.create.business.basic.industries.sewerage-waste-management-and-remediation-activities' },
			{ value: 6, title: 'screens.client.create.business.basic.industries.construction' },
			{ value: 7, title: 'screens.client.create.business.basic.industries.wholesale-and-retail-trade' },
			{ value: 8, title: 'screens.client.create.business.basic.industries.repair-of-motor-vehicles-and-motorcycles' },
			{ value: 9, title: 'screens.client.create.business.basic.industries.transportation-and-storage' },
			{ value: 10, title: 'screens.client.create.business.basic.industries.accommodation-and-food-service-activities' },
			{ value: 11, title: 'screens.client.create.business.basic.industries.information-and-communication' },
			{ value: 12, title: 'screens.client.create.business.basic.industries.financial-and-insurance-activities' },
			{ value: 13, title: 'screens.client.create.business.basic.industries.real-estate-activities' },
			{ value: 14, title: 'screens.client.create.business.basic.industries.professional-scientific-and-technical-activities' },
			{ value: 15, title: 'screens.client.create.business.basic.industries.administrative-and-support-service-activities' },
			{ value: 16, title: 'screens.client.create.business.basic.industries.public-administration-and-defence' },
			{ value: 17, title: 'screens.client.create.business.basic.industries.compulsory-social-security' },
			{ value: 18, title: 'screens.client.create.business.basic.industries.education' },
			{ value: 19, title: 'screens.client.create.business.basic.industries.human-health-and-social-work-activities' },
			{ value: 20, title: 'screens.client.create.business.basic.industries.arts-entertainment-and-recreation' },
			{ value: 21, title: 'screens.client.create.business.basic.industries.other-service-activities' },
			{ value: 22, title: 'screens.client.create.business.basic.industries.activities-of-households-as-employers' },
			{ value: 23, title: 'screens.client.create.business.basic.industries.activities-of-extraterritorial-organisations-and-bodies' },
			{ value: 24, title: 'screens.client.create.business.basic.industries.other' },
		];
	}
};
