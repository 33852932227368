import fetcher from 'helpers/fetcher';

export default {
	get (id, cid, bid) {
		return fetcher.get(`${bid ? `business/${bid}` : `private`}/payment/${cid}/international/${id}`);
	},
	create (cid, form, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/international/create`, form);
	},
	amount (id, cid, params, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/international/confirm/${id}`, {}, { params });
	},
	phone (id, cid, params, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/international/sign/${id}`, {}, { params });
	},
	code (id, cid, bid) {
		return fetcher.post(`${bid ? `business/${bid}` : `private`}/payment/${cid}/international/${id}/resend-token`);
	},
};
