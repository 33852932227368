import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Section from 'components/Section';
import Wrap from 'components/Wrap';
import Banner from 'components/Banner';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import * as authActions from 'actions/auth';
import * as toastActions from 'actions/toast';
import helpers from 'helpers';
import Auth from 'schemas/auth';
import M from './Reset.locale.json';
import './Reset.scss';

import intlTypes from 'types/intl';

const mapDispatch = dispatch => ({
	actions: {
		toast: bindActionCreators(toastActions, dispatch),
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@connect(null, mapDispatch)
export default class Reset extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		actions: PropTypes.shape({
			toast: PropTypes.shape({
				create: PropTypes.func.isRequired,
			}).isRequired,
			auth: PropTypes.shape({
				reset: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
	}

	state = {
		loader: false,
		messages: [],
		form: { email: '' },
		errors: { email: [] },
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;
		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.reset);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { intl, actions } = this.props;
		const { form, loader } = this.state;

		if (loader) return;

		const errors = helpers.validator.all(form, Auth.reset);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ messages: [], loader: true });

		const response = await actions.auth.reset(form);
		const t = intl.formatMessage;

		if (response) this.handleErrors(response);

		this.setState({
			messages: [{
				message: t(M.messages.success),
				type: 'success',
				id: 'screens.auth.reset.messages.success'
			}],
			loader: false,
			form: { email: '' },
		});
	}

	handleErrors = ({ errors, messages }) => {
		const { actions } = this.props;
		const update = {
			errors: { ...this.state.errors, ...errors },
			loader: false,
		};
		this.setState(update);

		if (messages && !messages.length) {
			return actions.toast.create({ title: t(M.messages.error), type: 'error' });
		}

		return actions.toast.create({
			title: t(M.messages.error),
			body: response.messages[0].message,
			type: 'error',
		});
	}

	render () {
		const { form, loader, errors, messages } = this.state;
		const t = this.props.intl.formatMessage;

		return (
			<Layout className="AuthResetScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Wrap>
							<Title title={t(M.title)} />
							{messages && !!messages.length && <Banner data={messages} type="success" />}
							<Form onSubmit={this.onSubmit}>
								<Form.Group>
									<Input
										name="email"
										placeholder={t(M.inputs.email)}
										onChange={this.onChange}
										value={form.email}
										errors={errors.email}
										autoFocus
									/>
								</Form.Group>
								<Button form loading={loader}>{t(M.buttons.reset)}</Button>
							</Form>
							<Form.Note>
								{`${t(M.messages.login)} `}
								<ButtonLink to="/auth/login">{t(M.buttons.login)}</ButtonLink>
							</Form.Note>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
