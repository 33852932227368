export default {
	stepone: {
		phone: {
			presence: { message: '^schemas.presence' },
			numericality: {
				message: 'can only contain numbers',
			},
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
			phonenumber: {
				message: '^schemas.phone.phonenumber'
			}
		},
		phoneCountry: {
			presence: { message: '^schemas.presence' },
		},
	},

	steptwo: {
		token: {
			presence: { message: '^schemas.presence' },
		}
	}
};
