export const prepareCurrencies = currencies => currencies.reduce((state, currency) => {
	if (currency.outgoing) {
		state.push({
			title: `${currency.name} (${currency.code})`,
			value: currency.code,
		});
	}
	return state;
}, []);

export const prepareCountries = countries => countries.reduce((state, country) => {
	if (country.priority) {
		state.push({
			title: `${country.name} (${country.iso2})`,
			value: country.iso2,
		});
	}
	return state;
}, []);
