import React from 'react';

import Icon from '../../Success/Icon';
import PaymentResultPage from '../../../PaymentResultPage';

const LINK_URL = 'weststein://home';

const Success = () => {
	return (
		<PaymentResultPage titleId="pages.payments.pfs.success.title" linkId="buttons.back" linkUrl={LINK_URL} mobileLink Icon={Icon} hideLayout />
	);
};

export default Success;
