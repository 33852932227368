export default {
	cardNumber: {
		presence: { message: '^schemas.presence' },
		numericality: { message: '^schemas.numerical' },
		length: {
			is: 16,
			message: '^schemas.length.is',
		},
	},
	password: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 8,
			message: '^schemas.length.min',
		},
	},
};
