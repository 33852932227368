import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PaginationButton from './PaginationButton';
import Icon from '../Icon';
import Button from '../Button';
import './Pagination.scss';

class Pagination extends React.PureComponent {
	static propTypes = {
		current: PropTypes.number,
		total: PropTypes.number,
		onChange: PropTypes.func,
		className: PropTypes.string,
	}

	onChangePrev = () => {
		const { onChange, current } = this.props;
		onChange(current - 1);
	}

	onChangeNext = () => {
		const { onChange, current } = this.props;
		onChange(current + 1);
	}

	render () {
		const { className, total, current, onChange } = this.props;

		const content = [];
		for (let i = 0; i < total; i++) {
			content.push(
				<PaginationButton
					key={i}
					idx={i}
					onClick={onChange}
					current={current}
				/>
			);
		}

		const canPrev = current !== 0;
		const canNext = total !== current + 1;

		return (
			<div className={classnames('Pagination', className)}>
				<Button
					className="Pagination__Prev"
					onClick={this.onChangePrev}
					small
					disabled={!canPrev}
				>
					<Icon name="caveat-left" />
				</Button>

				{content}

				<Button
					className="Pagination__Next"
					onClick={this.onChangeNext}
					small
					disabled={!canNext}
				>
					<Icon name="caveat-right" />
				</Button>
			</div>
		);
	}
}

export default Pagination;
