import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Badge.scss';

export default function Badge (props) {
	const { className, success, warning, error, info, orange } = props;
	const classNames = classnames(
		'Badge', className,
		success && 'Badge--Success',
		warning && 'Badge--Warning',
		error && 'Badge--Error',
		info && 'Badge--Info',
		orange && 'Badge--Orange',
	);

	return (
		<div className={classNames}>
			{props.children}
		</div>
	);
}

Badge.propTypes = {
	success: PropTypes.bool,
	warning: PropTypes.bool,
	error: PropTypes.bool,
	info: PropTypes.bool,
	orange: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
};
