import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Divider.scss';

export default function Divider (props) {
	const { className } = props;
	return (
		<div className={classnames('Divider', className)} />
	);
}

Divider.propTypes = {
	className: PropTypes.string,
};
