import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Cell extends React.PureComponent {
	static propTypes = {
		children: PropTypes.any,
		sort: PropTypes.shape({
			asc: PropTypes.any,
		}),
		current: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		item: PropTypes.object,
		onClick: PropTypes.func,
	}

	onClick = () => {
		const { onClick, item } = this.props;
		onClick && onClick(item);
	}

	render () {
		const { current, sort, children } = this.props;
		return (
			<td
				className={classnames(
					'Table__Cell',
					current && 'Table__Current',
					current && !sort.asc && 'Table__Up')}
				role="menuitem"
				onClick={this.onClick}
			>
				{children}
			</td>
		);
	}
}

export default Cell;
