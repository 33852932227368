import moment from 'moment';

export default function basic (data) {
	const convert = {
		...data,
	};

	delete convert.cardholderIds;
	delete convert.agree;
	delete convert.id;

	if (data.dateOfIncorporation) {
		convert.dateOfIncorporation = moment(data.dateOfIncorporation, 'DD-MM-YYYY').format('DD/MM/YYYY');
	}

	if (convert.industry) convert.industry = parseInt(convert.industry, 10);

	if (data.regulatedByAuthority) {
		convert.authority = convert.regulatedByAuthority;
		delete convert.regulatedByAuthority;

		convert.authorityName = convert.regulationAuthorityName;
		delete convert.regulationAuthorityName;

		convert.authorityLicence = convert.licenceNumberGrantedByAuthority;
		delete convert.licenceNumberGrantedByAuthority;
	}

	if (data.legalStatus) {
		convert.businessType = data.legalStatus;
		delete convert.legalStatus;

		convert.businessTypeOther = data.otherLegalStatus;
		delete convert.otherLegalStatus;
	}

	return convert;
}
