export default {
	cardNumber: {
		presence: { message: '^schemas.presence' },
		numericality: { message: '^schemas.numerical' },
		length: {
			is: 16,
			message: '^schemas.length.is',
		},
	},
	password: {
		presence: { message: '^schemas.presence' },
	},
	question: {
		presence: { message: '^schemas.presence' },
	},
	answer: {
		presence: { message: '^schemas.presence' },
	},
	passwordNewConfirm: {
		presence: { message: '^schemas.presence' },
		equality: {
			attribute: 'password',
			message: '^schemas.password.match',
			comparator: (v1, v2) => v1 === v2
		},
	},
};
