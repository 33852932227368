export default {
	create: {
		beneficiary: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.space', options: { space: true } },
			length: {
				minimum: 2,
				maximum: 20,
				message: '^schemas.length.between',
			},
		},
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			},
		},
		currency: {
			presence: { message: '^schemas.presence' },
		},
		creditorCountryCode: {
			presence: { message: '^schemas.presence' },
		},
		city: {
			presence: { message: '^schemas.presence' },
			length: {
				maximum: 35,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z -]+$',
				message: '^schemas.alphabetic'
			}
		},
		state: {
			length: {
				maximum: 35,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z0-9 .,&/()-]+$',
				message: '^schemas.alphanumeric.address',
			}
		},
		creditorAddress1: {
			presence: { message: '^schemas.presence' },
			length: {
				maximum: 30,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z0-9 .,&/()-]+$',
				message: '^schemas.alphanumeric.address',
			}
		},
		creditorAddress2: {
			length: {
				maximum: 30,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z0-9 .,&/()-]+$',
				message: '^schemas.alphanumeric.address',
			}
		},
		postCode: {
			length: {
				maximum: 15,
				message: '^schemas.length.max',
			},
			alphanumeric: { message: '^schemas.alphanumeric.hyphen', options: { space: true, canBeEmpty: true } },
		},
		iban: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				minimum: 15,
				maximum: 31,
				message: '^schemas.length.between',
			},
		},
		bic: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				minimum: 8,
				maximum: 11,
				message: '^schemas.length.between',
			},
		},
		details: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^[ /a-zA-Z0-9]+$/,
				message: '^schemas.alphanumeric.slash'
			},
			length: {
				minimum: 1,
				maximum: 35,
				message: '^schemas.length.between',
			},
		},
	},

	amount: {
		amount: {
			presence: { message: '^schemas.presence' },
			numericality: { message: '^schemas.numerical' },
			equality: {
				attribute: 'original',
				message: '^schemas.match',
				comparator: (v1, v2) => parseFloat(v1) === parseFloat(v2)
			},
		},
	},

	phone: {
		code: {
			presence: { message: '^schemas.presence' },
		},
	},
};
