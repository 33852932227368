import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import currentCardType from 'types/currentCard';
import clientTypes from 'types/client';

import helpers from 'helpers';

import * as paymentSelector from 'selectors/payment';
import * as clientSelector from 'selectors/client';
import * as cardSelector from 'selectors/card';

import * as paymentActions from 'actions/payment';
import * as cardActions from 'actions/card';

import Section from 'components/Section';
import Container from 'components/Container';
import Table from 'components/Table';
import Title from 'components/Title';
import Button from 'components/Button';
import InputSearch from 'components/InputSearch';
import InputFilter from 'components/InputFilter';
import Pagination from 'components/Pagination';

import './Payment.scss';

import M from './Payment.locale.json';



const PAGE_SIZE = 10; // fetches 10 SEPA and 10 C2C payments

const COLUMNS = [{
	id: 'beneficiary',
	title: M.table.beneficiary,
}, {
	id: 'bic',
	title: M.table.bic,
}, {
	id: 'iban',
	title: M.table.iban,
}, {
	id: 'details',
	title: M.table.details,
}, {
	id: 'status',
	title: M.table.status,
}, {
	id: 'created',
	title: M.table.created,
}, {
	id: 'amount',
	title: M.table.amount,
}];

const STATUS = [{
	id: 'COMPLETED',
	title: M.status.completed,
}, {
	id: 'SIGNING',
	title: M.status.signing,
}, {
	id: 'DRAFT',
	title: M.status.draft,
}, {
	id: 'DECLINED',
	title: M.status.declined,
}];

const mapState = (state, props) => ({
	client: clientSelector.getCurrentClient(state, props),
	payments: paymentSelector.fetch(state, props),

	columns: paymentSelector.columns(state),
	columnsNav: paymentSelector.columnsNav(props, COLUMNS),

	status: paymentSelector.status(state),
	statusNav: paymentSelector.statusNav(props, STATUS),

	sort: paymentSelector.sort(state),

	search: paymentSelector.search(state),

	currentCard: cardSelector.getById(state, state.card.current)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class PaymentSection extends PureComponent {
	static propTypes = {
		status: PropTypes.array,
		actions: PropTypes.shape({
			card: PropTypes.object,
			transaction: PropTypes.object,
		}),
		client: clientTypes,
		currentCard: currentCardType,
	}

	state = {
		loaderTable: true,
		page: 0,
		total: 0,
	}

	componentDidMount () {
		this.mounted = true;
		this.onFetch();
	}

	componentDidUpdate (prevProps, prevState) {
		if (!prevProps || !this.props) return;
		const { client, currentCard } = this.props;
		const { page } = this.state;
		const sameClient = (prevProps.client.id === client.id);
		const sameCard =
			currentCard && prevProps.currentCard &&
			currentCard.id && prevProps.currentCard.id &&
			currentCard.id === prevProps.currentCard.id;

		const samePage = prevState.page === page;

		if (sameClient && sameCard && samePage) return;

		this.onFetch(!sameClient, !samePage);
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onFetch = async (shouldUpdate, changePage) => {
		const { actions } = this.props;
		const { page } = this.state;

		this.mounted && this.setState({
			loaderTable: true,
		});

		try {
			await actions.card.fetch(shouldUpdate);

			const { number, totalElements } = await actions.payment.fetch({
				size: PAGE_SIZE,
				page: ((changePage && page) ? page : 0),
				sort: 'created,DESC',
			});

			this.mounted && this.setState({
				loaderTable: false,
				total: Math.ceil(totalElements / PAGE_SIZE / 2), // hack for correct page count
				page: number,
			});
		} catch (e) { }
	}

	onStatus = options => {
		const { actions } = this.props;

		actions.payment.status(options);
	}

	onColumn = options => {
		const { actions } = this.props;

		if (options.length === 0) return;

		actions.payment.columns(options);
	}

	onSearch = keyword => {
		const { actions } = this.props;

		actions.payment.search(keyword);
	}

	onPage = page => {
		this.setState({
			page,
		});
	}

	onBulk = () => {
		alert('Bulk payment?');
	}

	onSingle = item => {
		const { history } = this.props;

		if (item.type === 'c2c') {
			history.push(`/c2cpayments/create/${item.id}`);
		} else {
			history.push(`/payments/create/${item.id}`);
		}
	}

	mounted = false

	renderHeader = () => {
		const { columns, columnsNav } = this.props;

		return columnsNav.filter(item => (columns.indexOf(item.id) > -1));
	}

	renderRow = row => {
		const { columns, columnsNav } = this.props;

		return (
			<Table.Row
				key={row.id}
				onClick={() => this.onSingle(row)}
			>
				{
					columnsNav
						.filter(item => (columns.indexOf(item.id) > -1))
						.map(item => (
							<Table.Cell
								key={item.id}
							>
								{helpers.formatter(item.id, row[item.id])}
							</Table.Cell>
						))
				}
			</Table.Row>
		);
	}

	render () {
		const { loaderTable, total, page } = this.state;
		const { payments, columns, columnsNav, sort, status, statusNav, search, client } = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<Section className="PaymentSection">
				<Container>
					<Title
						title={t(M.title)}
					/>

					<div
						className="PaymentSection__Actions"
					>
						<div>
							<InputFilter
								name="columns"
								value={columns}
								data={columnsNav}
								onChange={this.onColumn}
							>
								{t(M.buttons.columns)}
							</InputFilter>

							<InputFilter
								name="status"
								value={status}
								data={statusNav}
								onChange={this.onStatus}
							>
								{t(M.buttons.status)}
							</InputFilter>
						</div>

						<InputSearch
							name="search"
							value={search}
							placeholder={t(M.inputs.filter)}
							onChange={this.onSearch}
						/>

						{
							// bulk payment for business
							client.roleType === 'BUSINESS' &&
							<Button
								onClick={this.onBulk}
								plain
							>
								{t(M.buttons.bulk)}
							</Button>
						}
					</div>

					<Table
						items={payments}
						onSort={this.onSort}
						sort={sort}
						renderHeader={this.renderHeader}
						renderRow={this.renderRow}
						className="History__Table"
						loading={loaderTable}
					/>

					{
						(payments.length > 0 && !loaderTable) &&
						<Pagination
							total={total}
							current={page}
							onChange={this.onPage}
						/>
					}
				</Container>
			</Section>
		);
	}
}
