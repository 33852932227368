const slugify = text => text.toString().toLowerCase()
	.replace(/\s+/g, '-')
	.replace(/[^\w\-]+/g, '')
	.replace(/\-\-+/g, '-')
	.replace(/^-+/, '')
	.replace(/-+$/, '');

const get = item => ({
	...item,
	slug: item.name ? slugify(item.name) : item.name,
});

const fetch = items => items.map(get);

export default {
	fetch,
	get,
};
