import PropTypes from 'prop-types';

export default PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	iso2: PropTypes.string,
	sepa: PropTypes.bool,
	enroll: PropTypes.bool,
	phoneCode: PropTypes.string,
	riskLevel: PropTypes.string,
	priority: PropTypes.bool,
	slug: PropTypes.string,
});
