import React from 'react';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from 'components/Avatar';
import Badge from 'components/Badge';
import Create from './Create';
import MemberButton from './MemberButton';
import M from './Member.locale.json';
import './Member.scss';

import intlTypes from 'types/intl';

@injectIntl
class Member extends React.PureComponent {
	static propTypes = {
		user: PropTypes.shape({
			userId: PropTypes.number,
			avatarId: PropTypes.number,
			name: PropTypes.string,
			surname: PropTypes.string,
			position: PropTypes.string,
			status: PropTypes.string,
			requestStatus: PropTypes.string,
			role: PropTypes.string,
		}).isRequired,
		current: PropTypes.number,
		large: PropTypes.bool,
		actions: PropTypes.arrayOf(PropTypes.any),
		onClick: PropTypes.func,
		className: PropTypes.string,
		intl: intlTypes.isRequired,
	}

	render () {
		const { className, intl, onClick, actions, user, current, large } = this.props;
		const { role, requestStatus, name, surname, userId, position } = user;

		const t = intl.formatMessage;
		let userRole = null;

		if (role === 'BOOKKEEPER') userRole = t(M.access.viewer);
		if (role === 'OWNER') userRole = t(M.access.administrator);

		return (
			<div
				className={classnames('Member', className)}
				onClick={onClick}
				role="button"
				tabIndex={0}
			>
				<div className="Member__Content">
					<Avatar
						className="Member__Avatar"
						large={large}
						user={user}
						tip={{
							title: userRole,
							short: userRole ? userRole[0] : null,
						}}
					/>
					<div className="Member__Name">
						{`${name} ${surname}`}
						{current === userId && <FM id={M.you.id} />}
					</div>
					<div className="Member__Position">{position || '\u00a0'}</div>
					{requestStatus === 'GRANTED' && (
						<Badge className="Member__Status" success>
							<FM id={M.status.active.id} />
						</Badge>
					)}
					{requestStatus === 'REQUESTED' && (
						<Badge className="Member__Status">
							<FM id={M.status.requested.id} />
						</Badge>
					)}
					<div className="Member__Actions">
						{actions && actions.map(item => <MemberButton key={item.title} item={item} />)}
					</div>
				</div>
			</div>
		);
	}
}

Member.Create = Create;

export default Member;
