import fetcher from 'helpers/fetcher';

export default {
	fetch (bid) {
		return fetcher.get(`business/${bid}/authorized-users`);
	},
	get (id, rid, bid) {
		let url = `business/${bid}/authorized-users/${id}`;
		if (rid) url += `/${rid}`;
		return fetcher.get(url);
	},
	create (bid, form) {
		return fetcher.post(`business/${bid}/user/request-new`, form);
	},
	edit (id, rid, bid, form) {
		let url = `business/${bid}/authorized-users/${id}`;
		if (rid) url += `/${rid}`;
		return fetcher.put(url, form);
	},
	remove (rid, bid) {
		return fetcher.delete(`business/${bid}/remove-role/${rid}`);
	},
};
