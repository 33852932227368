import fetcher from 'helpers/fetcher';

export default {
	create (file) {
		const data = new FormData();
		data.append('file', file);
		return fetcher.post(`user/avatar`, data, {
			headers: { 'Content-Type': null }
		});
	},
	remove () {
		return fetcher.delete('user/avatar');
	},
};
