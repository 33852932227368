import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';
import moment from 'moment';

import ButtonDate from 'components/ButtonDate';

import './HistoryDate.scss';



const mapState = state => ({
	// user: state.user,
	language: state.language,
});

@connect(mapState)
export default class HistoryDate extends PureComponent {
	onDate = range => {
		const { start, end } = this.props;

		this.props.onDate({
			start,
			end,
			...range,
		});
	}

	render () {
		const { language } = this.props;

		moment.locale(language.locale);

		const start = moment(this.props.start, 'DD-MM-YYYY');
		const end = moment(this.props.end, 'DD-MM-YYYY');

		return (
			<div
				className="HistoryDate"
			>
				<span>
					<FM id="sections.history.historydate.from" />
					<ButtonDate
						range={start.toDate()}
						month={start.month()}
						year={start.year()}
						name={`${this.props.name}From`}
						onChange={date => this.onDate({ start: moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY') })}
					>
						{start.format('LL')}
					</ButtonDate>
				</span>

				<span>
					<FM id="sections.history.historydate.till" />
					<ButtonDate
						range={end.toDate()}
						month={end.month()}
						year={end.year()}
						name={`${this.props.name}Till`}
						onChange={date => this.onDate({ end: moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY') })}
						disabledDate={new Date()}
					>
						{end.format('LL')}
					</ButtonDate>
				</span>
			</div>
		);
	}
}
