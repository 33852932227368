import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import Title from 'components/Title';
import Button from 'components/Button';

import './NotFound.scss';
import M from './NotFound.locale.json';


@injectIntl
export default class NotFound extends PureComponent {
	state = {
		loader: false
	};

	returnToDashboard = () => {
		const { history } = this.props;
		history.push('/');
	};

	render () {
		const t = this.props.intl.formatMessage;

		return (
			<div className={'wrapper'}>
				<div className={'image'} />
				<Title
					title={t(M.title)}
				/>
				<p>{t(M.legend)}</p>
				<Button
					className="Button return"
					onClick={this.returnToDashboard}
				>
					{t(M.buttons.back)}
				</Button>
			</div>
		);
	}
}
