import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import helpers from 'helpers';
import reduxer from 'helpers/reduxer';

import * as clientActions from 'actions/client';
import * as documentActions from 'actions/document';
import * as toastActions from 'actions/toast';

import Client from 'schemas/client';

import Label from 'components/Label';
import InputCheckbox from 'components/InputCheckbox';
import InputFile from 'components/InputFile';
import Form from 'components/Form';
import Button from 'components/Button';
import Collapse from 'components/Collapse';

import M from './Document.locale.json';



const mapState = state => ({
	document: state.document,
	form: state.client.business.form.documents,
});

const mapDispatch = dispatch => ({
	actions: {
		client: reduxer.bindNestedActionCreators(clientActions, dispatch),
		document: bindActionCreators(documentActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Document extends PureComponent {
	state = {
		loader: false,

		loaders: {
			certificateOfRegistration: false,
			memorandumOfAssociation: false,
			annualReturn: false,
			shareholderDetails: false,
			companyDirectors: false,
			evidenceOfBankAccount: false,
		},

		errors: {
			declaration: [],
		}
	};

	componentDidMount () {
		const { actions } = this.props;

		actions.document.fetch();
	}

	onUpload = async (file, name) => {
		const { actions } = this.props;
		const { loaders } = this.state;

		this.setState({
			loaders: {
				...loaders,
				[name]: true,
			}
		});

		await actions.document.create(file, name);

		this.setState({
			loaders: {
				...loaders,
				[name]: false,
			}
		});
	}

	onRemove = (id, type) => {
		const { actions, document } = this.props;

		const total = Object.keys(document).reduce((count, item) => (count + document[item].length), 0);

		actions.document.remove(id, type, total);
	}

	onDownload = async (idx, type) => {
		const { document, actions } = this.props;

		const item = document[type][idx];

		const data = await actions.document.get(item.id);

		helpers.downloader.file(item.name, item.type, data);
	}

	onChange = (value, name) => {
		const { errors } = this.state;
		const { actions, form } = this.props;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Client.create.documents);
		}

		this.setState({
			errors: {
				...errors,
				[name]: messages,
			},
		});

		actions.client.business.form({
			[name]: value,
		}, 'documents');
	}

	onSubmit = async e => {
		e.preventDefault();

		const { loader } = this.state;
		const { history, form, actions, document, match } = this.props;

		const { id } = match.params;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, Client.create.documents);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
				loader: false,
			});

			return;
		}

		const total = Object.keys(document).reduce((count, item) => (count + document[item].length), 0);

		if (total <= 0) {
			actions.toast.create({
				title: 'Failed to submit application',
				body: 'There should be atleast one uploaded document',
				type: 'error',
			});

			return;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.client.business.create(form, 'documents');

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState({
			loader: false,
		});

		history.push(`/clients/create/business/${id}/submitted`);
	}

	render () {
		const { loader, loaders, errors } = this.state;
		const { form, document, current, onPrev } = this.props;

		const content = {};

		const t = this.props.intl.formatMessage;

		for (const key in document) {
			content[key] = document[key].map((item, idx) => (
				<InputFile.Preview
					key={item.id}
					item={item}
					onRemove={() => this.onRemove(item.id, key)}
					onDownload={() => this.onDownload(idx, key)}
				/>
			));
		}

		return (
			<div
				className="ClientCreateBusinessScreen__Step"
			>
				<Form.Note>{t(M.messages.note)}</Form.Note>

				<Form.Group
					float
					small
				>
					<Label>{t(M.inputs.certificateOfRegistration)}</Label>

					<div>
						{content.certificateOfRegistration}

						<InputFile
							name="certificateOfRegistration"
							onChange={this.onUpload}
							loading={loaders.certificateOfRegistration}
							errors={errors.certificateOfRegistration}
						/>
					</div>
				</Form.Group>

				<Form.Group
					float
					small
				>
					<Label>{t(M.inputs.memorandumOfAssociation)}</Label>

					<div>
						{content.memorandumOfAssociation}

						<InputFile
							name="memorandumOfAssociation"
							onChange={this.onUpload}
							loading={loaders.memorandumOfAssociation}
							errors={errors.memorandumOfAssociation}
						/>
					</div>
				</Form.Group>

				<Form.Group
					float
					small
				>
					<Label>{t(M.inputs.annualReturn)}</Label>

					<div>
						{content.annualReturn}

						<InputFile
							name="annualReturn"
							onChange={this.onUpload}
							loading={loaders.annualReturn}
							errors={errors.annualReturn}
						/>
					</div>
				</Form.Group>

				<Form.Group
					float
					small
				>
					<Label>{t(M.inputs.shareholderDetails.title)}</Label>

					<p>{t(M.inputs.shareholderDetails.body)}</p>

					<div>
						{content.shareholderDetails}

						<InputFile
							name="shareholderDetails"
							onChange={this.onUpload}
							loading={loaders.shareholderDetails}
							errors={errors.shareholderDetails}
						/>
					</div>
				</Form.Group>

				<Form.Group
					float
					small
				>
					<Label>{t(M.inputs.companyDirectors.title)}</Label>

					<p>{t(M.inputs.companyDirectors.body)}</p>

					<div>
						{content.companyDirectors}

						<InputFile
							name="companyDirectors"
							onChange={this.onUpload}
							loading={loaders.companyDirectors}
							errors={errors.companyDirectors}
						/>
					</div>
				</Form.Group>

				<Form.Group
					float
					small
				>
					<Label>{t(M.inputs.evidenceOfBankAccount)}</Label>

					<div>
						{content.evidenceOfBankAccount}

						<InputFile
							name="evidenceOfBankAccount"
							onChange={this.onUpload}
							loading={loaders.evidenceOfBankAccount}
							errors={errors.evidenceOfBankAccount}
						/>
					</div>
				</Form.Group>

				<p
					className="ClientCreateBusinessScreen__Note"
				>
					{t(M.messages.necessary)}
				</p>

				<Form
					onSubmit={this.onSubmit}
				>
					<Form.Group
						row
					>
						<InputCheckbox
							name="declaration"
							type="declaration"
							onChange={this.onChange}
							value="on"
							checked={form.declaration}
							errors={errors.declaration}
						>
							<Collapse>
								<h4>{t(M.declaration.title)}</h4>
								<p>{t(M.declaration.body)}</p>
							</Collapse>
						</InputCheckbox>
					</Form.Group>

					<Button
						className="Stepper__Button"
						loading={loader}
						form
						small
					>
						{t(M.buttons.finish)}
					</Button>

					<Button
						className="Stepper__Button"
						disabled={loader}
						onClick={() => onPrev()}
						secondary
						small
					>
						{t(M.buttons.back)}
					</Button>
				</Form>
			</div>
		);
	}
}
