import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import validator from 'helpers/validator';

import * as paymentActions from 'actions/payment';
import * as countryActions from 'actions/country';

import * as paymentSelector from 'selectors/payment';
import * as userSelector from 'selectors/user';

import PaymentSchema from 'schemas/payment';

import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Timer from 'components/Timer';
import Title from 'components/Title';

import M from './Phone.locale.json';



const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	return {
		payment: paymentSelector.get(state, id),
		user: state.user,
		userPhone: userSelector.getUserPhone(state),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,
		loaderResend: false,

		disbaledResend: false,

		form: {
			code: '',
		},

		errors: {
			code: [],
		},
	}

	componentDidMount () {
		this.onMount();
	}

	componentWillUnmount () {
		clearTimeout(this.timeout);
	}

	onMount = () => {
		const { actions } = this.props;

		actions.country.fetch();
	}

	onResend = async () => {
		const { actions, payment } = this.props;
		const { loaderResend, disbaledResend } = this.state;

		if (loaderResend || disbaledResend) return;

		this.setState({
			loaderResend: true,
		});

		await actions.payment.code(payment.id);

		this.onDisable();
	}

	onDisable = () => {
		this.setState({
			loaderResend: false,
			disbaledResend: true,
		});

		this.timeout = setTimeout(() => {
			this.setState({
				disbaledResend: false,
			});
		}, 15 * 1000);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = validator.single({
				...form,
				[name]: value,
			}, name, PaymentSchema.phone);
		}

		this.setState({
			loader: false,

			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
		});
	};

	onSubmit = async e => {
		e.preventDefault();

		const { actions, payment } = this.props;
		const { form, loader, loaderResend } = this.state;

		// check if form was already submitted
		if (loader || loaderResend) return;

		const errors = validator.all(form, PaymentSchema.phone);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.payment.phone(payment.id, form);

		const update = {
			loader: false,
		};

		if (response && response.errors) {
			update.errors = {
				...this.state.errors,
				...response.errors,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState(update);
	};

	render () {
		const { form, errors, loader, loaderResend, disbaledResend } = this.state;
		const { user, userPhone } = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>

				<Form
					onSubmit={this.onSubmit}
				>
					<Form.Group>
						<Input
							name="phoneCountry"
							placeholder={t(M.inputs.phoneCountry)}
							value={`${userPhone}`}
							disabled
						/>
						<Input
							name="phone"
							placeholder={t(M.inputs.phone)}
							value={user.phone}
							disabled
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="code"
							placeholder={t(M.inputs.code)}
							onChange={this.onChange}
							value={form.code}
							errors={errors.code}
						/>
					</Form.Group>

					<Button
						className="PaymentCreateScreen__Submit"
						loading={loader}
						disabled={loaderResend}
						form
						small
					>
						{t(M.buttons.pay)}
					</Button>

					<Button
						className="PaymentCreateScreen__Submit"
						onClick={this.onResend}
						loading={loaderResend}
						disabled={loader || disbaledResend}
						type="div"
						form
						small
						secondary
					>
						{
							disbaledResend &&
							<Timer time={15} />
						}

						{
							!disbaledResend &&
							t(M.buttons.resend)
						}
					</Button>
				</Form>
			</div>
		);
	}
}
