import React from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import RegulatoryDataCollectionForm from 'sections/RegulatoryDataCollectionForm';
import { cardStatus } from 'constants/common';
import * as cardActions from 'actions/card';
import { getCurrentCardId, getCurrentCard } from 'selectors/card';
import M from './RegulatoryDataMenu.locale.json';
import './RegulatoryDataMenu.scss';

import intlTypes from 'types/intl';
import currentCardType from 'types/currentCard';

const mapState = state => ({
	currentCardId: getCurrentCardId(state),
	currentCard: getCurrentCard(state),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
class RegulatoryDataMenu extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		currentCard: currentCardType,
		actions: PropTypes.shape({
			card: PropTypes.shape({
				setNewCardData: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
		currentCardId: PropTypes.number.isRequired,
	}

	onSubmit = values => {
		const { actions } = this.props;
		actions.card.setNewCardData({ udfs: values });
	}

	render () {
		const { intl, currentCard, currentCardId } = this.props;
		const t = intl.formatMessage;
		const disabled = currentCard && currentCard.currentCard === cardStatus.CLOSED;

		return (
			<div className="regulatory-data-menu">
				{currentCard && (
					<RegulatoryDataCollectionForm
						disabled={disabled || currentCard.updating}
						currentCardId={currentCardId}
						values={currentCard.udfs}
						onSubmit={this.onSubmit}
						button={
							<Button
								form
								small
								disabled={disabled}
								loading={currentCard.updating}
							>
								{t(M.button)}
							</Button>
						}
					/>
				)}
			</div>
		);
	}
}

export default RegulatoryDataMenu;
