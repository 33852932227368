import React, { PureComponent } from 'react';

import Nav from 'sections/Nav';

import Layout from 'components/Layout';

import SectionSetting from 'sections/Setting';



export default class Dashboard extends PureComponent {
	render () {
		return (
			<Layout className="PasswordChangeScreen Screen">
				<Nav
					logo
				/>

				<SectionSetting.Passwordchange />
			</Layout>
		);
	}
}
