export default function password (form) {
	if (!form) return form;

	const convert = {};

	if (form.current) convert.oldPassword = form.current;
	if (form.new) convert.newPassword = form.new;

	return convert;
}
