import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

class NavVerticalItem extends React.PureComponent {
	static propTypes = {
		idx: PropTypes.number.isRequired,
		onClick: PropTypes.func.isRequired,
		item: PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
			title: PropTypes.string.isRequired,
			icon: PropTypes.string,
		}).isRequired,
		className: PropTypes.string.isRequired,
	}

	onClick = () => {
		const { onClick, item, idx } = this.props;
		onClick(item.id, idx);
	}

	render () {
		const { className, item } = this.props;
		return (
			<li>
				<div
					onClick={this.onClick}
					className={className}
					role="button"
					tabIndex={0}
				>
					{item.icon && <Icon className="NavVertical__Icon" name={item.icon} />}
					<div className="NavVertical__Content">{item.title}</div>
				</div>
			</li>
		);
	}
}

export default NavVerticalItem;
