import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as clientSelector from 'selectors/client';

import * as clientActions from 'actions/client';

import Nav from 'sections/Nav';

import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Button from 'components/Button';

import PlantImage from '../../../img/start/plant.png';
import CoffeeImage from '../../../img/start/coffee.png';
import ClipImage from '../../../img/start/clip.png';
import PencilImage from '../../../img/start/pencil.png';

import M from './Start.locale.json';

import './Start.scss';



const mapState = state => ({
	user: state.user,
	clientId: clientSelector.getEntityClientId(state),
});

const mapDispatch = dispatch => ({
	actions: {
		client: bindActionCreators(clientActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Start extends PureComponent {
	onStart = () => {
		const { history, clientId } = this.props;

		history.push(`/clients/create/${clientId}/basic`);
	}

	render () {
		const { user } = this.props;

		const t = this.props.intl.formatMessage;

		return (
			<Layout className="ClientStartScreen Screen">
				<Nav
					hide
				/>

				<Section className="ClientStartScreen__Section">
					<Container>
						<Title
							title={t(M.title, { name: user.name })}
						/>

						<div
							className="ClientStartScreen__Background ClientStartScreen__Plant"
							style={{ backgroundImage: `url(${PlantImage})` }}
							alt="Plant"
						/>

						<div
							className="ClientStartScreen__Background ClientStartScreen__Coffee"
							style={{ backgroundImage: `url(${CoffeeImage})` }}
							alt="Coffee"
						/>

						<div
							className="ClientStartScreen__Background ClientStartScreen__Pencil"
							style={{ backgroundImage: `url(${PencilImage})` }}
							alt="Pencil"
						/>

						<div
							className="ClientStartScreen__Background ClientStartScreen__Clip"
							style={{ backgroundImage: `url(${ClipImage})` }}
							alt="Clip"
						/>

						<p className="ClientStartScreen__Note">{t(M.note)}</p>
						<p className="ClientStartScreen__Instruction">
							<FM
								id={M.instruction.id}
								values={{
									duration: (<strong>{t(M.values.duration)}</strong>),
									saved: (<strong>{t(M.values.saved)}</strong>)
								}}
							/>
						</p>

						<Button
							onClick={this.onStart}
							className="ClientStartScreen__Button"
							small
						>
							{t(M.buttons.start)}
						</Button>
					</Container>
				</Section>
			</Layout>
		);
	}
}
