import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from '../Loader';
import Icon from '../Icon';
import Preview from './Preview';
import './InputFile.scss';
import M from './InputFile.locale.json';

import intlTypes from 'types/intl';

@injectIntl
export default class InputFile extends PureComponent {
	static propTypes = {
		label: PropTypes.string,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		autoFocus: PropTypes.bool,
		disabled: PropTypes.bool,
		loading: PropTypes.bool,
		errors: PropTypes.arrayOf(PropTypes.string),
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
		intl: intlTypes.isRequired,
	}

	state = {
		hover: false,
		focus: false,
	}

	onChange = e => {
		const { onChange, name } = this.props;
		onChange(e.target.files[0], name, e);
		document.getElementById(name).value = '';
	}

	onDragEnter = () => this.setState({ hover: true })
	onDragLeave = () => this.setState({ hover: false })

	render () {
		const { disabled, intl, label, className, errors, name,
			onFocus, onBlur, value, autoFocus, placeholder, loading } = this.props;
		const { hover, focus } = this.state;
		const t = intl.formatMessage;

		return (
			<div className={classnames(
				'InputFile', className,
				errors && 'InputFile--Invalid',
				hover && 'InputFile--Dragover',
				focus && 'InputFile--Focus',
				disabled && 'InputFile--Disabled')}
			>
				<input
					id={name}
					type="file"
					className="InputFile__Input"
					onChange={this.onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					name={name}
					value={value}
					checked={value}
					autoFocus={autoFocus}
					placeholder={placeholder}
					onDragEnter={this.onDragEnter}
					onDragLeave={this.onDragLeave}
					onDragExit={this.onDragLeave}
					onDrop={this.onDragLeave}
					disabled={disabled}
				/>

				{!loading && (
					<label htmlFor={name} className="InputFile__Label">
						<Icon className="InputFile__Icon" name="upload" />
						{label || t(M.buttons.drop)}
					</label>
				)}

				{loading && <Loader className="InputFile__Loader" />}

				{errors && !!errors.length && (
					<div className="InputFile__Errors">
						<div>{errors[0]}</div>
					</div>
				)}
			</div>
		);
	}
}

InputFile.Preview = Preview;
