import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// import * as userActions from "actions/user";

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import NavVertical from 'components/NavVertical';
import Wrap from 'components/Wrap';
import Button from 'components/Button';

import './Phone.scss';



const mapState = (state, props) => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		// user: bindActionCreators(userActions, dispatch),
	},
});

@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	constructor (props) {
		super(props);

		this.state = {
			loader: false,
			form: {
				phone: (props.user.phone ? props.user.phone : ''),
				phoneCountry: '',
			},
			errors: {
				phone: [],
				phoneCountry: [],
			}
		};
	}

	nav = [{
		id: 'account',
		title: 'Account',
		icon: 'account',
	}, {
		id: 'interface',
		title: 'Interface',
		icon: 'interface',
	}, {
		id: 'phone',
		title: 'Phone',
		icon: 'phone',
	}, {
		id: 'password',
		title: 'Password',
		icon: 'password',
	}]

	onNav = idx => {
		const { history } = this.props;

		const path = this.nav[idx].id;

		history.push(`/settings/${path}`);
	}

	render () {
		const { user } = this.props;
		const { form, errors } = this.state;

		return (
			<Section className="SettingPhoneSection">
				<Container>
					<NavVertical
						className="SettingScreen__Nav"
						items={this.nav}
						onClick={this.onNav}
						active={2}
					/>

					<Wrap>
						<Title
							title="Settings"
							legend="Phone"
							className="SettingScreen__Title"
						/>

						{
							user.phone &&
							<Form.Group>
								<Input
									name="phone"
									placeholder="Current phone"
									value={user.phone}
									errors={errors.phone}
									disabled
								/>
							</Form.Group>
						}
					</Wrap>
				</Container>
			</Section>
		);
	}
}
