import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import InputSelect from 'components/InputSelect/InputSelect';
import InputCheckbox from 'components/InputCheckbox/InputCheckbox';
import InputDate from 'components/InputDate/InputDate';
import RegulatoryDataCollectionForm from 'sections/RegulatoryDataCollectionForm';
import * as cardActions from 'actions/card';
import * as privateActions from 'actions/client/private';
import { fetchToAddSecondary } from 'selectors/card';
import { getPhoneCodesCountries } from 'selectors/country';
import helpers from 'helpers';
import formFocusErrors from '../../../helpers/form-focus-errors';
import CardSchema from 'schemas/card';
import { deliveryTypeOptions, deliveryTypes } from 'constants/common';

import intlTypes from 'types/intl';
import historyTypes from 'types/history';
import {
	cardActionsTypes,
	privateActionsTypes,
} from 'types/actions';
import { isVirtualCard } from '../../../services/card';
import { getCurrentClient } from '../../../selectors/client';
import M from '../CardCreate.locale.json';

const cardsCreateSuccessUrl = '/cards/create/success';
const CREATE_OTHER_MAIN_FORM_ID = 'CREATE_OTHER_MAIN_FORM_ID';
const CREATE_OTHER_REGULATORY_FORM_ID = 'CREATE_OTHER_REGULATORY_FORM_ID';

const mapState = (state, props) => ({
	current: getCurrentClient(state),
	cards: fetchToAddSecondary(state),
	phoneCodesCountries: getPhoneCodesCountries(state, props),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		private: bindActionCreators(privateActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class CardCreateOther extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		history: historyTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
			private: privateActionsTypes.isRequired,
		}).isRequired,
		phoneCodesCountries: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				subtitle: PropTypes.string.isRequired,
				code: PropTypes.string.isRequired,
				value: PropTypes.number.isRequired,
			})
		).isRequired,
		cards: PropTypes.array,
	}

	state = {
		form: {
			name: '',
			surname: '',
			card: '',
			deliveryType: deliveryTypes.VIRTUAL,
			address: '',
			email: '',
			dateOfBirth: '',
			phone: '',
			phoneCountry: '',
			child: false,
		},

		errors: {
			name: [],
			surname: [],
			address: [],
			card: [],
			deliveryType: [],
			email: [],
			dateOfBirth: [],
			phone: [],
			phoneCountry: [],
			child: [],
		},

		loader: false,
	}

	componentDidMount () {
		this.mounted = true;
		this.onMount();
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	// eslint-disable-next-line consistent-return
	onMount = async () => {
		const { actions, history } = this.props;
		const { form } = this.state;
		const response = await actions.private.getAddress();

		if (response && response.errors) return history.goBack();

		this.mounted && this.setState({
			loader: false,
			form: { ...form, address: response },
		});
	}

	onChangeName = (value, name) => {
		const { form, errors } = this.state;

		// update form before
		this.setState({ form: { ...form, [name]: value } }, () => {
			const nameValue = this.nameRef.current.props.value;
			const surnameValue = this.surnameRef.current.props.value;
			const messages = helpers.validator.single(
				{ ...form, name: nameValue },
				'name',
				CardSchema.secondaryOther
			);
			const messagesLname = helpers.validator.single(
				{ ...form, surname: surnameValue },
				'surname',
				CardSchema.secondaryOther
			);
			this.setState({ errors: { ...errors, name: messages, surname: messagesLname } });
		});
	}

	onSubmit = async regulatoryData => {
		const { actions, history, current } = this.props;
		const { loader, form } = this.state;

		if (loader) return;
		const errors = helpers.validator.all(form, CardSchema.secondaryOther);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors }, current: 1 });
			formFocusErrors(CREATE_OTHER_MAIN_FORM_ID, { ...this.state.errors, ...errors }, Object.keys({ ...this.state.errors, ...errors }));
			return;
		}

		this.setState({ messages: [], loader: true });
		const response = await actions.card.createOther({ udfs: regulatoryData, ...form }, history);
		const update = { loader: false };

		if (response && response.errors) {
			if (response.errors.firstName) response.errors.name = response.errors.firstName;
			if (response.errors.lastName) response.errors.surname = response.errors.lastName;
			if (response.messages) update.messages = response.messages;
			update.errors = { ...this.state.errors, ...response.errors };

			this.mounted && this.setState(update);
			return;
		}

		this.mounted && this.setState(update);

		// API returns all cards on response so we get the last one
		const lastCardId = response.cardholderIds.length - 1;
		const lastCard = response.cardholderIds[lastCardId];
		const deliveryDate = lastCard.deliveryDate;

		actions.card.get(lastCard.id, current)
			.then((data) => {
				let isVirtual = false;

				if (data) {
					isVirtual = isVirtualCard(data);
				}

				history.push(cardsCreateSuccessUrl, { deliveryDate, isVirtual });
			});
	};

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];
		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, CardSchema.secondaryOther);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	mounted = false
	nameRef = React.createRef()
	surnameRef = React.createRef()

	render () {
		const { intl, phoneCodesCountries, cards } = this.props;
		const { form, errors, loader } = this.state;
		const t = intl.formatMessage;
		const cardsSelect = cards.map(card => ({ title: card.cardNumber, value: card.id }));

		if (cardsSelect && cardsSelect.length > 0) form.card = cardsSelect[0].value;

		const dateOfBirth = new Date();
		if (form.child) dateOfBirth.setFullYear(dateOfBirth.getFullYear() - 13);
		else dateOfBirth.setFullYear(dateOfBirth.getFullYear() - 18);

		const year = dateOfBirth.getFullYear();

		return (
			<Wrap>
				<Form id={CREATE_OTHER_MAIN_FORM_ID}>
					<Form.Group>
						<InputSelect
							data={deliveryTypeOptions}
							name='deliveryType'
							placeholder={t(M.inputs.cardType)}
							onChange={this.onChange}
							value={form.deliveryType}
							errors={errors.deliveryType}
						/>
						{form.deliveryType && form.deliveryType === deliveryTypes.VIRTUAL && (
							<span>*You can upgrade your virtual card to a plastic one at any time</span>
						)}
					</Form.Group>
					<Form.Group>
						<InputSelect
							data={cardsSelect}
							name="card"
							placeholder={t(M.inputs.cardNumber)}
							onChange={this.onChange}
							value={form.card}
							errors={errors.card}
						/>
					</Form.Group>
					<Form.Group>
						<Input
							id="name-input"
							ref={this.nameRef}
							name="name"
							placeholder={t(M.inputs.name)}
							onChange={this.onChangeName}
							value={form.name}
							errors={errors.name}
						/>
						<Input
							id="surname-input"
							ref={this.surnameRef}
							name="surname"
							placeholder={t(M.inputs.surname)}
							onChange={this.onChangeName}
							value={form.surname}
							errors={errors.surname}
						/>
					</Form.Group>
					<Form.Group>
						<InputCheckbox
							name="child"
							type="agree"
							onChange={this.onChange}
							value="on"
							checked={form.child}
							errors={errors.child}
						>
							<FormattedMessage id={M.inputs.child.id} />
						</InputCheckbox>
					</Form.Group>
					<Form.Group>
						<InputDate
							name="dateOfBirth"
							label={t(M.inputs.dateOfBirth)}
							placeholder={t(M.inputs.date)}
							onChange={this.onChange}
							value={form.dateOfBirth}
							errors={errors.dateOfBirth}
							disabledDate={dateOfBirth}
							year={year}
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="email"
							placeholder={t(M.inputs.email)}
							onChange={this.onChange}
							value={form.email}
							errors={errors.email}
						/>
					</Form.Group>
					<Form.Group>
						<InputSelect
							name="phoneCountry"
							onChange={this.onChange}
							placeholder={t(M.inputs.phoneCountry)}
							value={form.phoneCountry}
							errors={errors.phoneCountry}
							data={phoneCodesCountries}
						/>
						<Input
							name="phone"
							placeholder={t(M.inputs.phone)}
							onChange={this.onChange}
							value={form.phone}
							errors={errors.phone}
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="address"
							placeholder={t(M.inputs.address)}
							// onChange={this.onChange}
							value={`${form.address.line1}, ${form.address.city}, ${form.address.postalCode}`}
							disabled
						/>
					</Form.Group>
				</Form>
				<RegulatoryDataCollectionForm
					id={CREATE_OTHER_REGULATORY_FORM_ID}
					disabled={loader}
					onSubmit={this.onSubmit}
					button={(
						<Button form small loading={loader}>
							{t(M.buttons.add)}
						</Button>
					)}
				/>
			</Wrap>
		);
	}
}

export default CardCreateOther;
