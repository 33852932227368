import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import Phone from 'schemas/setting/phone';

import * as countrySelector from 'selectors/country';
import * as countryActions from 'actions/country';
import * as phoneActions from 'actions/phone';
import * as applicationActions from 'actions/applications';


import Tab from 'components/Tab';
import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import InputSelect from 'components/InputSelect';
import Timer from 'components/Timer';
import BlockingScreen from '../../Blocking';


import M from './Phone.locale.json';


const mapState = (state, props) => ({
	user: state.user,
	country: countrySelector.getEnrollCountries(state, props, M.countries),
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props, M.countries),
	cards: state.card.items
});

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		phone: bindActionCreators(phoneActions, dispatch),
		applications: bindActionCreators(applicationActions, dispatch)
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	constructor (props) {
		super(props);

		this.state = {
			disableResend: false,
			loaderResend: false,
			loader: false,
			current: 0,
			formId: 0,
			form: {
				phoneCurrent: (props.user.phone ? props.user.phone : ''),
				phone: '',
				country: '',
				phoneCountry: '',
				token: '',
			},
			errors: {
				phoneCurrent: [],
				phone: [],
				phoneCountry: [],
				token: []
			}
		};
	}

	componentDidMount () {
		this.onMount();
	}

    onMount = async () => {
    	const { actions } = this.props;

    	actions.country.fetch({
    		entroll: true,
    	});



    	const response = await actions.applications.get();

    	// We check for existing phone requests to change tab
    	for (var i = 0; i < response.length; i++) {
    		if (response[i].type === 'PHONE' && response[i].status === 'REQUESTED') {
    			this.setState({
				    current: 2,
    				formId: response[i].id,
    				form: {
    					phone: response[i].property1,
    				}
    			});

    			// setting phonecountry
    			const list = this.props.phoneCountry;
    			const result = list.filter(list => list.value == response[i].property2);
    			if (result) {
    				document.getElementById('phoneCountryDFilter').value = result[0].title;
    			}
    			break;
    		}
    	}
    }


    onClick = async () => {
    	const { actions, history, user } = this.props;
    	const { form, current, formId, loader } = this.state;

    	switch (current) {
    		case 0:
    			this.setState({ current: 1 });
    			break;
    		case 1:
    			const errors = helpers.validator.all(form, Phone.stepone);

    			if (errors) {
    				this.setState({
    					errors: {
    						...this.state.errors,
    						...errors,
    					},
    				});

    				return;
    			}

    			this.setState({
    				loader: true,
    			});

    			const response = await actions.phone.change(form);

    			if (response && response.errors) {
    				const update = {
    					errors: {
    						...this.state.errors,
    						...response.errors,
    					},
    					loader: false,
    				};

    				if (response.messages) update.messages = response.messages;

    				this.setState(update);

    				return false;
    			}
    			this.setState({
    				loader: false,
    			});
    			this.setState({ current: 2, formId: response.id });
    			break;
    		case 2:
    			const errorsSecondStep = helpers.validator.all(form, Phone.steptwo);
    			// set errors and return
    			if (errorsSecondStep) {
    				this.setState({
    					errors: {
    						...this.state.errors,
    						...errorsSecondStep,
    					},
    				});

    				return;
    			}

    			this.setState({
    				loader: true,
    			});

    			const result = await actions.phone.confirm(formId, form.token);

    			if (result && result.errors) {
    				const update = {
    					errors: {
    						...this.state.errors,
    						...result.errors,
    					},
    					loader: false,
    				};

    				if (result.messages) update.messages = result.messages;
    				this.setState(update);

    				return false;
    			}

    			this.setState({ current: 0 });
    			user.phoneVerified = false;
    			user.phone = form.phone;
    			user.phoneCountry = form.phoneCountry;
    			history.push('/auth/phone');
    			break;
    	}
    }

    onResend = async () => {
    	const { actions } = this.props;
    	const { loaderResend, disableResend, formId } = this.state;

    	if (loaderResend || disableResend) return;

    	this.setState({
    		loaderResend: true,
    	});

    	await actions.phone.resend(formId);

    	this.onDisable();
    }

    onDisable = () => {
    	this.setState({
    		loaderResend: false,
    		disableResend: true,
    	});

    	this.timeout = setTimeout(() => {
    		this.setState({
    			disableResend: false,
    		});
    	}, 15 * 1000);
    }

    onChange = (value, name) => {
    	const { form, errors } = this.state;

    	let messages = [];

    	if (errors[name].length) {
    		messages = helpers.validator.single({
    			...form,
    			[name]: value,
    		}, name, Phone.stepone);
    	}

    	this.setState({
    		form: {
    			...form,
    			[name]: value,
    		},
    		errors: {
    			...errors,
    			[name]: messages,
    		},
    	});
    };

    onChangeSecondStep = (value, name) => {
    	const { form, errors } = this.state;
    	let messages = [];

    	if (errors[name].length) {
    		messages = helpers.validator.single({
    			...form,
    			[name]: value,
    		}, name, Phone.steptwo);
    	}

    	this.setState({
    		form: {
    			...form,
    			[name]: value,
    		},
    		errors: {
    			...errors,
    			[name]: messages,
    		},
    	});
    };

    render () {
    	const { user, phoneCountry } = this.props;
    	const { form, errors, current, disableResend, loader } = this.state;

    	const t = this.props.intl.formatMessage;

    	if (this.props.cards.some(cards => ['CARD_01', 'CARD_02', 'CARD_03'].includes(cards.product))) {
    		return (
    			<div className="SettingScreen__Documents">
		{
    					!loader &&
                        <BlockingScreen />
    				}
    			</div>
    		);
    	}
    		return (
	<div className="PhoneScreen">
	<Title
			title={t(M.title)}
			legend={t(M.legend)}
    				className="SettingScreen__Title"
		/>

	<Tab
    				current={current}
    			>
	<Tab.Panel>
			{
    							user.phone &&
                                <Form.Group>
	<Input
                                		name="phoneCurrent"
	placeholder={t(M.inputs.phone)}
                                		value={user.phone}
                                		errors={errors.phone}
                                		disabled
                                	/>
                                </Form.Group>
    						}
			<Button
	className="PhoneScreen__Button"
    						onClick={this.onClick}
    						small
    						form
    					>
	{t(M.buttons.change)}
    					</Button>
    				</Tab.Panel>

	<Tab.Panel>
	<Form.Group>
    						{
    								user.phone &&
                                    <Input
	name="phoneCurrentD"
	placeholder={t(M.inputs.phone)}
	value={user.phone}
                                    	disabled
                                    />
    							}
    					</Form.Group>
    					<Form.Group>
    						<InputSelect
    							name="phoneCountry"
		onChange={this.onChange}
		placeholder={t(M.inputs.phoneCountry)}
		value={form.phoneCountry}
		errors={errors.phoneCountry}
		data={phoneCountry}
	/>
		</Form.Group>
    					<Form.Group>
    						<Input
		name="phone"
		type="number"
		placeholder={t(M.inputs.phone)}
    							onChange={this.onChange}
    							value={form.phone}
		errors={errors.phone}
	/>
		</Form.Group>
	<Form.Group>
	<Button
	className="PhoneScreen__Button"
	onClick={this.onClick}
    							loading={loader}
    							small
    							form
    						>
	{t(M.buttons.confirm)}
    						</Button>
    					</Form.Group>
    				</Tab.Panel>

	<Tab.Panel>
	{
    							user.phone &&
                                <Form.Group>
	<Input
	name="phoneCurrentDD"
	placeholder={t(M.inputs.phone)}
                                		value={user.phone}
	disabled
                                	/>
                                </Form.Group>
    						}
	<Form.Group>
	<InputSelect
					name="phoneCountryD"
    							onChange={this.onChangeSecondStep}
    							placeholder={t(M.inputs.phoneCountry)}
    							value={form.phoneCountry}
    							data={phoneCountry}
					disabled
				/>
    					</Form.Group>
	<Form.Group>
    						<Input
					name="phoneD"
					placeholder={t(M.inputs.phone)}
					onChange={this.onChangeSecondStep}
					value={form.phone}
    							disabled
				/>
    					</Form.Group>
    					<Form.Group>
			<Input
    							name="token"
	placeholder={t(M.inputs.token)}
	onChange={this.onChangeSecondStep}
	value={form.token}
	errors={errors.token}
    						/>
    					</Form.Group>
    					<Button
			className="PhoneScreen__Button"
			onClick={this.onClick}
			loading={loader}
    						small
			form
		>
    						{t(M.buttons.verify)}
    					</Button>
	<Button
	className="PhoneScreen__Resend"
    						onClick={this.onResend}
    						disabled={disableResend}
	small
	form
    						secondary
    					>
	{
    								disableResend &&
                                    <Timer time={15} />
    							}
    						{
    								!disableResend &&
                                    t(M.buttons.resend)
    							}
    					</Button>
    				</Tab.Panel>
    			</Tab>
    		</div>
    		);
    }
}
