export const getDeliveryDate = () => {
	const today = new Date(new Date().getTime() + 604800000 * 2);

	let dd = today.getDate();
	let mm = today.getMonth() + 1;
	let yyyy = today.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	return `${dd}.${mm}.${yyyy}`;
};
