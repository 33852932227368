import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Mark.scss';

export default function Mark (props) {
	const { className, children, large } = props;
	return (
		<div className={classnames('TooltipMark', className)} role="button" tabIndex={0}>
			<div className={classnames('TooltipMark__Mark', large && 'TooltipMark__Mark_Large')}>?</div>
			{children}
		</div>
	);
}

Mark.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	large: PropTypes.bool,
};
