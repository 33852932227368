import { createStore, applyMiddleware, compose } from 'redux';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import thunk from 'redux-thunk';
import storage from 'services/storage';
import reducers from '../reducers';

const persisted = storage.get('store') || {};

const composeEnhancers =
	(process.env.NODE_ENV === 'development' &&
	(typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__))
	|| compose;

const store = createStore(
	reducers,
	persisted,
	composeEnhancers(applyMiddleware(loadingBarMiddleware(), thunk))
);

store.subscribe(() => {
	const state = store.getState();
	storage.set('store', state);
});

if (module.hot) {
	module.hot.accept('../reducers', () => {
		const nextRootReducer = require('../reducers/index');
		store.replaceReducer(nextRootReducer);
	});
}

export default store;
