import { keys } from 'constants/recaptcha';

export const getKeys = env => {
	switch (env) {
		case 'dev': return keys.dev;
		case 'prod': return keys.prod;
		case 'localhost': return keys.localhost;
		case 'demo': return keys.demo;
		default: {
			const { origin } = location;
			const foundKey = Object.keys(keys).find(key => origin.includes(keys[key].site));
			if (foundKey) return keys[foundKey];
			return keys.prod;
		}
	}
};
