import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';

export default {
	fetch (business) {
		return fetcher.get(`business/${business}/application/shareholder`);
	},
	get (id, business) {
		return fetcher.get(`business/${business}/application/shareholder/${id}`);
	},
	create (form, business) {
		const convert = renamer.to.shareholder(form);
		return fetcher.post(`business/${business}/application/shareholder`, convert);
	},
	edit (form, id, business) {
		const convert = renamer.to.shareholder(form);
		return fetcher.put(`business/${business}/application/shareholder/${id}`, convert);
	},
	remove (id, business) {
		return fetcher.delete(`business/${business}/application/shareholder/${id}`);
	},
};
