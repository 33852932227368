import Card from './Card';
import CardCreateMe from './CardCreateMe';
import CardCreateOther from './CardCreateOther';
import Single from './Single';
import Success from './Success/Success';
import CardCreateTab from './CardCreateTab';

export default {
	Card,
	CardCreateMe,
	CardCreateOther,
	Single,
	Success,
	CardCreateTab,
};
