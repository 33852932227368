import {
	DOCUMENT_FETCH_SUCCESS,
	DOCUMENT_CREATE_SUCCESS,
	DOCUMENT_REMOVE_REQUEST,
	DOCUMENT_REMOVE_SUCCESS,
} from 'constants';

const initial = {
	certificateOfRegistration: [],
	memorandumOfAssociation: [],
	annualReturn: [],
	shareholderDetails: [],
	companyDirectors: [],
	evidenceOfBankAccount: [],
};

const reducer = (state = initial, action) => {
	switch (action.type) {
		case DOCUMENT_FETCH_SUCCESS:
			return action.payload;
		case DOCUMENT_CREATE_SUCCESS: {
			const item = action.payload;
			const type = item.type;
			return {
				...state,
				[type]: [
					...state[type],
					...item.document[type],
				],
			};
		}
		case DOCUMENT_REMOVE_REQUEST: {
			const { type } = action.payload;
			const documents = state[type].map(document => {
				if (document.id === action.payload.id) {
					return {
						...document,
						loading: true,
					};
				}
				return document;
			});

			return {
				...state,
				[type]: documents,
			};
		}
		case DOCUMENT_REMOVE_SUCCESS: {
			const { type } = action.payload;
			const documents = state[type].filter(document => document.id !== action.payload.id);
			return {
				...state,
				[type]: documents,
			};
		}
		default:
			return state;
	}
};

export default reducer;
