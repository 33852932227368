import React from 'react';
import { getCounter, FETCH_COUNTER_IS_ZERO_EVENT } from 'helpers/fetchCounter';
import LoaderGlobal from 'components/LoaderGlobal';

const globalLoaderShowEvent = 'global_loader_show';
const globalLoaderHideEvent = 'global_loader_hide';
const globalLoaderShowControlledEvent = 'global_loader_show_controlled';
const globalLoaderHideControlledEvent = 'global_loader_hide_controlled';
const hardHideLoaderTimeout = 20000;

class GlobalLoaderController extends React.PureComponent {
	static show () {
		document.dispatchEvent(new Event(globalLoaderShowEvent));
	}

	static hide () {
		document.dispatchEvent(new Event(globalLoaderHideEvent));
	}

	static showControlled () {
		document.dispatchEvent(new Event(globalLoaderShowControlledEvent));
	}

	static hideControlled () {
		document.dispatchEvent(new Event(globalLoaderHideControlledEvent));
	}

	state = {
		isShown: getCounter(),
		isShowControlled: false,
	}

	componentDidMount () {
		this.mounted = true;
		document.addEventListener(globalLoaderShowEvent, this.showLoader);
		document.addEventListener(globalLoaderHideEvent, this.hideLoader);
		document.addEventListener(globalLoaderShowControlledEvent, this.showLoaderControlled);
		document.addEventListener(globalLoaderHideControlledEvent, this.hideLoaderControlled);
		document.addEventListener(FETCH_COUNTER_IS_ZERO_EVENT, this.hideLoader);
		this.state.isShown && setTimeout(this.hideLoader, hardHideLoaderTimeout);
	}

	componentWillUnmount () {
		this.mounted = false;
		document.removeEventListener(globalLoaderShowEvent, this.showLoader);
		document.removeEventListener(globalLoaderHideEvent, this.hideLoader);
		document.removeEventListener(globalLoaderShowControlledEvent, this.showLoaderControlled);
		document.removeEventListener(globalLoaderHideControlledEvent, this.hideLoaderControlled);
		document.removeEventListener(FETCH_COUNTER_IS_ZERO_EVENT, this.hideLoader);
	}

	showLoader = () => this.mounted && this.setState({ isShown: true })

	hideLoader = () => this.mounted && this.setState({ isShown: false })

	showLoaderControlled = () => this.mounted && this.setState({ isShowControlled: true })

	hideLoaderControlled = () => this.mounted && this.setState({ isShowControlled: false })

	render () {
		const { isShown, isShowControlled } = this.state;
		return <LoaderGlobal loading={Boolean(isShown) || isShowControlled} />;
	}
}

export default GlobalLoaderController;
