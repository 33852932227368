import React from 'react';
import Layout from 'components/Layout';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import CardSection from 'sections/Card';

export default class Screen extends React.PureComponent {
	render () {
		return (
			<Layout className="CardScreen Screen" sidebar>
				<Nav />
				<Header />
				<CardSection />
			</Layout>
		);
	}
}
