import fetcher from 'helpers/fetcher';

export default {
	fetch (params) {
		return fetcher.get('open/currency', {}, { params });
	},
	convert (cid, params) {
		return fetcher.post(`/private/payment/${cid}/international/currency`, params);
	}
};
