import { createSelector } from 'reselect';
import { transactionSubType } from 'constants/common';
import comparer from 'helpers/comparer';

const subTypesList = [
	transactionSubType.POS_DOMESTIC,
	transactionSubType.POS_INTERNATIONAL,
	transactionSubType.ATM_DOMESTIC,
];

const getListDashboardItems = state => state.dashboard.items;

export const getSortParams = state => state.dashboard.sort;

export const fetch = createSelector(
	getListDashboardItems,
	getSortParams,
	(list, sort) => {
		const items = list && sort ? list.sort(comparer(sort)) : list;
		return items
			.map(item => {
				if (!subTypesList.includes(item.subType) &&
					item.details.includes('AuthNum', 0) && !item.details.includes('AuthNum', 2)) {
					return { ...item, details: '' };
				}
				return item;
			});
	},
);
