import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as paymentSelector from 'selectors/payment';
import * as cardActions from 'actions/card';

import Completed from './Completed';
import Declined from './Declined';
import { bindActionCreators } from 'redux';



const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	return {
		payment: paymentSelector.get(state, id),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});


@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	onFetch = async () => {
		const { actions } = this.props;
		await actions.card.fetch();
	};

	componentDidUpdate (prevProps) {
		this.onFetch();
	}

	render () {
		const { payment } = this.props;

		if (!payment) return null;

		return (
			<div>
				{
					// true &&
					(payment.status === 'COMPLETED') &&
					<Completed />
				}

				{
					// true &&
					(payment.status === 'DECLINED') &&
					<Declined />
				}
			</div>
		);
	}
}
