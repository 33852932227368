import * as config from 'constants/config';

export default {
	all: {
		file: {
			type: {
				value: /(jpg|jpeg|png|pdf)/,
				message: 'File type not supported'
			},
			size: {
				max: {
					value: config.MAX_FILE_SIZE,
					message: 'Too large'
				}
			}
		}
	}
};
