import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Container.scss';

export default function Container (props) {
	const { className, small, medium } = props;
	return (
		<div className={classnames(
			'Container', className,
			small && 'Container--Small',
			medium && 'Container--Medium')}
		>
			{props.children}
		</div>
	);
}

Container.propTypes = {
	medium: PropTypes.bool,
	small: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
};
