import PropTypes from 'prop-types';

export default PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	convertOnline: PropTypes.bool,
	decimalPlaces: PropTypes.number,
	incoming: PropTypes.bool,
	outgoing: PropTypes.bool,
});
