import { TOGGLE_MAINTENANCE_MODAL } from 'constants';

const initial = false;

const maintenanceModal = (state = initial, action) => {
	if (action.type === TOGGLE_MAINTENANCE_MODAL) {
		return !state;
	}

	return state;
};

export default maintenanceModal;
