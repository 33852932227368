import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Icon from 'components/Icon';
import Button from 'components/Button';

import M from './Retry.locale.json';



@injectIntl
export default class Retry extends Component {
	render () {
		const t = this.props.intl.formatMessage;

		return (
			<Wrap>
				<div
					className="ClientCreatePrivateScreen__Icon ClientCreatePrivateScreen__Icon--Warning"
				>
					<Icon
						name="warning"
					/>
				</div>

				<Title
					title={t(M.title)}
				/>

				<p>{t(M.messages.note)}</p>
				<p>{t(M.messages.retry)}</p>

				<Button
					form
					small
					loading={this.props.loader}
					onClick={this.props.onRetry}
				>
					{t(M.buttons.retry)}
				</Button>
			</Wrap>
		);
	}
}
