import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as clientSelector from 'selectors/client';
import { clientStatus } from '../../constants/common';

const mapState = state => ({
	user: state.user,
	client: clientSelector.getCurrentClient(state),
	initialRegulatoryDataCollection: state.card.initialRegulatoryDataCollection,
});


// TODO this component should be rewrite
@connect(mapState)
export default class RoutePrivate extends PureComponent {
	static propTypes = {
		client: PropTypes.object,
		user: PropTypes.object,
		location: PropTypes.object,
		component: PropTypes.oneOfType([
			PropTypes.node,
			PropTypes.func,
		]),
		initialRegulatoryDataCollection: PropTypes.object,
	}
	render () {
		const { client, user, location, component, initialRegulatoryDataCollection } = this.props;
		const { pathname, state } = location;

		// have user and is verified
		if (!user || !client) {
			return (
				<Redirect
					to={{
						pathname: '/auth/login',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (state && state.redirect) {
			delete this.props.location.state;
			return (
				<Route component={component} {...this.props} />
			);
		}

		if (user.migration === 'STARTED') {
			return (
				<Redirect
					to={{
						pathname: '/auth/resetMigrated',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (!user.verified) {
			return (
				<Redirect
					to={{
						pathname: '/auth/email',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (!user.phoneVerified) {
			return (
				<Redirect
					to={{
						pathname: '/auth/phone',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (user.phoneVerified && user.verified && !initialRegulatoryDataCollection && client.status !== clientStatus.ACTIVE) {
			return (
				<Redirect
					to={{
						pathname: '/auth/regulatory-data-collection',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		// if invited must change password
		if (user.status === 'REQUESTED') {
			return (
				<Redirect
					to={{
						pathname: `/invite/password`,
						state: {
							redirect: true,
						},
					}}
				/>
			);
		}

		const { status, role } = client;
		const inShareholders = (pathname.indexOf('shareholders') !== -1);

		if (status === 'INITIATED' && inShareholders) {
			delete this.props.location.state;
			return (
				<Route component={component} {...this.props} />
			);
		}

		if (status === 'INITIATED' && pathname.indexOf('/clients/create/') === -1) {
			return (
				<Redirect
					to={{
						pathname: `/clients/create/${role === 'PRIVATE' ? 'private' : `business/${client.entityId}`}`,
						state: {
							redirect: true,
						},
					}}
				/>
			);
		}

		if (status === 'REQUESTED') {
			return (
				<Redirect
					to={{
						pathname: `/clients/create/business/${client.entityId}/submitted`,
						state: {
							redirect: true,
						},
					}}
				/>
			);
		}

		return (
			<Route component={component} {...this.props} />
		);
	}
}
