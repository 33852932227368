import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

class MemberButton extends React.PureComponent {
	static propTypes = {
		item: PropTypes.shape({
			title: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		}).isRequired,
	}

	onClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.item.onClick();
	}

	render () {
		return (
			<Button
				key={idx}
				plain
				onClick={this.onClick}
			>
				{this.props.item.title}
			</Button>
		);
	}
}

export default MemberButton;
