import { SESSION_EXTEND_SUCCESS, SESSION_MODAL } from 'constants';
import API from 'services/api';

export const extend = () => async dispatch => {
	try {
		await API.user.get();
		dispatch({ type: SESSION_EXTEND_SUCCESS });
	} catch (error) { }
};

export const modal = () => ({
	type: SESSION_MODAL,
});
