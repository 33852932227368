import { createSelector } from 'reselect';
import { card, wallet, cardStatus } from 'constants/common';

const commonCards = [card.CARD01, card.CARD02, card.CARD03, card.CARD07, card.CARD08];
const wallets = [wallet.WALLET01, wallet.WALLET02];
const productList = [...commonCards, ...wallets];

const filterClosedStatus = items => items.filter(item => item.cardStatus !== cardStatus.CLOSED);
const filterStatus = items => item => items.indexOf(item.cardStatusTrunk) > -1;
const filterSearch = raw => {
	const keyword = raw.toLowerCase();
	return c => {
		if (c.cardName.toLowerCase().includes(keyword)) return true;
		if (c.expirationDate.toLowerCase().includes(keyword)) return true;
		if (c.cardStatusTrunk.toLowerCase().includes(keyword)) return true;
		return c.cardNumber.toLowerCase().includes(keyword);
	};
};

export const getList = state => state.card.items;
export const getCardStatus = state => String(state.card.status);

export const getFirstCardId = createSelector(
	getList,
	items => {
		if (items && items.length) return items[0].id;
		return null;
	}
);

export const getCurrentCardId = state => state.card.current;

export const getCurrentCard = createSelector(
	getList,
	getCurrentCardId,
	(items, currentId) => items.find(item => item.id === currentId) || items[0]
);

export const getBalance = createSelector(
	getList,
	items => items.reduce((total, currentCard) => {
		const value = currentCard.availBal || 0;
		return total + value;
	}, 0)

);

export const statusNav = (props, items) => {
	const t = props.intl.formatMessage;
	return items.map(item => ({
		...item,
		title: t(item.title),
	}));
};

export const getCardSearch = state => state.card.search;

export const fetch = createSelector(
	getList,
	getCardStatus,
	getCardSearch,
	(items, status, search) => {
		const filtered = status.length > 0
			? items.filter(filterStatus(status))
			: [];

		const finalResult = search
			? filtered.filter(filterSearch(search))
			: filtered;

		finalResult.sort((a, b) => b.id - a.id);

		return filtered;
	},
);

export const fetchToAddSecondary = createSelector(
	getList,
	getCardStatus,
	(items, status) => {
		const filtered = status.length
			? items.filter(filterStatus(status))
			: [];

		return filtered.filter(card => card.canAddSecondaryCard);
	},
);

export const getById = (state, id) => {
	if (!id || isNaN(Number(id))) return null;
	return state.card.items.find(item => item.id === Number(id));
};

export const getDropdownValues = createSelector(
	getList,
	items => items.map(item => ({
		value: item.id,
		title: item.cardName,
		subtitle: item.cardNumber,
	}))
);

export const getDropdownValuesWithoutClosedStatus = createSelector(
	getList,
	items => filterClosedStatus(items).map(item => ({
		value: item.id,
		title: item.cardName,
		subtitle: item.cardNumber,
	}))
);

export const getInitialRegulatoryDataCollection =
		state => state.card.initialRegulatoryDataCollection;

export const getCardsWithoutUDFS = createSelector(
	getList,
	items => filterClosedStatus(items).filter(item => !item.udfs)
);

export const getUnverifyCards = createSelector(
	getList,
	items => filterClosedStatus(items).filter(item => !productList.includes(item.product))
);
