import Login from './Login';
import Signup from './Signup';
import Reset from './Reset';
import Password from './Password';
import Email from './Email';
import Phone from './Phone';
import Activate from './Activate';
import ResetMigrated from './ResetMigrated';
import RegulatoryDataCollection from './RegulatoryDataCollection';


export default {
	Login,
	Signup,
	Reset,
	Password,
	Email,
	Phone,
	Activate,
	ResetMigrated,
	RegulatoryDataCollection
};
