import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import M from './Collapse.locale.json';
import './Collapse.scss';

import intlTypes from 'types/intl';

@injectIntl
export default class Collapse extends PureComponent {
	static propTypes = {
		children: PropTypes.any,
		className: PropTypes.string,
		intl: intlTypes.isRequired,
	}

	state = {
		open: false,
	}

	onToggle = e => {
		e.preventDefault();
		const { open } = this.state;
		this.setState({ open: !open });
	}

	render () {
		const { open } = this.state;
		const { className, intl, children } = this.props;
		const t = intl.formatMessage;

		return (
			<div className={classnames('Collapse', open && 'Collapse--Open', className)}>
				<div className="Collapse__Content">
					{children}
				</div>

				<div
					className="Collapse__Button"
					onClick={this.onToggle}
					role="button"
					tabIndex={0}
				>
					{open ? t(M.buttons.close) : t(M.buttons.expand)}
				</div>
			</div>
		);
	}
}
