import geocoderHelper from 'helpers/geocoder';

const client = {
	autocomplete: new google.maps.places.AutocompleteService(),
	geocode: new google.maps.Geocoder(),
};

export default {
	autocomplete (place, limit) {
		return new Promise((resolve, reject) => {
			const options = { input: place, types: ['address'] };
			if (limit) options.componentRestrictions = { country: limit };

			client.autocomplete.getPlacePredictions(options, (data, status) => {
				if (status !== google.maps.places.PlacesServiceStatus.OK) reject(status);
				resolve(data);
			});
		});
	},

	geocode (pid) {
		return new Promise((resolve, reject) => {
			client.geocode.geocode({
				placeId: pid,
			}, (data, status) => {
				if (status !== google.maps.places.PlacesServiceStatus.OK) reject(status);
				resolve(geocoderHelper.decode(data));
			});
		});
	},

	language (locale) {
		document.getElementById('AutocompleteLoader').remove();
		delete window.google;
		const script = document.createElement('script');
		script.id = 'AutocompleteLoader';
		let query = '?key=AIzaSyDwhrExOBKrBwirUG1Pmpy8RfwMUDIVOyE&libraries=places';
		query += `&language=${locale}`;
		script.src = `https://maps.googleapis.com/maps/api/js${query}`;
		script.addEventListener('load', () => {
			client.autocomplete = new google.maps.places.AutocompleteService();
			client.geocode = new google.maps.Geocoder();
		});
		document.head.appendChild(script);
	},
};
