import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputCheckboxError from './Error';
import './InputCheckbox.scss';

class InputCheckbox extends React.PureComponent {
	static propTypes = {
		children: PropTypes.any,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		checked: PropTypes.bool,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,
		errors: PropTypes.array,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
	}

	onSpace = e => {
		const { onChange, name } = this.props;
		if (e.keyCode === 32) onChange(!this.ref.checked, name, e);
	}

	onChange = e => {
		const { onChange, name } = this.props;
		onChange(e.target.checked, name, e);
	}

	ref = null

	render () {
		const { className, name, children, placeholder, disabled, errors,
			autoFocus, checked, value, onBlur, onFocus } = this.props;
		return (
			<div className={classnames(
				'InputCheckbox', className,
				disabled && 'InputCheckbox--Disabled',
				errors && 'InputCheckbox--Invalid')}
			>
				<input
					id={name}
					ref={input => { this.ref = input; }}
					type="checkbox"
					className="InputCheckbox__Input"
					onChange={this.onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					name={name}
					value={value}
					checked={checked}
					autoFocus={autoFocus}
					disabled={disabled}
					placeholder={placeholder}
				/>

				<label htmlFor={name} className="InputCheckbox__Label" onKeyUp={this.onSpace}>
					{children}
				</label>

				{errors && !!errors.length && (
					<InputCheckboxError
						name={name}
						placeholder={placeholder}
						errors={errors}
					/>
				)}
			</div>
		);
	}
}

InputCheckbox.Error = InputCheckboxError;

export default InputCheckbox;
