export default function user (form) {
	if (!form) return null;

	const convert = {
		...form,
	};

	if (form.name) {
		convert.firstName = form.name;
		delete convert.name;
	}
	if (form.surname) {
		convert.lastName = form.surname;
		delete convert.surname;
	}
	return convert;
}
