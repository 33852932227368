import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '../Avatar';
import './User.scss';

@withRouter
export default class User extends React.PureComponent {
	static propTypes = {
		user: PropTypes.shape({
			image: PropTypes.string,
			name: PropTypes.string,
			surname: PropTypes.string,
		}).isRequired,
		className: PropTypes.string,
		history: PropTypes.object.isRequired,
	}

	onSettings = () => this.props.history.push('/settings')

	render () {
		const { className, user } = this.props;
		return (
			<div className={classnames('User', className)}>
				<div
					onClick={this.onSettings}
					role="button"
					tabIndex={0}
					className="User__Wrap"
				>
					<Avatar user={user} className="User__Avatar" small />
					<div className="User__Name">{user.name}</div>
				</div>
			</div>
		);
	}
}
