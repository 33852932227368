import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Panel.scss';

export default function TabPanel (props) {
	const { className, current, children, id } = props;

	return (
		<div className={classnames('TabPanel', className, current && 'TabPanel--Active')} id={`settings-${id}`}>
			{children}
		</div>
	);
}

TabPanel.propTypes = {
	current: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	id: PropTypes.string,
};
