import { memberAccess, memberRequestStatus } from 'constants/common';
import storage from 'services/storage';
import CONFIG from '../../../../../env';

const get = user => {
	const convert = {
		...user,
	};
	const token = storage.get('session').split(' ')[1];

	if (convert.firstName) {
		convert.name = user.firstName;
		delete convert.firstName;
	}
	if (convert.lastName) {
		convert.surname = user.lastName;
		delete convert.lastName;
	}
	if (convert.role === memberAccess.OWNER) convert.roleShort = 'A';
	if (convert.role === memberAccess.BOOKKEEPER) convert.roleShort = 'V';
	if (!convert.requestStatus) convert.requestStatus = memberRequestStatus.GRANTED;
	if (convert.avatarId) convert.avatar = `${CONFIG.api}/open/user/avatar/${convert.userId}?token=${token}`;
	return convert;
};

const fetch = users => {
	if (!users) return users;
	return users.map(get);
};

export default {
	fetch,
	get,
};
