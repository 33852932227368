import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetcher from 'helpers/fetcher';

export const performPayment = ({ amount }) => async (dispatch, getState) => {
	const state = getState();
	const cardHolderId = state.card.items.find(x => x.id === state.card.current)
		.id;

	showLoading();

	fetcher.post(`/private/card-info/${cardHolderId}/trans-to-card`, {
		amount,
		myData: true,
		mobile: false,
	}).then(({ data: { response } }) => {
		if (response && response.urlToCompletePayment) {
			window.location.replace(response.urlToCompletePayment);
		}
	})
		.finally(() => {
			hideLoading();
		});
};
