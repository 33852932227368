import {
	PHONE_GET_SUCCESS,
	PHONE_CREATE_SUCCESS,
} from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import M from './phone.locale.json';

export const get = form => async dispatch => {
	try {
		const { data: { response } } = await API.phone.get(form);
		dispatch({ type: PHONE_GET_SUCCESS, payload: { ...response } });
		success(dispatch)({ title: M.success.get.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.get.title });
		return err;
	}
};

export const create = form => async dispatch => {
	try {
		const { data: { response } } = await API.phone.create(form);
		dispatch({ type: PHONE_CREATE_SUCCESS, payload: { ...response } });
		return response;
	} catch (err) { return err; }
};

export const change = form => async dispatch => {
	try {
		const { data: { response } } = await API.phone.change(form);
		success(dispatch)({ title: M.success.get.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.get.title });
		return err;
	}
};

export const confirm = (formId, token) => async dispatch => {
	try {
		const { data: { response } } = await API.phone.confirm(formId, token);
		success(dispatch)({ title: M.success.verify.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.code.title });
		return err;
	}
};

export const resend = id => async dispatch => {
	try {
		const { data: { response } } = await API.phone.resend(id);
		success(dispatch)({ title: M.success.get.title });
		return response;
	} catch (err) {
		error(dispatch)({ title: M.error.get.title });
		return err;
	}
};
