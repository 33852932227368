export default {
	get (key, local) {
		const storage = local ? localStorage : sessionStorage;
		try {
			const data = storage.getItem(key);
			if (!data) return null;
			return JSON.parse(data);
		} catch (err) { return null; }
	},
	set (key, value, local) {
		const storage = local ? localStorage : sessionStorage;
		try { storage.setItem(key, JSON.stringify(value)); } catch (err) { }
	},
	remove (key, local) {
		const storage = local ? localStorage : sessionStorage;
		return storage.removeItem(key);
	},
};
