import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NavVerticalItem from './NavVerticalItem';
import './NavVertical.scss';

export default function NavVertical (props) {
	const { className, title, onClick, active, items } = props;
	return (
		<div className={classnames('NavVertical', className)}>
			{title && <h3 className="NavVertical__Title">{title}</h3>}
			<ul>
				{items.map((item, idx) => (
					<NavVerticalItem
						key={item.id}
						idx={idx}
						onClick={onClick}
						item={item}
						className={classnames('NavVertical__Link', active && 'NavVertical__Item--Active')}
					/>
				))}
			</ul>
		</div>
	);
}

NavVertical.propTypes = {
	title: PropTypes.string,
	active: PropTypes.number,
	items: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.any,
		link: PropTypes.link,
	})),
	onClick: PropTypes.func,
	className: PropTypes.string,
};
