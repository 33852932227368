import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Title.scss';

export default function Title (props) {
	const { className, small, title, legend } = props;
	return (
		<div className={classnames('Title', className)}>
			{!small && <h1 className="Title__Heading">{title}</h1>}
			{small && <h2 className="Title__Heading">{title}</h2>}
			{legend && <div className="Title__Legend">{legend}</div>}
		</div>
	);
}

Title.propTypes = {
	title: PropTypes.string.isRequired,
	legend: PropTypes.any,
	small: PropTypes.bool,
	className: PropTypes.string,
};
