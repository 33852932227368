import React, { PureComponent } from 'react';

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import PaymentSection from 'sections/Payment';

import Layout from 'components/Layout';

import Create from './Create';



export default class Screen extends PureComponent {
	render () {
		return (
			<Layout
				className="Payment Page"
				sidebar
			>
				<Nav />
				<Header />

				<PaymentSection />
			</Layout>
		);
	}
}


Screen.Create = Create;
