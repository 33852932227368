export default {
	export: {
		from: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
				message: '^schemas.incorrect',
			},
			dateBefore: { message: '^schemas.date.future' },
		},

		till: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
				message: '^schemas.incorrect',
			},
			dateBefore: { message: '^schemas.date.future' },
		},

		type: {
			presence: { message: '^schemas.presence' },
		},
	},
};
