import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as clientSelector from 'selectors/client';

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import MemberSection from 'sections/Member';

import Layout from 'components/Layout';

import Create from './Create';
import Edit from './Edit';

import './Member.scss';



const mapState = state => ({
	client: clientSelector.getCurrentClient(state),
});

const mapDispatch = dispatch => ({
	actions: {
	},
});

@connect(mapState, mapDispatch)
export default class Screen extends Component {
	render () {
		const { client } = this.props;

		if (client.role === 'BOOKKEEPER') {
			return (
				<Redirect to="/" />
			);
		}

		return (
			<Layout
				className="MemberScreen Screen"
				sidebar
			>
				<Nav />
				<Header />

				<MemberSection />
			</Layout>
		);
	}
}



Screen.Create = Create;
Screen.Edit = Edit;
