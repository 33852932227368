import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'components/Loader';
import './ModalLoader.scss';

export default function ModalLoader (props) {
	const { className } = props;
	return (
		<div className={classnames('ModalLoader', className)}>
			<Loader />
		</div>
	);
}

ModalLoader.propTypes = {
	className: PropTypes.string,
};
