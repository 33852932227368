import PropTypes from 'prop-types';

export default PropTypes.shape({
	accountBaseCurrency: PropTypes.string,
	accountNumber: PropTypes.string,
	activated: PropTypes.bool,
	availBal: PropTypes.number,
	badPinTries: PropTypes.number,
	bic: PropTypes.string,
	canAddSecondaryCard: false,
	cardName: PropTypes.string,
	cardNumber: PropTypes.string,
	cardStatus: PropTypes.string,
	cardStatusTrunk: PropTypes.string,
	cardStyle: PropTypes.string,
	cardType: PropTypes.string,
	cardholderId: PropTypes.string,
	distributorCode: PropTypes.string,
	expirationDate: PropTypes.string,
	iban: PropTypes.string,
	id: PropTypes.number,
	ledgerBal: PropTypes.number,
	loadAmount: PropTypes.number,
	phoneCardNumber: PropTypes.string,
	pinTriesExceeded: PropTypes.number,
	product: PropTypes.string,
	udfs: PropTypes.shape({
		occupationProfession: PropTypes.number,
		occupation: PropTypes.number,
		cardPurpose: PropTypes.number,
		monthlyIncome: PropTypes.number,
		estate: PropTypes.number,
	}),
	updating: PropTypes.bool,
});
