import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';

import M from './General.locale.json';

import './Terms.scss';


@injectIntl
export default class Terms extends Component {
	pop = () => {
		this.props.history.goBack();
	}

	render () {
		const t = this.props.intl.formatMessage;

		return (
			<Modal
				onClick={this.pop}
				className="TermsScreen Screen"
			>
				<Section>
					<Container>
						<Wrap>
							<Modal.Close
								onClick={this.pop}
							/>

							<div
								className="PrivacyScreen__Iframe"
							>
								<iframe src={t(M.links.terms)} />
							</div>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
