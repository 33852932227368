import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './PaymentResultPage.scss';
import '../Dashboard/Dashboard.scss';
import Icon from './screens/Success/Icon';
import ButtonLink from '../../components/ButtonLink';
import Title from '../../components/Title';
import intlTypes from '../../types/intl';
import Nav from '../Nav';
import Layout from '../../components/Layout';

const PaymentResultPage = ({ intl, history, titleId, Icon, linkId, linkUrl, mobileLink, hideLayout }) => {
	const t = intl.formatMessage;

	const handleLinkClick = useCallback(() => {
		if (mobileLink) {
			window.location.href = linkUrl;
			return;
		}

		history.push(linkUrl);
	}, [linkUrl]);

	const pageContent = useMemo(() => (
		<div className={cn('pfs-result-screen', { 'pfs-result-screen--page': hideLayout })}>
			<div className="pfs-result-screen-content">
				<Icon className="pfs-result-screen-content__icon" />
				<Title title={t({ id: titleId })} />
				{(linkId) && <ButtonLink className="pfs-result-screen-content__link" onClick={handleLinkClick}>{t({ id: linkId })}</ButtonLink>}
			</div>
		</div>
	), [t, hideLayout, linkId, titleId, handleLinkClick, Icon]);

	return !hideLayout ? (
		<Layout className="DashboardScreen" sidebar>
			<Nav />
			{pageContent}
		</Layout>
	) : pageContent;
};

PaymentResultPage.propTypes = {
	hideLayout: PropTypes.bool,
	titleId: PropTypes.string.isRequired,
	Icon: PropTypes.node.isRequired,
	linkId: PropTypes.string,
	linkUrl: PropTypes.string,
	mobileLink: PropTypes.bool,
	intl: intlTypes.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default withRouter(injectIntl(PaymentResultPage));
