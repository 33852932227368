import { createSelector } from 'reselect';



export const language = (state, props) => {
	const t = props.intl.formatMessage;
	const hasTitle = item => item.title && item.title.hasOwnProperty('id');

	return state.toast.map(item => {
		const convert = {
			...item,
			title: hasTitle(item) ? t(item.title) : '',
		};

		if (convert.body) convert.body = t(convert.body);

		return convert;
	});
};
