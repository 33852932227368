const get = item => ({
	...item,
	cardStatusTrunk: item.cardStatus,
});

const fetch = items => items.map(get);

export default {
	fetch,
	get,
};
