import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import Timer from 'components/Timer';
import * as authActions from 'actions/auth';
import * as emailActions from 'actions/email';
import helpers from 'helpers';
import { triggerEvent } from 'helpers/analytics';
import { GTMEvent } from 'constants/gtm';
import AuthSchema from 'schemas/auth';
import M from './Email.locale.json';
import './Email.scss';
import tracker from 'helpers/tracker';
import { UTMParams } from 'constants/common';

import intlTypes from 'types/intl';

const disableTime = 15000;

const mapState = state => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
		email: bindActionCreators(emailActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Email extends Component {
	static propTypes = {
		intl: intlTypes.isRequired,
		user: PropTypes.shape({
			email: PropTypes.string.isRequired,
			verified: PropTypes.bool.isRequired,
		}).isRequired,
		location: PropTypes.shape({
			state: PropTypes.shape({
				from: PropTypes.shape({
					pathname: PropTypes.string,
				}),
			})
		}),
	}

	state = {
		loaderResend: false,
		disabledResend: false,
		form: { code: '' },
		errors: { code: [] },
	}

	componentDidMount () {
		this.onResend();
		triggerEvent(GTMEvent.eMailVerification, '/auth/email');

		document.addEventListener('mousemove', tracker.onMouseMove);
	}


	componentWillUnmount () {
		clearTimeout(this.timeout);

		document.removeEventListener('mousemove', tracker.onMouseMove);
	}

	onResend = async () => {
		const { actions } = this.props;
		const { loaderResend, disabledResend } = this.state;

		if (loaderResend || disabledResend) return;

		this.setState({ loaderResend: true });
		await actions.email.get();
		this.onDisable();
	}

	onDisable = () => {
		this.setState({ loaderResend: false, disabledResend: true });
		this.timeout = setTimeout(
			() => this.setState({ disabledResend: false }), disableTime);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, AuthSchema.email);
		}

		this.setState({
			loader: false,
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions, user } = this.props;
		const { form, loader } = this.state;
		if (loader) return;
		const errors = helpers.validator.all(form, AuthSchema.email);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });

			tracker.onError();
			return;
		}
		form.language = user.language;
		this.setState({ loader: true });

		this.fixateTrackResult();

		const response = await actions.auth.email({ ...form });

		if (response && response.errors) {
			const update = {
				errors: { ...this.state.errors, ...response.errors },
				loader: false,
			};
			if (response.messages) update.messages = response.messages;
			this.setState(update);
		}
	}

	fixateTrackResult = () => {
		const result = tracker.analyse();
		if (result.count > 0) {
			const params = {
				utm_source: 'PROBABLY_UNSAFE',
				utm_medium: 'PROBABLY_UNSAFE',
				utm_campaign: result.mark,
			};
			localStorage.setItem(UTMParams, JSON.stringify(params));
		}
	}

	render () {
		const { form, errors, loader, loaderResend, disabledResend } = this.state;
		const { user, intl, location } = this.props;
		const { from } = location.state || { from: { pathname: '/' } };

		if (!user) return <Redirect to="/" />;
		if (user.verified) return <Redirect to={from} />;

		const t = intl.formatMessage;

		return (
			<Layout className="AuthEmailScreen Screen">
				<Nav logo />
				<Section>
					<Container small>
						<Title title={t(M.title)} />
						<Form.Note>{t(M.messages.verification)} <strong>{user.email}</strong>.</Form.Note>
						<Form onSubmit={this.onSubmit} onKeyDown={tracker.onKeyboardEvent}>
							<Form.Group>
								<Input
									name="code"
									placeholder={t(M.inputs.code)}
									onChange={this.onChange}
									value={form.code}
									errors={errors.code}
								/>
							</Form.Group>
							<Button
								form
								small
								loading={loader}
							>
								{t(M.buttons.verify)}
							</Button>
							<ButtonLink
								onClick={this.onResend}
								className={cn('AuthEmailScreenResend', { 'AuthEmailScreenResendDisabled': loader || disabledResend })}
							>
								{disabledResend && <Timer time={15} />}
								{!disabledResend && t(M.buttons.resend)}
							</ButtonLink>
						</Form>
					</Container>
				</Section>
			</Layout>
		);
	}
}
