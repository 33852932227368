import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Day from './Day';

export default function Week (props) {
	const { className, data, disabled, onSelect } = props;
	const content = data.map(item => {
		const id = Math.random() * 10000;
		if (!item) return <Day key={id} />;
		const timestamp = item.timestamp || id;
		return (
			<Day
				key={timestamp}
				onClick={() => onSelect(timestamp)}
				date={timestamp}
				today={item.today}
				range={item.range}
				disabled={disabled < timestamp}
			/>
		);
	});

	return (
		<div className={classnames('Calendar__Week', className)}>
			{content}
		</div>
	);
}

Week.propTypes = {
	disabled: PropTypes.instanceOf(Date),
	data: PropTypes.arrayOf(PropTypes.any),
	className: PropTypes.string,
	onSelect: PropTypes.func,
};
