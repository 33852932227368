import API from 'services/api';

export const get = () => async () => {
	try {
		const { data: { response } } = await API.application.get();
		return response;
	} catch (error) {
		return error;
	}
};
