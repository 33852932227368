export default {
	create: {
		name: {
			presence: true,
			length: {
				minimum: 3,
				message: 'must be at least 3 characters',
			},
		},
		password: {
			presence: true,
			length: {
				minimum: 6,
				message: 'must be at least 6 characters',
			},
		},
	},
	activate: {
		cardNumber: {
			presence: true,
			numericality: {
				message: 'can only contain numbers',
			},
			length: {
				minimum: 16,
				message: 'must be at least 16 characters',
			},
		},
	},
	secondaryMy: {
		card: {
			minimum: 1,
			presence: true,
		},
	},

	secondaryOther: {
		name: {
			presence: { message: '^schemas.presence' },
			length: {
				minimum: 2,
				tooShort: '^schemas.length.min',
				maximum: 20,
				tooLong: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z ]+$',
				message: '^schemas.alphabetic'
			},
			fullnamelenght: {
				message: '^schemas.length.name.fullname'
			}
		},
		surname: {
			presence: {
				message: '^schemas.presence',
			},
			length: {
				minimum: 2,
				tooShort: '^schemas.length.min',
				maximum: 20,
				tooLong: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z ]+$',
				message: '^schemas.alphabetic'
			},
			fullnamelenght: {
				message: '^schemas.length.surname.fullname'
			}
		},
		dateOfBirth: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
				message: '^schemas.incorrect',
			},
			dateBefore: { message: '^schemas.date.future' },
		},
		email: {
			presence: { message: '^schemas.presence' },
			email: { message: '^schemas.email' },
		},
		phone: {
			presence: { message: '^schemas.presence' },
			numericality: {
				message: 'can only contain numbers',
			},
			length: {
				minimum: 2,
				message: '^schemas.length.min',
			},
		},
		address: {
			presence: { message: '^schemas.presence' },
		},
		phoneCountry: {
			presence: { message: '^schemas.presence' },
		},
	},
};
