import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './Loader.scss';

export default function LoaderGlobal (props) {
	const { className, loading } = props;
	return (
		<div className={classnames('GlobalLoader', className, loading && 'GlobalLoader--Open')}>
			<div className="ModalLoader">
				<Loader small />
			</div>
		</div>
	);
}

LoaderGlobal.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool,
};
