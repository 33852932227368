import {
	paymentsCreate,
	c2cPaymentsCreate,
	internationalPaymentCreate,
	depositSend,
} from 'constants/paths';

export const paths = {
	payments: paymentsCreate,
	c2cPayments: c2cPaymentsCreate,
	internationalPayment: internationalPaymentCreate,
	depositSend,
};
