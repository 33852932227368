export default function contact (data) {
	if (!data) return data;

	const convert = {
		...data,
	};

	if (data.firstName) {
		convert.name = data.firstName;
		delete convert.firstName;
	}

	if (data.lastName) {
		convert.surname = data.lastName;
		delete convert.lastName;
	}

	// fake created object
	convert.id = 1;

	return convert;
}
