import storage from 'services/storage';
import CONFIG from '../../../../../env';

const get = form => {
	if (!form) return form;

	const token = storage.get('session').split(' ')[1];

	const convert = {
		...form,
	};

	delete convert.role;

	if (convert.avatarId) convert.avatar = `${CONFIG.api}open/user/avatar/${convert.id}?token=${token}`;
	if (convert.firstName) {
		convert.name = form.firstName;
		delete convert.firstName;
	}
	if (convert.lastName) {
		convert.surname = form.lastName;
		delete convert.lastName;
	}
	return convert;
};

export default {
	get,
};
