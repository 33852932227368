import {
	TRANSACTION_FETCH_REQUEST,
	TRANSACTION_FETCH_SUCCESS,
	TRANSACTION_GET_SUCCESS,
	TRANSACTION_SET_SEARCH,
	TRANSACTION_SET_TYPES,
	TRANSACTION_SET_COLUMNS,
	TRANSACTION_SET_STATUS,
	TRANSACTION_SET_SORT,
} from 'constants';
import { transactions, transactionTypes } from 'constants/common';

const INITIAL = {
	search: '',
	sort: {
		id: 'date',
		asc: true,
	},
	columns: ['beneficiary', 'details', 'status', 'date', 'transactionType', 'amount', 'translated', 'type'],
	types: transactionTypes,
	status: transactions,
	items: [],
};

const reducer = (state = INITIAL, action) => {
	switch (action.type) {
		case TRANSACTION_FETCH_REQUEST:
			return { ...state, items: [...state.items] };
		case TRANSACTION_FETCH_SUCCESS:
			return { ...state, items: [...action.payload.transactions] };
		case TRANSACTION_GET_SUCCESS: {
			const items = [...state.items];
			const idx = items.findIndex(item => item.id === action.payload.id);
			if (idx >= 0) items.splice(idx, 1, { ...action.payload });
			return { ...state, items };
		}
		case TRANSACTION_SET_SEARCH:
			return { ...state, search: action.payload };
		case TRANSACTION_SET_TYPES:
			return { ...state, types: action.payload };
		case TRANSACTION_SET_COLUMNS:
			return { ...state, columns: action.payload };
		case TRANSACTION_SET_STATUS:
			return { ...state, status: action.payload };
		case TRANSACTION_SET_SORT:
			return { ...state, sort: action.payload };
		default:
			return state;
	}
};

export default reducer;
