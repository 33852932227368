export default function location (data) {
	const convert = {
		regCountry: data.registeredAddress.country,
	};

	if (data.registeredAddress.line1) convert.regAddress = data.registeredAddress.line1;
	if (data.registeredAddress.postalCode) convert.regPostalCode = data.registeredAddress.postalCode;
	if (data.registeredAddress.city) convert.regCity = data.registeredAddress.city;

	if (data.principalAddress) {
		convert.identical = false;

		if (data.principalAddress.line1 !== null) convert.address = data.principalAddress.line1;
		if (data.principalAddress.country !== null) convert.country = data.principalAddress.country;
		if (data.principalAddress.postalCode !== null) {
			convert.postalCode = data.principalAddress.postalCode;
		}
		if (data.principalAddress.city) convert.city = data.principalAddress.city;
	}

	if (data.website) convert.website = data.website;

	return convert;
}
