import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { gtmId, GAId } from 'constants/gtm';

const tagManagerArgs = { gtmId };

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(GAId);

export default TagManager;

export const triggerEvent = (data, name = '') => {
	ReactGA.event(data);
	// TagManager.dataLayer({
	// 	dataLayer: data,
	// 	dataLayerName: name,
	// })
};
