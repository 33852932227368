import React, { Component } from 'react';

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import SectionHistory from 'sections/History';

import Layout from 'components/Layout';

import Single from './Single';
import Export from './Export';

import './History.scss';



export default class History extends Component {
	render () {
		return (
			<Layout
				className="HistoryScreen Screen"
				sidebar
			>
				<Nav />

				<Header />

				<SectionHistory />
			</Layout>
		);
	}
}



History.Single = Single;
History.Export = Export;
