import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Form from 'components/Form';
import InputSelect from 'components/InputSelect';
import formFocusErrors from '../../helpers/form-focus-errors';
import data from './constants';
import M from './RegulatoryDataCollectionForm.locale.json';

import intlTypes from 'types/intl';

const initErrors = {
	occupationProfession: null,
	occupation: null,
	cardPurpose: null,
	monthlyIncome: null,
	estate: null,
};
const initValues = {
	occupationProfession: null,
	occupation: null,
	cardPurpose: null,
	monthlyIncome: null,
	estate: null,
};

const setInitValues = values => {
	if (!values) return initValues;
	return {
		occupationProfession: values.occupationProfession,
		occupation: values.occupation,
		cardPurpose: values.cardPurpose,
		monthlyIncome: values.monthlyIncome,
		estate: values.estate,
	};
};

@injectIntl
class RegulatoryDataCollectionForm extends React.PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		intl: intlTypes.isRequired,
		button: PropTypes.node,
		values: PropTypes.shape({
			occupationProfession: PropTypes.number,
			occupation: PropTypes.number,
			cardPurpose: PropTypes.number,
			monthlyIncome: PropTypes.number,
			estate: PropTypes.number,
		}),
		disabled: PropTypes.bool,
		currentCardId: PropTypes.number,
		id: PropTypes.string,
	};

	static getDerivedStateFromProps (props, state) {
		if (props.currentCardId && props.currentCardId !== state.currentCardId) {
			return {
				...state,
				currentCardId: props.currentCardId,
				form: setInitValues(props.values),
			};
		}
		return null;
	}

	state = {
		form: setInitValues(this.props.values),
		currentCardId: this.props.currentCardId,
		errors: initErrors,
	}

	handleChange = (value, name) => {
		this.setState(state => ({
			...state,
			form: {
				...state.form,
				[name]: value,
			},
		}));
	}

	handleSubmit = e => {
		e.preventDefault();
		const { onSubmit } = this.props;
		const { form } = this.state;

		if (Object.values(form).some(elem => !elem)) {
			const errors = {};
			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(form)) {
				errors[key] = !value ? [M.error] : null;
			}

			this.setState(state => {
				const finalErrors = {
					...state.errors,
					...errors,
				};

				this.focusAfterError(finalErrors);

				return {
					errors: finalErrors,
				};
			});
		} else {
			this.setState({ errors: initErrors });
			// eslint-disable-next-line no-unused-expressions
			onSubmit && onSubmit(form);
		}
	};

	focusAfterError = errors => {
		const { id } = this.props;
		const errorKeys = Object.keys(errors);

		formFocusErrors(id, errors, errorKeys, name => `${name}Filter`);
	};

	matchTranslations = defaultData => defaultData.map(item => ({
		...item,
		title: this.props.intl.formatMessage({ id: item.title }),
	}));

	render () {
		const { button, intl, disabled, id } = this.props;
		const { form, errors } = this.state;
		const t = intl.formatMessage;

		return (
			<Form onSubmit={this.handleSubmit} id={id}>
				<Form.Group>
					<InputSelect
						name="occupationProfession"
						onChange={this.handleChange}
						placeholder={t(M.inputs.occupationProfession)}
						value={form.occupationProfession}
						errors={errors.occupationProfession}
						data={this.matchTranslations(data.occupationProfession)}
						disabled={disabled}
					/>
				</Form.Group>

				<Form.Group>
					<InputSelect
						name="occupation"
						onChange={this.handleChange}
						placeholder={t(M.inputs.occupation)}
						value={form.occupation}
						errors={errors.occupation}
						data={this.matchTranslations(data.occupation)}
						disabled={disabled}
					/>
				</Form.Group>

				<Form.Group>
					<InputSelect
						name="cardPurpose"
						onChange={this.handleChange}
						placeholder={t(M.inputs.cardPurpose)}
						value={form.cardPurpose}
						errors={errors.cardPurpose}
						data={this.matchTranslations(data.cardPurpose)}
						disabled={disabled}
					/>
				</Form.Group>

				<Form.Group>
					<InputSelect
						name="monthlyIncome"
						onChange={this.handleChange}
						placeholder={t(M.inputs.monthlyIncome)}
						value={form.monthlyIncome}
						errors={errors.monthlyIncome}
						data={this.matchTranslations(data.monthlyIncome)}
						disabled={disabled}
					/>
				</Form.Group>

				<Form.Group>
					<InputSelect
						name="estate"
						onChange={this.handleChange}
						placeholder={t(M.inputs.estate)}
						value={form.estate}
						errors={errors.estate}
						data={this.matchTranslations(data.estate)}
						disabled={disabled}
					/>
				</Form.Group>

				{button}
			</Form>
		);
	}
}

export default RegulatoryDataCollectionForm;
