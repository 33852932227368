const formFocusErrors = (formId, errors = [], errorKeys = [], inputNameModifier) => {
	for (let i = 0; i <= errorKeys.length; i++) {
		if (errors[errorKeys[i]] && errors[errorKeys[i]].length) {
			let inputName = errorKeys[i];

			if (inputNameModifier) {
				inputName = inputNameModifier(inputName);
			}

			const input = document.querySelector(`#${formId} input[name="${inputName}"]`);

			if (input) {
				input.focus();
			}

			return;
		}
	}
};

export default formFocusErrors;
