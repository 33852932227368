import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import icon from 'svg/logo.svg';
import './Logo.scss';

export default function Logo (props) {
	const { className } = props;
	return (
		<Link to="/">
			<div
				className={classnames('Logo', className)}
				dangerouslySetInnerHTML={{ __html: icon }}
			/>
		</Link>
	);
}

Logo.propTypes = {
	className: PropTypes.string,
};
