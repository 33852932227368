import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as shareholderSelector from 'selectors/shareholder';

import * as shareholderActions from 'actions/shareholder';

import Table from 'components/Table';
import Form from 'components/Form';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Icon from 'components/Icon';
import Tip from 'components/Tip';
import Banner from 'components/Banner';
import Tab from 'components/Tab';

import M from './Shareholder.locale.json';



const ROLES = {
	shareholder: M.roles.shareholder,
	director: M.roles.director,
	both: M.roles.both,
};

const COLUMNS = [{
	id: 'name',
	title: M.columns.name,
}, {
	id: 'role',
	title: M.columns.role,
}, {
	id: 'remove',
	title: M.columns.remove,
}];

const mapState = (state, props) => ({
	shareholder: shareholderSelector.rolesTable(state.shareholder, props, ROLES),
});

const mapDispatch = dispatch => ({
	actions: {
		shareholder: bindActionCreators(shareholderActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Shareholder extends Component {
	state = {
		messages: [],
	}

	componentDidMount () {
		const { actions } = this.props;

		actions.shareholder.fetch();
	}

	onSubmit = () => {
		const { shareholder, onNext } = this.props;

		if (!shareholder || shareholder.length <= 0) {
			this.setState({
				messages: [{
					message: 'There should be at least one shareholder.',
				}],
			});

			return;
		}

		history.push(`/clients/create/business/${id}/documents`);
	}

	onEdit = id => {
		const { history } = this.props;

		history.push(`/shareholders/edit/${id}`, { modal: true });
	}

	onRemove = async (e, id) => {
		e.preventDefault();
		e.stopPropagation();

		const { actions, shareholder } = this.props;
		const total = shareholder.length;

		actions.shareholder.remove(id, total);
	}

	renderHeader = () => {
		const t = this.props.intl.formatMessage;

		return COLUMNS.map(item => ({
			...item,
			title: t(item.title),
		}));
	}

	renderRow = (item, idx) => (
		<Table.Row
			key={idx}
			onClick={() => this.onEdit(item.id)}
		>
			<Table.Cell>
				{item.firstName} {item.lastName}
			</Table.Cell>

			<Table.Cell>
				{item.role}
			</Table.Cell>

			<Table.Cell>
				<Button
					className="ShareholderTable__Remove"
					loading={item.loading}
					onClick={e => this.onRemove(e, item.id)}
					ghost
					small
				>
					<Icon name="close-small" />
				</Button>
			</Table.Cell>
		</Table.Row>
	)

	render () {
		const { messages } = this.state;
		const { shareholder, onNext, onPrev, loader, current } = this.props;

		const t = this.props.intl.formatMessage;

		const empty = (
			<div
				className="ShareholderTable__Empty"
			>
				<span>{t(M.empty)}</span>

				<ButtonLink
					className="ShareholderTable__Add"
					to={{
						pathname: '/shareholders/create',
						state: { modal: true }
					}}
					plain
				>
					<Icon name="plus-small" />
				</ButtonLink>
			</div>
		);

		return (
			<div
				className="ClientCreateBusinessScreen__Step"
			>
				<Form.Note>{t(M.messages.note)}</Form.Note>

				{
					((messages && messages.length > 0) &&
					shareholder.length <= 0) &&
					<Banner type="error" data={messages} />
				}

				<ButtonLink
					className="ShareholderTable__Add"
					to={{
						pathname: '/shareholders/create',
						state: { modal: true }
					}}
					plain
				>
					{t(M.buttons.add)}
				</ButtonLink>

				<Table
					items={shareholder}
					className="Dashboard__Table"
					renderHeader={this.renderHeader}
					renderRow={this.renderRow}
					empty={empty}
				/>

				<Button
					className="Stepper__Button"
					loading={loader}
					onClick={() => this.onSubmit()}
					small
				>
					<span>5/5</span> {t(M.buttons.next)}
				</Button>

				<Button
					className="Stepper__Button"
					loading={loader}
					onClick={() => onPrev()}
					secondary
					small
				>
					{t(M.buttons.back)}
				</Button>
			</div>
		);
	}
}
