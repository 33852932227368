import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Icon.scss';

/* eslint-disable react/no-danger */
function Icon (props) {
	const { className, name } = props;
	const svg = require(`svg/${name}.svg`);

	return (
		<div
			className={classnames('Icon', className)}
			dangerouslySetInnerHTML={{ __html: svg }}
			{...props}
		/>
	);
}
/* eslint-enable react/no-danger */

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default Icon;
