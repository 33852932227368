import {
	SHAREHOLDER_FETCH_SUCCESS,
	SHAREHOLDER_CREATE_SUCCESS,
	SHAREHOLDER_EDIT_SUCCESS,
	SHAREHOLDER_REMOVE_REQUEST,
	SHAREHOLDER_REMOVE_SUCCESS
} from 'constants';

const initial = [];

const reducer = (state = initial, action) => {
	switch (action.type) {
		case SHAREHOLDER_FETCH_SUCCESS:
			return action.payload;
		case SHAREHOLDER_CREATE_SUCCESS:
			return [...state, action.payload];
		case SHAREHOLDER_EDIT_SUCCESS: {
			const items = [...state].filter(item => item.id !== action.payload.id);
			return [...items, action.payload];
		}
		case SHAREHOLDER_REMOVE_REQUEST: {
			const items = state.map(item => {
				if (item.id !== action.payload.id) return item;
				return { ...item, loading: true };
			});
			return { ...items };
		}
		case SHAREHOLDER_REMOVE_SUCCESS:
			return [...state].filter(item => item.id !== action.payload.id);
		default:
			return state;
	}
};

export default reducer;
