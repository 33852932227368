import { COUNTRY_FETCH_SUCCESS } from 'constants';

const initial = [];

const reducer = (state = initial, action) => {
	if (action.type === COUNTRY_FETCH_SUCCESS) return action.payload;
	return state;
};

export default reducer;
