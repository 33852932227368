export default {
	create: {
		basic: {
			name: {
				presence: { message: '^schemas.presence' },
				length: {
					minimum: 2,
					message: '^schemas.length.min',
				},
			},
			tradingName: {
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			},
			registrationNumber: {
				presence: { message: '^schemas.presence' },
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			},
			dateOfIncorporation: {
				presence: { message: '^schemas.presence' },
				format: {
					pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
					message: '^schemas.incorrect',
				},
				dateBefore: { message: '^schemas.date.future' },
			},
			businessType: {
				presence: { message: '^schemas.select' },
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			},
			businessTypeOther: {
				presenceDependency: {
					attributes: {
						businessType: 'OTHER',
					},
					message: '^schemas.presence',
				},
			},
			industry: {
				presence: { message: '^schemas.presence' },
			},
			taxNumber: {
				alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			},
			vatNumber: {
				alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			},
			authority: {
				presence: { message: '^schemas.presence' },
			},
			authorityName: {
				presenceDependency: {
					attributes: {
						authority: true,
					},
					message: '^schemas.presence',
				},
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			},
			authorityLicence: {
				presenceDependency: {
					attributes: {
						authority: true,
					},
					message: '^schemas.presence',
				},
				length: {
					minimum: 3,
					message: '^schemas.length.min',
				},
			}
		},
		location: {
			regAddress: {
				presence: { message: '^schemas.presence' },
			},
			regCountry: {
				presence: { message: '^schemas.presence' },
			},
			regPostalCode: {
				presence: { message: '^schemas.presence' },
			},
			regCity: {
				presence: { message: '^schemas.presence' },
			},
			identical: {
				presence: { message: '^schemas.presence' },
			},
			address: {
				presenceDependency: {
					attributes: {
						identical: false,
					},
					message: '^schemas.presence',
				},
			},
			city: {
				presenceDependency: {
					attributes: {
						identical: false,
					},
					message: '^schemas.presence',
				},
			},
			country: {
				presenceDependency: {
					attributes: {
						identical: false,
					},
					message: '^schemas.presence',
				},
			},
			postalCode: {
				presenceDependency: {
					attributes: {
						identical: false,
					},
					message: '^schemas.presence',
				},
			}
		},
		documents: {
			declaration: {
				presence: { message: '^schemas.presence' },
				inclusion: {
					within: [true],
					message: '^schemas.agree',
				},
			}
		},

		contact: {
			surname: {
				presence: { message: '^schemas.presence' },
				length: {
					minimum: 2,
					message: '^schemas.length.min',
				},
			},
			name: {
				presence: { message: '^schemas.presence' },
				length: {
					minimum: 2,
					message: '^schemas.length.min',
				},
			},
			title: {
				presence: { message: '^schemas.presence' },
			},
			email: {
				presence: { message: '^schemas.presence' },
				email: { message: '^schemas.email' },
			},
			phone: {
				presence: { message: '^schemas.presence' },
				numericality: { message: '^schemas.numerical' },
				length: {
					minimum: 2,
					message: '^schemas.length.min',
				},
			},
			position: {
				presence: { message: '^schemas.presence' },
				length: {
					minimum: 2,
					message: '^schemas.length.min',
				},
			},
			phoneCountry: {
				presence: { message: '^schemas.presence' },
			},
		}
	},
};
