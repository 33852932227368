import moment from 'moment';

export default function user (form) {
	if (!form) return form;

	const convert = {
		...form,
	};

	convert.address = {
		line1: form.address,
		country: form.country,
		postalCode: form.postalCode,
	};

	delete convert.country;
	delete convert.postalCode;

	if (form.name) {
		convert.firstName = form.name;
		delete convert.name;
	}
	if (form.surname) {
		convert.lastName = form.surname;
		delete convert.surname;
	}
	if (form.dateOfBirth) {
		convert.dateOfBirth = moment(form.dateOfBirth, 'DD/MM/YYYY').format('DD-MM-YYYY');
	}
	return convert;
}
