import React from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import classnames from 'classnames';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Icon from '../Icon';
import './InputFilter.scss';

@enhanceWithClickOutside
export default class InputFilter extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		value: PropTypes.array,
		name: PropTypes.string,
		onChange: PropTypes.func,
		data: PropTypes.array,
		children: PropTypes.node,
	}

	state = {
		open: false,
	}

	handleClickOutside = () => this.setState({ open: false })

	onToggle = () => this.setState({ open: !this.state.open })

	onSelect = item => {
		const { value, name, onChange } = this.props;
		const option = item.value || item.id;
		const items = [...value];
		const idx = items.indexOf(option);
		if (idx !== -1) items.splice(idx, 1);
		else items.push(option);
		onChange(items, name);
	}

	render () {
		const { data, value, className, children } = this.props;
		const { open } = this.state;

		const items = data.map(item => ({
			...item,
			check: value.indexOf(item.value || item.id) !== -1,
		}));

		return (
			<div className={classnames('InputFilter', className)}>
				<div className="InputFilter__Wrap">
					<Button onClick={this.onToggle} plain>
						{children}
						<Icon name="filter" />
					</Button>
				</div>
				<Dropdown
					data={items}
					className="InputFilter__Dropdown"
					onClick={this.onSelect}
					open={open}
				/>
			</div>
		);
	}
}
