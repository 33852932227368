import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Note.scss';

export default function Note (props) {
	const { className, children } = props;
	return (
		<div className={classnames('FormNote', className)}>
			{children}
		</div>
	);
}

Note.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
};
