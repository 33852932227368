import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { paymentStatus, paymentStatuses } from 'constants/common';
import * as cardActions from 'actions/card';
import * as paymentSelector from 'selectors/payment';
import Completed from '../Completed';
import Declined from '../Declined';

const mapState = (state, props) => ({
	payment: paymentSelector.get(state, props.match.params.id),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.shape({
			card: PropTypes.shape({
				fetch: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
		payment: PropTypes.shape({
			status: PropTypes.oneOf(paymentStatuses).isRequired,
		}),
	}

	componentDidUpdate () {
		this.props.actions.card.fetch();
	}

	render () {
		const { payment } = this.props;
		if (!payment) return null;

		return (
			<div>
				{payment.status === paymentStatus.COMPLETED && <Completed />}
				{payment.status === paymentStatus.DECLINED && <Declined />}
			</div>
		);
	}
}
