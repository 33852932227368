// TODO refactoring this file
import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';
import storage from 'services/storage';

export default {
	auth: {
		login () {
			return new Promise(resolve => {
				const data = {
					id: 37392,
					email: 'john.doe@weststeincard.com',
					firstName: 'John',
					lastName: 'Doe',
					language: 'EN',
					phone: '287172744',
					phoneCountry: 3858,
					status: 'ACTIVE',
					phoneVerified: true,
					verified: true,
				};

				setTimeout(() => {
					storage.set('session', 'Bearer 12345678');
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		signup () {
			return new Promise(resolve => {
				const data = {
					id: 37392,
					email: 'john.doe@weststeincard.com',
					firstName: 'John',
					lastName: 'Doe',
					language: 'EN',
					phone: '287172744',
					phoneCountry: 3858,
					status: 'ACTIVE',
					phoneVerified: true,
					verified: true,
				};
				setTimeout(() => {
					storage.set('session', 'Bearer 12345678');
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		email () {
			return new Promise(resolve => {
				setTimeout(() => {
					const data = {
						message: 'Email verified',
					};
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		reset () {
			return new Promise(resolve => {
				const data = {
					message: 'Email with password reset link has been sent',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		password () {
			return new Promise(resolve => {
				const data = {
					message: 'Password changed',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},
	},

	user: {
		get () {
			return new Promise(resolve => {
				const data = {
					id: 37392,
					email: 'john.doe@weststeincard.com',
					firstName: 'John',
					lastName: 'Doe',
					// avatarId: 52297,
					language: 'EN',
					phone: '287172744',
					phoneCountry: 3858,
					status: 'ACTIVE',
					phoneVerified: true,
					verified: true,
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	client: {
		fetch (id) {
			return new Promise(resolve => {
				const data = [{
					id: 37389,
					entityId: 37391,
					name: 'Unicorn Ltd.',
					role: 'OWNER',
					roleType: 'BUSINESS',
					status: 'ACTIVE',
				}, {
					id: 37389,
					entityId: 37391,
					name: 'Hilton Ltd.',
					role: 'OWNER',
					roleType: 'BUSINESS',
					status: 'ACTIVE',
				}];

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		create (form, id, step) {
			return new Promise(resolve => {
				let data;
				switch (step) {
					case 'basic':
						data = {
							name: 'Unicorn Ltd.',
							registrationNumber: '834432456546546',
						};
						break;
					case 'location':
						data = {
							regCountry: 3858,
						};
						break;
					case 'contact':
						data = {};
						break;
					case 'documents':
						data = {};
						break;
					default:
						data = {};
						break;
				}

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		get (id, step) {
			return new Promise(resolve => {
				let data;
				switch (step) {
					case 'basic':
						data = {
							id: 37389,
							legalStatus: 'PRIVATE',
							address: null,
							dateOfIncorporation: '10-01-2018',
							name: 'Unicorn Ltd.',
							tradingName: 'Unicorn Ltd.',
							otherLegalStatus: '',
							registrationNumber: '834432456546546',
							taxNumber: '',
							vatNumber: '',
							regulatedByAuthority: false,
							regulationAuthorityName: null,
							licenceNumberGrantedByAuthority: null,
							industry: '23',
							agree: true,
							agreeOn: null,
							cardholderIds: [{
								id: 37907,
								cardholderId: '400000605397',
								iban: 'GREATBAN1000',
								cardNumber: null,
								cardName: 'JURIS AUZINS',
							}, {
								id: 37908,
								cardholderId: '400000605398',
								iban: 'GREATBAN1001',
								cardNumber: null,
								cardName: 'UNICORN LTD.'
							}, {
								id: 37909,
								cardholderId: '400000605399',
								iban: 'GREATBAN1002',
								cardNumber: null,
								ardName: 'JOHN DOE',
							}]
						};

						break;
					case 'location':
						data = {
							regCountry: 3858,
						};
						break;
					case 'contact':
						data = {};
						break;
					default:
						data = {};
						break;
				}

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		edit (form, id, step) {
			return new Promise(resolve => {
				let data;
				switch (step) {
					case 'basic':
						data = {
							legalName: 'Unicorn Ltd.',
							registrationNumber: '834432456546546',
							...form,
						};
						break;
					case 'location':
						data = {
							regCountry: 3858,
							...form,
						};
						break;
					case 'contact':
						data = {
							...form,
						};
						break;
					default:
						data = {};
						break;
				}

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		balance (id) {
			return new Promise(resolve => {
				const data = {
					id: 'ZZZZ',
					name: 'Unicorn Ltd.',
					iban: 'LVWEST434345645634234',
					status: 'active',
					account: {
						eur: 2343,
						usd: 1981,
						gbp: 17502,
					},
					total: {
						eur: 24340,
						usd: 27121,
						gbp: 18354,
					}
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		progress (id) {
			return new Promise(resolve => {
				const data = {
					basic: 'COMPLETE',
					location: 'EMPTY',
					contact: 'COMPLETE',
					shareholders: 'EMPTY',
					documents: 'EMPTY',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},
	},

	member: {
		fetch () {
			return new Promise(resolve => {
				const data = [{
					id: 'AAAA',
					email: 'teodors.zeltins@gmail.com',
					name: 'Teodors',
					surname: 'Zeltiņš',
					position: 'Accountant',
					access: 'administrator',
					status: 'activated'
				}, {
					id: 'BBBB',
					email: 'marin.mayer@gmail.com',
					name: 'Marin',
					surname: 'Mayer',
					position: 'Director',
					access: 'moderator',
					status: 'reviewing'
				}, {
					id: 'CCCC',
					email: 'tim.cook@gmail.com',
					name: 'Tim',
					surname: 'Cook',
					position: 'Developer',
					access: 'viewer',
					status: 'activated'
				}];

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		get (id) {
			return new Promise(resolve => {
				const data = {
					id: 'CCCC',
					email: 'tim.cook@gmail.com',
					phone: '+371 28717273',
					name: 'Tim',
					surname: 'Cook',
					position: 'Developer',
					access: 'administrator',
					status: 'active'
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		create (form) {
			return new Promise(resolve => {
				const data = {
					...form,
					status: 'reviewing',
					id: 'EEEEE',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		edit (form) {
			return new Promise(resolve => {
				const data = {
					id: 'CCCC',
					email: 'tim.cook@gmail.com',
					name: 'Tim',
					surname: 'Cook',
					position: 'Developer',
					access: 'reviewing',
					status: 'active'
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	document: {
		fetch () {
			return new Promise(resolve => {
				const data = {
					certificateOfRegistration: [],
					memorandumOfAssociation: [],
					annualReturn: [],
					shareholderDetails: [],
					companyDirectors: [],
					evidenceOfBankAccount: [],
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		create (file) {
			return new Promise(resolve => {
				const reader = new FileReader();

				reader.onloadend = () => {
					resolve(renamer.from.wrap.wrap({
						preview: reader.result,
						id: file.lastModified,
						name: file.name,
					}));
				};

				reader.readAsDataURL(file);
			});
		},

		remove (id) {
			return new Promise(resolve => {
				const data = {
					messages: [{
						message: 'Document deleted',
					}]
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	avatar: {
		create (file) {
			return new Promise(resolve => {
				const reader = new FileReader();

				reader.onloadend = () => {
					resolve(renamer.from.wrap.wrap({
						preview: reader.result,
						id: file.lastModified,
						name: file.name,
					}));
				};

				reader.readAsDataURL(file);
			});
		},

		remove () {
			return new Promise(resolve => {
				const data = {
					messages: [{
						message: 'Avatar deleted',
					}]
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	shareholder: {
		fetch () {
			return new Promise(resolve => {
				const data = [
					{ id: 1, firstName: 'John', lastName: 'Smith', role: 'Director' },
					{ id: 2, firstName: 'James', lastName: 'Bond', role: 'Shareholder' }
				];

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		create (form) {
			return new Promise(resolve => {
				const data = {
					id: 4,
					...form
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		edit (id, form) {
			return new Promise(resolve => {
				setTimeout(() => {
					resolve(renamer.wrap(form));
				}, 1000);
			});
		},

		remove (id) {
			return new Promise(resolve => {
				const data = {
					message: 'Shareholder deleted'
				};
				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	card: {
		fetch () {
			return new Promise(resolve => {
				const data = [{
					id: 'AAAA',
					name: 'Unicorn Ltd.',
					number: '4535',
					status: 'activated',
				}, {
					id: 'BBBB',
					name: 'Unicorn Ltd.',
					number: '8535',
					status: 'sent',
				}, {
					id: 'CCCC',
					name: 'Tim Cook',
					number: '1235',
					status: 'blocked',
				}];

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		get () {
			const cards = {
				client: null,
				accountBaseCurrency: '978',
				cardType: '20',
				accountNumber: '704844277953',
				cardStatus: 'DONE',
				pinTriesExceeded: 0,
				badPinTries: 0,
				expirationDate: '2102',
				availBal: 17,
				ledgerBal: 17,
				distributorCode: '723',
				loadAmount: 0,
				companyName: null,
				cardStyle: '01',
				deliveryType: null,
				bic: 'PFSRIE21',
				iban: 'IE72PFSR99107000605399',
				phoneCardNumber: null,
				cardNumber: '599911******5339',
				cardName: 'JURIS AUZINS',
			};

			return new Promise(resolve => {
				const data = cards[id];
				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		activate (form) {
			return new Promise(resolve => {
				const data = {
					message: 'Card activated',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		pin (form) {
			return new Promise(resolve => {
				const data = {
					message: 'PIN sent',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		remove (form) {
			return new Promise(resolve => {
				const data = {
					message: 'Card removed'
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	payment: {
		fetch (card, business, params) {
			return new Promise(resolve => {
				const template = {
					amount: 10.23,
					beneficiary: 'Beneficiary #1',
					bic: 'SOMEBIC',
					currency: {
						code: 'EUR',
						convertOnline: true,
						decimalPlaces: 2,
						id: 1,
						name: 'EUR'
					},
					details: 'Details #1',
					iban: 'SOMEIBAN107000605399',
					id: 1,
					paymentDate: new Date('27 Feb, 2014 13:00').toISOString(),
					predictedFee: 0,
					status: 'accepted',
				};

				const data = Array.from(
					{ length: 10 },
					(_, idx) => ({
						...template,
						id: `${cardId}-${idx}`,
						amount: (Math.random() * 100).toFixed(2),
						bic: `SOMEBIC${idx % 10}`,
						beneficiary: `Beneficiary ${idx % 20}`,
						details: `Some details here ${idx % 20}, card: ${cardId}, business: ${businessId}`,
						iban: `SOMEIBAN10700060539${idx % 10}`,
						paymentDate: new Date(2017, idx % 12, idx % 27, idx % 23, idx % 59).toISOString(),
						status: idx % 2 ? 'approved' : 'declined'
					})
				);

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		get () {
			return new Promise(resolve => {
				const data = {
					amount: 10.23,
					beneficiary: 'Beneficiary #1',
					bic: 'SOMEBIC',
					currency: {
						code: 'EUR',
						convertOnline: true,
						decimalPlaces: 2,
						id: 1,
						name: 'EUR'
					},
					details: 'Details #1',
					iban: 'SOMEIBAN107000605399',
					id: 1,
					paymentDate: new Date('27 Feb, 2014 13:00').toISOString(),
					predictedFee: 0,
					status: 'accepted',
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},
	},

	transaction: {
		fetch (cardHolderId, businessId, type, size, page, start, end) {
			const transactions = Array.from({
				length: page <= 3 ? size : 0
			}, (_, idx) => ({
				id: `${cardHolderId}-${idx}`,
				date: new Date(2017, idx % 12, idx % 27, idx % 23, idx % 59).toISOString(),
				transactionType: (idx % 2 === 0 ? 'OUT' : 'IN'),
				beneficiary: `Beneficiary ${idx % 20}`,
				details: `Some details here ${idx % 20}, card: ${cardHolderId}, business: ${businessId}`,
				amount: (idx % 2 === 0 ? -1 : 1) * (Math.random() * 100).toFixed(2),
				balanceAfter: 37.32,
				status: idx % 5 === 0 ? 'approved' : 'declined'
			}));

			return new Promise(resolve => {
				const data = {
					account: '400000605397',
					startDate: '01-01-1900',
					endDate: '30-01-2018',
					transactions,
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},
	},

	country: {
		fetch (params) {
			return fetcher.get('open/country', {}, { params });
		},
	},

	password: {
		change () {
			return new Promise(resolve => {
				const data = {
					errors: [],
					messages: [],
					response: {},
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	},

	phone: {
		get () {
			return new Promise(resolve => {
				const data = {
					errors: [],
					messages: [],
					response: {},
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},

		create (form) {
			return new Promise(resolve => {
				const data = {
					...form,
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		},
	},

	email: {
		get () {
			return new Promise(resolve => {
				const data = {
					errors: [],
					messages: [],
					response: {},
				};

				setTimeout(() => {
					resolve(renamer.from.wrap.wrap(data));
				}, 1000);
			});
		}
	}
};
