export default {
	types: {
		PASSPORT: 'Passport',
		PHOTO_ID_FRONT: 'PhotoIdFront',
		PHOTO_ID_BACK: 'PhotoIdBack',
		DRIVERS_LICENCE_FRONT: 'DriverLicenseFront',
		DRIVERS_LICENCE_BACK: 'DriverLicenseBack',
		PROOF_OF_ADDRESS: 'ProofOfAddress',
		BANK_ACCOUNT_EVIDENCE: 'BankAccountEvidence',
		UTILITY_BILL: 'UtilityBill',
		CERTIFICATE_OF_REGISTRATION: 'CertificateOfRegistration',
		ARTICLE_OF_ASSOCIATION: 'ArticleOfAssociation',
		ANNUAL_RETURN: 'AnnualReturn',
		CONFIRMATION_OF_COMPANY_DIRECTORS: 'ConfirmationOfCompanyDirectors',
		CONFIRMATION_OF_SHAREHOLDING_DETAILS: 'ConfirmationOfShareholdingDetails',
	}
};
