import {
	DASHBOARD_FETCH_SUCCESS,
	DASHBOARD_GET_SUCCESS,
	DASHBOARD_SET_SORT,
} from 'constants';

const initial = {
	sort: {
		id: 'date',
		asc: true,
	},
	items: [],
};

const reducer = (state = initial, action) => {
	switch (action.type) {
		case DASHBOARD_FETCH_SUCCESS:
			return {
				...state,
				items: [
					...action.payload.transactions,
				],
			};
		case DASHBOARD_GET_SUCCESS: {
			const items = [...state.items];
			const idx = items.findIndex(item => item.id === action.payload.id);
			if (idx >= 0) items.splice(idx, 1, { ...action.payload });
			return { ...state, items };
		}
		case DASHBOARD_SET_SORT:
			return {
				...state,
				sort: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
