import moment from 'moment';

const get = item => {
	const convert = {
		...item,
	};
	convert.amount += '';
	convert.predictedFee += '';
	convert.idShort = convert.id;
	const created = moment(convert.created, 'DD-MM-YYYY HH:mm:ss');
	const paymentDate = moment(convert.paymentDate, 'DD-MM-YYYY HH:mm:ss');
	convert.created = (created.isValid() ? created.valueOf() : '');
	convert.paymentDate = (paymentDate.isValid() ? paymentDate.valueOf() : '');
	return convert;
};

const fetch = transactions => {
	if (!transactions) return transactions;
	return transactions.map(get);
};


export default {
	fetch,
	get,
};
