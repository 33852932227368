import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Wrap.scss';

export default function Wrap (props) {
	const { className, children } = props;
	return (
		<div className={classnames('FormWrap', className)}>
			{children}
		</div>
	);
}

Wrap.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
};
