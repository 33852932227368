import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Wrap from './Wrap';
import Group from './Group';
import Note from './Note';
import './Form.scss';

const Form = props => {
	const { className } = props;

	return (
		<form
			className={classnames('Form', className)}
			autoComplete="off"
			onSubmit={props.onSubmit}
			onKeyDown={props.onKeyDown}
			id={props.id}
		>
			{props.children}
		</form>
	);
};

Form.propTypes = {
	children: PropTypes.any,
	onSubmit: PropTypes.func,
	className: PropTypes.string,
	id: PropTypes.id
};

Form.Wrap = Wrap;
Form.Group = Group;
Form.Note = Note;

export default Form;
