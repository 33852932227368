import {
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	LANGUAGE_GET_SUCCESS,
	LANGUAGE_EDIT_SUCCESS,
} from 'constants';

import storage from 'services/storage';

const initial = {
	locale: null,
	messages: {},
};

const reducer = (state = initial, action) => {
	switch (action.type) {
		case AUTH_LOGIN_SUCCESS:
		case AUTH_SIGNUP_SUCCESS: {
			const { locale, messages } = action.payload.language;
			storage.set('locale', locale, true);
			return {
				locale,
				messages,
			};
		}
		case LANGUAGE_GET_SUCCESS: {
			const { messages } = action.payload;
			const locale = action.payload.locale.toLowerCase();
			storage.set('locale', locale, true);
			return {
				...state,
				messages,
				locale,
			};
		}
		case LANGUAGE_EDIT_SUCCESS: {
			const { messages } = action.payload;
			const locale = action.payload.locale.toLowerCase();
			storage.set('locale', locale, true);
			return {
				...state,
				messages,
				locale,
			};
		}
		default:
			return state;
	}
};

export default reducer;
