import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';

export default {
	edit (form) {
		const convert = renamer.to.password(form);
		return fetcher.post(`user/change-password`, {}, { params: convert });
	},
	migrate (form) {
		return fetcher.post(`user/migration/set-password`, {}, { params: form });
	},
};
