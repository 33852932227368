import {
	TRANSACTION_FETCH_REQUEST,
	TRANSACTION_FETCH_SUCCESS,
	TRANSACTION_GET_SUCCESS,
	TRANSACTION_SET_SEARCH,
	TRANSACTION_SET_STATUS,
	TRANSACTION_SET_COLUMNS,
	TRANSACTION_SET_TYPES,
	TRANSACTION_SET_SORT,
} from 'constants';
import { clientRole } from 'constants/common';
import renamer from 'helpers/renamer';
import API from 'services/api';
import * as clientSelector from 'selectors/client';
import * as cardSelector from 'selectors/card';
import * as transactionSelector from 'selectors/transaction';

export const fetch = params => async (dispatch, getState) => {
	try {
		const state = getState();
		const client = clientSelector.getCurrentClient(state);
		const isBusiness = client.roleType === clientRole.BUSINESS;
		const clientId = isBusiness ? client.entityId : null;
		const cardId = state.card.current;
		dispatch({ type: TRANSACTION_FETCH_REQUEST });
		const { data } = await API.transaction.fetch(cardId, { ...params }, clientId);
		const payload = renamer.from.transaction.fetch(data.response);
		dispatch({ type: TRANSACTION_FETCH_SUCCESS, payload });
		return data;
	} catch (error) { return error; }
};

export const get = id => async (dispatch, getState) => {
	try {
		const state = getState();
		const payload = transactionSelector.get(state, id);
		await new Promise(resolve => setTimeout(resolve, 1000));
		dispatch({ type: TRANSACTION_GET_SUCCESS, payload });
		return payload;
	} catch (err) { return err; }
};

export const pdf = form => async (dispatch, getState) => {
	try {
		const state = getState();
		const client = clientSelector.getCurrentClient(state);
		const isBusiness = client.roleType === clientRole.BUSINESS;
		const clientId = (isBusiness ? client.entityId : null);
		const cardId = state.card.current;
		const result = await API.transaction.pdf(cardId, form, clientId);
		return result.data;
	} catch (err) { return err; }
};

export const singlepdf = (form, lang) => async (dispatch, getState) => {
	try {
		const state = getState();
		const client = clientSelector.getCurrentClient(state);
		const isBusiness = client.roleType === clientRole.BUSINESS;
		const clientId = isBusiness ? client.entityId : null;
		const cardId = cardSelector.getCurrentCardId(state);
		const result = await API.transaction.singlepdf(cardId, form, clientId, lang.toUpperCase());
		return result.data;
	} catch (err) { return err; }
};

export const csv = form => async (dispatch, getState) => {
	try {
		const state = getState();
		const client = clientSelector.getCurrentClient(state);
		const isBusiness = client.roleType === clientRole;
		const clientId = isBusiness ? client.entityId : null;
		const cardId = state.card.current;
		const result = await API.transaction.csv(cardId, form, clientId);
		return result.data;
	} catch (err) { return err; }
};

export const search = value => ({
	type: TRANSACTION_SET_SEARCH,
	payload: value,
});

export const status = value => ({
	type: TRANSACTION_SET_STATUS,
	payload: value
});

export const columns = value => ({
	type: TRANSACTION_SET_COLUMNS,
	payload: value
});

export const types = value => ({
	type: TRANSACTION_SET_TYPES,
	payload: value
});

export const sort = value => ({
	type: TRANSACTION_SET_SORT,
	payload: value
});
