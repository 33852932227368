import React from 'react';
import Layout from 'components/Layout';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import SectionDashboard from 'sections/Dashboard';
import './Dashboard.scss';

import historyTypes from 'types/history';

class Dashboard extends React.PureComponent {
	static propTypes = {
		history: historyTypes.isRequired,
	}

	state = {
		tableSort: {
			name: 'date',
			asc: true,
		}
	}

	onView = item => {
		const { history } = this.props;
		history.push(`/history/${item.id}`);
	}

	onSort = item => {
		const { tableSort } = this.state;
		this.setState({
			tableSort: {
				name: item.name,
				asc: (tableSort.name === item.name ? !tableSort.asc : true)
			}
		});
	}

	render () {
		const { tableSort } = this.state;
		return (
			<Layout className="DashboardScreen" sidebar>
				<Nav />
				<Header />
				<SectionDashboard
					tableSort={tableSort}
					onSort={this.onSort}
					onClick={this.onView}
				/>
			</Layout>
		);
	}
}

export default Dashboard;
