import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputError from '../InputError';
import Dropdown from '../Dropdown';
import './InputAutocomplete.scss';

export default class InputAutocomplete extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		label: PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		autoFocus: PropTypes.bool,
		disabled: PropTypes.bool,
		data: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number
				]),
			})
		),
		errors: PropTypes.array,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onSelect: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
	}

	state = {
		focused: false,
		holder: false,
		focusedIdx: 0,
	}

	onKeyDown = e => {
		const { disabled } = this.props;

		if (disabled) return;

		switch (e.keyCode) {
			case 13: // enter
				e.preventDefault();
				e.stopPropagation();
				this.onSelectFocus();
				break;
			case 27: // escape
				e.preventDefault();
				e.stopPropagation();
				this.holder.focus();
				break;
			case 38: // up
				e.preventDefault();
				this.onFocusNext();
				break;
			case 40: // down
				e.preventDefault();
				this.onFocusPrev();
				break;
			case 33: // page up
				e.preventDefault();
				this.onFocusNext();
				break;
			case 34: // page down
				e.preventDefault();
				this.onFocusPrev();
				break;
			default:
				break;
		}
	}

	onChange = e => {
		const { name, onChange } = this.props;
		if (onChange) onChange(e.target.value, name, e);
		this.setState({ focusedIdx: 0 });
	}

	onKeyDownHolder = e => {
		switch (e.keyCode) {
			case 13: // enter
				e.preventDefault();
				e.stopPropagation();
				this.filter.focus();
				break;
			case 27: // escape
				e.preventDefault();
				e.stopPropagation();
				this.holder.blur();
				break;
			default:
				this.filter.focus();
				break;
		}
	}

	onFocus = () => {
		const { onFocus } = this.props;
		this.setState({ focused: true },
			() => onFocus === 'function' && onFocus());
	}

	onFocusNext = () => {
		const { data } = this.props;
		const { focusedIdx } = this.state;
		if (data.length <= 0) return;
		const nextIdx = focusedIdx - 1;
		this.setState({ focusedIdx: nextIdx < 0 ? data.length - 1 : nextIdx });
	}

	onFocusPrev = () => {
		const { data } = this.props;
		const { focusedIdx } = this.state;
		if (data.length <= 0) return;
		const nextIdx = focusedIdx + 1;
		this.setState({ focusedIdx: nextIdx > data.length - 1 ? 0 : nextIdx });
	}

	onBlur = () => {
		const { onBlur } = this.props;
		this.setState({ focused: false },
			() => onBlur === 'function' && onBlur());
	}

	onBlurHolder = () => this.setState({ holder: false })

	onSelect = item => {
		const { name, onSelect } = this.props;
		if (onSelect) onSelect(item.value, item.title, name);
		this.setState({ holder: true },
			() => this.holder.focus());
	}

	onSelectFocus = () => {
		const { data, name, onSelect } = this.props;
		const { focusedIdx } = this.state;
		if (focusedIdx < 0) return;
		const current = data.find((item, idx) => focusedIdx === idx);
		if (onSelect) onSelect(current.value, name);
		this.setState({ holder: true },
			() => this.holder.focus());
	}

	onMouseOverItem = (item, idx) => this.setState({ focusedIdx: idx })

	onPrevent = e => {
		e.preventDefault();
		e.stopPropagation();
	}

	render () {
		const { name, placeholder, label, value, data,
			errors, disabled, className, autoFocus } = this.props;
		const { focusedIdx, holder } = this.state;
		const selectedIdx = data.findIndex(item => item.value === value);
		return (
			<div className={classnames(
				'InputAutocomplete', className,
				errors && 'InputAutocomplete--Invalid',
				disabled && 'InputAutocomplete--Disabled')}
			>
				<input
					type="hidden"
					className="InputAutocomplete__Input"
					ref={ref => { this.input = ref; }}
					name={name}
					value={value}
				/>

				{((placeholder && value.length) || label) && (
					<label htmlFor={`${name}Filter`} className="Input__Label">
						{label || placeholder}
					</label>
				)}

				<div
					ref={ref => { this.holder = ref; }}
					className="InputAutocomplete__Holder"
					tabIndex={-1}
					role="button"
					onBlur={this.onBlurHolder}
					onKeyDown={this.onKeyDownHolder}
				/>

				<input
					id={name}
					ref={ref => { this.filter = ref; }}
					className="InputAutocomplete__Filter"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					name={name}
					value={value}
					autoComplete="off"
					autoFocus={autoFocus}
					placeholder={placeholder}
					disabled={disabled}
					onKeyDown={this.onKeyDown}
					tabIndex={holder ? -1 : 0}
				/>

				{errors && errors.length && (
					<InputError
						errors={errors}
						placeholder={placeholder}
						className="InputSelect__Errors"
					/>
				)}

				<Dropdown
					ref={ref => { this.dropdown = ref; }}
					className="InputAutocomplete__List"
					onMouseDown={this.onPrevent}
					onMouseOverItem={this.onMouseOverItem}
					onClick={this.onSelect}
					focusedIdx={focusedIdx}
					selectedIdx={selectedIdx}
					data={data}
				/>
			</div>
		);
	}
}
