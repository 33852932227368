import {
	CLIENT_GET_BUSINESS_SUCCESS,
	CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS,
	CLIENT_BUSINESS_FORM,
} from 'constants';
import { clientStep } from 'constants/common';

const initial = {
	[clientStep.BASIC]: {
		name: '',
		tradingName: '',
		registrationNumber: '',
		dateOfIncorporation: '',
		businessType: '',
		businessTypeOther: '',
		industry: '',
		taxNumber: '',
		vatNumber: '',
		authority: false,
		authorityName: '',
		authorityLicence: '',
	},
	[clientStep.LOCATION]: {
		regAddress: '',
		regCountry: '',
		regPostalCode: '',
		identical: true,
		address: '',
		country: '',
		postalCode: '',
		website: '',
	},
	[clientStep.CONTACT]: {
		id: null,
		title: '',
		name: '',
		surname: '',
		position: '',
		email: '',
		phone: '',
		phoneCountry: '',
	},
	[clientStep.DOCUMENTS]: {
		declaration: false,
	},
};

const form = (state = initial, action) => {
	switch (action.type) {
		case CLIENT_GET_BUSINESS_SUCCESS:
			return {
				...state,
				[action.payload.step]: {
					...state[action.payload.step],
					...action.payload.form,
				}
			};
		case CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS:
			return { ...state, contact: { ...state.contact, id: 1 } };
		case CLIENT_BUSINESS_FORM:
			return {
				...state,
				[action.payload.step]: {
					...state[action.payload.step],
					...action.payload.form,
				}
			};
		default:
			return state;
	}
};

export default form;
