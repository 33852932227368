import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';
import Banner from 'components/Banner';
import InputSelect from 'components/InputSelect';
import PasswordPolicy from 'components/PasswordPolicy';
import * as authActions from 'actions/auth';
import helpers from 'helpers';
import Auth from 'schemas/auth';
import M from './Activate.locale.json';
import '../Login/Login.scss';

import intlTypes from 'types/intl';

const mapState = state => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Activate extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		user: PropTypes.object.isRequired,
	}

	state = {
		loader: false,
		form: {
			cardNumber: '',
			password: '',
			question: '',
			answer: ''
		},
		messages: [],
		errors: {
			cardNumber: [],
			password: [],
			passwordNewConfirm: [],
			question: [],
			answer: []
		},
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.activate);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions } = this.props;
		const { form, loader } = this.state;
		if (loader) return;

		const errors = helpers.validator.all(form, Auth.activate);

		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return;
		}

		this.setState({ messages: [], loader: true });

		const response = await actions.auth.activate(form);

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;
			this.setState(update);
		}
	}

	setQuestions = () => {
		const { intl } = this.props;
		const t = intl.formatMessage;

		return [
			{
				title: t(M.questions.a),
				value: 'Mother\'s maiden name'
			},
			{
				title: t(M.questions.b),
				value: 'First pet\'s name'
			},
			{
				title: t(M.questions.c),
				value: 'Place of birth'
			},
			{
				title: t(M.questions.d),
				value: 'Favorite teacher\'s name'
			},
			{
				title: t(M.questions.e),
				value: 'Favourite football team'
			},
			{
				title: t(M.questions.f),
				value: 'Street you grew up on'
			},
			{
				title: t(M.questions.g),
				value: 'Who was your childhood hero?'
			},
			{
				title: t(M.questions.h),
				value: 'What was your childhood nickname?'
			},
			{
				title: t(M.questions.i),
				value: 'What are you most afraid of?'
			}
		];
	}

	questions = this.setQuestions()

	render () {
		const { form, errors, messages, loader } = this.state;
		const { user, intl } = this.props;
		const t = intl.formatMessage;

		if (user) return <Redirect to={{ pathname: '/' }} />;

		return (
			<Layout className="AuthLoginScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Wrap>
							<Title title={t(M.title)} />
							{messages && !!messages.length && <Banner type="error" data={messages} />}
							<Form onSubmit={this.onSubmit}>
								<p>{t(M.guide1)}</p>
								<Form.Group>
									<Input
										name="cardNumber"
										placeholder={t(M.inputs.card)}
										onChange={this.onChange}
										value={form.cardNumber}
										errors={errors.cardNumber}
										autoFocus
									/>
								</Form.Group>
								<p>{t(M.guide2)}</p>
								<Form.Group>
									<InputSelect
										name="question"
										onChange={this.onChange}
										placeholder={t(M.inputs.securityQuestion)}
										value={form.question}
										data={this.questions}
									/>
								</Form.Group>
								<Form.Group>
									<Input
										name="answer"
										placeholder={t(M.inputs.answer)}
										onChange={this.onChange}
										value={form.answer}
										errors={errors.answer}
									/>
								</Form.Group>
								<p>{t(M.guide3)}</p>
								<Form.Group row>
									<Input
										name="password"
										type="password"
										placeholder={t(M.inputs.password)}
										onChange={this.onChange}
										value={form.password}
										errors={errors.password}
									/>
									<PasswordPolicy value={form.password} />
								</Form.Group>
								<Form.Group>
									<Input
										name="passwordNewConfirm"
										type="password"
										placeholder={t(M.inputs.confirmPassword)}
										onChange={this.onChange}
										value={form.passwordNewConfirm}
										errors={errors.passwordNewConfirm}
									/>
								</Form.Group>
								<Button form loading={loader}>{t(M.buttons.activate)}</Button>
							</Form>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
