import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Wrap from 'components/Wrap';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import InputSelect from 'components/InputSelect';
import RegulatoryDataCollectionForm from 'sections/RegulatoryDataCollectionForm';
import * as cardActions from 'actions/card';
import * as privateActions from 'actions/client/private';
import * as countriesActions from 'actions/country';
import helpers from 'helpers';
import CardSchema from 'schemas/card';
import { getCurrentClient } from 'selectors/client';
import { getUser, getUserPhone, getCountryName } from 'selectors/user';
import { fetchToAddSecondary } from 'selectors/card';
import { deliveryTypeOptions, deliveryTypes } from 'constants/common';

import intlTypes from 'types/intl';
import userTypes from 'types/user';
import clientTypes from 'types/client';
import historyTypes from 'types/history';
import {
	cardActionsTypes,
	privateActionsTypes,
	countriesActionsTypes,
} from 'types/actions';

import M from '../CardCreate.locale.json';
import { isVirtualCard } from '../../../services/card';

const cardCreateSuccessUrl = '/cards/create/success';
const CREATE_ME_REGULATORY_FORM_ID = 'CREATE_ME_REGULATORY_FORM_ID';

const mapState = state => ({
	current: getCurrentClient(state),
	user: getUser(state),
	userPhone: getUserPhone(state),
	userCountry: getCountryName(state),
	cards: fetchToAddSecondary(state),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		private: bindActionCreators(privateActions, dispatch),
		countries: bindActionCreators(countriesActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class CardCreateMe extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		history: historyTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
			private: privateActionsTypes.isRequired,
			countries: countriesActionsTypes.isRequired,
		}).isRequired,
		current: clientTypes.isRequired,
		user: userTypes.isRequired,
		userPhone: PropTypes.string,
		userCountry: PropTypes.string,
		cards: PropTypes.array,
	}

	state = {
		form: {
			card: '',
			deliveryType: deliveryTypes.VIRTUAL
		},
		errors: {
			deliveryType: [],
			card: []
		},
		loader: true,
		address: {},
	}

	componentDidMount () {
		this.mounted = true;
		this.onMount();
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	// eslint-disable-next-line consistent-return
	onMount = async () => {
		const { actions, history } = this.props;

		const response = await actions.private.getAddress();
		await actions.countries.fetch();

		if (response && response.errors) return history.goBack();

		this.mounted && this.setState({ loader: false, address: response });
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, CardSchema.secondaryMy);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		});
	};

	onSubmit = async regulatoryData => {
		const { actions, history, current } = this.props;
		const { loader, form } = this.state;

		if (loader) return;

		this.setState({ loader: true });
		const response = await actions.card.create({ udfs: regulatoryData, ...form }, history);
		this.mounted && this.setState({ loader: false });
		const update = { loader: false };

		if (response && response.errors) {
			update.errors = { ...this.state.errors, ...response.errors };
			if (response.messages) update.messages = response.messages;
			this.mounted && this.setState(update);
			return;
		}

		this.mounted && this.setState({ loader: false, current: 2 });

		// API returns all cards on response so we get the last one
		const lastCard = response.cardholderIds.length - 1;
		const { deliveryDate, id } = response.cardholderIds[lastCard];

		actions.card.get(id, current).then((data) => {
			let isVirtual = false;

			if (data) {
				isVirtual = isVirtualCard(data);
			}

			history.push(cardCreateSuccessUrl, { deliveryDate, isVirtual });
		});
	};

	mounted = false

	render () {
		const { current, user, userPhone, userCountry, cards, intl } = this.props;
		const { form, errors, address, loader } = this.state;
		const t = intl.formatMessage;
		const cardsSelect = cards.map(card => ({ title: card.cardNumber, value: card.id }));

		if (cardsSelect && !!cardsSelect.length) form.card = cardsSelect[0].value;

		return (
			<Wrap>
				<Form>
					<Form.Group>
						<InputSelect
							data={deliveryTypeOptions}
							name='deliveryType'
							placeholder={t(M.inputs.cardType)}
							onChange={this.onChange}
							value={form.deliveryType}
							errors={errors.deliveryType}
						/>
						{form.deliveryType && form.deliveryType === deliveryTypes.VIRTUAL && (
							<span>*You can upgrade your virtual card to a plastic one at any time</span>
						)}
					</Form.Group>
					<Form.Group>
						<InputSelect
							data={cardsSelect}
							name="card"
							placeholder={t(M.inputs.cardNumber)}
							onChange={this.onChange}
							value={form.card}
							errors={errors.card}
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="name"
							placeholder={t(M.inputs.nameoncard)}
							onChange={this.onChange}
							value={current.name}
							disabled
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="country"
							placeholder={t(M.inputs.country)}
							onChange={this.onChange}
							value={userCountry}
							disabled
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="phone"
							placeholder="Phone"
							onChange={this.onChange}
							value={`${userPhone} ${user.phone}`}
							disabled
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="address"
							placeholder={t(M.inputs.address)}
							onChange={this.onChange}
							value={`${address.line1}, ${address.city}, ${address.postalCode}`}
							disabled
						/>
					</Form.Group>
				</Form>
				<RegulatoryDataCollectionForm
				    id={CREATE_ME_REGULATORY_FORM_ID}
					disabled={loader}
					onSubmit={this.onSubmit}
					button={(
						<Button form loading={loader} small>
							{t(M.buttons.add)}
						</Button>
					)}
				/>
			</Wrap>
		);
	}
}

export default CardCreateMe;
