import moment from 'moment';

const calendar = (date, range) => {
	const today = moment(new Date());
	const current = moment(date);

	const year = current.year();
	const month = current.month();

	const start = current.isoWeekday() - 1; // weekday of start
	const days = current.daysInMonth();

	// calendar in arrays
	const content = [];
	let g = 1;
	for (let i = 0; g <= days && i < 8; i++) { // runs while g < days
		// new week
		content[i] = [];

		for (let k = 0; k < 7; k++) {
			if ((i === 0 && k < start) || g > days) {
				content[i].push(null);
			} else {
				const timestamp = new Date(year, month, g);

				content[i].push({
					timestamp,
					today: (today.isSame(timestamp, 'day')),
					range: (range && range.isSame(timestamp, 'day')),
				});

				g++;
			}
		}
	}

	return content;
};

export default {
	calendar,
};
