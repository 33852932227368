import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "components/Button";
import * as cardActions from "actions/card";
import * as toastActions from 'actions/toast';
import { getById } from "selectors/card";

import currentCardTypes from "types/currentCard";
import { cardActionsTypes } from "types/actions";
import intlTypes from "../../../../types/intl";
import { getIsUserVerified } from "../../../../selectors/user";
import VerifyCardModal from "../../../../sections/Dashboard/VerifyCardModal";
import historyTypes from "../../../../types/history";

const DOCUMENT_URL = "/settings/document";

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id),
	isUserVerified: getIsUserVerified(state)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class GetPlastic extends React.PureComponent {
	static propTypes = {
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired
		}).isRequired,
		intl: intlTypes.isRequired,
		isUserVerified: PropTypes.bool,
		toggleSuccessModal: PropTypes.bool,
		history: historyTypes.isRequired
	};

	state = {
		isVerificationModalVisible: false,
		isLoading: false,
	};

	componentDidMount () {
		this.mounted = true;
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onGetPlasticCardClick = async () => {
		const {
			actions,
			isUserVerified,
			card,
			toggleSuccessModal
		} = this.props;

		if (!isUserVerified) {
			return this.setState({ isVerificationModalVisible: true });
		}

		this.setState({ isLoading: true });

		actions.card
			.updateVirtualToPlastic(card)
			.then(() => {
				this.setState({ isLoading: false });
				toggleSuccessModal();
			})
			.catch(e => console.error(e));
	};

	onVerificationSkip = () =>
		this.setState({ isVerificationModalVisible: false });

	onVerifyCard = () => this.props.history.push(DOCUMENT_URL);

	mounted = false;

	render () {
		const { isUserVerified } = this.props;
		const { isVerificationModalVisible, isLoading } = this.state;
		const t = this.props.intl.formatMessage;

		return (
			<div>
				<React.Fragment>
					{isVerificationModalVisible && !isUserVerified && (
						<VerifyCardModal
							t={t}
							subtitle=""
							onSkip={this.onVerificationSkip}
							onVerify={this.onVerifyCard}
						/>
					)}
				</React.Fragment>
				<p>{t({ id: "card.get.plastic.text" })}</p>
				<Button onClick={this.onGetPlasticCardClick} loading={isLoading}>
					<FormattedMessage id="card.get.plastic.button" />
				</Button>
			</div>
		);
	}
}

export default GetPlastic;
