import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import helpers from 'helpers';

import * as applicationsActions from 'actions/applications';
import * as clientSelector from 'selectors/client';
import * as clientActions from 'actions/client';
import * as toastActions from 'actions/toast';

import Title from 'components/Title';
import Form from 'components/Form';
import Button from 'components/Button';
import InputCheckbox from 'components/InputCheckbox';
import Table from 'components/Table';

import M from './Applications.locale.json';

import '../Setting.scss';



const mapState = () => ({
});

const mapDispatch = dispatch => ({
	actions: {
		applications: bindActionCreators(applicationsActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	constructor (props) {
		super(props);

		this.state = {
			loader: false,
			notification: false,
			applications: []
		};
	}

    static columns = [{
    	id: 'created',
    	title: M.columns.created,
    }, {
    	id: 'type',
    	title: M.columns.type,
    }, {
    	id: 'status',
    	title: M.columns.status,
    }];

    renderHeader = () => {
    	const t = this.props.intl.formatMessage;

    	return this.constructor.columns.map(item => ({
    		...item,
    		title: t(item.title),
    	}));
    };

    renderRow = row => (
    	<Table.Row
    		key={row.id}
	>
    		{
    			this.constructor.columns
    				.map(item => (
	<Table.Cell
	key={item.id}
    					>
	{helpers.formatter(item.id, row[item.id])}
    					</Table.Cell>
    				))
    		}
	</Table.Row>
    );

    componentDidMount () {
    	this.onMount();
    }

    onMount = async () => {
    	const { actions } = this.props;

    	this.setState({
    		loader: true,
    	});

    	const result = await actions.applications.get();
    	this.setState({
    		applications: result,
    		loader: false,
    	});
    };

    render () {
    	const t = this.props.intl.formatMessage;
    	const { applications, loader } = this.state;

    	return (
    		<div>
    			<Title
    				title={t(M.title)}
    				legend={t(M.legend)}
		className="SettingScreen__Title"
	/>

		<Table
	items={applications}
	renderHeader={this.renderHeader}
	renderRow={this.renderRow}
	className="ApplicationSection__Table"
	loading={loader}
    			/>
	</div>
    	);
    }
}
