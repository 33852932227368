import { CURRENCY_FETCH_SUCCESS } from 'constants';
import API from 'services/api';

export const fetch = () => async dispatch => {
	try {
		const result = await API.currency.fetch();
		dispatch({ type: CURRENCY_FETCH_SUCCESS, payload: result.data.response });
	} catch (error) { }
};

export const convert = params => async (dispatch, getState) => {
	try {
		const state = getState();
		const cid = state.card.current;
		const { data: { response } } = await API.currency.convert(cid, { cardholderId: cid, ...params });
		return response.amount;
	} catch (error) { }
};
