export default {
	businessName: {
		presence: { message: '^schemas.presence' },
	},
	registrationNumber: {
		presence: { message: '^schemas.presence' },
	},
	businessCountry: {
		presence: { message: '^schemas.presence' },
	},
	name: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 2,
			message: '^schemas.length.min',
		},
	},
	surname: {
		presence: {
			message: '^schemas.presence',
		},
		length: {
			minimum: 2,
			message: '^schemas.length.min',
		},
	},
	email: {
		presence: { message: '^schemas.presence' },
		email: { message: '^schemas.email' },
	},
	phone: {
		presence: { message: '^schemas.presence' },
		numericality: {
			message: 'can only contain numbers',
		},
		length: {
			minimum: 2,
			message: '^schemas.length.min',
		},
	},
	phoneCountry: {
		presence: { message: '^schemas.presence' },
	},
	password: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 8,
			maximum: 20,
			message: '^schemas.length.between',
		}
	},
	passwordConfirm: {
		presence: { message: '^schemas.presence' },
		equality: {
			attribute: 'password',
			message: '^schemas.password.match',
			comparator: (v1, v2) => v1 === v2
		},

	},
	agree: {
		presence: { message: '^schemas.presence' },
		inclusion: {
			within: [true],
			message: '^schemas.agree',
		}
	},
	legalRepresentative: {
		presence: { message: '^schemas.presence' },
		inclusion: {
			within: [true],
			message: '^schemas.agree',
		}
	}
};
