import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Create.scss';

export default function Create (props) {
	const { className, disabled, onClick } = props;
	return (
		<div
			className={classnames('CardCreate', className, disabled && 'CardCreate--Disabled')}
			onClick={onClick}
			role="button"
			tabIndex={0}
		>
			<div className="CardCreate__Button">
				{props.children}
			</div>
		</div>
	);
}

Create.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.any,
	className: PropTypes.string,
};
