import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from 'helpers';
import Loader from '../Loader';
import Icon from '../Icon';
import Row from './Row';
import Cell from './Cell';
import M from './Table.locale.json';
import './Table.scss';

function Table (props) {
	const { className, items, renderHeader, loading, empty,
		sort, onSort, renderRow, onClick } = props;
	const header = renderHeader().map(item => {
		const current = (sort && sort.id === item.id) ? item.id : null;
		return (
			<Cell
				key={item.id}
				onClick={onSort}
				item={item}
				current={current}
				sort={sort}
			>
				{item.title}
				{onSort && <Icon className="Table__Sort" name="caveat-down-small" /> }
			</Cell>
		);
	});

	const content = items && items.map((row, idx) => {
		if (renderRow) return props.renderRow(row, idx);

		return (
			<Row key={row.id} row={row} onClick={onClick}>
				{Object.entries(row).map(item => (
					<Cell key={item[0]}>
						{helpers.formatter(item[0], item[1])}
					</Cell>
				))}
			</Row>
		);
	});

	return (
		<div className={classnames('Table', className)}>
			<table className="Table__Table">
				<thead className="Table__Header">
					<tr>{header}</tr>
				</thead>

				<tbody className="Table__Body">
					{!loading && !!content.length && content}

					{loading && (
						<tr>
							<td className="Table__Empty" colSpan="100">
								<Loader className="Table__Loader" />
							</td>
						</tr>
					)}

					{!loading && !content.length &&
						<tr>
							<td className="Table__Empty" colSpan="100">
								<div className="Table__Message">
									{empty || <FM id={M.empty.id} />}
								</div>
							</td>
						</tr>
					}
				</tbody>
			</table>
		</div>
	);
}

Table.propTypes = {
	items: PropTypes.array,
	empty: PropTypes.any,
	sort: PropTypes.shape({
		id: PropTypes.string.number,
		name: PropTypes.string,
		asc: PropTypes.bool,
	}),
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	onSort: PropTypes.func,
	renderHeader: PropTypes.func,
	renderRow: PropTypes.func,
	className: PropTypes.string,
};

Table.Row = Row;
Table.Cell = Cell;

export default Table;
