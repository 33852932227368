import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as passwordActions from 'actions/password';

import SettingSchema from 'schemas/setting';

import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import PasswordPolicy from 'components/PasswordPolicy';

import M from './Password.locale.json';

// import "./Password.scss";



const mapState = () => ({});

const mapDispatch = dispatch => ({
	actions: {
		password: bindActionCreators(passwordActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,

		form: {
			current: '',
			new: '',
			confirm: '',
		},

		errors: {
			current: [],
			new: [],
			confirm: [],
		}
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, SettingSchema.password);
		}

		this.setState({
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
		});
	};

	onSubmit = async e => {
		e.preventDefault();

		const { actions } = this.props;
		const { form, loader } = this.state;

		if (loader) return;

		const errors = helpers.validator.all(form, SettingSchema.password);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return;
		}

		this.setState({
			loader: true,
		});

		const response = await actions.password.edit(form);

		if (response && response.errors) {
			this.setState({
				loader: false,
			});

			return false;
		}

		this.setState({
			loader: false,
			form: {
				current: '',
				new: '',
				confirm: '',
			},
		});
	};

	render () {
		const { errors, form, loader } = this.state;

		const t = this.props.intl.formatMessage;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="SettingScreen__Title"
				/>

				<Form.Note>{t(M.messages.note)}</Form.Note>

				<Form
					onSubmit={this.onSubmit}
				>
					<Form.Group>
						<Input
							name="current"
							placeholder={t(M.inputs.password.current)}
							onChange={this.onChange}
							value={form.current}
							errors={errors.current}
							type="password"
							autoFocus
						/>
					</Form.Group>

					<Form.Group
						row
					>
						<Input
							name="new"
							placeholder={t(M.inputs.password.new)}
							onChange={this.onChange}
							value={form.new}
							type="password"
							errors={errors.new}
						/>

						<PasswordPolicy
							value={form.new}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="confirm"
							placeholder={t(M.inputs.password.repeat)}
							onChange={this.onChange}
							value={form.confirm}
							type="password"
							errors={errors.confirm}
						/>
					</Form.Group>

					<Button
						className="SettingScreen__Submit"
						loading={loader}
						small
						form
					>
						{t(M.buttons.change)}
					</Button>
				</Form>
			</div>
		);
	}
}
