export default function contact (data) {
	const convert = {
		...data,
	};

	if (data.name) {
		convert.firstName = data.name;
		delete convert.name;
	}
	if (data.surname) {
		convert.lastName = data.surname;
		delete convert.surname;
	}
	return convert;
}
