import auth from './auth';
import user from './user';
import client from './client';
import business from './business';
import shareholder from './shareholder';
import document from './document';
import password from './password';
import transaction from './transaction';
import member from './member';

export default {
	auth,
	user,
	client,
	business,
	shareholder,
	document,
	password,
	transaction,
	member,
};
