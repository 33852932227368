import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import helpers from 'helpers';
import reduxer from 'helpers/reduxer';

import title from '../../../../services/title';

import * as clientActions from 'actions/client';
import * as countryActions from 'actions/country';

import * as countrySelector from 'selectors/country';

import Client from 'schemas/client';

import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import InputSelect from 'components/InputSelect';
import Banner from 'components/Banner';
import Tip from 'components/Tip';
import Tab from 'components/Tab';

import M from './Contact.locale.json';



const mapState = (state, props) => ({
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props),
	form: state.client.business.form.contact,
});

const mapDispatch = dispatch => ({
	actions: {
		client: reduxer.bindNestedActionCreators(clientActions, dispatch),
		country: bindActionCreators(countryActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Contact extends PureComponent {
	state = {
		loader: false,
		errors: {
			title: [],
			name: [],
			surname: [],
			position: [],
			email: [],
			phoneCountry: [],
			phone: [],
		},
		messages: [],
	}

	componentDidMount () {
		const { actions, country } = this.props;

		actions.client.business.get('contact');
		actions.country.fetch();
	}

	onChange = (value, name) => {
		const { errors } = this.state;
		const { actions, form } = this.props;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Client.create.contact);
		}

		this.setState({
			errors: {
				...errors,
				[name]: messages,
			},
		});

		actions.client.business.form({
			[name]: value,
		}, 'contact');
	};

	onSubmit = async e => {
		e.preventDefault();
		const { loader } = this.state;
		const { history, form, actions, match } = this.props;

		const { id } = match.params;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, Client.create.contact);

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
				loader: false,
			});

			return;
		}

		this.setState({
			loader: true,
		});

		const request = (form.id ? actions.client.business.edit : actions.client.business.createContact);

		const response = await request(form, 'contact');

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loader: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState({
			loader: false,
		});

		history.push(`/clients/create/business/${id}/shareholders`);
	};

	render () {
		const { loader, errors, messages } = this.state;
		const { form, current, phoneCountry, step, onPrev } = this.props;
		const titles = title.list();

		const t = this.props.intl.formatMessage;

		return (
			<div
				className="ClientCreateBusinessScreen__Step"
			>
				<Form onSubmit={this.onSubmit}>
					<Form.Note>
						{t(M.messages.note)}
					</Form.Note>

					{
						(messages && messages.length > 0) &&
						<Banner type="error" data={messages} />
					}

					<Form.Group>
						<InputSelect
							name="title"
							onChange={this.onChange}
							placeholder={t(M.inputs.title)}
							value={form.title}
							errors={errors.title}
							data={titles}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="name"
							placeholder={t(M.inputs.name)}
							onChange={this.onChange}
							value={form.name}
							errors={errors.name}
							autoFocus
						/>

						<Input
							name="surname"
							placeholder={t(M.inputs.surname)}
							onChange={this.onChange}
							value={form.surname}
							errors={errors.surname}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="position"
							placeholder={t(M.inputs.position)}
							onChange={this.onChange}
							value={form.position}
							errors={errors.position}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="email"
							placeholder={t(M.inputs.email)}
							onChange={this.onChange}
							value={form.email}
							errors={errors.email}
						/>
					</Form.Group>

					<Form.Group>
						<InputSelect
							name="phoneCountry"
							onChange={this.onChange}
							placeholder={t(M.inputs.phoneCountry)}
							value={form.phoneCountry}
							errors={errors.phoneCountry}
							data={phoneCountry}
						/>
						<Input
							name="phone"
							placeholder={t(M.inputs.phone)}
							onChange={this.onChange}
							value={form.phone}
							errors={errors.phone}
						/>
					</Form.Group>

					<Button
						className="Stepper__Button"
						loading={loader}
						form
						small
					>
						<span>4/5</span> {t(M.buttons.next)}
					</Button>

					<Button
						className="Stepper__Button"
						disabled={loader}
						onClick={() => onPrev()}
						small
						secondary
					>
						{t(M.buttons.back)}
					</Button>
				</Form>
			</div>
		);
	}
}
