const wrap = data => {
	if (typeof Object.prototype.toString.call(data) !== 'object') return data;

	const convert = {};

	Object.keys(data).forEach(key => {
		if (!data[key]) return;

		convert[key] = data[key];
	});

	return convert;
};

export default {
	wrap,
};
