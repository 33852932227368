import user from './user';
import client from './client';
import wrap from './wrap';
import clean from './clean';
import shareholder from './shareholder';
import document from './document';
import email from './email';
import card from './card';
import member from './member';
import transaction from './transaction';
import bank from './bank';
import country from './country';
import payment from './payment';

export default {
	user,
	client,
	wrap,
	clean,
	shareholder,
	document,
	email,
	card,
	member,
	transaction,
	bank,
	country,
	payment,
};
