import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as avatarActions from 'actions/avatar';

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Form from 'components/Form';
import Input from 'components/Input';
import NavVertical from 'components/NavVertical';
import Wrap from 'components/Wrap';
import InputAvatar from 'components/InputAvatar';

// import "./Account.scss";



const mapState = (state, props) => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		avatar: bindActionCreators(avatarActions, dispatch),
	},
});

@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loaderAvatar: false,

		errors: {
			avatar: [],
		}
	}

	nav = [{
		id: 'account',
		title: 'Account',
		icon: 'account',
	}, {
		id: 'interface',
		title: 'Interface',
		icon: 'interface',
	}, {
		id: 'phone',
		title: 'Phone',
		icon: 'phone',
	}, {
		id: 'password',
		title: 'Password',
		icon: 'password',
	}]

	onNav = idx => {
		const { history } = this.props;

		const path = this.nav[idx].id;

		history.push(`/settings/${path}`);
	}

	onUpload = async file => {
		const { actions } = this.props;
		const { loaderAvatar } = this.state;

		if (loaderAvatar) return;

		this.setState({
			loaderAvatar: true,
		});

		const response = await actions.avatar.create(file);

		if (response && response.errors) {
			const update = {
				errors: {
					...this.state.errors,
					...response.errors,
				},
				loaderAvatar: false,
			};

			if (response.messages) update.messages = response.messages;

			this.setState(update);

			return false;
		}

		this.setState({
			loaderAvatar: false,
		});

		return response;
	}

	onRemove = async id => {
		const { actions } = this.props;
		const { loaderAvatar } = this.state;

		if (loaderAvatar) return;

		this.setState({
			loaderAvatar: true,
		});

		await actions.avatar.remove();

		this.setState({
			loaderAvatar: false,
		});
	}

	render () {
		const { user } = this.props;
		const { loaderAvatar, errors } = this.state;

		return (
			<Section className="SettingAccountSection">
				<Container>
					<NavVertical
						className="SettingSection__Nav"
						items={this.nav}
						onClick={this.onNav}
						active={0}
					/>

					<Wrap>
						<Title
							title="Settings"
							legend="Account"
							className="SettingScreen__Title"
						/>

						<InputAvatar
							user={user}
							onUpload={this.onUpload}
							onRemove={this.onRemove}
							name="avatar"
							loading={loaderAvatar}
							errors={errors.avatar}
						/>

						<Form.Group>
							<Input
								name="name"
								placeholder="Name"
								value={user.name}
								disabled
							/>

							<Input
								name="surname"
								placeholder="Surname"
								value={user.surname}
								disabled
							/>
						</Form.Group>

						<Form.Group>
							<Input
								name="email"
								placeholder="Email"
								value={user.email}
								disabled
							/>
						</Form.Group>
					</Wrap>
				</Container>
			</Section>
		);
	}
}
